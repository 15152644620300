
import { defineComponent } from 'vue';
import Loader from '../../atoms/Loader/Loader.vue';
import Tooltip from '../../atoms/Tooltip/Tooltip.vue';

export default defineComponent({
  components: {
    'arc-loader': Loader,
    'arc-tooltip': Tooltip,
  },
  props: {
    multiBrand: {
      type: Boolean,
      default: false,
    },
    locationOptions: {
      type: Array,
      required: true,
    },
    filteredLocationSelections: {
      type: Array,
      required: true,
    },
    locationSelections: {
      type: Array,
      required: true,
    },
    attemptedSubmit: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPreviewOpen: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    borderStyle(): string {
      return this.attemptedSubmit && (!this.locationSelections || this.locationSelections?.length === 0)
        ? 'border: 1px hsl(4, 76%, 59%) solid;'
        : 'border: 1px hsl(0, 0%, 84%) solid;';
    },
    loaderStyle(): string {
      let style = '';
      if (this.isPreviewOpen) {
        style = 'transform: translate(-21%, 4rem);';
      } else {
        style = 'transform: translate(1%, 4rem);';
      }
      return style;
    },
  },
  methods: {
    locationInfo(location: any): string {
      const brand = this.multiBrand ? `${location?.brand?.description} - ` : '';
      const storeNumber = location?.storeNumber;
      const storeIdentifier = location?.storeIdentifier ? ` (${location?.storeIdentifier})` : '';
      const mall = location?.mall?.description;
      const city = location?.mall?.city;
      const province = location?.mall?.province ? `${location?.mall?.province}, ` : '';
      const country = location?.mall?.country;
      return `${brand}${storeNumber}${storeIdentifier}, ${mall}, ${city}, ${province}${country}`;
    },
  },
});
