/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';
import { IUser } from '../../../../interfaces/src/v2';
import { IAccess, IPermissions } from '../../../../interfaces/src/v2';
import HttpService from '@/services/HttpService';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/vue';
import { setAccess, setPermissions } from '@/utilities/Permissions/permission-utillty';
import captureExceptionInSentry from './errorHandler';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const UserModule = {
  state(): UserState {
    return {
      user: null,
      access: null,
      permissions: null,
      permissionTemplates: null,
      forceLogout: false,
    };
  },

  mutations: {
    updateUser(state: UserState, payload: IUser): void {
      state.user = payload;
    },
    updateAccess(state: UserState, payload: IAccess): void {
      state.access = payload;
      console.log('ACCESS: ', state.access);
    },
    updatePermissions(state: UserState, payload: IPermissions): void {
      state.permissions = payload;
      console.log('PERMISSIONS: ', state.permissions);
    },
    updatePermissionTemplates(state: UserState, payload: any[]): void {
      state.permissionTemplates = payload;
    },
    updateForceLogout(state: UserState, payload: boolean): void {
      state.forceLogout = payload;
    },
  },

  actions: {
    fetchUser(context: ActionContext<UserState, State>, userId: string) {
      new HttpService()
        .getUser(userId)
        .then(async (records: AxiosResponse<Response>) => {
          context.commit('updateUser', records.data.data);
          context.commit('updateAccess', setAccess(records.data.data));
          context.commit('updatePermissions', setPermissions(records.data.data));
        })
        .catch((err: any) => {
          context.commit('updateForceLogout', true);
          errorMessage = `Error: ${err.message || 'Unknown error'}. Payload: ${userId}`;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        });
    },
    fetchPermissionTemplates(context: ActionContext<UserState, State>, userId: string): void {
      new HttpService()
        .getPermissionTemplates()
        .then((records: AxiosResponse<Response>) => {
          context.commit('updatePermissionTemplates', records.data.data);
        })
        .catch((err: any) => {
          Sentry.captureMessage(JSON.parse(err?.request?.response));
        });
    },
    updateCurrentUser(context: ActionContext<UserState, State>, payload: IUser): void {
      context.commit('updateUser', payload);
      context.commit('updateAccess', setAccess(payload));
      context.commit('updatePermissions', setPermissions(payload));
    },
    updateForceLogout(context: ActionContext<UserState, State>, payload: boolean): void {
      context.commit('updateForceLogout', payload);
    },

    saveUserEmailTemplate(context: ActionContext<UserState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveUserEmailTemplate(payload)
          .then((records: AxiosResponse<Response>) => {
            resolve(records.data.data);
          })
          .catch((err: any) => {
            reject();
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          });
      });
    },

    saveUserEmailPreference(context: ActionContext<UserState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveUserEmailPreference(payload)
          .then((records: AxiosResponse<Response>) => {
            resolve(records.data.data);
          })
          .catch((err: any) => {
            reject();
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          });
      });
    },
  },

  getters: {
    user(state: UserState): IUser | null {
      return state.user;
    },
    access(state: UserState): IAccess | null {
      return state.access;
    },
    permissions(state: UserState): IPermissions | null {
      return state.permissions;
    },
    permissionTemplates(state: UserState) {
      return state.permissionTemplates;
    },
    forceLogout(state: UserState): boolean {
      return state.forceLogout;
    },
  },
};
