import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Sidebar from './atomic/organisms/Sidebar/Sidebar.vue';
import Footer from './atomic/organisms/Footer/Footer.vue';
import Notifications from '@kyvg/vue3-notification';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as Sentry from '@sentry/vue';

import './assets/styles/styles.scss';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(faFileArrowDown, faTrashCan, faRepeat);

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://897802f8bf52414b8d50cccd9b8716e6@o4503926966255616.ingest.sentry.io/4504164191567872',
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  logErrors: true,
  environment: process.env.VUE_APP_ENV === 'production' ? 'production' : process.env.VUE_APP_ENV === 'staging' ? 'staging' : 'local',
});

app
  .use(store)
  .use(VueAxios, axios)
  .use(Notifications)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Sidebar', Sidebar)
  .component('ContentFooter', Footer)
  .use(router)
  .mount('#app');
