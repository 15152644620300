import { MarketingCampaign, RecruitmentCampaign, MallMemo, StoreMemo } from '@/utilities/Types/communication.types';
import { ModalState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';
import router from '@/router';

export const ModalModule = {
  state(): ModalState {
    return {
      modalOpen: false,
      modalType: '',
      modalComponent: '',
      modalData: null,
      closingModal: false,
    };
  },

  mutations: {
    openModal(state: ModalState): void {
      state.modalOpen = true;
    },
    closeModal(state: ModalState): void {
      state.modalOpen = false;
      state.modalType = '';
      state.modalComponent = '';
      state.modalData = null;
    },
    toggleModal(state: ModalState): void {
      state.modalOpen = !state.modalOpen;
    },
    updateModalType(state: ModalState, payload: string): void {
      state.modalType = payload;
    },
    updateModalComponent(state: ModalState, payload: string): void {
      state.modalComponent = payload;
    },
    updateModalData(state: ModalState, payload: MarketingCampaign | RecruitmentCampaign | MallMemo | StoreMemo | null): void {
      state.modalData = payload;
    },
    updateClosingModal(state: ModalState, payload: boolean): void {
      state.closingModal = payload;
    },
  },

  actions: {
    openModal(context: ActionContext<ModalState, State>): void {
      context.commit('openModal');
    },
    closeModal(context: ActionContext<ModalState, State>): void {
      context.commit('closeModal');
      // clear url query params on modal close
      router.replace({ query: {} });
    },
    toggleModal(context: ActionContext<ModalState, State>): void {
      context.commit('toggleModal');
    },
    updateModalType(context: ActionContext<ModalState, State>, payload: string): void {
      context.commit('updateModalType', payload);
    },
    updateModalComponent(context: ActionContext<ModalState, State>, payload: string): void {
      context.commit('updateModalComponent', payload);
    },
    updateModalData(context: ActionContext<ModalState, State>, payload: MarketingCampaign | RecruitmentCampaign | MallMemo | StoreMemo | null): void {
      context.commit('updateModalData', payload);
    },
    updateClosingModal(context: ActionContext<ModalState, State>, payload: boolean): void {
      context.commit('updateClosingModal', payload);
    },
  },

  getters: {
    modalOpen(state: ModalState): boolean {
      return state.modalOpen;
    },
    modalType(state: ModalState): string {
      return state.modalType;
    },
    modalComponent(state: ModalState): string {
      return state.modalComponent;
    },
    modalData(state: ModalState): MarketingCampaign | RecruitmentCampaign | MallMemo | StoreMemo | null {
      return state.modalData;
    },
    closingModal(state: ModalState): boolean {
      return state.closingModal;
    },
  },
};
