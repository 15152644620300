
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {},
    placeholder: {
      type: String,
    },
    status: {
      type: String,
    },
    message: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: String,
    },
    inputTitle: {
      type: String,
    },
  },

  computed: {
    inputStatus(): {
      'input--success': boolean;
      'input--warn': boolean;
      'input--error': boolean;
    } {
      return {
        'input--success': this.status === 'success',
        'input--warn': this.status === 'warn',
        'input--error': this.status === 'error',
      };
    },
  },
  methods: {
    checkboxClicked(): void {
      this.$emit('checkboxClicked');
    },
  },
});
