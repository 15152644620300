/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { EmailState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { IEmail } from '../../../../interfaces/src/v2/index';
import * as Sentry from '@sentry/vue';
import { Status } from '@/enums/Status';
import captureExceptionInSentry from './errorHandler';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const EmailModule = {
  state(): EmailState {
    return {
      emails: [],
      loadingEmails: false,
    };
  },

  mutations: {
    clearEmails(state: EmailState): void {
      state.emails = [];
    },
    updateEmails(state: EmailState, payload: IEmail[]): void {
      state.emails = payload;
    },
    updateLoadingEmails(state: EmailState, payload: boolean): void {
      state.loadingEmails = payload;
    },
  },

  actions: {
    clearEmailConnections(context: ActionContext<EmailState, State>): void {
      context.commit('clearEmails');
    },

    async fetchEmailConnections(
      context: ActionContext<EmailState, State>,
      payload: { page?: number; limit?: number; search?: string; sortKey?: string; sortDirection?: string },
    ): Promise<any> {
      context.commit('updateLoadingEmails', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getEmails(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((email: IEmail) => {
          //
        });
        context.commit(
          'updateEmails',
          records.data.data.filter((email: IEmail) => email.statusId !== Status.Deleted),
        );
        context.commit('updateLoadingEmails', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingEmails', false);
      }
    },

    async fetchEmailConnection(context: ActionContext<EmailState, State>, payload: { emailId: string }): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().getEmailById(payload.emailId);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    saveEmailConnection(context: ActionContext<EmailState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveEmail(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchEmails');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    updateEmailConnection(context: ActionContext<EmailState, State>, payload: { payload: any; emailId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateEmail(payload.payload, payload.emailId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchEmails');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    disableEmailConnection(context: ActionContext<EmailState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .disableEmail(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchEmails');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },
  },

  getters: {
    emails(state: EmailState): IEmail[] {
      return state.emails;
    },
    loadingEmails(state: EmailState): boolean {
      return state.loadingEmails;
    },
  },
};
