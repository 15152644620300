
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../../atoms/Input/Input.vue';
import Search from '../../../../../atoms/Search/Search.vue';
import Dropdown from '../../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import TextArea from '../../../../../atoms/Text-Area/Text-Area.vue';
import Checkbox from '../../../../../atoms/Checkbox/Checkbox.vue';
import Button from '../../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../../atoms/Modal/Modal.vue';
import DatePicker from '../../../../../molecules/DatePicker/DatePicker.vue';
import SingleSelectInputSuggestion from '../../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import LocationSelector from '../../../../../molecules/Locations-Selector/Location-Selector.vue';
import LanguageSelector from '../../../../../molecules/Language-Selector/Language-Selector.vue';
import Carousel from '../../../../../molecules/Carousel/Carousel.vue';
import { ILanguage, IStore, ITag } from '../../../../../../../../../interfaces/src/v2/index';
import { FormBuilder, FormControl } from '../../../../../../utilities/Forms';
import { Validators } from '../../../../../../utilities/Validators/Validators';
import { dateFormatter } from '../../../../../../utilities/Functions/formatting';
import { IDropdownOption } from '../../../../../../utilities/Interfaces/form.interfaces';
import FileUploader from '../../../../../molecules/File-Uploader/File-Uploader.vue';
import Divider from '../../../../../atoms/Divider/Divider.vue';
import Chip from '../../../../../atoms/Chip/Chip.vue';
import Loader from '../../../../../atoms/Loader/Loader.vue';
import AttachmentDownloader from '../../../../../atoms/Attachment-Downloader/Attachment-Downloader.vue';
import dayjs from 'dayjs';
import { CommunicationTypes } from '@/enums/CommunicationTypes';
import { IANA_TIMEZONES } from '../../../../../../utilities/Interfaces/timezone.interface';
import { Status } from '@/enums/Status';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';
import { AbortHandler } from '@/services/AbortHandler';
import { MemoSubtype } from '@/enums/MemoSubtype';
import { Role } from '@/enums/Role';

dayjs.extend(timezone);
dayjs.extend(utc);

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-search': Search,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-text-area': TextArea,
    'arc-checkbox': Checkbox,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-language-selector': LanguageSelector,
    'arc-date-picker': DatePicker,
    'arc-carousel': Carousel,
    'arc-location-selector': LocationSelector,
    'arc-file-uploader': FileUploader,
    'arc-divider': Divider,
    'arc-chip': Chip,
    'arc-loader': Loader,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-attachment': AttachmentDownloader,
    'arc-multi-select-input': MultiSelectInput,
  },

  computed: {
    MemoSubtype() {
      return MemoSubtype;
    },
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'companyOptions',
      'brandOptions',
      'mallOptions',
      'userOptions',
      'storeOptions',
      'memoSubtypeOptions',
      'availableBrandSpecificTags',
      'selectedStoreMemo',
      'loadingStores',
      'firstDay',
      'lastDay',
    ]),
    filteredLocations(): any {
      // set all location options
      let locations = this.storeOptions;

      const campaignTypeFilter: any[] = [];
      locations?.forEach((location: any) => {
        location?.mall?.communicationTypes?.forEach((commType: any) => {
          if (commType?.communicationTypeId === CommunicationTypes['Store Memo']) {
            campaignTypeFilter.push(location);
          }
        });
      });
      locations = campaignTypeFilter;
      let tagCount: any = this.tagValues?.length;
      // filter tags
      if (tagCount > 0) {
        const tagIds = this.tagValues?.map((tag: any) => tag.value);
        // filter locations by tags
        locations = locations.filter((location: any) => {
          const locationTags = location?.tags?.map((tag: any) => tag.tagId);
          if (this.tagComboType.value === 'or') {
            return tagIds?.some((lt: number) => locationTags?.includes(lt));
          } else if (this.tagComboType.value === 'and') {
            return tagIds?.every((lt: number) => locationTags?.includes(lt));
          }
        });
      }

      // filter search params
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        locations.forEach((element: any) => {
          let matchFound = false;
          if (typeof Object.values(element) === 'string') {
            if (Object.values(element).includes(this.searchFilter)) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'number') {
            if (
              Object.values(element)
                .toString()
                .includes(this.searchFilter)
            ) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'object' && Object.values(element)) {
            Object.values(element)
              .filter((value) => value)
              .forEach((nestedElement: any) => {
                if (typeof nestedElement === 'string') {
                  if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'number') {
                  if (nestedElement.toString().includes(this.searchFilter)) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'object' && nestedElement) {
                  Object.values(nestedElement)
                    .filter((value) => value)
                    .forEach((nestedElementL2: any) => {
                      if (typeof nestedElementL2 === 'string') {
                        if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'number') {
                        if (nestedElementL2.toString().includes(this.searchFilter)) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                        Object.values(nestedElementL2)
                          .filter((value) => value)
                          .forEach((nestedElementL3: any) => {
                            if (typeof nestedElementL3 === 'string') {
                              if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                matchFound = true;
                              }
                            }
                            if (typeof nestedElementL3 === 'number') {
                              if (nestedElementL3.toString().includes(this.searchFilter)) {
                                matchFound = true;
                              }
                            }
                          });
                      }
                    });
                }
              });
          }
          if (matchFound) {
            searchResults.push(element);
          }
        });
        locations = searchResults;
      }
      locations = locations?.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      return locations;
    },
    filteredLocationsAvailable(): any {
      return this.filteredLocations.filter((location: any) => !this.selectedLocationIds?.includes(location?.storeId));
    },
    filteredLocationsSelected(): any {
      return this.filteredLocations.filter((location: any) => this.selectedLocationIds?.includes(location?.storeId));
    },
    updateDisabled(): boolean {
      const userStoreIds = this.user?.stores.map((store: any) => store.storeId);
      let ds = false;
      if (userStoreIds?.length > 0) {
        if (this.formData && this.formData?.controls?.locations?.value?.length > 0) {
          this.formData?.controls?.locations?.value?.forEach((store: IStore) => {
            if (!userStoreIds.includes(store.storeId)) {
              ds = true;
            }
          });
        }
        if (
          (this.permissions &&
            this.modalData?.row &&
            !this.permissions?.canUpdateAllStoreMemos &&
            (!this.permissions?.canUpdateOwnedStoreMemos ||
              (this.permissions?.canUpdateOwnedStoreMemos && this.user?.userId !== this.modalData?.row?.createdByUserId))) ||
          ds
        ) {
          if (this.initialLoad) {
            this.$notify({
              title: 'UPDATE PERMISSIONS DISABLED',
              text: 'There are locations in this campaign that you do not have permission to update.',
              type: 'warn',
              duration: 5000,
            });
          }
        }
      }
      return (
        (this.permissions &&
          this.modalData?.row &&
          !this.permissions?.canUpdateAllStoreMemos &&
          (!this.permissions?.canUpdateOwnedStoreMemos ||
            (this.permissions?.canUpdateOwnedStoreMemos && this.user?.userId !== this.modalData?.row?.createdByUserId))) ||
        ds
      );
    },
    deleteDisabled(): boolean {
      const userStoreIds = this.user?.stores.map((store: any) => store.storeId);
      let ds = false;
      if (userStoreIds?.length > 0) {
        if (this.formData && this.formData?.controls?.locations?.value?.length > 0) {
          this.formData?.controls?.locations?.value?.forEach((store: IStore) => {
            if (!userStoreIds.includes(store.storeId)) {
              ds = true;
            }
          });
        }
      }
      return (
        (this.permissions &&
          this.modalData?.row &&
          !this.permissions?.canDeleteAllStoreMemos &&
          (!this.permissions?.canDeleteOwnedStoreMemos ||
            (this.permissions?.canDeleteOwnedStoreMemos && this.user?.userId !== this.modalData?.row?.createdByUserId))) ||
        ds
      );
    },
    duplicateDisabled(): boolean {
      let oldEaExists = false;
      const creationDate = new Date(this.memo?.createdAt);
      const ea3Date = new Date('June 06, 2022');
      if (creationDate < ea3Date) {
        oldEaExists = true;
      }
      return oldEaExists;
    },
    headline(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.headline.value, this.activeLanguage?.isoCode);
    },
    description(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.description.value, this.activeLanguage?.isoCode);
    },
    selectCompanyPlaceholder(): string {
      if (this.memoSubtypeValue.value === MemoSubtype.Store) {
        return this.brandOptions && this.brandOptions.length > 0 ? 'Select' : 'Select Company First';
      } else if (this.memoSubtypeValue.value === MemoSubtype.Retailer) {
        return this.userOptions && this.userOptions.length > 0 ? 'Select' : 'Select Mall First';
      } else {
        return 'Select';
      }
    },
    containerType(): string {
      if (this.permissions?.superAdminAccess) {
        return 'admin-container';
      } else if (this.isMallAdmin()) {
        return 'mall-admin-container';
      } else {
        return 'user-container';
      }
    },
    contentContainerStyle(): string {
      return 'width: 70%;';
    },
    duplicationTooltipText(): string {
      return this.duplicating ? 'Duplicating' : 'Nothing to Duplicate';
    },
    searchLocationsStyle(): string {
      return this.availableBrandSpecificTags?.length > 0 ? 'grid-column-end: span 15;' : 'grid-column-end: span 30;';
    },
  },

  watch: {
    'formData.controls.locations.value': {
      deep: true,
      handler(locations) {
        this.extractDataFromLocations(locations);
      },
    },
    'formData.controls.startDate.value': {
      deep: true,
      handler() {
        if (this.formData?.controls?.startDate?.value && this.formData?.controls?.endDate?.value && this.canCheckDates) {
          let d1: any = new Date(this.formData?.controls?.startDate?.value);
          let d2: any = new Date(this.formData?.controls?.endDate?.value);
          if (this.daysBetween(d1, d2) >= 30) {
            this.$notify({
              title: 'CHECK YOUR DATES',
              text:
                'We noticed the time between your Start Date and End Date are greater than one month.  While you can submit a memo for any length that, as a courtesy, if your memo is supposed to be less than one month, please re-check your dates entered.  If your dates are correct, please continue.',
              type: 'warn',
              duration: 5000,
            });
          }
        }
      },
    },
    'formData.controls.endDate.value': {
      deep: true,
      handler() {
        if (this.formData?.controls?.startDate?.value && this.formData?.controls?.endDate?.value && this.canCheckDates) {
          let d1: any = new Date(this.formData?.controls?.startDate?.value);
          let d2: any = new Date(this.formData?.controls?.endDate?.value);
          if (this.memo?.statusId === 2 && this.isToday(d2)) {
            this.$notify({
              title: 'EXPIRING CAMPAIGN',
              text:
                'If you would like to expire the campaign now, please click the expire button below. If you would like the campaign to expire EOD, please click the udpate button below when you are finished your updates.',
              type: 'warn',
              duration: 15000,
            });
          }
          if (this.daysBetween(d1, d2) >= 30) {
            this.$notify({
              title: 'CHECK YOUR DATES',
              text:
                'We noticed the time between your Start Date and End Date are greater than one month.  While you can submit a memo for any length that, as a courtesy, if your memo is supposed to be less than one month, please re-check your dates entered.  If your dates are correct, please continue.',
              type: 'warn',
              duration: 15000,
            });
          }
        }
      },
    },
    companyOptions: {
      deep: true,
      handler() {
        this.allowShowOptions = true;
        this.companyOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.company?.value
              ? this.formData?.controls?.company?.value === item?.value
              : this.selectedStoreMemo?.brand?.companyId === item?.value
          ) {
            this.updateCompany(item);
          }
        });
      },
    },
    brandOptions: {
      deep: true,
      handler() {
        this.brandOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.brand?.value
              ? this.formData?.controls?.brand?.value === item?.value
              : this.selectedStoreMemo?.brand?.brandId === item?.value
          ) {
            this.brandValue = item;
            this.formData.controls.brand.value = this.brandValue?.value;
            this.loadBrandTags({ brandIds: this.formData.controls.brand.value });
          }
        });
      },
    },
    mallOptions: {
      deep: true,
      handler() {
        this.mallOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.mall?.value
              ? this.formData?.controls?.mall?.value === item?.value
              : this.selectedStoreMemo?.mall?.mallId === item?.value
          ) {
            this.updateMall(item);
            // this.loadmallTags({ mallIds: this.formData.controls.mall.value });
          }
        });
      },
    },
    memoSubtypeOptions: {
      deep: true,
      handler() {
        this.memoSubtypeOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.memoSubtype?.value
              ? this.formData?.controls?.memoSubtype?.value === item?.value
              : this.selectedStoreMemo?.memoSubtype === item?.value
          ) {
            this.updateMemoSubtype(item);
          }
        });
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
    user: {
      deep: true,
      handler() {
        if (this.permissions?.superAdminAccess) {
          this.formData.controls.company.validators = [Validators.required];
          this.formData.controls.user.validators = [Validators.required];
        }
      },
    },
    userOptions: {
      deep: true,
      handler() {
        this.userOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.user?.value
              ? this.formData?.controls?.user?.value === item?.value
              : this.selectedStoreMemo?.createdByUserId === item?.value
          ) {
            this.userValue = item;
          }
        });
      },
    },
    openEnded: {
      deep: true,
      handler() {
        if ((!this.openEnded || !this.options) && !this.editingQuestion) {
          this.options = [
            {
              description: '',
            },
            {
              description: '',
            },
          ];
        }
      },
    },
    availableBrandSpecificTags: {
      deep: true,
      handler() {
        this.memoTagValues = [];
        this.availableBrandSpecificTags.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.tags?.map((tag: ITag) => tag.tagId).includes(item.value)) {
            this.memoTagValues.push(item);
          }
        });
      },
    },
  },

  async beforeUnmount(): Promise<void> {
    const controller = AbortHandler.getInstance();
    controller.voidControllers();
    this.fetchStoreMemos();
  },

  async mounted(): Promise<void> {
    if (this.user?.onboarding) {
      if (this.initialLoad) {
        this.$notify({
          title: 'SUBMIT PERMISSIONS DISABLED',
          text:
            'You are currently unable to submit communications as your account activation remains incomplete. To finalize your account activation, kindly contact our team at team@engagementagents.com.',
          type: 'warn',
          duration: 5000,
        });
      }
    }

    if (this.modalData?.campaignId) {
      this.memo = await this.fetchStoreMemo({ memoId: this.modalData?.campaignId });
      this.status = 'Edit';
      this.clearStores();
      this.loadMemoSubtypeOptions();
      this.setForm();
      this.selectEnglishAsDefaultLanguage();
      this.loadCompanies();
      this.loadMallOptions();
      this.loaded = true;
    } else {
      this.status = 'New';
      this.clearStores();
      this.loadMemoSubtypeOptions();
      this.setForm();
      this.selectEnglishAsDefaultLanguage();
      this.loadCompanies();
      this.loadMallOptions();
      this.loaded = true;
    }
  },
  data(): {
    status: string;
    changesMade: boolean;
    previewable: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    duplicating: boolean;
    ongoing: boolean;
    companyValue: IDropdownOption | null;
    userValue: IDropdownOption | null;
    brandValue: IDropdownOption | null;
    customSearchValue: IDropdownOption;
    customSearchOptions: IDropdownOption[];
    searchLocationsFilter: string;
    tagValues: IDropdownOption[] | null;
    tagOptions: IDropdownOption[];
    searchFilter: string;
    locationOptions: IStore[];
    selectedLocationIds: string[];
    languages: ILanguage[];
    activeLanguage: ILanguage;
    formData: any;
    loaded: boolean;
    reviewByDateUpdatedCount: number;
    startDateUpdatedCount: number;
    endDateUpdatedCount: number;
    attemptedSubmit: boolean;
    fromCalendar: boolean;
    memo: any;
    filesUploading: boolean;
    question: string;
    openEnded: boolean;
    options: any[];
    questions: any;
    canCheckDates: boolean;
    editingQuestion: boolean;
    questionBeingEdited: any;
    initialLoad: boolean;
    allowShowOptions: boolean;
    timezones: any;
    timezone: IDropdownOption | null;
    selectedTimezone: string;
    savedTimezone: IDropdownOption | null;
    selectedReason: string;
    reasonMessage: string;
    reasonDescription: string;
    requirementsOpen: boolean;
    tagComboTypes: IDropdownOption[] | null;
    tagComboType: IDropdownOption;
    memoSubtypeValue: IDropdownOption;
    memoTagValues: IDropdownOption[];
    mallValue: IDropdownOption | null;
    hasAttachmentChange: boolean;
  } {
    return {
      status: 'New',
      changesMade: false,
      previewable: false,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      duplicating: false,
      ongoing: false,
      formData: null,
      loaded: false,
      reviewByDateUpdatedCount: 0,
      startDateUpdatedCount: 0,
      endDateUpdatedCount: 0,

      // temp metadata
      companyValue: null,
      userValue: null,
      brandValue: null,
      customSearchValue: { description: 'All', value: '1' },
      customSearchOptions: [
        { description: 'All', value: '1' },
        { description: 'Canada', value: '2' },
        { description: 'US', value: '3' },
        { description: 'Canada & US', value: '4' },
      ],
      searchLocationsFilter: '',
      tagValues: [],
      tagOptions: [],
      searchFilter: '',
      locationOptions: [],
      selectedLocationIds: [],
      languages: [
        {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
          required: true,
        },
      ],
      activeLanguage: {
        languageId: 37,
        description: 'English',
        isoCode: 'en',
        updatedAt: '',
        createdAt: '',
        required: true,
      },
      attemptedSubmit: false,
      fromCalendar: false,
      memo: null,
      filesUploading: false,
      canCheckDates: false,
      question: '',
      openEnded: true,
      options: [{ description: '' }, { description: '' }],
      editingQuestion: false,
      questionBeingEdited: null,
      questions: [],
      initialLoad: true,
      allowShowOptions: false,
      timezones: IANA_TIMEZONES,
      timezone: null,
      selectedTimezone: dayjs.tz.guess(),
      savedTimezone: null,
      selectedReason: '',
      reasonMessage: '',
      reasonDescription: '',
      requirementsOpen: false,
      tagComboTypes: [
        {
          value: 'or',
          description: 'OR',
        },
        {
          value: 'and',
          description: 'AND',
        },
      ],
      tagComboType: {
        value: 'or',
        description: 'OR',
      },
      memoSubtypeValue: { value: 1, description: 'Store' },
      memoTagValues: [],
      mallValue: null,
      hasAttachmentChange: false,
    };
  },

  //
  // ███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
  // ████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
  // ██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║███████╗
  // ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║╚════██║
  // ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████║
  // ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝
  //
  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadBrands',
      'loadMallOptions',
      'loadBrandTags',
      'clearBrandTags',
      'fetchStoresByCommunicationType',
      'loadUsers',
      'loadCompanies',
      'loadMemoSubtypeOptions',
      'clearStores',
      'updateModalData',
      'updateModalType',
      'openModal',
      'updateModalComponent',
      'showUpdateDrafts',
      'hideUpdateDrafts',
      'fetchStoreMemo',
      'refreshCalendar',
      'saveStoreMemo',
      'updateStoreMemo',
      'uploadImage',
      'fetchStoreMemos',
    ]),

    //
    // ███████╗███████╗████████╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗
    // ██╔════╝██╔════╝╚══██╔══╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
    // ███████╗█████╗     ██║       █████╗  ██║   ██║██████╔╝██╔████╔██║
    // ╚════██║██╔══╝     ██║       ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
    // ███████║███████╗   ██║       ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
    // ╚══════╝╚══════╝   ╚═╝       ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
    //
    setForm(): void {
      // initializers
      let headline: any = [];
      let description: any = [];
      let headlineParsed: any;
      let descriptionParsed: any;
      let reviewByDate = '';
      let startDate = '';
      let endDate = '';
      let ongoing = false;
      let company = this.permissions?.superAdminAccess ? '' : this.user?.companyId;
      let user = '';
      let brand = '';
      let mall = '';
      let memoName = '';
      let locations: any[] = [];
      let attachments: any[] = [];
      this.timezone = this.timezones.find((timezone: any) => timezone.value === dayjs.tz.guess());
      let memoSubtype = MemoSubtype.Store;

      // // set values if editing existing campaign
      if (this.selectedStoreMemo) {
        this.memo = this.selectedStoreMemo;
        // parse multi language capable fields
        headlineParsed = this.languageParser(this.memo?.headline);
        descriptionParsed = this.languageParser(this.memo?.longDescription);
        company = this.memo?.brand?.companyId;
        user = this.memo?.createdByUserId;
        brand = this.memo?.brand?.brandId;
        mall = this.memo?.mall?.mallId;
        memoName = this.memo?.description;
        locations = this.memo?.stores;
        attachments = this.memo?.attachments;
        memoSubtype = this.memo?.memoSubtypeId ?? MemoSubtype.Store;
        this.questions = this.memo?.questions;

        if (this.memo?.reviewByDate) {
          reviewByDate = this.memo?.reviewByDate;
        }
        if (this.memo?.fromDate) {
          startDate = this.memo?.fromDate;
        }
        if (this.memo?.toDate) {
          endDate = this.memo?.toDate;
          ongoing = false;
          this.ongoing = false;
        } else {
          ongoing = true;
          this.ongoing = true;
        }

        if (this.memo.statusId === 1) {
          if (
            dayjs(reviewByDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            reviewByDate = '';
          }
          if (
            dayjs(startDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            startDate = '';
          }
          if (
            dayjs(endDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            endDate = '';
          }
        }

        // extract languages from locations
        this.extractDataFromLocations(locations);
        this.timezone = this.timezones.find((timezone: any) => timezone.value === this.selectedStoreMemo?.timezone);
        this.selectedTimezone = this.timezones.find((timezone: any) => timezone.value === this.selectedStoreMemo?.timezone)?.value;

        // set dropdowns
        this.companyOptions.forEach((item: IDropdownOption) => {
          if (company === item?.value) {
            this.companyValue = item;
          }
        });
        this.userOptions.forEach((item: IDropdownOption) => {
          if (user === item?.value) {
            this.userValue = item;
          }
        });
        this.brandOptions.forEach((item: IDropdownOption) => {
          if (brand === item?.value) {
            this.brandValue = item;
          }
        });
        this.memoSubtypeOptions.forEach((item: IDropdownOption) => {
          if (memoSubtype === item?.value) {
            this.memoSubtypeValue = item;
          }
        });
        this.mallOptions.forEach((item: IDropdownOption) => {
          if (mall === item?.value) {
            this.mallValue = item;
          }
        });
      }

      // set multi language objects for new form
      this.languages.forEach((language) => {
        let headlineLang = '';
        let descriptionLang = '';
        if (this.selectedStoreMemo) {
          headlineParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              headlineLang = item[language?.isoCode];
            }
          });
          descriptionParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              descriptionLang = item[language?.isoCode];
            }
          });
        }
        headline.push({ language: language?.isoCode, value: headlineLang });
        description.push({
          language: language?.isoCode,
          value: descriptionLang,
        });
      });

      // set form based on user type
      this.formData = new FormBuilder({
        company: new FormControl(company),
        mall: new FormControl(mall),
        user: new FormControl(user),
        brand: new FormControl(brand),
        memoName: new FormControl(memoName, [Validators.required]),
        locations: new FormControl(locations, [Validators.required]),
        reviewByDate: new FormControl(reviewByDate, [Validators.required]),
        startDate: new FormControl(startDate, [Validators.required]),
        endDate: new FormControl(endDate, [Validators.required]),
        ongoing: new FormControl(ongoing),
        headline: new FormControl(headline, [Validators.required]),
        description: new FormControl(description, [Validators.required]),
        attachments: new FormControl(attachments),
        timezone: new FormControl(dayjs.tz.guess()),
        memoSubtype: new FormControl(memoSubtype),
      });

      this.languages?.forEach((language: any) => {
        let languageExists = false;
        this.formData?.controls?.headline?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.headline?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.description?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.description?.value?.push({ language: language?.isoCode, value: '' });
        }
      });
      setTimeout(() => {
        this.formData.controls.reviewByDate.value = reviewByDate;
        this.formData.controls.startDate.value = startDate;
        this.formData.controls.endDate.value = endDate;
        if (this.permissions?.superAdminAccess) {
          this.formData.controls.user.validators = [Validators.required];
          this.formData.controls.memoSubtype.validators = [Validators.required];
          if (this.formData.controls.memoSubtype.value === MemoSubtype.Store) {
            this.formData.controls.company.validators = [Validators.required];
          }
        }
        if (this.formData.controls.memoSubtype.value === MemoSubtype.Store && !this.isMallAdmin()) {
          this.formData.controls.brand.validators = [Validators.required];
          this.formData.controls.mall.validators = [];
        } else {
          this.formData.controls.brand.validators = [];
          this.formData.controls.mall.validators = [Validators.required];
        }
        if (this.formData.controls.ongoing.value) {
          this.formData.controls.endDate.validators = [];
        }
        if (this.modalData?.endStr) {
          this.updateModalData(null);
        }
        if (this.formData.controls.brand.value) {
          console.log('brand', this.formData.controls.brand.value);
          this.fetchStoresByCommunicationType({ communicationType: CommunicationTypes['Store Memo'], brandId: this.formData.controls.brand.value });
        }
      }, 0);
      setTimeout(() => {
        this.canCheckDates = true;
        if (this.brandOptions && this.brandOptions?.length === 1 && brand === '') {
          this.updateBrand(this.brandOptions[0], true);
        }
        if (this.mallOptions && this.mallOptions?.length === 1 && mall === '') {
          this.updateMall(this.mallOptions[0], true);
        }
        this.initialLoad = false;
      }, 3000);
    },
    async documentsUploaded(files: Blob[]): Promise<void> {
      const file: any = files[0];
      this.filesUploading = true;
      try {
        const url = await this.uploadImage(file);
        this.formData.controls.attachments.value.push({ name: file?.name, url });
        this.setChangesMade();
        this.filesUploading = false;
      } catch (err) {
        this.filesUploading = false;
      }
    },
    toggleOngoing() {
      this.formData.controls.ongoing.value = !this.formData.controls.ongoing.value;
      this.setChangesMade();
      if (this.formData.controls.ongoing.value) {
        this.formData.controls.endDate.validators = [];
        this.formData.controls.endDate.value = '';
        this.validateControl('endDate');
      } else {
        this.formData.controls.endDate.validators = [Validators.required];
      }
    },
    languageParser(value: string) {
      const parsedData: any = [];
      const languages = value?.split('::: ');
      languages.forEach((language) => {
        parsedData.push({
          [language.substring(0, language.indexOf('='))]: language.substring(language.indexOf('=') + 1, language.length),
        });
      });
      return parsedData;
    },
    getLanguageSpecificValue(item: any, isoCode: string) {
      let value: any = '';
      item?.forEach((i: any) => {
        if (i?.language === isoCode) {
          // if (i?.language === this.activeLanguage?.isoCode) {
          value = i?.value;
        }
      });
      return value;
    },
    setLanguageSpecificValue(item: any, value: any, isoCode: string) {
      item?.forEach((i: any) => {
        if (i?.language === isoCode) {
          i.value = value;
        }
      });
    },
    languageSelected(value: ILanguage, fromForm = false) {
      this.activeLanguage = value;
      if (fromForm) {
        this.setChangesMade();
      }
      if (this.activeLanguage?.required && this.attemptedSubmit) {
        this.validateControl('headline');
        this.validateControl('description');
      } else {
        this.formData.controls.headline.errors = [];
        this.formData.controls.description.errors = [];
      }
    },
    checkLanguageErrors() {
      this.languages.forEach((language: ILanguage) => {
        let hasErrors = false;
        if (language.required) {
          this.formData.controls.headline.value.forEach((el: any) => {
            if (el.language === language.isoCode && el.value === '') {
              hasErrors = true;
            }
          });
          this.formData.controls.description.value.forEach((el: any) => {
            if (el.language === language.isoCode && el.value === '') {
              hasErrors = true;
            }
          });
        }
        language.hasErrors = hasErrors;
      });
    },
    languageButtonStyle(language: ILanguage) {
      let style = '';
      if (language === this.activeLanguage) {
        style = language?.hasErrors
          ? 'color: hsl(0, 0%, 100%); background-color: hsl(138, 50%, 61%); border-color: red;'
          : 'color: hsl(0, 0%, 100%); background-color: hsl(138, 50%, 61%);';
      } else {
        style = language?.hasErrors ? 'color: hsl(0, 0%, 44%); border-color: red;' : 'color: hsl(0, 0%, 44%)';
      }
      return style;
    },
    daysBetween(d1: any, d2: any): number {
      let diff = Math.abs(d1.getTime() - d2.getTime());
      return diff / (1000 * 60 * 60 * 24);
    },
    isToday(date: any): boolean {
      const today = new Date();
      return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    },
    extractDataFromLocations(locations: IStore[]) {
      this.selectedLocationIds = [];
      if (locations?.length > 0) {
        const languageExtraction: any = [...new Set(locations.map((el: IStore) => el?.mall?.languages))];
        const mergedLanguages: ILanguage[] = [].concat.apply([], languageExtraction);

        let uniqueLanguages: any[] = [];
        const map = new Map();
        for (const item of mergedLanguages) {
          if (!map.has(item.isoCode)) {
            map.set(item.isoCode, true);
            item.required = item?.Mall_Language?.languageTypeId === 1 || item?.Mall_Language?.languageTypeId === 2;
            uniqueLanguages.push(item);
          } else {
            if (item?.Mall_Language?.languageTypeId === 1 || item?.Mall_Language?.languageTypeId === 2) {
              uniqueLanguages = uniqueLanguages.filter((el: any) => item?.isoCode !== el?.isoCode);
              uniqueLanguages.push({ ...item, required: true });
            }
          }
        }

        this.languages = uniqueLanguages;
        this.languages?.forEach((language: ILanguage) => {
          this.activeLanguage = language;

          let languageExists = false;
          this.formData?.controls?.headline?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.headline?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.description?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.description?.value?.push({ language: language?.isoCode, value: '' });
          }
        });

        const englishIndex = this.languages.findIndex((item) => item.isoCode === 'en');
        if (englishIndex > -1) {
          this.activeLanguage = this.languages[englishIndex];
        }

        if (this.attemptedSubmit) {
          this.checkLanguageErrors();
        }

        locations.forEach((location: IStore) => {
          this.selectedLocationIds.push(location.storeId);
        });
      } else {
        this.languages = [
          {
            languageId: 37,
            description: 'English',
            isoCode: 'en',
            updatedAt: '',
            createdAt: '',
            required: true,
          },
        ];
        this.activeLanguage = {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
          required: true,
        };
      }
    },
    selectEnglishAsDefaultLanguage() {
      const englishIndex = this.languages.findIndex((item) => item.isoCode === 'en');
      if (englishIndex > -1) {
        this.languageSelected(this.languages[englishIndex]);
      } else {
        this.languageSelected(this.languages[0]);
      }
    },
    locationAdded(value: any): void {
      this.formData.controls.locations.value.push(value);
      this.formData.controls.locations.value.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      this.setChangesMade();
    },
    locationRemoved(value: any): void {
      const index = this.formData.controls.locations.value.findIndex((item: any) => item.storeId === value.storeId);
      this.formData.controls.locations.value.splice(index, 1);
      this.setChangesMade();
    },
    addAllLocations(): void {
      this.filteredLocationsAvailable.forEach((location: any) => {
        this.locationAdded(location);
      });
    },
    removeAllLocations(): void {
      this.filteredLocationsSelected.forEach((location: any) => {
        this.locationRemoved(location);
      });
    },
    addOption() {
      this.options.push({ description: '' });
    },
    removeOption(index: number) {
      this.options.splice(index, 1);
    },
    addQuestion() {
      let emptyOption = false;
      this.options.forEach((option: any) => {
        if (option?.description === '') {
          emptyOption = true;
        }
      });
      if (this.question !== '' && (!emptyOption || this.openEnded)) {
        this.questions.push({
          description: this.question,
          memoQuestionTypeId: this.openEnded ? 1 : 2,
          options: this.options,
        });
        this.questionBeingEdited = null;
        this.editingQuestion = false;
        this.question = '';
        this.openEnded = true;
        this.options = [{ description: '' }, { description: '' }];
      } else {
        if (this.question === '') {
          this.$notify({
            title: 'ERROR',
            text: 'Please enter a question to add!',
            type: 'error',
            duration: 5000,
          });
        } else {
          this.$notify({
            title: 'ERROR',
            text: 'Please complete all options to add!',
            type: 'error',
            duration: 5000,
          });
        }
      }
    },
    editQuestion(question: any) {
      this.editingQuestion = true;
      this.questionBeingEdited = question;
      this.question = question?.description;
      this.openEnded = question?.memoQuestionTypeId === 1 ? true : false;
      this.options = question?.options;
    },
    cancelEdit() {
      this.editingQuestion = false;
      this.questionBeingEdited = null;
      this.question = '';
      this.openEnded = true;
      this.options = [{ description: '' }, { description: '' }];
    },
    saveEdit() {
      let emptyOption = false;
      this.options.forEach((option: any) => {
        if (option?.description === '') {
          emptyOption = true;
        }
      });
      if (this.question !== '' && (!emptyOption || this.openEnded)) {
        this.questions.forEach((item: any) => {
          if (item.description === this.questionBeingEdited.description) {
            item.description = this.question;
            item.memoQuestionTypeId = this.openEnded ? 1 : 2;
            item.options = this.options;
          }
        });
        this.questionBeingEdited = null;
        this.editingQuestion = false;
        this.question = '';
        this.openEnded = true;
        this.options = [{ description: '' }, { description: '' }];
      } else {
        if (this.question === '') {
          this.$notify({
            title: 'ERROR',
            text: 'Please enter a question to add!',
            type: 'error',
            duration: 5000,
          });
        } else {
          this.$notify({
            title: 'ERROR',
            text: 'Please complete all options to add!',
            type: 'error',
            duration: 5000,
          });
        }
      }
    },
    deleteQuestion(question: any) {
      this.questions = this.questions.filter((item: any) => item.description !== question.description);
    },
    updateCompany(value: IDropdownOption, clicked?: boolean) {
      this.companyValue = value;
      this.formData.controls.company.value = this.companyValue.value;
      this.loadBrands({ companyId: value.value });
      this.loadUsers({ companyId: value.value });
      if (clicked) {
        this.setChangesMade();
        this.brandValue = null;
        this.formData.controls.brand.value = '';
        this.userValue = null;
        this.formData.controls.user.value = '';
        this.clearStores();
        this.clearBrandTags();
        this.validateControl('company');
        setTimeout(() => {
          if (this.brandOptions && this.brandOptions?.length === 1) {
            this.updateBrand(this.brandOptions[0], true);
          }
        }, 3000);
      }
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.setChangesMade();
        this.validateControl('company');
      }
    },
    updateMall(value: IDropdownOption, clicked?: boolean) {
      this.mallValue = value;
      this.formData.controls.mall.value = this.mallValue.value;
      this.loadUsers({ mallId: value.value });
      if (value?.value) {
        this.fetchStoresByCommunicationType({ communicationType: CommunicationTypes['Store Memo'], brandId: '', mallId: value?.value });
      }
      if (clicked) {
        this.clearStores();
        this.formData.controls.locations.value = [];
        this.selectedLocationIds = [];
        this.setChangesMade();
      }
    },
    updateUser(value: IDropdownOption) {
      this.userValue = value;
      this.formData.controls.user.value = this.userValue.value;
      this.setChangesMade();
      this.validateControl('user');
    },
    updateBrand(value: IDropdownOption, fromForm = false) {
      if (fromForm) {
        this.setChangesMade();
        this.validateControl('brand');
        this.formData.controls.locations.value = [];
        this.selectedLocationIds = [];
        if (value?.value) {
          this.fetchStoresByCommunicationType({ communicationType: CommunicationTypes['Store Memo'], brandId: value?.value });
        }
      }
      this.brandValue = value;
      this.formData.controls.brand.value = this.brandValue?.value;
      this.loadBrandTags({ brandIds: this.formData.controls.brand.value });
    },
    tagsUpdated(value: any): void {
      this.tagValues = value;
    },
    memoTagsUpdated(value: any): void {
      this.memoTagValues = value;
      this.setChangesMade();
    },
    updateTagComboType(value: IDropdownOption) {
      this.tagComboType = value;
      this.setChangesMade();
      this.validateControl('campaignType');
    },
    updateMemoSubtype(value: IDropdownOption, clicked?: boolean) {
      this.memoSubtypeValue = value;
      this.formData.controls.memoSubtype.value = this.memoSubtypeValue.value;
      // clear values
      if (clicked) {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.brandValue = null;
        this.formData.controls.brand.value = null;
        this.mallValue = null;
        this.formData.controls.mall.value = null;
        this.setChangesMade();
      }
      // set validators
      if (this.memoSubtypeValue.value === MemoSubtype.Store && !this.isMallAdmin()) {
        if (this.permissions?.superAdminAccess) {
          this.formData.controls.company.validators = [Validators.required];
        }
        this.formData.controls.brand.validators = [Validators.required];
        this.formData.controls.mall.validators = [];
      } else if (this.memoSubtypeValue.value === MemoSubtype.Retailer) {
        this.loadMallOptions();
        if (this.permissions?.superAdminAccess) {
          this.formData.controls.company.validators = [];
        }
        this.formData.controls.brand.validators = [];
        this.formData.controls.mall.validators = [Validators.required];
      }
    },
    setChangesMade() {
      this.changesMade = true;
    },
    async uploadDocuments(files: Blob[]): Promise<void> {
      this.filesUploading = true;
      try {
        files?.forEach(async (file: Blob) => {
          const url = await this.uploadImage(file);
          this.formData.controls.attachments.value.push({
            name: file?.name,
            url,
          });
        });
        this.setChangesMade();
        this.filesUploading = false;
        this.hasAttachmentChange = true;
      } catch (err) {
        this.filesUploading = false;
      }
    },
    removeAttachment(attachment: any) {
      this.formData.controls.attachments.value = this.formData?.controls?.attachments?.value.filter((item: any) => item?.url !== attachment?.url);
      this.setChangesMade();
      this.hasAttachmentChange = true;
    },
    async downloadAttachment(attachment: any) {
      let blob = await fetch(attachment?.url, { mode: 'no-cors' }).then((r) => r.blob());
      let objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.setAttribute('download', attachment?.name);
      a.setAttribute('crossorigin', 'anonymous');
      a.setAttribute('target', '_blank');
      a.click();
      window.URL.revokeObjectURL(objectUrl);
    },
    updateHeadline(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.headline.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade();
      this.validateControl('headline');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateDescription(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.description.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade();
      this.validateControl('description');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateSearchLocationsFilter(value: string) {
      this.searchFilter = value;
    },
    validateDateControl(controlName: string): void {
      if (controlName === 'reviewByDate') {
        this.reviewByDateUpdatedCount++;
        if (!this.initialLoad && (this.reviewByDateUpdatedCount > 1 || !this.modalData?.campaignId || !this.selectedStoreMemo?.reviewByDate)) {
          this.setChangesMade();
        }
      }
      if (controlName === 'startDate') {
        this.startDateUpdatedCount++;
        if (!this.initialLoad && (this.startDateUpdatedCount > 1 || !this.modalData?.campaignId || !this.selectedStoreMemo?.startDate)) {
          this.setChangesMade();
        }
      }
      if (controlName === 'endDate') {
        this.endDateUpdatedCount++;
        if (!this.initialLoad && (this.endDateUpdatedCount > 1 || !this.modalData?.campaignId || !this.selectedStoreMemo?.endDate)) {
          this.setChangesMade();
        }
      }
      setTimeout(() => {
        this.formData.controls[controlName].validate();
      }, 0);
    },
    updateTimezone(value: IDropdownOption) {
      this.timezone = value;
      this.formData.controls.timezone.value = this.timezone.value;
      this.selectedTimezone = this.timezones.find((timezone: any) => timezone.value === value.value)?.value;
      this.formData.controls.startDate.value = null;
      this.formData.controls.endDate.value = null;
      this.formData.controls.reviewByDate.value = null;
    },
    clearTimezone() {
      if (this.timezone) {
        this.savedTimezone = this.timezone;
        this.timezone = null;
      }
    },
    fixTimezone() {
      setTimeout(() => {
        if (!this.timezone) {
          this.timezone = this.savedTimezone;
        }
      }, 100);
    },
    validateControl(controlName: string): void {
      this.setChangesMade();
      if (controlName === 'headline') {
        if (this.activeLanguage.required) {
          this.formData.controls.headline.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.headline.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.headline.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.headline.errors = [];
        }
      } else if (controlName === 'description') {
        if (this.activeLanguage.required) {
          this.formData.controls.description.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.description.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.description.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.description.errors = [];
        }
      } else {
        setTimeout(() => {
          this.formData.controls[controlName].validate();
        }, 0);
      }
    },
    formatDate(date: string): string {
      return dateFormatter(date);
    },
    closeRequirements(): void {
      this.requirementsOpen = false;
    },
    openRequirements(): void {
      this.requirementsOpen = true;
    },
    copyRequirements(): void {
      let requirements = 'Location,Image Size Requirements\n';
      this.formData.controls.locations.value.forEach((store: any) => {
        requirements += `${store?.mall?.description}${store.storeIdentifier ? ` (${store.storeIdentifier})` : ''}`;
        if (store?.mall?.requirements?.length === 0) {
          requirements += 'No Requirements\n';
        } else {
          requirements += ',';
          store?.mall?.requirements?.forEach((requirement: any, index: any) => {
            requirements += requirement?.width + 'x' + requirement?.height + (index < store?.mall?.requirements?.length - 1 ? ',' : '\n');
          });
        }
      });
      const blob = new Blob([requirements], { type: 'text/csv;charset=utf-8;' });
      let link = document.createElement('a');
      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'image_requirements.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.$notify({
        title: 'SUCCESS',
        text: `Requirements CSV Downloaded!`,
        type: 'info',
        duration: 5000,
      });
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      const payload = this.memo;
      payload.statusId = 5;
      this.saving = true;
      try {
        await this.updateStoreMemo({ body: payload, memoId: this.memo?.memoId });
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Store Memo deleted successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.refreshCalendar();
        await this.fetchStoreMemos();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.saving = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to delete Store Memo!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    expirePrompt(): void {
      this.openPrompt('expire');
    },
    async expire(): Promise<void> {
      const payload = this.memo;
      payload.statusId = Status['Manually Expired'];
      payload.reasonForExpire = `${this.selectedReason}${this.reasonDescription ? ' - ' + this.reasonDescription : ''}`;
      payload.expiredByUserId = this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '';
      this.saving = true;
      try {
        await this.updateStoreMemo({ body: payload, memoId: this.memo?.memoId });
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Store Memo expired successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.refreshCalendar();
        await this.fetchStoreMemos();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.saving = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to expire Store Memo!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    async save(submit?: boolean): Promise<void> {
      if (
        (this.formData?.controls?.brand?.value || this.formData?.controls?.mall?.value) &&
        this.formData?.controls?.locations?.value &&
        this.formData?.controls?.locations?.value?.length > 0
      ) {
        let upcoming = false;
        const reviewByDate = this.formData?.controls?.reviewByDate?.value ?? '';

        const fromDate = this.formData?.controls?.startDate?.value ?? '';
        if (this.formData?.controls?.startDate?.value) {
          let fd = new Date(this.formData?.controls?.startDate?.value);
          upcoming = new Date().setHours(23, 59, 59, 999) < fd.setHours(0, 0, 0, 0);
        }

        const toDate = this.formData?.controls?.endDate?.value ?? '';

        let headline = '';
        if (this.formData?.controls?.headline?.value) {
          let items = [...this.formData?.controls?.headline?.value];
          items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
          items?.forEach((el: any) => {
            headline += `${el.language}=${el.value}::: `;
          });
          headline = headline.slice(0, headline.length - 4);
        }
        let longDescription = '';
        if (this.formData?.controls?.description?.value) {
          let items = [...this.formData?.controls?.description?.value];
          items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
          items?.forEach((el: any) => {
            longDescription += `${el.language}=${el.value}::: `;
          });
          longDescription = longDescription.slice(0, longDescription.length - 4);
        }

        const payload = {
          description: this.formData?.controls?.memoName?.value,
          companyId: this.memoSubtypeValue.value === MemoSubtype.Store && !this.isMallAdmin() ? this.formData?.controls?.company?.value : null,
          brandId: this.memoSubtypeValue.value === MemoSubtype.Store && !this.isMallAdmin() ? this.formData?.controls?.brand?.value : null,
          mallId: this.memoSubtypeValue.value === MemoSubtype.Retailer || this.isMallAdmin() ? this.formData?.controls?.mall?.value : null,
          createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '',
          headline: headline,
          longDescription: longDescription,
          reviewByDate: reviewByDate,
          fromDate: fromDate,
          toDate: toDate,
          statusId: submit === true ? (upcoming ? 3 : 2) : 1,
          storeIds: this.selectedLocationIds,
          questions: this.questions,
          attachments: this.formData?.controls?.attachments?.value,
          memoSubtypeId: this.formData?.controls?.memoSubtype?.value,
          timezone: this.timezone?.value,
          tagIds: this.memoTagValues.map((tag) => tag.value),
          hasAttachmentChange: this.hasAttachmentChange,
        };

        this.saving = true;
        try {
          if (submit === true) {
            this.formData.markAllAsTouched();
            this.validateControl('headline');
            this.validateControl('description');
            this.checkLanguageErrors();
            this.attemptedSubmit = true;
          }
          if (this.memo?.memoId) {
            await this.updateStoreMemo({ body: payload, memoId: this.memo?.memoId });
          } else {
            this.memo = await this.saveStoreMemo(payload);
            this.status = 'Edit';
          }
          this.refreshCalendar();
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: `Store Memo ${submit === true ? 'submitted' : 'saved'} successfully!`,
            type: 'success',
            duration: 5000,
          });
          if (this.fromCalendar && !(submit === true)) {
            this.hideUpdateDrafts();
            setTimeout(() => {
              this.showUpdateDrafts();
            }, 5000);
          }
          this.changesMade = false;
          await this.fetchStoreMemos();
          if (submit === true) {
            this.closeModal();
            this.clearMetadata();
            this.updateClosingModal(false);
          }
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: `Unable to ${submit === true ? 'submit' : 'save'} Store Memo!`,
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        if (this.memoSubtypeValue.value === MemoSubtype.Store && !this.formData?.controls?.brand?.value) {
          this.$notify({
            title: 'ERROR',
            text: `Brand is required!`,
            type: 'error',
            duration: 5000,
          });
        } else if (this.memoSubtypeValue.value === MemoSubtype.Retailer && !this.formData?.controls?.mall?.value) {
          this.$notify({
            title: 'ERROR',
            text: `Mall is required!`,
            type: 'error',
            duration: 5000,
          });
        } else {
          this.$notify({
            title: 'ERROR',
            text: `At least 1 location is required!`,
            type: 'error',
            duration: 5000,
          });
        }
      }
    },
    getLanguageId(isoCode: string): number {
      let id = 0;
      this.languages.forEach((language: ILanguage) => {
        if (language?.isoCode === isoCode) {
          id = language?.languageId;
        }
      });
      return id;
    },
    updatePrompt(): void {
      this.formData.markAllAsTouched();
      this.validateControl('headline');
      this.validateControl('description');
      this.checkLanguageErrors();
      this.attemptedSubmit = true;
      let languageErrors = false;
      this.languages?.forEach((language: any) => {
        if (language?.hasErrors) {
          languageErrors = true;
        }
      });
      if (!this.formData.hasErrors && !languageErrors) {
        this.openPrompt('update');
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Store Memo form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      const reviewByDate = this.formData?.controls?.reviewByDate?.value ?? '';
      const fromDate = this.formData?.controls?.startDate?.value ?? '';
      const toDate = this.formData?.controls?.endDate?.value ?? '';

      let headline = '';
      if (this.formData?.controls?.headline?.value) {
        let items = [...this.formData?.controls?.headline?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          headline += `${el.language}=${el.value}::: `;
        });
        headline = headline.slice(0, headline.length - 4);
      }
      let longDescription = '';
      if (this.formData?.controls?.description?.value) {
        let items = [...this.formData?.controls?.description?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          longDescription += `${el.language}=${el.value}::: `;
        });
        longDescription = longDescription.slice(0, longDescription.length - 4);
      }

      const payload = {
        description: this.formData?.controls?.memoName?.value,
        companyId: this.memoSubtypeValue.value === MemoSubtype.Store && !this.isMallAdmin() ? this.formData?.controls?.company?.value : null,
        brandId: this.memoSubtypeValue.value === MemoSubtype.Store && !this.isMallAdmin() ? this.formData?.controls?.brand?.value : null,
        mallId: this.memoSubtypeValue.value === MemoSubtype.Retailer || this.isMallAdmin() ? this.formData?.controls?.mall?.value : null,
        createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '',
        headline: headline,
        longDescription: longDescription,
        reviewByDate: reviewByDate,
        fromDate: fromDate,
        toDate: toDate,
        statusId: this.memo?.statusId,
        storeIds: this.selectedLocationIds,
        questions: this.questions,
        attachments: this.formData?.controls?.attachments?.value,
        memoSubtypeId: this.formData?.controls?.memoSubtype?.value,
        timezone: this.timezone?.value,
        tagIds: this.memoTagValues.map((tag) => tag.value),
        hasAttachmentChange: this.hasAttachmentChange,
      };

      this.saving = true;
      try {
        await this.updateStoreMemo({ body: payload, memoId: this.memo?.memoId });
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Store Memo updated successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.changesMade = false;
        await this.fetchStoreMemos();
        this.closePrompt();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.saving = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to update Store Memo!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    submitPrompt(): void {
      this.formData.markAllAsTouched();
      this.validateControl('headline');
      this.validateControl('description');
      this.checkLanguageErrors();
      this.attemptedSubmit = true;
      let languageErrors = false;
      this.languages?.forEach((language: any) => {
        if (language?.hasErrors) {
          languageErrors = true;
        }
      });
      if (!this.formData.hasErrors && !languageErrors) {
        this.openPrompt('submit');
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Store Memo form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async duplicate(): Promise<void> {
      this.duplicating = true;
      const reviewByDate = null;
      const fromDate = null;
      const toDate = null;
      let headline = '';
      if (this.formData?.controls?.headline?.value) {
        this.formData?.controls?.headline?.value?.forEach((el: any) => {
          headline += `${el.language}=${el.value}::: `;
        });
        headline = headline.slice(0, headline.length - 4);
      }

      let longDescription = '';
      if (this.formData?.controls?.description?.value) {
        this.formData?.controls?.description?.value?.forEach((el: any) => {
          longDescription += `${el.language}=${el.value}::: `;
        });
        longDescription = longDescription.slice(0, longDescription.length - 4);
      }

      const payload = {
        description: this.formData?.controls?.memoName?.value,
        companyId: this.memoSubtypeValue.value === MemoSubtype.Store && !this.isMallAdmin() ? this.formData?.controls?.company?.value : null,
        brandId: this.memoSubtypeValue.value === MemoSubtype.Store && !this.isMallAdmin() ? this.formData?.controls?.brand?.value : null,
        mallId: this.memoSubtypeValue.value === MemoSubtype.Retailer || this.isMallAdmin() ? this.formData?.controls?.mall?.value : null,
        createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '',
        headline: headline,
        longDescription: longDescription,
        reviewByDate: reviewByDate,
        fromDate: fromDate,
        toDate: toDate,
        statusId: 1,
        storeIds: this.selectedLocationIds,
        questions: this.questions,
        attachments: this.formData?.controls?.attachments?.value,
        timezone: this.timezone?.value,
        tagIds: this.memoTagValues.map((tag) => tag.value),
      };

      this.duplicating = true;
      try {
        const response = await this.saveStoreMemo(payload);
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Store Memo duplicated successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.changesMade = false;
        await this.fetchStoreMemos();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
        setTimeout(() => {
          this.updateModalComponent('store-memo');
          this.updateModalType('large');
          this.updateModalData({ campaignId: response?.memoId, row: response, mode: 'edit' });
          this.openModal();
        }, 500);
      } catch (err) {
        this.duplicating = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to duplicate Store Memo!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    expireReason() {
      switch (this.selectedReason) {
        case 'Knowledge/Training Issue':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
        case 'PR Opportunities':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
        case 'Other':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
      }
    },
    isMallAdmin(): boolean {
      return this.user.role?.roleId === Role.MallAdmin;
    },
  },
});
