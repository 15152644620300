/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { UsersState, State, UserState } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { IUser } from '../../../../interfaces/src/v2/index';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';
import { any } from 'cypress/types/bluebird';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

interface ImageUploadResponse {
  success: boolean;
  url: string;
}

export const UsersModule = {
  state(): UsersState {
    return {
      users: [],
      loadingUsers: false,
    };
  },

  mutations: {
    clearUsers(state: UsersState): void {
      state.users = [];
    },
    updateUsers(state: UsersState, payload: IUser[]): void {
      state.users = payload;
    },
    updateLoadingUsers(state: UsersState, payload: boolean): void {
      state.loadingUsers = payload;
    },
  },

  actions: {
    clearUsers(context: ActionContext<UsersState, State>): void {
      context.commit('clearUsers');
    },

    uploadImage(context: ActionContext<UserState, State>, image: any): Promise<string> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .uploadImage(image)
          .then((records: AxiosResponse<ImageUploadResponse>) => {
            return resolve(records.data.url);
          })
          .catch((err: any) => {
            captureExceptionInSentry(err);
            reject();
          });
      });
    },

    uploadImageToApple(
      context: ActionContext<UserState, State>,
      payload: {
        image: any;
        appleBusinessBusinessId: string;
        appleBusinessLocationId: string;
      },
    ): Promise<any> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .uploadImageToApple(payload.image, payload.appleBusinessBusinessId, payload.appleBusinessLocationId)
          .then((records: AxiosResponse<Response>) => {
            return resolve(records.data.data);
          })
          .catch((err: any) => {
            captureExceptionInSentry(err);
            reject();
          });
      });
    },

    async fetchUsers(context: ActionContext<UsersState, State>): Promise<any> {
      context.commit('updateLoadingUsers', true);
      const payload = context.rootState['PaginationModule'];
      return new HttpService()
        .getUsers(payload?.page, payload?.limit, payload?.search, payload?.sortKey, payload?.sortDirection)
        .then((records: AxiosResponse<Response>) => {
          records.data.data.forEach((user: IUser) => {
            user.companyDesc = user?.company?.description;
            user.statusDesc = user?.status?.description;
          });

          context.commit(
            'updateUsers',
            records.data.data.filter((user: IUser) => user.statusId !== 5),
          );
          // context.commit('updateUsers', records.data.data.filter((user: IUser) => user.statusId !== 5));
          context.commit('updateLoadingUsers', false);
          return records.data;
        })
        .catch((err: any) => {
          const errorAsAny: any = err as any;
          errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          context.commit('updateLoadingUsers', false);
        });
    },

    saveUser(context: ActionContext<UsersState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveUser(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchUsers');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    updateUser(context: ActionContext<UsersState, State>, payload: { payload: any; userId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateUser(payload.payload, payload.userId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchUsers');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    deleteUser(context: ActionContext<UsersState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .deleteUser(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchUsers');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    async exportUsers(context: ActionContext<UserState, State>): Promise<any> {
      const users = context.getters.users;
      try {
        const exportData = await new HttpService().exportUsers(users);

        return { didExport: true, data: exportData };
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${context}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        return { didExport: false, data: null };
      }
    },
  },

  getters: {
    users(state: UsersState): IUser[] {
      return state.users;
    },
    loadingUsers(state: UsersState): boolean {
      return state.loadingUsers;
    },
  },
};
