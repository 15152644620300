/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from '@vue/shared';
import dayjs from 'dayjs';

export class Validators {
  static required(
    value: string | number | any[],
  ): {
    message: string;
    status: string;
  } | null {
    if (isArray(value)) {
      return value.length > 0
        ? null
        : {
            message: 'At least one item must be selected',
            status: 'error',
          };
    }

    return value
      ? null
      : {
          message: 'Field is required',
          status: 'error',
        };
  }

  static email(
    value: string | null,
  ): {
    message: string;
    status: string;
  } | null {
    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (value) {
      return emailRegex.test(value as string)
        ? {
            message: '',
            status: 'success',
          }
        : {
            message: 'Field must be an email',
            status: 'warn',
          };
    }

    return null;
  }

  static password(
    value: string | null,
  ): {
    message: string;
    status: string;
  } | null {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    if (value) {
      return passwordRegex.test(value as string)
        ? {
            message: 'Password is strong',
            status: 'success',
          }
        : {
            message: 'Password is weak',
            status: 'warn',
          };
    }

    return null;
  }

  static dateGreaterEqualToToday(
    value: string | null,
  ): {
    message: string;
    status: string;
  } | null {
    if (value !== null && dayjs(value) < dayjs().startOf('day')) {
      return {
        message: 'Date must be greater or equal to today',
        status: 'error',
      };
    }
    return null;
  }

  static attachmentsRequired(
    value: string | null,
  ): {
    message: string;
    status: string;
  } | null {
    if (isArray(value)) {
      return value.length > 0
        ? null
        : {
            message:
              'You must still include at least one file under the "Attach Documents" section as your shopping centers will need creative to promote your campaign',
            status: 'error',
          };
    }
    return null;
  }
}
