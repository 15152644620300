
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import Notification from '../../molecules/Notification/Notification.vue';

export default defineComponent({
  components: {
    'arc-notification': Notification,
  },

  emits: {
    masterFileChange: (): boolean => true,
    multiFileChange: (): boolean => true,
  },

  props: {
    type: {
      type: String,
    },
    imageSizeRequirements: {
      type: Object,
    },
    masterImageSizes: {
      type: Object,
    },
    status: {},
  },

  computed: {
    multiTheme(): string {
      // let status: any = this.status;
      let theme: any = 'notification--info-grey';
      // if (status?.square?.uploaded) {
      //   theme = 'notification--success';
      // } else if (status?.square?.required) {
      //   theme = 'notification--error';
      // }
      return theme;
    },
    multiIcon(): string {
      let status: any = this.status;
      let icon: any = '';
      // if (status?.square?.uploaded) {
      //   icon = 'success';
      // } else if (status?.square?.required) {
      //   icon = 'error';
      // }
      return icon;
    },
    squareTheme(): string {
      let status: any = this.status;
      let theme: any = 'notification--info-grey';
      if (status?.square?.uploaded) {
        theme = 'notification--success';
      } else if (status?.square?.required) {
        theme = 'notification--error';
      }
      if (status?.square?.warning) {
        theme = 'notification--warn';
      }
      return theme;
    },
    squareIcon(): string {
      let status: any = this.status;
      let icon: any = '';
      if (status?.square?.uploaded) {
        icon = 'success';
      } else if (status?.square?.required) {
        icon = 'error';
      }
      if (status?.square?.warning) {
        icon = 'warn';
      }
      return icon;
    },
    landscapeTheme(): string {
      let status: any = this.status;
      let theme = 'notification--info-grey';
      if (status?.landscape?.uploaded) {
        theme = 'notification--success';
      } else if (status?.landscape?.required) {
        theme = 'notification--error';
      }
      if (status?.landscape?.warning) {
        theme = 'notification--warn';
      }
      return theme;
    },
    landscapeIcon(): string {
      let status: any = this.status;
      let icon = '';
      if (status?.landscape?.uploaded) {
        icon = 'success';
      } else if (status?.landscape?.required) {
        icon = 'error';
      }
      if (status?.landscape?.warning) {
        icon = 'warn';
      }
      return icon;
    },
    portraitTheme(): string {
      let status: any = this.status;
      let theme = 'notification--info-grey';
      if (status?.portrait?.uploaded) {
        theme = 'notification--success';
      } else if (status?.portrait?.required) {
        theme = 'notification--error';
      }
      if (status?.portrait?.warning) {
        theme = 'notification--warn';
      }
      return theme;
    },
    portraitIcon(): string {
      let status: any = this.status;
      let icon = '';
      if (status?.portrait?.uploaded) {
        icon = 'success';
      } else if (status?.portrait?.required) {
        icon = 'error';
      }
      if (status?.portrait?.warning) {
        icon = 'warn';
      }
      return icon;
    },
  },
});
