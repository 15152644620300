export function dateFormatter(dateString: string, showTime = false): string {
  if (dateString === '' || dateString === null) {
    return '';
  }

  if (dateString?.includes('GMT')) {
    dateString = dateString.slice(0, 15);
  } else if (dateString?.includes('Z')) {
    dateString = dateString.slice(0, 10);
  } else {
    return dateString;
  }

  const date = new Date(dateString);

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  let formattedDate = date.toUTCString();

  if (formattedDate?.includes('GMT')) {
    formattedDate = formattedDate.slice(0, 16);
  }

  if (formattedDate?.includes('Z')) {
    formattedDate = formattedDate.slice(0, 10);
  }

  const weekday = formattedDate.slice(0, 5);
  const day = formattedDate.slice(5, 8);
  const month = formattedDate.slice(8, 12);
  const year = formattedDate.slice(12, 16);

  formattedDate = `${weekday}${month}${day}${year}`;

  return formattedDate;
}

export function dateFormatterLocalized(dateString: string): string {
  if (dateString === '' || dateString === null) {
    return '';
  }

  const date = new Date(dateString);

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  return date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' });
}

export function timeFormatterLocalized(dateString: string): string {
  if (dateString === '' || dateString === null) {
    return '';
  }

  const date = new Date(dateString);

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  return date.toLocaleTimeString('en-US', { timeStyle: 'short' });
}
