export class AbortHandler {
  private static instance: AbortHandler;
  private controllers: AbortController[];

  private constructor() {
    this.controllers = [];
  }

  public static getInstance(): AbortHandler {
    if (!AbortHandler.instance) {
      AbortHandler.instance = new AbortHandler();
    }

    return AbortHandler.instance;
  }

  public addController(controller: AbortController): void {
    this.controllers.push(controller);
  }

  public voidControllers(): void {
    this.controllers = [];
  }

  public kill(): void {
    this.controllers.forEach((controller: AbortController) => {
      controller.abort();
    });

    this.controllers = [];
  }
}
