import { FormControl } from './FormControl';

export class FormBuilder {
  public constructor(
    public controls: {
      [key: string]: FormControl;
    },
  ) {}

  public markAllAsTouched(): void {
    for (const key in this.controls) {
      this.controls[key].resetErrors();
      this.controls[key].validate();
    }
  }

  public reset(): void {
    for (const key in this.controls) {
      this.controls[key].reset();
    }
  }

  public get errors(): {
    [key: string]: string[] | null[];
  } {
    const foundErrors = {};

    for (const key in this.controls) {
      if (this.controls[key].errors) {
        foundErrors[key] = this.controls[key].errors;
      }
    }

    return foundErrors;
  }

  public get hasErrors(): number {
    let errorsFound = 0;

    for (const key in this.controls) {
      if ((this.controls[key].errors.message || this.controls[key].errors.status) && this.controls[key].errors.status !== 'success') {
        errorsFound++;
      }
    }

    return errorsFound;
  }
}
