
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import Jimp from 'jimp';

export default defineComponent({
  components: {},
  emits: {},
  props: {},
  computed: {
    ...mapGetters(['modalData', 'user']),
    image(): any {
      return this.modalData;
    },
  },
  methods: {
    async downloadImg(type: string, image?: any) {
      if (type === 'png') {
        const jimpImage = await Jimp.read(image.url);
        const base64url = await jimpImage.getBase64Async('image/png');
        const a = document.createElement('a');
        a.href = base64url; //Image Base64 Goes here
        a.download = `${image?.downloadLink}.${type}`;
        a.click(); //Downloaded file
      } else if (type === 'gif') {
        const jimpImage = await Jimp.read(image.url);
        const base64url = await jimpImage.getBase64Async('image/gif');
        const a = document.createElement('a');
        a.href = base64url; //Image Base64 Goes here
        a.download = `${image?.downloadLink}.${type}`;
        a.click(); //Downloaded file
      } else {
        const jimpImage = await Jimp.read(image.url);
        const base64url = await jimpImage.getBase64Async('image/jpeg');
        const a = document.createElement('a');
        a.href = base64url; //Image Base64 Goes here
        a.download = `${image?.downloadLink}.${type}`;
        a.click(); //Downloaded file
      }
    },
  },
});
