
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    downloadableStyle(): string {
      return this.downloadable ? 'cursor: pointer;' : '';
    },
  },

  emits: {
    removeChip: (): boolean => {
      return true;
    },
    chipClicked: (): boolean => {
      return true;
    },
  },

  methods: {
    removeChip(): void {
      this.$emit('removeChip');
    },
    chipClicked(): void {
      this.$emit('chipClicked');
    },
  },
});
