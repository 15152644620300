
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    attachment: {
      type: Object,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      overlayActive: false,
    };
  },

  computed: {
    type(): string {
      let type = 'document';
      const imageTypes = ['.jpeg', '.jpg', '.gif', '.png', '.svg', '.eps', '.raw', '.cr2', '.nef', '.orf', '.sr2', '.bmp', '.tif', '.tiff'];
      const videoTypes = [
        '.webm',
        '.mpg',
        '.mp2',
        '.mpeg',
        '.mpe',
        '.mpv',
        '.ogg',
        '.mp4',
        '.m4p',
        '.m4v',
        '.avi',
        '.wmv',
        '.mov',
        '.qt',
        '.flv',
        '.swf',
        '.mkv',
      ];
      const wordTypes = ['.doc', '.docm', '.docx'];
      const excelTypes = ['.xlm', '.xls', '.xlsm', '.xlsx'];
      const powerpointTypes = ['.pot', '.potm', '.potx', '.pps', '.ppsm', '.ppsx', '.ppt', 'pptm', '.pptx'];
      imageTypes.forEach((element: string) => {
        if (this.attachment?.url?.includes(element)) {
          type = 'image';
        }
      });
      videoTypes.forEach((element: string) => {
        if (this.attachment?.url?.includes(element)) {
          type = 'video';
        }
      });
      wordTypes.forEach((element: string) => {
        if (this.attachment?.url?.includes(element)) {
          type = 'word';
        }
      });
      excelTypes.forEach((element: string) => {
        if (this.attachment?.url?.includes(element)) {
          type = 'excel';
        }
      });
      powerpointTypes.forEach((element: string) => {
        if (this.attachment?.url?.includes(element)) {
          type = 'powerpoint';
        }
      });
      if (this.attachment?.url?.includes('.pdf')) {
        type = 'pdf';
      }
      return type;
    },
    url() {
      let url = '';
      if (this.type === 'document') {
        url = 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/doc.png';
      }
      if (this.type === 'word') {
        url = 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/docx_icon.png';
      }
      if (this.type === 'excel') {
        url = 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/excel.png';
      }
      if (this.type === 'powerpoint') {
        url = 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/ppt.png';
      }
      if (this.type === 'pdf') {
        url = 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/PDF_file_icon.png';
      }
      if (this.type === 'image' || this.type === 'video') {
        url = this.attachment?.url;
      }
      return url;
    },
  },

  emits: {
    removeAttachment: () => {
      return true;
    },
    attachmentClicked: () => {
      return true;
    },
  },

  methods: {
    removeAttachment(): void {
      this.$emit('removeAttachment');
    },
    attachmentClicked(): void {
      if (this.downloadable) {
        this.$emit('attachmentClicked');
      }
      if (this.deletable && !this.downloadable) {
        this.$emit('removeAttachment');
      }
    },
  },
});
