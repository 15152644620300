
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  props: {
    images: {
      required: true,
    },
    imageContainerStyles: {
      type: String,
    },
    imageStyles: {
      type: String,
    },
    imageSize: {
      type: String,
    },
    showType: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageContainerStyle(): string {
      return (
        this.imageContainerStyles + (this.imageSize ? ` height: ${+this.imageSize + 4 + 'rem'}; width: ${this.imageSize + 'rem'}` : '') ??
        'width: 100%;'
      );
    },
    imageStyle(): string {
      const images: any = this.images;
      return this.imageStyles + this.orientationStyle(images[this.slideIndex]?.orientation) ?? 'width: 100%;';
    },
  },
  data() {
    return {
      slideIndex: 0,
    };
  },
  mounted(): void {
    this.currentSlide(this.slideIndex);
  },
  methods: {
    orientationStyle(orientation: string) {
      return orientation === 'landscape'
        ? ` width: 100%; max-width: ${this.imageSize + 'rem' ?? '100%'};`
        : ` height: 100%; max-height: ${this.imageSize + 'rem' ?? '100%'};`;
    },

    plusSlides(n: any) {
      const images: any = this.images;
      if (n + this.slideIndex > images.length - 1) {
        this.currentSlide(0);
      } else if (n + this.slideIndex < 0) {
        this.currentSlide(images.length - 1);
      } else {
        this.currentSlide(this.slideIndex + n);
      }
    },

    currentSlide(n: any) {
      this.slideIndex = n;
    },
  },
});
