import { IBadges, IBadge, INotification } from '../utilities/Interfaces/notification.interfaces';
import { NotificationState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';
import HttpService from '@/services/HttpService';
import { AxiosResponse } from 'axios';
import captureExceptionInSentry from '@/store/errorHandler';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const NotificationModule = {
  state(): NotificationState {
    return {
      badges: {
        dashboardBadge: { amount: 0 },
        communicationsBadge: { amount: 0 },
        reportsBadge: { amount: 0 },
        mallsBadge: { amount: 0 },
        storesBadge: { amount: 0 },
        companiesBadge: { amount: 0 },
        usersBadge: { amount: 0 },
        importBadge: { amount: 0 },
        emailsBadge: { amount: 0 },
        accountBadge: { amount: 0 },
      },
      notifications: [],
      userNotifications: [],
      activeNotification: null,
      announcements: [],
      activeAnnouncement: null,
      tips: [],
      activeTip: {
        index: 0,
        title: 'Tip',
        description: 'If you experience any difficulties please either contact your Engagement Agents account manager, or call (416) 577-7326',
        icon: 'info',
      },
    };
  },

  mutations: {
    updateBadges(state: NotificationState, payload: IBadges): void {
      state.badges = payload;
    },
    updateDashboardBadge(state: NotificationState, payload: IBadge): void {
      state.badges.dashboardBadge = payload;
    },
    updateCommunicationsBadge(state: NotificationState, payload: IBadge): void {
      state.badges.communicationsBadge = payload;
    },
    updateReportsBadge(state: NotificationState, payload: IBadge): void {
      state.badges.reportsBadge = payload;
    },
    updateMallsBadge(state: NotificationState, payload: IBadge): void {
      state.badges.mallsBadge = payload;
    },
    updateStoresBadge(state: NotificationState, payload: IBadge): void {
      state.badges.storesBadge = payload;
    },
    updateCompaniesBadge(state: NotificationState, payload: IBadge): void {
      state.badges.companiesBadge = payload;
    },
    updateUsersBadge(state: NotificationState, payload: IBadge): void {
      state.badges.usersBadge = payload;
    },
    updateImportBadge(state: NotificationState, payload: IBadge): void {
      state.badges.importBadge = payload;
    },
    updateEmailsBadge(state: NotificationState, payload: IBadge): void {
      state.badges.emailsBadge = payload;
    },
    updateAccountBadge(state: NotificationState, payload: IBadge): void {
      state.badges.accountBadge = payload;
    },
    updateNotifications(state: NotificationState, payload: INotification[]): void {
      state.notifications = payload;
    },
    updateUserNotifications(state: NotificationState, payload: INotification[]): void {
      state.userNotifications = payload;
    },
    updateActiveNotification(state: NotificationState, payload: INotification): void {
      state.activeNotification = payload;
    },
    updateAnnouncements(state: NotificationState, payload: INotification[]): void {
      state.announcements = payload;
    },
    updateActiveAnnouncement(state: NotificationState, payload: INotification): void {
      state.activeAnnouncement = payload;
    },
    updateTips(state: NotificationState, payload: INotification[]): void {
      state.tips = payload;
    },
    updateActiveTip(state: NotificationState, payload: INotification): void {
      state.activeTip = payload;
    },
  },

  actions: {
    updateBadges(context: ActionContext<NotificationState, State>, payload: IBadges): void {
      context.commit('updateBadges', payload);
    },
    updateDashboardBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateDashboardBadge', payload);
    },
    updateCommunicationsBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateCommunicationsBadge', payload);
    },
    updateReportsBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateReportsBadge', payload);
    },
    updateMallsBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateMallsBadge', payload);
    },
    updateStoresBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateStoresBadge', payload);
    },
    updateCompaniesBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateCompaniesBadge', payload);
    },
    updateUsersBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateUsersBadge', payload);
    },
    updateImportBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateImportBadge', payload);
    },
    updateEmailsBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateEmailsBadge', payload);
    },
    updateAccountBadge(context: ActionContext<NotificationState, State>, payload: IBadge): void {
      context.commit('updateAccountBadge', payload);
    },
    updateNotifications(context: ActionContext<NotificationState, State>, payload: INotification[]): void {
      context.commit('updateNotifications', payload);
    },
    updateActiveNotification(context: ActionContext<NotificationState, State>, payload: INotification): void {
      context.commit('updateActiveNotification', payload);
    },
    updateAnnouncements(context: ActionContext<NotificationState, State>, payload: INotification[]): void {
      context.commit('updateAnnouncements', payload);
    },
    updateActiveAnnouncement(context: ActionContext<NotificationState, State>, payload: INotification): void {
      context.commit('updateActiveAnnouncement', payload);
    },
    updateTips(context: ActionContext<NotificationState, State>, payload: INotification[]): void {
      context.commit('updateTips', payload);
    },
    updateActiveTip(context: ActionContext<NotificationState, State>, payload: INotification): void {
      context.commit('updateActiveTip', payload);
    },

    async fetchNotifications(context: ActionContext<NotificationState, State>): Promise<any> {
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getNotifications(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey === 'id' ? 'notificationId' : payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((notification: any) => {
          notification.brandsDesc = notification?.brands?.map((brand: any) => brand.description);
          notification.mallsDesc = notification?.malls?.map((mall: any) => mall.description);
        });
        context.commit('updateNotifications', records?.data?.data);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}.`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async fetchNotification(context: ActionContext<NotificationState, State>, payload: { notificationId: string }): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().getNotification(payload.notificationId);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}.`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async saveNotification(context: ActionContext<NotificationState, State>, payload: any): Promise<any> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveNotification(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchNotifications');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    async updateNotification(context: ActionContext<NotificationState, State>, payload: { payload: any; notificationId: string }): Promise<any> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateNotification(payload.payload, payload.notificationId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchNotifications');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    async deleteNotification(context: ActionContext<NotificationState, State>, payload: { notificationId: string }): Promise<any> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .deleteNotification(payload.notificationId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchNotifications');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    async fetchUserNotifications(context: ActionContext<NotificationState, State>): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().getUserNotifications();
        context.commit('updateUserNotifications', records?.data?.data);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}.`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async dismissUserNotification(context: ActionContext<NotificationState, State>, payload: { notificationId: string }): Promise<any> {
      try {
        await new HttpService().dismissUserNotification(payload.notificationId);
        context.dispatch('fetchUserNotifications');
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}.`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },
  },

  getters: {
    badges(state: NotificationState): IBadges {
      return state.badges;
    },
    dashboardBadge(state: NotificationState): IBadge {
      return state.badges.dashboardBadge;
    },
    communicationsBadge(state: NotificationState): IBadge {
      return state.badges.communicationsBadge;
    },
    reportsBadge(state: NotificationState): IBadge {
      return state.badges.reportsBadge;
    },
    mallsBadge(state: NotificationState): IBadge {
      return state.badges.mallsBadge;
    },
    storesBadge(state: NotificationState): IBadge {
      return state.badges.storesBadge;
    },
    companiesBadge(state: NotificationState): IBadge {
      return state.badges.companiesBadge;
    },
    usersBadge(state: NotificationState): IBadge {
      return state.badges.usersBadge;
    },
    importBadge(state: NotificationState): IBadge {
      return state.badges.importBadge;
    },
    emailsBadge(state: NotificationState): IBadge {
      return state.badges.emailsBadge;
    },
    accountBadge(state: NotificationState): IBadge {
      return state.badges.accountBadge;
    },
    notifications(state: NotificationState): any[] {
      return state.notifications;
    },
    userNotifications(state: NotificationState): any[] {
      return state.userNotifications;
    },
    activeNotification(state: NotificationState): INotification | null {
      return state.activeNotification;
    },
    announcements(state: NotificationState): INotification[] {
      return state.announcements;
    },
    activeAnnouncement(state: NotificationState): INotification | null {
      return state.activeAnnouncement;
    },
    tips(state: NotificationState): INotification[] {
      return state.tips;
    },
    activeTip(state: NotificationState): INotification {
      return state.activeTip;
    },
  },
};
