
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';

export default defineComponent({
  changesMade: {
    deep: true,
    handler() {
      this.$emit('hasChangesMade', this.changesMade);
    },
  },
  components: {
    'arc-input': Input,
    'arc-dropdown': Dropdown,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
  },

  computed: {
    ...mapGetters(['modalData', 'user', 'access', 'permissions', 'companyStatuses']),
    containerType(): string {
      return this.permissions?.superAdminAccess ? 'admin-container' : 'user-container';
    },
    updateDisabled(): boolean {
      return (
        this.permissions &&
        this.modalData?.row &&
        !this.permissions?.canUpdateAllCompanies &&
        (!this.permissions?.canUpdateOwnedCompanies ||
          (this.permissions?.canUpdateOwnedCompanies && this.user?.userId !== this.modalData?.row?.createdByUserId))
      );
    },
    deleteDisabled(): boolean {
      return (
        this.permissions &&
        this.modalData?.row &&
        !this.permissions?.canDeleteAllCompanies &&
        (!this.permissions?.canDeleteOwnedCompanies ||
          (this.permissions?.canDeleteOwnedCompanies && this.user?.userId !== this.modalData?.row?.createdByUserId))
      );
    },
  },

  watch: {
    companyStatuses: {
      deep: true,
      handler() {
        this.companyStatuses.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.statusId === item?.value) {
            this.statusValue = item;
          }
        });
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
  },

  async beforeUnmount(): Promise<void> {
    this.fetchCompanies();
  },

  async mounted(): Promise<void> {
    if (this.modalData?.companyId) {
      this.modalData.row = await this.fetchCompany({ companyId: this.modalData?.companyId });
    }
    this.loadCompanyStatuses();
    this.setForm();
    this.loaded = true;
  },
  data(): {
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    statusValue: IDropdownOption | null;
    formData: any;
    loaded: boolean;
  } {
    return {
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      formData: null,
      loaded: false,
      statusValue: null,
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadCompanyStatuses',
      'saveCompany',
      'updateCompany',
      'deleteCompany',
      'fetchCompany',
      'fetchCompanies',
    ]),
    setForm(): void {
      let status = '';
      let description = '';
      let appleBusinessCompanyId = '';
      // set values if editing existing campaign
      if (this.modalData?.row) {
        status = this.modalData?.row?.statusId;
        description = this.modalData?.row?.description;
        appleBusinessCompanyId = this.modalData?.row?.appleBusinessCompanyId;
      }
      // set form based on user type
      // if (this.permissions?.superAdminAccess) {
      this.formData = new FormBuilder({
        status: new FormControl(status),
        description: new FormControl(description, [Validators.required]),
        appleBusinessCompanyId: new FormControl(appleBusinessCompanyId),
      });
      // } else {
      //   this.formData = new FormBuilder({
      //     status: new FormControl(status, [Validators.required, ]),
      //     description: new FormControl(description, [Validators.required, ]),
      //   });
      // }
    },
    updateStatus(value: IDropdownOption) {
      this.statusValue = value;
      this.formData.controls.status.value = this.statusValue.value;
      this.setChangesMade();
    },
    setChangesMade() {
      this.changesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      this.deleting = true;
      this.promptOpen = false;

      try {
        await this.deleteCompany(this.modalData?.row?.companyId);
        this.deleting = false;
        this.promptOpen = false;
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'Company deleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.deleting = false;
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to delete company.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      this.formData.markAllAsTouched();
      console.log('FORM ERRORS: ', this.formData?.hasErrors);
      if (!this.formData.hasErrors) {
        let payload = {
          description: this.formData?.controls?.description?.value,
          statusId: 2,
          appleBusinessCompanyId: this.formData?.controls?.appleBusinessCompanyId?.value,
        };

        this.saving = true;

        try {
          await this.saveCompany(payload);
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Company saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          const error: any = err;
          this.$notify({
            title: 'ERROR',
            text: error === 'There is already a Company associated with that name.' ? error : 'Unable to update Company',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.saving = false;
        this.$notify({
          title: 'INVALID FORM',
          text: 'Company form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          description: this.formData?.controls?.description?.value,
          statusId: 2,
          appleBusinessCompanyId: this.formData?.controls?.appleBusinessCompanyId?.value,
        };

        this.saving = true;

        try {
          await this.updateCompany({
            payload,
            companyId: this.modalData?.row?.companyId,
          });
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Company updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          const error: any = err;
          this.$notify({
            title: 'ERROR',
            text: error === 'There is already a Company associated with that name.' ? error : 'Unable to update Company',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Company form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
  },
});
