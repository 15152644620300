
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';
import SingleSelectInputSuggestion from '../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import MultiSelectInput from '../../../../molecules/Multi-SelectInput/MultiSelectInput.vue';
import { IUser } from '../../../../../../../../interfaces/src/v1/Users/User';

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-multi-select-input': MultiSelectInput,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
  },
  computed: {
    ...mapGetters(['modalData', 'user', 'access', 'permissions', 'primaryUserOptions', 'secondaryUsersOptions']),
    containerType(): string {
      return this.permissions?.superAdminAccess ? 'admin-container' : 'user-container';
    },
    updateDisabled(): boolean {
      return this.permissions && this.modalData?.row && !this.permissions?.superAdminAccess;
    },
    disableDisabled(): boolean {
      return this.permissions && this.modalData?.row && !this.permissions?.superAdminAccess;
    },
  },

  async mounted(): Promise<void> {
    if (this.modalData?.emailId) {
      this.modalData.row = await this.fetchEmailConnection({ emailId: this.modalData?.emailId });
    }
    this.loadPrimaryUser({ email: true });
    this.loadSecondaryUsers({ email: true });
    this.setForm();
    this.loaded = true;
  },
  data(): {
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    disabling: boolean;
    primaryUserValue: IDropdownOption | null;
    allowShowOptions: boolean;
    secondaryUsersValues: IDropdownOption[];
    formData: any;
    loaded: boolean;
  } {
    return {
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      disabling: false,
      formData: null,
      loaded: false,
      primaryUserValue: null,
      allowShowOptions: false,
      secondaryUsersValues: [],
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadPrimaryUser',
      'loadSecondaryUsers',
      'saveEmailConnection',
      'disableEmailConnection',
      'updateEmailConnection',
      'resetPagination',
      'fetchEmailConnection',
    ]),
    setForm(): void {
      // initializers
      let alias = '';
      let primaryUser = '';
      let secondaryUsers: string[] = [];
      // set values if editing existing email
      if (this.modalData?.row) {
        alias = this.modalData?.row?.alias;
        primaryUser = this.modalData?.row?.primaryUser.userId;
        this.modalData?.row?.secondaryUsers.forEach((user: IUser) => {
          secondaryUsers.push(user?.userId.toString());
        });
      }
      // set form based on user type
      this.formData = new FormBuilder({
        alias: new FormControl(alias, [Validators.required]),
        primaryUser: new FormControl(primaryUser, [Validators.required]),
        secondaryUsers: new FormControl(secondaryUsers),
      });
    },
    updatePrimaryUser(value: IDropdownOption) {
      this.primaryUserValue = value;
      this.formData.controls.primaryUser.value = this.primaryUserValue.value;
      this.setChangesMade();
      this.validateControl('primaryUser');
    },
    clearPrimaryUser(value: any) {
      if (value === '') {
        this.primaryUserValue = null;
        this.formData.controls.primaryUser.value = null;
        this.setChangesMade();
        this.validateControl('primaryUser');
      }
    },
    secondaryUsersUpdated(value: any): void {
      this.secondaryUsersValues = value;
      this.formData.controls.secondaryUsers.value = this.secondaryUsersValues?.map((el: { value: unknown; description: string }) => el.value);
      this.setChangesMade();
    },
    setChangesMade() {
      this.changesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    disablePrompt(): void {
      this.openPrompt('disable');
    },
    async disable(): Promise<void> {
      this.disabling = true;
      this.promptOpen = false;

      try {
        await this.disableEmailConnection(this.modalData?.row?.emailId);
        this.disabling = false;
        this.promptOpen = false;
        this.resetPagination();
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'Email disabled successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.disabling = false;
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to disable Email.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          alias: this.formData?.controls?.alias?.value + '@incoming.engagementagents.com',
          primaryUserId: this.formData?.controls?.primaryUser?.value,
          secondaryUsersIds: this.formData?.controls?.secondaryUsers?.value,
          createdByUserId: this.user?.userId,
        };

        this.saving = true;

        try {
          await this.saveEmailConnection(payload);
          this.$notify({
            title: 'SUCCESS',
            text: 'Email saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.resetPagination();
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.$notify({
            title: 'ERROR',
            text: 'Unable to save Email.',
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Email form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors) {
        let payload = {
          alias: this.formData?.controls?.alias?.value,
          primaryUserId: this.formData?.controls?.primaryUser?.value,
          secondaryUsersIds: this.formData?.controls?.secondaryUsers?.value,
          createdByUserId: this.user?.userId,
        };

        this.saving = true;

        try {
          await this.updateEmailConnection({ payload, emailId: this.modalData?.row?.emailId });
          this.$notify({
            title: 'SUCCESS',
            text: 'Email updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.resetPagination();
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.$notify({
            title: 'ERROR',
            text: 'Unable to update Email.',
            type: 'error',
            duration: 5000,
          });
        } finally {
          this.saving = false;
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Email form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
  },
  watch: {
    primaryUserOptions: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 250);
        this.primaryUserValue = this.primaryUserOptions.find((u: any) => u.value === this.modalData?.row?.primaryUserId);
      },
    },
    secondaryUsersOptions: {
      deep: true,
      handler() {
        this.modalData?.row?.secondaryUsers.forEach((user: any) => {
          const secondaryUserValue = this.secondaryUsersOptions.find((u: any) => u.value === user.userId);
          this.secondaryUsersValues.push(secondaryUserValue);
        });
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
  },
});
