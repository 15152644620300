
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  props: {
    tabs: {
      type: Array,
    },
    activeTabFlag: {
      type: String,
    },
  },

  computed: {
    ...mapGetters(['currentActiveRoute']),
  },

  methods: {
    isActive(flag: string): string {
      let active = '';
      if (!this.activeTabFlag) {
        active = this.currentActiveRoute.startsWith(flag) ? 'active' : '';
      } else {
        active = this.activeTabFlag === flag ? 'active' : '';
      }
      return active;
    },
    tabClicked(tab: string): void {
      this.$emit('tabClicked', tab);
    },
  },
});
