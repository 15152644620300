/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { OpportunityState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { IOpportunity } from '../../../../interfaces/src/v2/index';
import * as Sentry from '@sentry/vue';
import { Status } from '@/enums/Status';
import captureExceptionInSentry from './errorHandler';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const OpportunityModule = {
  state(): OpportunityState {
    return {
      opportunities: [],
      loadingOpportunities: false,
    };
  },

  mutations: {
    clearOpportunities(state: OpportunityState): void {
      state.opportunities = [];
    },
    updateOpportunities(state: OpportunityState, payload: IOpportunity[]): void {
      state.opportunities = payload;
    },
    updateLoadingOpportunities(state: OpportunityState, payload: boolean): void {
      state.loadingOpportunities = payload;
    },
  },

  actions: {
    clearOpportunities(context: ActionContext<OpportunityState, State>): void {
      context.commit('clearOpportunities');
    },

    async fetchOpportunities(
      context: ActionContext<OpportunityState, State>,
      payload: { page?: number; limit?: number; search?: string; sortKey?: string; sortDirection?: string },
    ): Promise<any> {
      context.commit('updateLoadingOpportunities', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getOpportunities(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((opportunity: IOpportunity) => {
          //
        });
        context.commit(
          'updateOpportunities',
          records.data.data.filter((opportunity: IOpportunity) => opportunity.statusId !== Status.Deleted),
        );
        context.commit('updateLoadingOpportunities', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingOpportunities', false);
      }
    },

    async fetchOpportunityById(context: ActionContext<OpportunityState, State>, opportunityId: string): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().getOpportunityById(opportunityId);
      return records?.data?.data;
    },

    saveOpportunity(context: ActionContext<OpportunityState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveOpportunity(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchOpportunities');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    updateOpportunity(context: ActionContext<OpportunityState, State>, payload: { payload: any; opportunityId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateOpportunity(payload.payload, payload.opportunityId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchOpportunities');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    disableOpportunity(context: ActionContext<OpportunityState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .disableOpportunity(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchOpportunities');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },
  },

  getters: {
    opportunities(state: OpportunityState): IOpportunity[] {
      return state.opportunities;
    },
    loadingOpportunities(state: OpportunityState): boolean {
      return state.loadingOpportunities;
    },
  },
};
