export enum PermissionTypes {
  'canCreateMarketingCampaigns' = 1,
  'canReadAllMarketingCampaigns' = 2,
  'canReadOwnedMarketingCampaigns' = 3,
  'canUpdateAllMarketingCampaigns' = 4,
  'canUpdateOwnedMarketingCampaigns' = 5,
  'canDeleteAllMarketingCampaigns' = 6,
  'canDeleteOwnedMarketingCampaigns' = 7,
  'canCreateRecruitmentCampaigns' = 8,
  'canReadAllRecruitmentCampaigns' = 9,
  'canReadOwnedRecruitmentCampaigns' = 10,
  'canUpdateAllRecruitmentCampaigns' = 11,
  'canUpdateOwnedRecruitmentCampaigns' = 12,
  'canDeleteAllRecruitmentCampaigns' = 13,
  'canDeleteOwnedRecruitmentCampaigns' = 14,
  'canCreateMallMemos' = 15,
  'canReadAllMallMemos' = 16,
  'canReadOwnedMallMemos' = 17,
  'canUpdateAllMallMemos' = 18,
  'canUpdateOwnedMallMemos' = 19,
  'canDeleteAllMallMemos' = 20,
  'canDeleteOwnedMallMemos' = 21,
  'canCreateStoreMemos' = 22,
  'canReadAllStoreMemos' = 23,
  'canReadOwnedStoreMemos' = 24,
  'canUpdateAllStoreMemos' = 25,
  'canUpdateOwnedStoreMemos' = 26,
  'canDeleteAllStoreMemos' = 27,
  'canDeleteOwnedStoreMemos' = 28,
  'canCreateMalls' = 29,
  'canReadAllMalls' = 30,
  'canReadOwnedMalls' = 31,
  'canUpdateAllMalls' = 32,
  'canUpdateOwnedMalls' = 33,
  'canDeleteAllMalls' = 34,
  'canDeleteOwnedMalls' = 35,
  'canCreateStores' = 36,
  'canReadAllStores' = 37,
  'canReadOwnedStores' = 38,
  'canUpdateAllStores' = 39,
  'canUpdateOwnedStores' = 40,
  'canDeleteAllStores' = 41,
  'canDeleteOwnedStores' = 42,
  'canCreateCompanies' = 43,
  'canReadAllCompanies' = 44,
  'canReadOwnedCompanies' = 45,
  'canUpdateAllCompanies' = 46,
  'canUpdateOwnedCompanies' = 47,
  'canDeleteAllCompanies' = 48,
  'canDeleteOwnedCompanies' = 49,
  'canCreateBrands' = 50,
  'canReadAllBrands' = 51,
  'canReadOwnedBrands' = 52,
  'canUpdateAllBrands' = 53,
  'canUpdateOwnedBrands' = 54,
  'canDeleteAllBrands' = 55,
  'canDeleteOwnedBrands' = 56,
  'canCreateUsers' = 57,
  'canReadAllUsers' = 58,
  'canReadOwnedUsers' = 59,
  'canUpdateAllUsers' = 60,
  'canUpdateOwnedUsers' = 61,
  'canDeleteAllUsers' = 62,
  'canDeleteOwnedUsers' = 63,
  'superAdminAccess' = 64,
  'canSubmitMarketingCampaigns' = 65,
  'canSubmitRecruitmentCampaigns' = 66,
  'canSubmitMallMemos' = 67,
  'canSubmitStoreMemos' = 68,
}
