/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { CompanyState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { ICompany } from '../../../../interfaces/src/v2/index';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const CompanyModule = {
  state(): CompanyState {
    return {
      companies: [],
      loadingCompanies: false,
    };
  },

  mutations: {
    clearCompanies(state: CompanyState): void {
      state.companies = [];
    },
    updateCompanies(state: CompanyState, payload: ICompany[]): void {
      state.companies = payload;
    },
    updateLoadingCompanies(state: CompanyState, payload: boolean): void {
      state.loadingCompanies = payload;
    },
  },

  actions: {
    clearCompanies(context: ActionContext<CompanyState, State>): void {
      context.commit('clearCompanies');
    },

    async fetchCompanies(context: ActionContext<CompanyState, State>): Promise<any> {
      context.commit('updateLoadingCompanies', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getCompanies(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );

        records.data.data.forEach((company: ICompany) => {
          company.statusDesc = company?.status?.description;
          company.userList = company.userEmails;
        });

        context.commit(
          'updateCompanies',
          records.data.data.filter((company: ICompany) => company.statusId !== 5),
        );
        context.commit('updateLoadingCompanies', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingCompanies', false);
      }
    },

    async fetchCompany(context: ActionContext<CompanyState, State>, payload: { companyId: string }): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().getCompany(payload.companyId);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async saveCompany(context: ActionContext<CompanyState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveCompany(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchCompanies');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    async updateCompany(context: ActionContext<CompanyState, State>, payload: { payload: any; companyId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateCompany(payload.payload, payload.companyId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchCompanies');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    deleteCompany(context: ActionContext<CompanyState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .deleteCompany(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchCompanies');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },
  },

  getters: {
    companies(state: CompanyState): ICompany[] {
      return state.companies;
    },
    loadingCompanies(state: CompanyState): boolean {
      return state.loadingCompanies;
    },
  },
};
