
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../atomic/atoms/Input/Input.vue';
import Search from '../../../atomic/atoms/Search/Search.vue';
import Dropdown from '../../../atomic/atoms/Dropdown/Dropdown.vue';
import Chip from '../../../atomic/atoms/Chip/Chip.vue';
import MultiSelectDropdown from '../../../atomic/molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import TextArea from '../../../atomic/atoms/Text-Area/Text-Area.vue';
import Button from '../../../atomic/atoms/Button/Button.vue';
import Tooltip from '../../../atomic/atoms/Tooltip/Tooltip.vue';
import Modal from '../../../atomic/atoms/Modal/Modal.vue';
import { IChannel, IContact, ILanguage } from '../../../../../../interfaces/src/v2/index';
import { FormBuilder, FormControl } from '../../../utilities/Forms';
import { Validators } from '../../../utilities/Validators/Validators';
import SingleSelectInputSuggestion from '../../../atomic/molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import { ICommunicationType } from '../../../../../../interfaces/src/v2/CommunicationType';
import Divider from '../../../atomic/atoms/Divider/Divider.vue';
import Loader from '../../../atomic/atoms/Loader/Loader.vue';
import { dateFormatter } from '../../../utilities/Functions/formatting';
import { Role } from '@/enums/Role';
import HttpService from '@/services/HttpService';

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-search': Search,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-text-area': TextArea,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-chip': Chip,
    'arc-divider': Divider,
    'arc-loader': Loader,
  },

  computed: {
    ...mapGetters([
      'modalData',
      'access',
      'permissions',
      'mallTypes',
      'mallOwners',
      'contactTypes',
      'communicationTypes',
      'channels',
      'countries',
      'provinces',
      'languageOptions',
    ]),
    containerStyle() {
      const data: any = this.modalData;
      return !data || data?.mode !== 'approve' ? '' : 'max-height: calc(90vh - 12rem); min-height: calc(90vh - 12rem);';
    },
    countryFieldWidth(): string {
      let style = 'grid-column-end: span 12;';
      if (this.countryValue?.value && this.provinces.length > 0) {
        style = 'grid-column-end: span 6;';
      }
      return style;
    },
    provinceTitle(): string {
      return this.countryValue?.value === 2 ? 'Province' : 'State';
    },
    commTypeFieldWidth(): string {
      return this.commTypeValues?.length > 0 ? 'width: calc(40% - 15rem); margin-bottom: 1rem;' : 'width: 40%; margin-bottom: 1rem;';
    },
    containerType(): string {
      return this.permissions?.superAdminAccess ? 'admin-container' : 'user-container';
    },
    comTypePlaceholder(): string {
      return this.mallCommTypeValues?.length > 0 ? 'Search' : 'Select Mall Communication Types First';
    },
    channelPlaceholder(): string {
      return this.mallChannelValues?.length > 0 ? 'Search' : 'Select Mall Channels First';
    },
    filteredLanguages() {
      let options: any = this.languageOptions;
      const languageIds: any[] = [];
      // const languageIds: any[] = this.optionalLanguagesValues.map((el: IDropdownOption) => el.value);
      if (this.primaryLanguageValue) {
        languageIds.push(this.primaryLanguageValue.value);
      }
      if (this.secondaryLanguageValue) {
        languageIds.push(this.secondaryLanguageValue.value);
      }
      return options.filter((el: any) => {
        return languageIds.includes(el.value) ? null : el;
      });
    },
  },

  watch: {
    email: {
      deep: true,
      handler() {
        this.formData.controls.e.value = this.email?.trim();
        this.validateControl('e');
      },
    },
    communicationTypes: {
      deep: true,
      handler() {
        this.mallCommTypeValues = [];
        this.communicationTypes?.forEach((item: IDropdownOption) => {
          this.mallData?.communicationTypes?.forEach((ct: ICommunicationType) => {
            if (item.value === ct.communicationTypeId) {
              this.mallCommTypeValues.push(item);
            }
          });
        });
      },
    },
    actionRequired: {
      deep: true,
      handler() {
        this.notificationOnly = !this.actionRequired;
      },
    },
    channels: {
      deep: true,
      handler() {
        this.mallChannelValues = [];
        this.channels?.forEach((item: IDropdownOption) => {
          this.mallData?.channels?.forEach((ch: IChannel) => {
            if (item.value === ch.channelId) {
              this.mallChannelValues.push(item);
            }
          });
        });
      },
    },
    mallTypes: {
      deep: true,
      handler() {
        this.mallTypes?.forEach((item: IDropdownOption) => {
          if (this.mallData?.mallLocationTypeId === item?.value) {
            this.typeValue = item;
          }
        });
      },
    },
    contactTypes: {
      deep: true,
      handler() {
        this.contactTypes?.forEach((item: IDropdownOption) => {
          if (this.mallData?.contactTypeId === item?.value) {
            this.contactTypeValue = item;
          }
        });
      },
    },
    countries: {
      deep: true,
      handler() {
        this.countries?.forEach((item: IDropdownOption) => {
          if (this.mallData?.country === item?.description) {
            this.countryValue = item;
            this.loadProvinceOptions(this.countryValue.value);
          }
        });
      },
    },
    provinces: {
      deep: true,
      handler() {
        this.provinces?.forEach((item: IDropdownOption) => {
          if (this.mallData?.province === item?.description) {
            this.provinceValue = item;
          }
        });
      },
    },
    mallOwners: {
      deep: true,
      handler() {
        this.mallOwners?.forEach((item: IDropdownOption) => {
          if (this.mallData?.mallOwnerId && this.mallData?.mallOwnerId === item?.value) {
            this.ownerValue = item;
          }
        });
      },
    },
    languageOptions: {
      deep: true,
      handler() {
        // setTimeout(() => {
        //   this.allowShowOptions = true;
        // }, 250);
        this.optionalLanguagesValues = [];
        this.languageOptions?.forEach((item: IDropdownOption) => {
          this.mallData?.languages?.forEach((language: ILanguage) => {
            if (language?.languageId === item?.value) {
              if (language?.Mall_Language?.languageTypeId === 1) {
                this.primaryLanguageValue = item;
                this.formData.controls.languageFlag.value = 'true';
              }
              if (language?.Mall_Language?.languageTypeId === 2) {
                this.secondaryLanguageValue = item;
              }
              if (language?.Mall_Language?.languageTypeId === 3) {
                this.optionalLanguagesValues.push(item);
              }
            }
          });
        });
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
    contact: {
      deep: true,
      handler() {
        this.fName = this.contact?.firstName;
        this.lName = this.contact?.lastName;
        this.title = this.contact?.title;
        this.email = this.contact?.email;
        this.setForm();
      },
    },
  },

  async mounted(): Promise<void> {
    if (this.$route?.query?.mallId) {
      this.mallData = await this.fetchMall({ mallId: this.$route.query.mallId });
      this.mallData = { ...this.mallData.data };
    } else {
      if (this.modalData?.mode === 'approve') {
        this.mallData = this.modalData?.row?.dataSnapshot;
        this.currentMallState = await this.fetchMall({ mallId: this.modalData?.row?.dataSnapshot?.mallId });
        this.currentMallState = this.currentMallState?.data;
      }
    }
    if (this.$route?.query?.contactId) {
      await new HttpService().getContact(this.$route?.query?.contactId as string).then((results: any) => {
        this.contact = results?.data?.data;
      });
    }
    this.loadMallTypes();
    this.loadMallOwners();
    this.loadCountryOptions();
    this.loadLanguageOptions();
    this.loadContactTypes('mall');
    this.loadMallCommunicationTypes();
    this.loadChannels();
    this.setForm();
    this.loaded = true;
    setTimeout(() => {
      this.compareMallData();
    }, 1000);
  },

  data(): {
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    typeValue: IDropdownOption | null;
    contactTypeValue: IDropdownOption | null;
    emailTypeValue: IDropdownOption | null;
    emailTypes: IDropdownOption[];
    countryValue: IDropdownOption | null;
    provinceValue: IDropdownOption | null;
    primaryLanguageValue: IDropdownOption | null;
    secondaryLanguageValue: IDropdownOption | null;
    optionalLanguagesValues: IDropdownOption[];
    allowShowOptions: boolean;
    mallCommTypeValues: IDropdownOption[];
    mallChannelValues: IDropdownOption[];
    editingContact: boolean;
    fName: string;
    lName: string;
    title: string;
    email: string;
    phone: string;
    phone2: string;
    changeNotes: string;
    commTypeValues: IDropdownOption[] | [];
    channelValues: IDropdownOption[] | [];
    contacts: any[];
    contactBeingEdited: any;
    formData: any;
    loaded: boolean;
    requiredImgSizes: { width: string; height: string; dimensions: string }[];
    requiredWidth: string;
    requiredHeight: string;
    ownerValue: any;
    ownerOptions: IDropdownOption[];
    actionRequired: boolean | number;
    notificationOnly: boolean | number;
    mallData: any;
    currentMallState: any;
    changes: any[];
    changesComplete: boolean;
    reasonDescription: string;
    formSubmitted: boolean;
    submitDisabled: boolean;
    contact: any;
  } {
    return {
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      formData: null,
      loaded: false,
      typeValue: null,
      contactTypeValue: null,
      emailTypeValue: null,
      emailTypes: [
        { value: 'to', description: 'To' },
        { value: 'cc', description: 'CC' },
      ],
      countryValue: null,
      provinceValue: null,
      primaryLanguageValue: null,
      secondaryLanguageValue: null,
      optionalLanguagesValues: [],
      allowShowOptions: false,
      mallCommTypeValues: [],
      mallChannelValues: [],
      editingContact: false,
      fName: '',
      lName: '',
      title: '',
      email: '',
      phone: '',
      phone2: '',
      changeNotes: '',
      commTypeValues: [],
      channelValues: [],
      contacts: [],
      contactBeingEdited: null,
      ownerValue: null,
      ownerOptions: [],
      requiredImgSizes: [],
      requiredWidth: '',
      requiredHeight: '',
      actionRequired: true,
      notificationOnly: false,
      mallData: null,
      currentMallState: null,
      changes: [],
      changesComplete: false,
      reasonDescription: '',
      formSubmitted: false,
      submitDisabled: false,
      contact: null,
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateModalData',
      'updateClosingModal',
      'fetchMall',
      'loadMallTypes',
      'loadContactTypes',
      'loadMallOwners',
      'loadCountryOptions',
      'loadProvinceOptions',
      'updateMallRequest',
      'updateMall',
      'loadLanguageOptions',
      'loadMallCommunicationTypes',
      'loadChannels',
      'resetPagination',
      'updateChangeRequest',
    ]),
    setForm(): void {
      // initializers
      let typeId = 0;
      let mallOwnerId = 0;
      let description = '';
      let url = '';
      let promoUrl = '';
      let jobPostingUrl = '';
      let streetAddress = '';
      let city = '';
      let country = '';
      let province = '';
      let postalCode = '';
      let facebookUrl = '';
      let instagramUrl = '';
      let twitterUrl = '';
      let notes = '';
      let e2 = '';
      let fName = '';
      let lName = '';
      let title = '';

      // set values if editing existing campaign
      if (this.mallData) {
        typeId = this.mallData?.mallLocationTypeId;
        this.mallTypes?.forEach((item: IDropdownOption) => {
          if (typeId === item?.value) {
            this.typeValue = item;
          }
        });
        mallOwnerId = this.mallData?.mallOwnerId;
        if (typeof this.mallData?.mallOwnerId === 'number') {
          this.mallOwners?.forEach((owner: any) => {
            if (owner?.value === mallOwnerId) {
              this.ownerValue = owner;
            }
          });
        } else {
          this.ownerValue = {
            description: mallOwnerId,
            value: 0,
          };
          this.mallOwners.push(this.ownerValue);
        }

        this.mallData?.requirements?.forEach((requirement: any) => {
          this.requiredImgSizes.push({
            width: `${requirement.width}`,
            height: `${requirement.height}`,
            dimensions: `${requirement.width}x${requirement.height}`,
          });
        });
        description = this.mallData?.description;
        url = this.mallData?.url;
        promoUrl = this.mallData?.promoUrl;
        jobPostingUrl = this.mallData?.jobPostingUrl;
        streetAddress = this.mallData?.streetAddress;
        city = this.mallData?.city;
        country = this.mallData?.country;
        province = this.mallData?.province;
        postalCode = this.mallData?.postalCode;
        this.optionalLanguagesValues = [];
        this.languageOptions?.forEach((item: IDropdownOption) => {
          this.mallData?.languages?.forEach((language: any) => {
            if (language?.languageId === item?.value) {
              if (language?.languageTypeId === 1 || language?.Mall_Language?.languageTypeId === 1) {
                this.primaryLanguageValue = item;
              }
              if (language?.languageTypeId === 2 || language?.Mall_Language?.languageTypeId === 2) {
                this.secondaryLanguageValue = item;
              }
              if (language?.languageTypeId === 3 || language?.Mall_Language?.languageTypeId === 3) {
                this.optionalLanguagesValues.push(item);
              }
            }
          });
        });
        this.mallCommTypeValues = [];
        this.communicationTypes?.forEach((item: IDropdownOption) => {
          this.mallData?.communicationTypes?.forEach((ct: any) => {
            if (typeof ct === 'number') {
              if (item.value === ct) {
                this.mallCommTypeValues.push(item);
              }
            } else {
              if (item.value === ct.communicationTypeId) {
                this.mallCommTypeValues.push(item);
              }
            }
          });
        });
        this.mallChannelValues = [];
        this.channels?.forEach((item: IDropdownOption) => {
          this.mallData?.channels?.forEach((ch: IChannel) => {
            if (item.value === ch.channelId) {
              this.mallChannelValues.push(item);
            }
          });
        });
        this.contacts = this.mallData?.contacts;
        facebookUrl = this.mallData?.facebookUrl;
        twitterUrl = this.mallData?.twitterUrl;
        instagramUrl = this.mallData?.instagramUrl;
        notes = this.mallData?.notes;
      }

      if (this.modalData?.mode === 'approve') {
        notes = this.modalData?.row?.notes;
        e2 = this.modalData?.row?.requestedByEmail;
        fName = this.modalData?.row?.requestedByFirstName;
        lName = this.modalData?.row?.requestedByLastName;
        title = this.modalData?.row?.requestedByTitle;
        this.phone2 = this.modalData?.row?.requestedByPhoneNumber;
      } else if (this.contact) {
        fName = this.contact?.firstName;
        lName = this.contact?.lastName;
        title = this.contact?.title;
        e2 = this.contact?.email;
      }

      // set form based on user type
      this.formData = new FormBuilder({
        e: new FormControl('', [Validators.email]),
        e2: new FormControl(e2, [Validators.required, Validators.email]),
        fName: new FormControl(fName, [Validators.required]),
        lName: new FormControl(lName, [Validators.required]),
        title: new FormControl(title, [Validators.required]),
        typeId: new FormControl(typeId),
        mallOwnerId: new FormControl(mallOwnerId),
        description: new FormControl(description, [Validators.required]),
        url: new FormControl(url),
        promoUrl: new FormControl(promoUrl),
        jobPostingUrl: new FormControl(jobPostingUrl),
        streetAddress: new FormControl(streetAddress),
        city: new FormControl(city),
        country: new FormControl(country, [Validators.required]),
        province: new FormControl(province),
        postalCode: new FormControl(postalCode),
        facebookUrl: new FormControl(facebookUrl),
        twitterUrl: new FormControl(twitterUrl),
        instagramUrl: new FormControl(instagramUrl),
        notes: new FormControl(notes),
        languageFlag: new FormControl('true', [Validators.required]),
      });
    },
    async compareMallData() {
      if (this.mallData?.mallLocationTypeId !== this.currentMallState?.mallLocationTypeId) {
        this.changes.push({
          title: 'TYPE',
          oldValue: await this.getDescriptionByValue(this.currentMallState?.mallLocationTypeId, this.mallTypes),
          newValue: await this.getDescriptionByValue(this.mallData?.mallLocationTypeId, this.mallTypes),
        });
      }
      if (this.mallData?.mallOwnerId !== this.currentMallState?.mallOwnerId) {
        this.changes.push({
          title: 'MALL OWNER',
          oldValue: await this.getDescriptionByValue(this.currentMallState?.mallOwnerId, this.mallOwners),
          newValue:
            typeof this.mallData?.mallOwnerId === 'number'
              ? await this.getDescriptionByValue(this.mallData?.mallOwnerId, this.mallOwners)
              : this.mallData?.mallOwnerId,
        });
      }
      if (this.mallData?.url !== this.currentMallState?.url) {
        this.changes.push({
          title: 'WEBSITE URL',
          oldValue: this.currentMallState?.url,
          newValue: this.mallData?.url,
        });
      }
      if (this.mallData?.promoUrl !== this.currentMallState?.promoUrl) {
        this.changes.push({
          title: 'PROMO URL',
          oldValue: this.currentMallState?.promoUrl,
          newValue: this.mallData?.promoUrl,
        });
      }
      if (this.mallData?.jobPostingUrl !== this.currentMallState?.jobPostingUrl) {
        this.changes.push({
          title: 'JOB POSTING URL',
          oldValue: this.currentMallState?.jobPostingUrl,
          newValue: this.mallData?.jobPostingUrl,
        });
      }
      if (this.mallData?.twitterUrl !== this.currentMallState?.twitterUrl) {
        this.changes.push({
          title: 'TWITTER ACCOUNT',
          oldValue: this.currentMallState?.twitterUrl,
          newValue: this.mallData?.twitterUrl,
        });
      }
      if (this.mallData?.facebookUrl !== this.currentMallState?.facebookUrl) {
        this.changes.push({
          title: 'FACEBOOK ACCOUNT',
          oldValue: this.currentMallState?.facebookUrl,
          newValue: this.mallData?.facebookUrl,
        });
      }
      if (this.mallData?.instagramUrl !== this.currentMallState?.instagramUrl) {
        this.changes.push({
          title: 'INSTAGRAM ACCOUNT',
          oldValue: this.currentMallState?.instagramUrl,
          newValue: this.mallData?.instagramUrl,
        });
      }
      if (this.mallData?.streetAddress !== this.currentMallState?.streetAddress) {
        this.changes.push({
          title: 'STREET ADDRESS',
          oldValue: this.currentMallState?.streetAddress,
          newValue: this.mallData?.streetAddress,
        });
      }
      if (this.mallData?.city !== this.currentMallState?.city) {
        this.changes.push({
          title: 'CITY',
          oldValue: this.currentMallState?.city,
          newValue: this.mallData?.city,
        });
      }
      if (this.mallData?.country !== this.currentMallState?.country) {
        this.changes.push({
          title: 'COUNTRY',
          oldValue: this.currentMallState?.country,
          newValue: this.mallData?.country,
        });
      }
      if (this.mallData?.province !== this.currentMallState?.province) {
        this.changes.push({
          title: 'PROVINCE',
          oldValue: this.currentMallState?.province,
          newValue: this.mallData?.province,
        });
      }
      if (this.mallData?.postalCode !== this.currentMallState?.postalCode) {
        this.changes.push({
          title: 'POSTAL CODE',
          oldValue: this.currentMallState?.postalCode,
          newValue: this.mallData?.postalCode,
        });
      }
      if (this.mallData?.languages?.length > 0) {
        let oldPrimaryLanguage: any = '';
        let oldSecondaryLanguage: any = '';
        let oldOptionalLanguages: any = [];
        let newPrimaryLanguage: any = '';
        let newSecondaryLanguage: any = '';
        let newOptionalLanguages: any = [];
        let optionalLanguagesChanged = false;
        await this.mallData?.languages?.forEach(async (language: any) => {
          const languageId = language?.languageId;
          if (language?.languageTypeId === 1 || language?.Mall_Language?.languageTypeId === 1) {
            newPrimaryLanguage = await this.getDescriptionByValue(languageId, this.languageOptions);
          }
          if (language?.languageTypeId === 2 || language?.Mall_Language?.languageTypeId === 2) {
            newSecondaryLanguage = await this.getDescriptionByValue(languageId, this.languageOptions);
          }
          if (language?.languageTypeId === 3 || language?.Mall_Language?.languageTypeId === 3) {
            newOptionalLanguages.push(await this.getDescriptionByValue(languageId, this.languageOptions));
          }
        });
        await this.currentMallState?.languages?.forEach(async (language: any) => {
          const languageId = language?.languageId;
          if (language?.Mall_Language?.languageTypeId === 1) {
            oldPrimaryLanguage = await this.getDescriptionByValue(languageId, this.languageOptions);
          }
          if (language?.Mall_Language?.languageTypeId === 2) {
            oldSecondaryLanguage = await this.getDescriptionByValue(languageId, this.languageOptions);
          }
          if (language?.Mall_Language?.languageTypeId === 3) {
            oldOptionalLanguages.push(await this.getDescriptionByValue(languageId, this.languageOptions));
          }
        });
        newOptionalLanguages = await newOptionalLanguages?.sort();
        oldOptionalLanguages = await oldOptionalLanguages?.sort();
        if (newOptionalLanguages?.length > oldOptionalLanguages?.length) {
          newOptionalLanguages?.forEach((value: any, index: any) => {
            if (value !== oldOptionalLanguages[index]) {
              optionalLanguagesChanged = true;
            }
          });
        } else {
          oldOptionalLanguages?.forEach((value: any, index: any) => {
            if (value !== newOptionalLanguages[index]) {
              optionalLanguagesChanged = true;
            }
          });
        }
        this.optionalLanguagesValues = [];
        this.languageOptions?.forEach((option: any) => {
          if (!this.primaryLanguageValue && newPrimaryLanguage === option?.description) {
            this.primaryLanguageValue = option;
          }
          if (!this.secondaryLanguageValue && newSecondaryLanguage === option?.description) {
            this.secondaryLanguageValue = option;
          }
          newOptionalLanguages?.forEach((item: any) => {
            if (item === option?.description) {
              this.optionalLanguagesValues.push(option);
            }
          });
        });
        if (oldPrimaryLanguage !== newPrimaryLanguage) {
          this.changes.push({
            title: 'PRIMARY LANGUAGE',
            oldValue: oldPrimaryLanguage,
            newValue: newPrimaryLanguage,
          });
        }
        if (oldSecondaryLanguage !== newSecondaryLanguage) {
          this.changes.push({
            title: 'SECONDARY LANGUAGE',
            oldValue: oldSecondaryLanguage,
            newValue: newSecondaryLanguage,
          });
        }
        if (optionalLanguagesChanged) {
          this.changes.push({
            title: 'OPTIONAL LANGUAGES',
            oldValue: oldOptionalLanguages,
            newValue: newOptionalLanguages,
          });
        }
        if (this.currentMallState?.requirements?.length !== this.mallData?.requirements?.length) {
          this.changes.push({
            title: 'REQUIRED IMAGE SIZES',
            oldValue: await this.getRequirementDescripitons(this.currentMallState?.requirements),
            newValue: await this.getRequirementDescripitons(this.mallData?.requirements),
          });
        }
      }
      setTimeout(() => {
        this.allowShowOptions = true;
      }, 250);
      let newComms: any[] = [];
      let oldComms: any[] = [];
      this.mallCommTypeValues = [];
      this.communicationTypes?.forEach((item: IDropdownOption) => {
        this.mallData?.communicationTypes?.forEach((ct: any) => {
          if (typeof ct === 'number') {
            if (item.value === ct) {
              this.mallCommTypeValues.push(item);
              newComms.push(item?.description);
            }
          } else {
            if (item.value === ct.communicationTypeId) {
              this.mallCommTypeValues.push(item);
            }
          }
        });
        this.currentMallState?.communicationTypes?.forEach((item: any) => {
          oldComms.push(item?.description);
        });
      });
      newComms = newComms?.sort();
      oldComms = oldComms?.sort();
      oldComms = oldComms.filter((v, i, a) => a.indexOf(v) === i).filter((c) => c !== 'Store Memo');
      if (newComms?.length !== oldComms?.length) {
        this.changes.push({
          title: 'COMMUNICATION TYPES',
          oldValue: oldComms,
          newValue: newComms,
        });
      } else if (newComms?.length > oldComms?.length) {
        newComms?.forEach((value: any, index: any) => {
          if (value !== oldComms[index]) {
            this.changes.push({
              title: 'COMMUNICATION TYPES',
              oldValue: oldComms,
              newValue: newComms,
            });
          }
        });
      } else if (newComms?.length < oldComms?.length) {
        oldComms?.forEach((value: any, index: any) => {
          if (value !== newComms[index]) {
            this.changes.push({
              title: 'COMMUNICATION TYPES',
              oldValue: oldComms,
              newValue: newComms,
            });
          }
        });
      }
      let newChannels: any[] = [];
      let oldChannels: any[] = [];
      this.mallChannelValues = [];
      this.channels?.forEach((item: IDropdownOption) => {
        this.mallData?.channels?.forEach((ch: IChannel) => {
          if (typeof ch === 'number') {
            if (item.value === ch) {
              this.mallChannelValues.push(item);
              newChannels.push(item?.description);
            }
          } else {
            if (item.value === ch.channelId) {
              this.mallChannelValues.push(item);
            }
          }
        });
        this.currentMallState?.channels?.forEach((item: any) => {
          oldChannels.push(item?.description);
        });
      });
      newChannels = newChannels?.sort();
      oldChannels = oldChannels?.sort();
      oldChannels = oldChannels.filter((v, i, a) => a.indexOf(v) === i);
      if (newChannels?.length !== oldChannels?.length) {
        this.changes.push({
          title: 'CHANNELS',
          oldValue: oldChannels,
          newValue: newChannels,
        });
      } else if (newChannels?.length > oldChannels?.length) {
        newChannels?.forEach((value: any, index: any) => {
          if (value !== oldChannels[index]) {
            this.changes.push({
              title: 'CHANNELS',
              oldValue: oldChannels,
              newValue: newChannels,
            });
          }
        });
      } else if (newChannels?.length < oldChannels?.length) {
        oldChannels?.forEach((value: any, index: any) => {
          if (value !== newChannels[index]) {
            this.changes.push({
              title: 'CHANNELS',
              oldValue: oldChannels,
              newValue: newChannels,
            });
          }
        });
      }
      let newContacts: any[] = [];
      let oldContacts: any[] = [];
      this.mallData?.contacts?.forEach((co: any) => {
        newContacts.push({
          contactTypeId: co?.contactTypeId,
          firstName: co?.firstName,
          lastName: co?.lastName,
          title: co?.title,
          email: co?.email,
          phoneNumber: co?.phoneNumber,
          emailType: co?.emailType,
          communicationTypes: co?.communicationTypes?.map((type: any) => {
            if (typeof type === 'number') {
              return type;
            } else {
              return type?.communicationTypeId;
            }
          }),
          channels: co?.channels?.map((type: any) => {
            if (typeof type === 'number') {
              return type;
            } else {
              return type?.channelId;
            }
          }),
        });
      });
      this.currentMallState?.contacts?.forEach((co: any) => {
        oldContacts.push({
          contactTypeId: co?.contactTypeId,
          firstName: co?.firstName,
          lastName: co?.lastName,
          title: co?.title,
          email: co?.email,
          phoneNumber: co?.phoneNumber,
          emailType: co?.emailType,
          communicationTypes: co?.communicationTypes?.map((type: any) => type?.communicationTypeId),
          channels: co?.channels?.map((type: any) => type?.channelId),
        });
      });
      if (newContacts?.length !== oldContacts?.length) {
        this.changes.push({
          title: 'CONTACTS',
          oldValue: oldContacts,
          newValue: newContacts,
        });
      } else {
        let changesExists = false;
        newContacts = newContacts?.sort((a: any, b: any) => {
          return a?.email < b?.email ? -1 : 1;
        });
        oldContacts = oldContacts?.sort((a: any, b: any) => {
          return a?.email < b?.email ? -1 : 1;
        });
        newContacts?.forEach((newValue: any) => {
          let emailExists = false;
          oldContacts?.forEach((oldValue: any) => {
            if (newValue?.email === oldValue?.email) {
              emailExists = true;
              if (
                oldValue?.contactTypeId !== newValue?.contactTypeId ||
                oldValue?.firstName !== newValue?.firstName ||
                oldValue?.lastName !== newValue?.lastName ||
                oldValue?.title !== newValue?.title ||
                oldValue?.phoneNumber !== newValue?.phoneNumber ||
                oldValue?.emailType !== newValue?.emailType
              ) {
                changesExists = true;
              }
              oldValue.communicationTypes = oldValue?.communicationTypes?.sort();
              newValue.communicationTypes = newValue?.communicationTypes?.sort();
              if (oldValue?.communicationTypes?.length > oldValue?.communicationTypes?.length) {
                oldValue?.communicationTypes?.forEach((element: any, index: any) => {
                  if (element !== newValue?.communicationTypes[index]) {
                    changesExists = true;
                  }
                });
              } else {
                newValue?.communicationTypes?.forEach((element: any, index: any) => {
                  if (element !== oldValue?.communicationTypes[index]) {
                    changesExists = true;
                  }
                });
              }

              oldValue.channels = oldValue?.channels?.sort();
              newValue.channels = newValue?.channels?.sort();
              if (oldValue?.channels?.length > oldValue?.channels?.length) {
                oldValue?.channels?.forEach((element: any, index: any) => {
                  if (element !== newValue?.channels[index]) {
                    changesExists = true;
                  }
                });
              } else {
                newValue?.channels?.forEach((element: any, index: any) => {
                  if (element !== oldValue?.channels[index]) {
                    changesExists = true;
                  }
                });
              }
            }
          });
          if (!emailExists) {
            changesExists = true;
          }
        });
        if (changesExists) {
          this.changes.push({
            title: 'CONTACTS',
            oldValue: oldContacts,
            newValue: newContacts,
          });
        }
      }
      this.changesComplete = true;
      this.changeNotes = this.modalData?.row?.notes ? this.modalData?.row?.notes : this.currentMallState?.notes;
      if (this.changeNotes) {
        this.changes.push({
          title: 'NOTES',
          oldValue: '',
          newValue: this.changeNotes,
        });
      }
    },
    getRequirementDescripitons(list: any) {
      let desc: any = [];
      list?.forEach((item: any) => {
        desc.push(`${item?.width}x${item?.height} (${item?.aspectRatio})`);
      });
      return desc;
    },
    async getDescriptionByValue(id: number, list: any[]) {
      let desc = '';
      await list.forEach((item: any) => {
        if (item?.value === id) {
          desc = item?.description;
        }
      });
      return desc;
    },
    addRequiredImageSize() {
      if (this.requiredHeight && this.requiredWidth) {
        this.requiredImgSizes.push({
          width: `${this.requiredWidth}`,
          height: `${this.requiredHeight}`,
          dimensions: `${this.requiredWidth}x${this.requiredHeight}`,
        });
        let gcd = this.gcd(+this.requiredWidth, +this.requiredHeight);
        if ((+this.requiredWidth / gcd + '').length >= 3 || (+this.requiredHeight / gcd + '').length >= 3) {
          this.$notify({
            title: 'SOMETHING LOOKS OFF',
            text: `Please confirm that the size requirement of ${this.requiredWidth}x${this.requiredHeight} is correct as the ratio ${+this
              .requiredWidth / gcd}:${+this.requiredHeight / gcd} looks like it might be incorrect`,
            type: 'warn',
            duration: 10000,
          });
        }
        this.requiredWidth = '';
        this.requiredHeight = '';
        this.setChangesMade();
      }
    },
    removeRequiredImageSize(image: any) {
      this.requiredImgSizes = this.requiredImgSizes.filter((item: any) => item?.dimensions !== image?.dimensions);
      this.setChangesMade();
    },
    gcd(a: number, b: number): number {
      return b === 0 ? a : this.gcd(b, a % b);
    },
    getAspectRatio(a: number, b: number): string {
      let ratio = '';
      let gcd = this.gcd(a, b);
      ratio = `${a / gcd}:${b / gcd}`;
      return ratio;
    },
    updateOwner(value: IDropdownOption) {
      this.ownerValue = value;
      this.formData.controls.mallOwnerId.value = value.value;
      this.setChangesMade();
    },
    clearOwner(value: any) {
      if (value === '') {
        this.ownerValue = null;
        this.formData.controls.mallOwnerId.value = null;
        this.setChangesMade();
      }
    },
    addNewOwner(event: any): void {
      this.ownerOptions.push({
        description: event,
        value: event,
      });
      this.setChangesMade();
    },
    clearType(value: any) {
      if (value === '') {
        this.typeValue = null;
        this.formData.controls.typeId.value = null;
        this.setChangesMade();
      }
    },
    addNewType(event: any): void {
      this.ownerOptions.push({
        description: event,
        value: event,
      });
      this.setChangesMade();
    },
    updateType(value: IDropdownOption) {
      this.typeValue = value;
      this.formData.controls.typeId.value = this.typeValue?.value;
      this.setChangesMade();
    },
    updateContactType(value: IDropdownOption) {
      this.contactTypeValue = value;
      this.setChangesMade();
    },
    updateEmailType(value: IDropdownOption) {
      this.emailTypeValue = value;
      this.setChangesMade();
    },
    updateCountry(value: IDropdownOption) {
      this.countryValue = value;
      this.formData.controls.country.value = this.countryValue.description;
      this.provinceValue = null;
      this.loadProvinceOptions(this.countryValue.value);
      this.setChangesMade();
      this.validateControl('country');
    },
    updateProvince(value: IDropdownOption) {
      this.provinceValue = value;
      this.formData.controls.province.value = this.provinceValue.description;
      this.setChangesMade();
    },
    updatePrimaryLanguage(value: IDropdownOption) {
      this.primaryLanguageValue = value;
      this.formData.controls.languageFlag.value = 'true';
      this.setChangesMade();
      this.validateControl('languageFlag');
    },
    clearPrimaryLanguage(value: any) {
      if (value === '') {
        this.primaryLanguageValue = null;
        this.formData.controls.languageFlag.value = '';
        this.setChangesMade();
        this.validateControl('languageFlag');
      }
    },
    updateSecondaryLanguage(value: IDropdownOption) {
      this.secondaryLanguageValue = value;
      this.setChangesMade();
    },
    clearSecondaryLanguage(value: any) {
      if (value === '') {
        this.secondaryLanguageValue = null;
        this.setChangesMade();
      }
    },
    optionalLanguagesUpdated(value: any): void {
      this.optionalLanguagesValues = value;
      this.setChangesMade();
    },
    mallCommTypesUpdated(value: any): void {
      this.mallCommTypeValues = value;
      this.setChangesMade();
    },
    commTypesUpdated(value: any): void {
      this.commTypeValues = value;
      this.setChangesMade();
    },
    mallChannelsUpdated(value: any): void {
      this.mallChannelValues = value;
      this.setChangesMade();
    },
    channelsUpdated(value: any): void {
      this.channelValues = value;
      this.setChangesMade();
    },
    getContactType(id: number) {
      let item = '';
      this.contactTypes?.forEach((type: IDropdownOption) => {
        if (type.value === id) {
          item = type.description;
        }
      });
      return item;
    },
    getCommunicationTypes(ids: any[]): string {
      let types = '';
      if (ids) {
        ids?.forEach((id) => {
          this.communicationTypes?.forEach((type: any) => {
            if (typeof id === 'object') {
              if (type.value === id.communicationTypeId) {
                types = types + type.description + ', ';
              }
            }
            if (typeof id === 'number') {
              if (type.value === id) {
                types = types + type.description + ', ';
              }
            }
          });
        });
        types = types.slice(0, types.length - 2);
      }
      return types;
    },
    getChannels(ids: any[]): string {
      let channels = '';
      if (ids) {
        ids.forEach((id) => {
          this.channels.forEach((channel: any) => {
            if (typeof id === 'object') {
              if (channel.value === id.channelId) {
                channels = channels + channel.description + ', ';
              }
            }
            if (typeof id === 'number') {
              if (channel.value === id) {
                channels = channels + channel.description + ', ';
              }
            }
          });
        });
        channels = channels.slice(0, channels.length - 2);
      }
      return channels;
    },
    addContact() {
      if (this.email && this.contactTypeValue && this.emailTypeValue && this.formData?.controls?.e?.errors?.status === 'success') {
        let emails = this.contacts.map((contact) => contact.email?.trim());
        if (emails.includes(this.email?.trim())) {
          this.$notify({
            title: 'ERROR',
            text: 'A contact with this email already exists!',
            type: 'error',
            duration: 5000,
          });
        } else {
          this.contacts.push({
            firstName: this.fName,
            lastName: this.lName,
            title: this.title,
            email: this.email?.trim(),
            phoneNumber: this.phone,
            emailType: this.emailTypeValue.value,
            actionRequired: this.actionRequired,
            notificationOnly: this.notificationOnly,
            contactTypeId: this.contactTypeValue?.value as number,
            communicationTypes: this.commTypeValues.map((el) => el.value),
            channels: this.channelValues.map((el) => el.value),
          });
          this.$notify({
            title: 'SUCCESS',
            text: 'Successfully added contact!',
            type: 'success',
            duration: 5000,
          });
          this.fName = '';
          this.lName = '';
          this.title = '';
          this.email = '';
          this.phone = '';
          this.contactTypeValue = null;
          this.emailTypeValue = null;
          this.commTypeValues = [];
          this.channelValues = [];
          this.actionRequired = true;
          this.notificationOnly = false;
        }
      } else {
        this.$notify({
          title: 'ERROR',
          text: 'Please properly fill in all required fields!',
          type: 'error',
          duration: 5000,
        });
      }
    },
    editContact(contact: IContact) {
      this.editingContact = true;
      this.contactBeingEdited = contact;
      this.fName = contact?.firstName ?? '';
      this.lName = contact?.lastName ?? '';
      this.title = contact?.title ?? '';
      this.email = contact.email?.trim();
      this.phone = contact?.phoneNumber ?? '';
      // this.actionRequired = contact?.actionRequired === true || contact?.actionRequired === false ? contact?.actionRequired : true;
      this.actionRequired = contact?.actionRequired ? true : false;
      // this.notificationOnly = contact?.notificationOnly === true || contact?.notificationOnly === false ? contact?.notificationOnly : false;
      this.notificationOnly = !contact?.actionRequired && contact?.notificationOnly ? true : false;

      this.contactTypes?.forEach((type: IDropdownOption) => {
        if (contact.contactTypeId === type.value) {
          this.contactTypeValue = type;
        }
      });
      this.emailTypes?.forEach((type: IDropdownOption) => {
        if (contact.emailType === type.value) {
          this.emailTypeValue = type;
        }
      });

      let ct: IDropdownOption[] = [];
      this.communicationTypes?.forEach((type: IDropdownOption) => {
        if (contact.communicationTypes) {
          contact.communicationTypes?.forEach((t: any) => {
            if (typeof t === 'object') {
              if (type.value === t.communicationTypeId) {
                ct.push(type);
              }
            }
            if (typeof t === 'number') {
              if (type.value === t) {
                ct.push(type);
              }
            }
          });
        }
      });
      this.commTypeValues = ct;

      let ch: IDropdownOption[] = [];
      this.channels.forEach((type: IDropdownOption) => {
        if (contact.channels) {
          contact.channels.forEach((t: any) => {
            if (typeof t === 'object') {
              if (type.value === t.channelId) {
                ch.push(type);
              }
            }
            if (typeof t === 'number') {
              if (type.value === t) {
                ch.push(type);
              }
            }
          });
        }
      });
      this.channelValues = ch;
    },
    cancelEdit() {
      this.editingContact = false;
      this.contactBeingEdited = null;
      this.fName = '';
      this.lName = '';
      this.title = '';
      this.email = '';
      this.phone = '';
      this.contactTypeValue = null;
      this.emailTypeValue = null;
      this.commTypeValues = [];
      this.channelValues = [];
      this.actionRequired = true;
      this.notificationOnly = false;
    },
    saveEdit() {
      if (this.email && this.contactTypeValue && this.emailTypeValue && this.formData?.controls?.e?.errors?.status === 'success') {
        this.contacts?.forEach((item: IContact) => {
          if (item.email?.trim() === this.contactBeingEdited.email?.trim()) {
            item.firstName = this.fName;
            item.lastName = this.lName;
            item.title = this.title;
            item.email = this.email?.trim();
            item.phoneNumber = this.phone;
            item.emailType = this.emailTypeValue?.value as string;
            item.contactTypeId = this.contactTypeValue?.value as number;
            item.communicationTypes = this.commTypeValues.map((el) => el.value);
            item.channels = this.channelValues.map((el) => el.value);
            item.actionRequired = this.actionRequired as boolean;
            item.notificationOnly = this.notificationOnly as boolean;
          }
        });
        this.contactBeingEdited = {};
        this.editingContact = false;
        this.fName = '';
        this.lName = '';
        this.title = '';
        this.email = '';
        this.phone = '';
        this.contactTypeValue = null;
        this.emailTypeValue = null;
        this.commTypeValues = [];
        this.channelValues = [];
        this.actionRequired = true;
        this.notificationOnly = false;
      } else {
        this.$notify({
          title: 'ERROR',
          text: 'Please properly fill in all required fields!',
          type: 'error',
          duration: 5000,
        });
      }
    },
    setChangesMade() {
      this.changesMade = true;
    },
    deleteContact(contact: IContact) {
      this.contacts = this.contacts.filter((item) => item.email?.trim() !== contact.email?.trim());
      this.setChangesMade();
    },

    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    formatDate(date: string): string {
      return dateFormatter(date);
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.reasonDescription = '';
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.reasonDescription = '';
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async approve(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors && this.primaryLanguageValue) {
        let payload: any = await this.setPayload();
        let dataSnapshot = payload?.dataSnapshot;
        await dataSnapshot?.contacts?.forEach((contact: any, index: number) => {
          contact?.communicationTypes?.forEach((communicationType: any, index2: number) => {
            if (communicationType?.communicationTypeId) {
              dataSnapshot.contacts[index].communicationTypes[index2] = communicationType?.communicationTypeId;
            }
          });
          contact?.channels?.forEach((channel: any, index2: number) => {
            if (channel?.channelId) {
              dataSnapshot.contacts[index].channels[index2] = channel?.channelId;
            }
          });
        });
        payload.dataSnapshot = JSON.stringify(dataSnapshot);
        await this.updateChangeRequest({ payload: { type: 'approve', data: payload }, requestId: this.modalData?.row?.changeRequestId });
        await this.updateMall({
          payload: dataSnapshot,
          mallId: dataSnapshot?.mallId,
        });
        this.$notify({
          title: 'SUCCESS',
          text: 'Mall changes requested have been accepted and the mall has been updated',
          type: 'success',
          duration: 11000,
        });
        this.reasonDescription = '';
        this.promptOpen = false;
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Mall form is incomplete or invalid! Please fill out all required fields.',
          type: 'warn',
          duration: 11000,
        });
      }
    },
    declinePrompt(): void {
      this.openPrompt('decline');
    },
    async decline(): Promise<void> {
      let payload: any = { ...this.modalData?.row };
      payload.dataSnapshot = JSON.stringify(payload?.dataSnapshot);
      const results = await this.updateChangeRequest({
        payload: { type: 'decline', data: payload, reasonDecline: this.reasonDescription },
        requestId: this.modalData?.row?.changeRequestId,
      });
      if (results) {
        this.$notify({
          title: 'SUCCESS',
          text: 'Mall changes requested have been declined',
          type: 'success',
          duration: 11000,
        });
      }
      this.reasonDescription = '';
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    setPayload() {
      let languages: any[] = [];
      if (this.primaryLanguageValue) {
        languages.push({
          languageId: this.primaryLanguageValue.value,
          languageTypeId: 1,
        });
      }
      if (this.secondaryLanguageValue) {
        languages.push({
          languageId: this.secondaryLanguageValue.value,
          languageTypeId: 2,
        });
      }
      if (this.optionalLanguagesValues && this.optionalLanguagesValues?.length > 0) {
        this.optionalLanguagesValues?.forEach((item: IDropdownOption) => {
          languages.push({
            languageId: item.value,
            languageTypeId: 3,
          });
        });
      }
      let requirements: any[] = [];
      if (this.requiredImgSizes && this.requiredImgSizes?.length > 0) {
        this.requiredImgSizes?.forEach((requirement: any) => {
          requirements.push({
            height: requirement.height,
            width: requirement.width,
            aspectRatio: this.getAspectRatio(requirement.width, requirement.height),
          });
        });
      }
      let cts = this.mallCommTypeValues.map((type: any) => type.value);
      let chs = this.mallChannelValues.map((type: any) => type.value);

      const payload = {
        requestedByFirstName: this.formData?.controls?.fName?.value,
        requestedByLastName: this.formData?.controls?.lName?.value,
        requestedByTitle: this.formData?.controls?.title?.value,
        requestedByEmail: this.formData?.controls?.e2?.value,
        requestedByPhoneNumber: this.phone2,
        notes: this.changeNotes,
        dataSnapshot: {
          mallId: this.mallData?.mallId,
          mallLocationTypeId: this.formData?.controls?.typeId?.value,
          description: this.formData?.controls?.description?.value,
          facebookUrl: this.formData?.controls?.facebookUrl?.value,
          twitterUrl: this.formData?.controls?.twitterUrl?.value,
          instagramUrl: this.formData?.controls?.instagramUrl?.value,
          notes: this.formData?.controls?.notes?.value,
          streetAddress: this.formData?.controls?.streetAddress?.value,
          city: this.formData?.controls?.city?.value,
          country: this.formData?.controls?.country?.value,
          province: this.formData?.controls?.province?.value,
          postalCode: this.formData?.controls?.postalCode?.value,
          url: this.formData?.controls?.url?.value,
          mallOwnerId: this.formData?.controls?.mallOwnerId?.value,
          promoUrl: this.formData?.controls?.promoUrl?.value,
          jobPostingUrl: this.formData?.controls?.jobPostingUrl?.value,
          languages: languages,
          requirements: requirements,
          contacts: this.contacts,
          communicationTypes: cts,
          channels: chs,
        },
      };
      return payload;
    },
    async save(): Promise<void> {
      this.submitDisabled = true;
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors && this.primaryLanguageValue) {
        const payload = await this.setPayload();
        this.saving = true;
        try {
          const response = await this.updateMallRequest(payload);
          this.saving = false;
          if (response?.success === false) {
            this.$notify({
              title: 'ERROR',
              text: response?.message,
              type: 'error',
              duration: 11000,
            });
          } else {
            this.resetPagination();
            this.formSubmitted = true;
            this.changesMade = false;
            this.closeModal();
            this.clearMetadata();
          }
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: 'Unable to request mall changes',
            type: 'error',
            duration: 11000,
          });
          this.submitDisabled = false;
        }
      } else {
        this.submitDisabled = false;
        this.$notify({
          title: 'INVALID FORM',
          text: 'Mall form is incomplete or invalid! Please fill out all required fields.',
          type: 'warn',
          duration: 11000,
        });
      }
    },
  },
});
