
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable vue/require-emit-validator */
import { defineComponent } from 'vue';
import TableHeader from './TableHeader/TableHeader.vue';
import TableCell from './TableCell/TableCell.vue';
import Button from '../../atoms/Button/Button.vue';
import Tooltip from '../../atoms/Tooltip/Tooltip.vue';
import { SortDefinition } from '../../../utilities/Types/table.types';
import Loader from '../../atoms/Loader/Loader.vue';

export default defineComponent({
  emits: [
    'sort',
    'rowClicked',
    'approvalClicked',
    'toggleAllCheckboxes',
    'actionClicked',
    'selectedActionClicked',
    'checkboxClicked',
    'answersClicked',
    'editChannels',
    'emailsClicked',
  ],
  components: {
    'arc-table-header': TableHeader,
    'arc-table-cell': TableCell,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-loader': Loader,
  },
  props: {
    columnDefinitions: {
      type: Array,
      required: true,
    },
    tableData: {
      required: true,
    },
    tableOptions: {
      type: Object,
    },
    checked: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    showMasterCheckbox: {
      type: Boolean,
      default: true,
    },
    showSelectedActions: {
      type: Boolean,
    },
    isAdmin: {
      type: Boolean,
    },
  },
  computed: {
    rowClass(): string {
      return `${this.tableOptions?.clickable ? 'clickable' : ''} `;
    },
  },
  methods: {
    sort(payload: SortDefinition) {
      this.$emit('sort', payload);
    },
    tryRowClicked(row: any) {
      if (this.tableOptions?.clickable) {
        this.$emit('rowClicked', row);
      }
    },
    approvalClicked(payload: { status: string; type: string }, row: any) {
      this.$emit('approvalClicked', { status: payload.status, type: payload.type, row });
    },
    answersClicked(row: any) {
      this.$emit('answersClicked', row);
    },
    toggleAllCheckboxes(): void {
      this.$emit('toggleAllCheckboxes');
    },
    editChannels(row: any): void {
      this.$emit('editChannels', row);
    },
    emailsClicked(row: any): void {
      this.$emit('emailsClicked', row);
    },
  },
});
