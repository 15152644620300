
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import Tooltip from '@/atomic/atoms/Tooltip/Tooltip.vue';

export default defineComponent({
  components: {
    'arc-tooltip': Tooltip,
  },
  emits: {
    fileChange: (files: any) => files,
  },
  props: {
    id: {
      type: String,
      default: 'upload',
    },
    uploadPercentage: {
      type: Number,
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'file',
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disablePercentage: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    marketingCampaignsTooltip: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  computed: {
    accept(): string {
      let accept = '*';
      if (this.type === 'file') {
        accept = '*';
      } else if (this.type === 'audio') {
        accept = 'audio/*';
      } else if (this.type === 'video') {
        accept = 'video/*';
      } else if (this.type === 'image') {
        accept = 'image/png, image/jpeg';
        // image/gif;
        // accept = 'image/*';
      }
      return accept;
    },
    text(): string {
      let text = 'UPLOAD';
      if (this.title) {
        text = this.title;
      } else if (this.type === 'file') {
        text = 'UPLOAD FILE';
      } else if (this.type === 'audio') {
        text = 'UPLOAD AUDIO';
      } else if (this.type === 'video') {
        text = 'UPLOAD VIDEO';
      } else if (this.type === 'image') {
        text = 'UPLOAD IMAGE';
      }
      return text;
    },
    toolTipText(): string {
      return (
        '\u2022 Red: You need to upload the recommended image size in order to submit your campaign.\n' +
        '\n' +
        '\u2022 Yellow: While the size recommendation is not met, based on your uploaded images, we have auto-created the required images. You can submit the campaign at this time, but if preferred, you need to upload the required image size to change from Yellow to Green.\n' +
        '\n' +
        '\u2022 Green: Size requirements met, and you can submit this campaign.'
      );
    },
    loadingStatus(): {
      icon__container: boolean;
      'icon__container--hidden': boolean;
    } {
      return {
        icon__container: this.isLoading,
        'icon__container--hidden': !this.isLoading,
      };
    },
    fileUploadStatus(): {
      'file-upload--error': boolean;
    } {
      return {
        'file-upload--error': this.status === 'error',
      };
    },
    fileUploadLabel(): {
      'file-upload__label--error': boolean;
    } {
      return {
        'file-upload__label--error': this.status === 'error',
      };
    },
  },
  methods: {
    ...mapActions(['setLoading']),
    filesChange(files: any) {
      if (!files.length) return;
      this.$emit('fileChange', files);
    },
  },
  watch: {
    isLoading(): void {
      // this.setLoading();
      return;
    },
  },
});
