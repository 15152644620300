
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

export default defineComponent({
  components: {},
  emits: {
    itemSelected: String,
  },
  props: {
    type: {
      type: String,
      default: 'communications',
    },
  },
  computed: {
    ...mapGetters(['modalData', 'user', 'access', 'permissions']),
  },
  methods: {
    ...mapActions(['closeModal']),
    select(selection: string) {
      this.$emit('itemSelected', selection);
    },
    route(route: string) {
      this.$router.push(route);
      this.closeModal();
    },
  },
});
