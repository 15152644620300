import { AbortHandler } from '@/services/AbortHandler';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/assets',
    name: 'Assets',
    component: () => import('../views/Assets/Assets.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Auth/Login/Login.vue'),
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('../views/Auth/Forgot/Forgot.vue'),
  },
  {
    path: '/code',
    name: 'Code',
    component: () => import('../views/Auth/Code/Code.vue'),
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/Auth/Reset/Reset.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard/Dashboard.vue'),
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import('../views/Create/Create.vue'),
  },
  {
    path: '/communications',
    name: 'Communications',
    component: () => import('../views/Communications/Communications.vue'),
    children: [
      {
        path: 'all',
        name: 'ViewAllCommunications',
        component: () => import('../views/Communications/ViewAllCommunications/ViewAllCommunications.vue'),
      },
      {
        path: 'marketing-campaigns',
        name: 'ViewMarketingCampaigns',
        component: () => import('../views/Communications/ViewMarketingCampaigns/ViewMarketingCampaigns.vue'),
      },
      {
        path: 'recruitment-campaigns',
        name: 'ViewRecruitmentCampaigns',
        component: () => import('../views/Communications/ViewRecruitmentCampaigns/ViewRecruitmentCampaigns.vue'),
      },
      {
        path: 'mall-memos',
        name: 'ViewMallMemos',
        component: () => import('../views/Communications/ViewMallMemos/ViewMallMemos.vue'),
      },
      {
        path: 'store-memos',
        name: 'ViewStoreMemos',
        component: () => import('../views/Communications/ViewStoreMemos/ViewStoreMemos.vue'),
      },
    ],
  },
  {
    path: '/communications-mall',
    alias: '/cm',
    name: 'CommunicationsMall',
    component: () => import('../views/CommunicationsMall/CommunicationsMall.vue'),
    children: [
      {
        path: 'all',
        alias: 'all',
        name: 'MallViewAllCommunications',
        component: () => import('../views/CommunicationsMall/ViewAllCommunications/ViewAllCommunications.vue'),
        children: [
          {
            path: ':id',
            name: 'DynamicMallViewAllCommunications',
            component: () => import('../views/CommunicationsMall/ViewAllCommunications/ViewAllCommunications.vue'),
          },
        ],
      },
      {
        path: 'marketing-campaigns',
        alias: 'mc',
        name: 'MallViewMarketingCampaigns',
        component: () => import('../views/CommunicationsMall/ViewMarketingCampaigns/ViewMarketingCampaigns.vue'),
        children: [
          {
            path: ':id',
            name: 'DynamicMallViewMarketingCampaigns',
            component: () => import('../views/CommunicationsMall/ViewMarketingCampaigns/ViewMarketingCampaigns.vue'),
          },
        ],
      },
      {
        path: 'recruitment-campaigns',
        alias: 'rc',
        name: 'MallViewRecruitmentCampaigns',
        component: () => import('../views/CommunicationsMall/ViewRecruitmentCampaigns/ViewRecruitmentCampaigns.vue'),
        children: [
          {
            path: ':id',
            name: 'DynamicMallViewRecruitmentCampaigns',
            component: () => import('../views/CommunicationsMall/ViewRecruitmentCampaigns/ViewRecruitmentCampaigns.vue'),
          },
        ],
      },
      {
        path: 'mall-memos',
        alias: 'mm',
        name: 'MallViewMallMemos',
        component: () => import('../views/CommunicationsMall/ViewMallMemos/ViewMallMemos.vue'),
        children: [
          {
            path: ':id',
            name: 'DynamicMallViewMallMemos',
            component: () => import('../views/CommunicationsMall/ViewMallMemos/ViewMallMemos.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import('../views/Reports/Reports.vue'),
    children: [
      {
        path: 'marketing-campaigns',
        name: 'MarketingCampaignsReport',
        component: () => import('../views/Reports/MarketingCampaignsReport/MarketingCampaignsReport.vue'),
      },
      {
        path: 'recruitment-campaigns',
        name: 'RecruitmentCampaignsReport',
        component: () => import('../views/Reports/RecruitmentCampaignsReport/RecruitmentCampaignsReport.vue'),
      },
      {
        path: 'mall-memos',
        name: 'MallMemosReport',
        component: () => import('../views/Reports/MallMemosReport/MallMemosReport.vue'),
      },
      {
        path: 'store-memos',
        name: 'StoreMemosReport',
        component: () => import('../views/Reports/StoreMemosReport/StoreMemosReport.vue'),
      },
      {
        path: 'opportunities',
        name: 'OpportunitiesReport',
        component: () => import('../views/Reports/OpportunitiesReport/OpportunitiesReport.vue'),
      },
    ],
  },
  {
    path: '/mall-admin-reports',
    name: 'MallAdminReports',
    component: () => import('../views/MallAdminReports/MallAdminReports.vue'),
    children: [
      {
        path: 'marketing-campaigns',
        name: 'MallAdminMarketingCampaignsReport',
        component: () => import('../views/MallAdminReports/MallAdminMarketingCampaignsReport/MallAdminMarketingCampaignsReport.vue'),
      },
      {
        path: 'recruitment-campaigns',
        name: 'MallAdminRecruitmentCampaignsReport',
        component: () => import('../views/MallAdminReports/MallAdminRecruitmentCampaignsReport/MallAdminRecruitmentCampaignsReport.vue'),
      },
    ],
  },
  {
    path: '/review',
    alias: '/r',
    name: 'Review',
    component: () => import('../views/Review/Review.vue'),
    children: [
      {
        path: 'marketing-campaign',
        alias: 'mc',
        name: 'MarketingCampaignReview',
        component: () => import('../views/Review/MarketingCampaignReview/MarketingCampaignReview.vue'),
        children: [
          {
            path: ':id',
            name: 'DynamicMarketingCampaignReview',
            component: () => import('../views/Review/MarketingCampaignReview/MarketingCampaignReview.vue'),
          },
        ],
      },
      {
        path: 'recruitment-campaign',
        alias: 'rc',
        name: 'RecruitmentCampaignReview',
        component: () => import('../views/Review/RecruitmentCampaignReview/RecruitmentCampaignReview.vue'),
        children: [
          {
            path: ':id',
            name: 'DynamicRecruitmentCampaignReview',
            component: () => import('../views/Review/RecruitmentCampaignReview/RecruitmentCampaignReview.vue'),
          },
        ],
      },
      {
        path: 'mall-memo',
        alias: 'mm',
        name: 'MallMemoReview',
        component: () => import('../views/Review/MallMemoReview/MallMemoReview.vue'),
        children: [
          {
            path: ':id',
            name: 'DynamicMallMemoReview',
            component: () => import('../views/Review/MallMemoReview/MallMemoReview.vue'),
          },
        ],
      },
      {
        path: 'store-memo',
        alias: 'sm',
        name: 'StoreMemoReview',
        component: () => import('../views/Review/StoreMemoReview/StoreMemoReview.vue'),
        children: [
          {
            path: ':id',
            name: 'DynamicStoreMemoReview',
            component: () => import('../views/Review/StoreMemoReview/StoreMemoReview.vue'),
          },
        ],
      },
      {
        path: 'mall-details',
        name: 'MallDetailsReview',
        component: () => import('../views/Review/MallDetailsReview/MallDetailsReview.vue'),
      },
      {
        path: 'marketing-opportunity',
        name: 'MarketingOpportunityReview',
        component: () => import('../views/Review/MarketingOpportunityReview/MarketingOpportunityReview.vue'),
      },
    ],
  },
  {
    path: '/malls',
    name: 'Malls',
    component: () => import('../views/Malls/Malls.vue'),
    children: [
      {
        path: 'view',
        name: 'ViewMalls',
        component: () => import('../views/Malls/ViewMalls/ViewMalls.vue'),
      },
      {
        path: 'change-requests',
        name: 'ChangeRequests',
        component: () => import('../views/Malls/ViewMallChangeRequests/ViewMallChangeRequests.vue'),
      },
    ],
  },
  {
    path: '/stores',
    name: 'Stores',
    component: () => import('../views/Stores/Stores.vue'),
    children: [
      {
        path: 'view',
        name: 'ViewStores',
        component: () => import('../views/Stores/ViewStores/ViewStores.vue'),
      },
    ],
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('../views/Companies/Companies.vue'),
    children: [
      {
        path: 'view',
        name: 'ViewCompanies',
        component: () => import('../views/Companies/ViewCompanies/ViewCompanies.vue'),
      },
    ],
  },
  {
    path: '/companies/brands',
    name: 'Brands',
    component: () => import('../views/Brands/Brands.vue'),
    children: [
      {
        path: 'view',
        name: 'ViewBrands',
        component: () => import('../views/Brands/ViewBrands/ViewBrands.vue'),
      },
    ],
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: () => import('../views/Emails/Emails.vue'),
    children: [
      // {
      //   path: '/opportunities/view',
      //   name: 'ViewOpportunities',
      //   component: () =>
      //     import(
      //       '../views/Emails/ViewOpportunities/ViewOpportunities.vue'
      //       ),
      // },
      {
        path: '/opportunities/emails',
        name: 'ViewEmails',
        component: () => import('../views/Emails/ViewEmails/ViewEmails.vue'),
      },
    ],
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users/Users.vue'),
    children: [
      {
        path: 'view',
        name: 'ViewUsers',
        component: () => import('../views/Users/ViewUsers/ViewUsers.vue'),
      },
    ],
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts/Contacts.vue'),
    children: [
      {
        path: 'view',
        name: 'ViewContacts',
        component: () => import('../views/Contacts/ViewContacts/ViewContacts.vue'),
      },
    ],
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () => import('../views/Tags/Tags.vue'),
    children: [
      {
        path: 'view',
        name: 'ViewTags',
        component: () => import('../views/Tags/ViewTags/ViewTags.vue'),
      },
    ],
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('../views/Notifications/Notifications.vue'),
    children: [
      {
        path: 'view',
        name: 'ViewNotifications',
        component: () => import('../views/Notifications/ViewNotifications/ViewNotifications.vue'),
      },
    ],
  },
  {
    path: '/data',
    name: 'Data',
    component: () => import('../views/Data/Data.vue'),
    children: [
      {
        path: 'countries',
        name: 'ViewCountries',
        component: () => import('../views/Data/ViewCountries/ViewCountries.vue'),
      },
      {
        path: 'provinces',
        name: 'ViewProvinces',
        component: () => import('../views/Data/ViewProvinces/ViewProvinces.vue'),
      },
      {
        path: 'languages',
        name: 'ViewLanguages',
        component: () => import('../views/Data/ViewLanguages/ViewLanguages.vue'),
      },
    ],
  },

  {
    path: '/import',
    name: 'Import',
    component: () => import('../views/Import/Import.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account/Account.vue'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/Maintenance/Maintenance.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/404/404.vue'),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // The below code has been commented out because it was causing the the refresh functionality to not work
  // properly. The issue was that the abort controller was being killed when the user navigated to a new page.
  // Since our modals are triggered by the router, the main data refresh was being killed.

  // const abortController = AbortHandler.getInstance();
  // abortController.kill();
  next();
});

export default router;
