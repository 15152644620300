import { CalendarState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';

export const CalendarModule = {
  state(): CalendarState {
    return {
      showDrafts: false,
      showExpired: false,
      firstDay: '',
      lastDay: '',
      refresh: 0,
    };
  },

  mutations: {
    showDrafts(state: CalendarState): void {
      state.showDrafts = true;
    },
    hideDrafts(state: CalendarState): void {
      state.showDrafts = false;
    },
    toggleDrafts(state: CalendarState): void {
      state.showDrafts = !state.showDrafts;
    },
    showExpired(state: CalendarState): void {
      state.showExpired = true;
    },
    hideExpired(state: CalendarState): void {
      state.showExpired = false;
    },
    toggleExpired(state: CalendarState): void {
      state.showExpired = !state.showExpired;
    },
    setCalendarLimits(state: CalendarState, payload: { firstDay: string; lastDay: string }): void {
      state.firstDay = payload?.firstDay;
      state.lastDay = payload?.lastDay;
    },
    refreshCalendar(state: CalendarState): void {
      state.refresh++;
    },
  },

  actions: {
    showUpdateDrafts(context: ActionContext<CalendarState, State>): void {
      context.commit('showDrafts');
    },
    hideUpdateDrafts(context: ActionContext<CalendarState, State>): void {
      context.commit('hideDrafts');
    },
    toggleDrafts(context: ActionContext<CalendarState, State>): void {
      context.commit('toggleDrafts');
    },
    showUpdateExpired(context: ActionContext<CalendarState, State>): void {
      context.commit('showExpired');
    },
    hideUpdateExpired(context: ActionContext<CalendarState, State>): void {
      context.commit('hideExpired');
    },
    toggleExpired(context: ActionContext<CalendarState, State>): void {
      context.commit('toggleExpired');
    },
    setCalendarLimits(context: ActionContext<CalendarState, State>): void {
      context.commit('setCalendarLimits');
    },
    refreshCalendar(context: ActionContext<CalendarState, State>): void {
      context.commit('refreshCalendar');
    },
  },

  getters: {
    showDrafts(state: CalendarState): boolean {
      return state.showDrafts;
    },
    showExpired(state: CalendarState): boolean {
      return state.showExpired;
    },
    firstDay(state: CalendarState): string {
      return state.firstDay;
    },
    lastDay(state: CalendarState): string {
      return state.lastDay;
    },
    refresh(state: CalendarState): number {
      return state.refresh;
    },
  },
};
