import { SidebarState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';

export const SidebarModule = {
  state(): SidebarState {
    return {
      sidebarOpen: false,
    };
  },

  mutations: {
    openSidebar(state: SidebarState): void {
      state.sidebarOpen = true;
    },
    closeSidebar(state: SidebarState): void {
      state.sidebarOpen = false;
    },
    toggleSidebar(state: SidebarState): void {
      state.sidebarOpen = !state.sidebarOpen;
    },
  },

  actions: {
    openSidebar(context: ActionContext<SidebarState, State>): void {
      context.commit('openSidebar');
    },
    closeSidebar(context: ActionContext<SidebarState, State>): void {
      context.commit('closeSidebar');
    },
    toggleSidebar(context: ActionContext<SidebarState, State>): void {
      context.commit('toggleSidebar');
    },
  },

  getters: {
    sidebarOpen(state: SidebarState): boolean {
      return state.sidebarOpen;
    },
  },
};
