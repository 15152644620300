/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { StoreState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { ITag } from '../../../../interfaces/src/v1/interfaces';
import { IContact, IStore } from '../../../../interfaces/src/v2/index';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';
import { AnyARecord } from 'dns';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const StoreModule = {
  state(): StoreState {
    return {
      stores: [],
      storeOptions: [],
      loadingStores: false,
    };
  },

  mutations: {
    clearStores(state: StoreState): void {
      state.storeOptions = [];
    },
    updateStores(state: StoreState, payload: IStore[]): void {
      state.stores = payload;
    },
    updateStoreOptions(state: StoreState, payload: IStore[]): void {
      state.storeOptions = payload;
    },
    updateLoadingStores(state: StoreState, payload: boolean): void {
      state.loadingStores = payload;
    },
  },

  actions: {
    clearStores(context: ActionContext<StoreState, State>): void {
      context.commit('clearStores');
    },

    async fetchStore(context: ActionContext<StoreState, State>, payload: { storeId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().getStoreById(payload.storeId);

      const store = records.data.data;

      store.mallOwnerDesc = store.mall?.mallOwner?.description;
      store.supportsMarketing = store?.mall?.communicationTypes && store?.mall?.communicationTypes?.length > 0 ? 'Yes' : 'No';
      store.companyDesc = store.company?.description;
      store.mallDesc = store.mall?.description;
      store.brandDesc = store.brand?.description;
      store.statusDesc = store.status?.description;
      store.storeAddress = {
        streetAddress: store?.streetAddress ?? '',
        city: store?.city ?? '',
        province: store?.province ?? '',
        country: store?.country ?? '',
        postalCode: store?.postalCode ?? '',
      };
      store.manager = {};

      if (store.contacts && store.contacts.length > 0) {
        const contactList: any[] = [];
        store?.contacts?.forEach((contact: IContact) => {
          if (contact?.contactTypeId === 10) {
            store.manager = {
              email: contact?.email ?? '',
              firstName: contact?.firstName ?? '',
              lastName: contact?.lastName ?? '',
              phoneNumber: contact?.phoneNumber ?? '',
              address: {
                streetAddress: store?.streetAddress ?? '',
                city: store?.city ?? '',
                province: store?.province ?? '',
                country: store?.country ?? '',
                postalCode: store?.postalCode ?? '',
              },
            };
          } else {
            if (contact?.email) {
              contactList.push(contact.email);
            }
          }

          if (contact?.communicationTypes && contact?.communicationTypes?.length > 0) {
            contact.actionRequired = contact?.communicationTypes[0]?.Contact_CommunicationType?.actionRequired === 1 ? true : false;
            contact.notificationOnly = contact?.communicationTypes[0]?.Contact_CommunicationType?.notificationOnly === 1 ? true : false;
          }
        });

        store.contactList = contactList;
      }

      if (store.tags && store.tags.length > 0) {
        const tagList: string[] = [];
        store.tags.forEach((tag: ITag) => {
          tagList.push(tag.description);
        });
        store.tagList = tagList;
      }

      return store;
    },

    async fetchStores(context: ActionContext<StoreState, State>): Promise<any> {
      context.commit('updateLoadingStores', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getStores(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((store: IStore) => {
          store.mallOwnerDesc = store.mall?.mallOwner?.description;
          const stats = store['stats'];
          store.supportsMarketing = stats.supportsMarketing ? 'Yes' : 'No';
          store.companyDesc = store.company?.description;
          store.mallDesc = store.mall?.description;
          store.brandDesc = store.brand?.description;
          store.statusDesc = store.status?.description;
          store.storeAddress = {
            streetAddress: store?.streetAddress ?? '',
            city: store?.city ?? '',
            province: store?.province ?? '',
            country: store?.country ?? '',
            postalCode: store?.postalCode ?? '',
          };
          store.manager = {};

          if (store.contacts && store.contacts.length > 0) {
            const contactList: any[] = [];
            store?.contacts?.forEach((contact: IContact) => {
              if (contact?.contactTypeId === 10) {
                store.manager = {
                  email: contact?.email ?? '',
                  firstName: contact?.firstName ?? '',
                  lastName: contact?.lastName ?? '',
                  phoneNumber: contact?.phoneNumber ?? '',
                  address: {
                    streetAddress: store?.streetAddress ?? '',
                    city: store?.city ?? '',
                    province: store?.province ?? '',
                    country: store?.country ?? '',
                    postalCode: store?.postalCode ?? '',
                  },
                };
              } else {
                if (contact?.email) {
                  contactList.push(contact.email);
                }
              }
              if (contact?.communicationTypes && contact?.communicationTypes?.length > 0) {
                contact.actionRequired = contact?.communicationTypes[0]?.Contact_CommunicationType?.actionRequired === 1 ? true : false;
                contact.notificationOnly = contact?.communicationTypes[0]?.Contact_CommunicationType?.notificationOnly === 1 ? true : false;
              }
            });
            store.contactList = contactList;
          }

          if (store.tags && store.tags.length > 0) {
            const tagList: string[] = [];
            store.tags.forEach((tag: ITag) => {
              tagList.push(tag.description);
            });
            store.tagList = tagList;
          }
        });
        context.commit('updateStores', records.data.data);
        context.commit('updateLoadingStores', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStores', false);
      }
    },

    async fetchStoreOptions(
      context: ActionContext<StoreState, State>,
      payload: { page?: number; limit?: number; search?: string; sortKey?: string; sortDirection?: string },
    ): Promise<any> {
      context.commit('updateLoadingStores', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getStores(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((store: IStore) => {
          store.mallOwnerDesc = store.mall?.mallOwner?.description;
          const stats = store['stats'];
          store.supportsMarketing = stats.supportsMarketing ? 'Yes' : 'No';
          store.companyDesc = store.company?.description;
          store.mallDesc = store.mall?.description;
          store.brandDesc = store.brand?.description;
          store.statusDesc = store.status?.description;
          store.storeAddress = {
            streetAddress: store?.streetAddress ?? '',
            city: store?.city ?? '',
            province: store?.province ?? '',
            country: store?.country ?? '',
            postalCode: store?.postalCode ?? '',
          };
          store.manager = {};

          if (store.contacts && store.contacts.length > 0) {
            const contactList: any[] = [];
            store?.contacts?.forEach((contact: IContact) => {
              if (contact?.contactTypeId === 10) {
                store.manager = {
                  email: contact?.email ?? '',
                  firstName: contact?.firstName ?? '',
                  lastName: contact?.lastName ?? '',
                  phoneNumber: contact?.phoneNumber ?? '',
                  address: {
                    streetAddress: store?.streetAddress ?? '',
                    city: store?.city ?? '',
                    province: store?.province ?? '',
                    country: store?.country ?? '',
                    postalCode: store?.postalCode ?? '',
                  },
                };
              } else {
                if (contact?.email) {
                  contactList.push(contact.email);
                }
              }
              if (contact?.communicationTypes && contact?.communicationTypes?.length > 0) {
                contact.actionRequired = contact?.communicationTypes[0]?.Contact_CommunicationType?.actionRequired === 1 ? true : false;
                contact.notificationOnly = contact?.communicationTypes[0]?.Contact_CommunicationType?.notificationOnly === 1 ? true : false;
              }
            });
            store.contactList = contactList;
          }

          if (store.tags && store.tags.length > 0) {
            const tagList: string[] = [];
            store.tags.forEach((tag: ITag) => {
              tagList.push(tag.description);
            });
            store.tagList = tagList;
          }
        });
        context.commit('updateStoreOptions', records.data.data);
        context.commit('updateLoadingStores', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStores', false);
      }
    },

    async fetchStoresByBrandId(context: ActionContext<StoreState, State>, payload: { brandId: string }): Promise<void> {
      context.commit('updateLoadingStores', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getStoresByBrandId(payload.brandId);
        records.data.data.forEach((store: IStore) => {
          store.mallOwnerDesc = store.mall?.mallOwner?.description;
          store.companyDesc = store.company?.description;
          store.brandDesc = store.brand?.description;
          store.statusDesc = store.status?.description;
          store.storeAddress = {
            streetAddress: store?.streetAddress ?? '',
            city: store?.city ?? '',
            province: store?.province ?? '',
            country: store?.country ?? '',
            postalCode: store?.postalCode ?? '',
          };
          store.manager = {};

          if (store.contacts && store.contacts.length > 0) {
            store.manager = {
              email: store?.contacts[0]?.email ?? '',
              firstName: store?.contacts[0].firstName ?? '',
              lastName: store?.contacts[0].lastName ?? '',
              phoneNumber: store?.contacts[0].phoneNumber ?? '',
              address: {
                streetAddress: store?.streetAddress ?? '',
                city: store?.city ?? '',
                province: store?.province ?? '',
                country: store?.country ?? '',
                postalCode: store?.postalCode ?? '',
              },
            };
            store?.contacts?.forEach((contact: any) => {
              if (contact?.communicationTypes && contact?.communicationTypes?.length > 0) {
                contact.actionRequired = contact?.communicationTypes[0]?.Contact_CommunicationType?.actionRequired === 1 ? true : false;
                contact.notificationOnly = contact?.communicationTypes[0]?.Contact_CommunicationType?.notificationOnly === 1 ? true : false;
              }
            });
          }

          if (store.tags && store.tags.length > 0) {
            const tagList: string[] = [];
            store.tags.forEach((tag: ITag) => {
              tagList.push(tag.description);
            });
            store.tagList = tagList;
          }
        });
        context.commit('updateStores', records.data.data);
        context.commit('updateLoadingStores', false);
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStores', false);
      }
    },

    async fetchStoresByBrandIds(context: ActionContext<StoreState, State>, payload: { brandIds: string[] }): Promise<void> {
      context.commit('updateLoadingStores', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getStoresByBrandIds(payload.brandIds);
        records.data.data.forEach((store: IStore) => {
          store.mallOwnerDesc = store.mall?.mallOwner?.description;
          store.companyDesc = store.company?.description;
          store.brandDesc = store.brand?.description;
          store.statusDesc = store.status?.description;
          store.storeAddress = {
            streetAddress: store?.streetAddress ?? '',
            city: store?.city ?? '',
            province: store?.province ?? '',
            country: store?.country ?? '',
            postalCode: store?.postalCode ?? '',
          };
          store.manager = {};

          if (store.contacts && store.contacts.length > 0) {
            store.manager = {
              email: store?.contacts[0]?.email ?? '',
              firstName: store?.contacts[0].firstName ?? '',
              lastName: store?.contacts[0].lastName ?? '',
              phoneNumber: store?.contacts[0].phoneNumber ?? '',
              address: {
                streetAddress: store?.streetAddress ?? '',
                city: store?.city ?? '',
                province: store?.province ?? '',
                country: store?.country ?? '',
                postalCode: store?.postalCode ?? '',
              },
            };
            store?.contacts?.forEach((contact: any) => {
              if (contact?.communicationTypes && contact?.communicationTypes?.length > 0) {
                contact.actionRequired = contact?.communicationTypes[0]?.Contact_CommunicationType?.actionRequired === 1 ? true : false;
                contact.notificationOnly = contact?.communicationTypes[0]?.Contact_CommunicationType?.notificationOnly === 1 ? true : false;
              }
            });
          }

          if (store.tags && store.tags.length > 0) {
            const tagList: string[] = [];
            store.tags.forEach((tag: ITag) => {
              tagList.push(tag.description);
            });
            store.tagList = tagList;
          }
        });
        context.commit('updateStoreOptions', records.data.data);
        context.commit('updateLoadingStores', false);
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStores', false);
      }
    },

    async fetchStoresByCommunicationType(
      context: ActionContext<StoreState, State>,
      payload: { communicationType: string; brandId: string; mallId: string },
    ): Promise<void> {
      context.commit('updateLoadingStores', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getStoresByCommunicationType(
          payload.communicationType,
          payload.brandId,
          payload.mallId,
        );
        records.data.data.forEach((store: IStore) => {
          store.mallOwnerDesc = store.mall?.mallOwner?.description;
          store.companyDesc = store.company?.description;
          store.brandDesc = store.brand?.description;
          store.statusDesc = store.status?.description;
          store.storeAddress = {
            streetAddress: store?.streetAddress ?? '',
            city: store?.city ?? '',
            province: store?.province ?? '',
            country: store?.country ?? '',
            postalCode: store?.postalCode ?? '',
          };
          store.manager = {};

          if (store.contacts && store.contacts.length > 0) {
            store.manager = {
              email: store?.contacts[0]?.email ?? '',
              firstName: store?.contacts[0].firstName ?? '',
              lastName: store?.contacts[0].lastName ?? '',
              phoneNumber: store?.contacts[0].phoneNumber ?? '',
              address: {
                streetAddress: store?.streetAddress ?? '',
                city: store?.city ?? '',
                province: store?.province ?? '',
                country: store?.country ?? '',
                postalCode: store?.postalCode ?? '',
              },
            };
            store?.contacts?.forEach((contact: any) => {
              if (contact?.communicationTypes && contact?.communicationTypes?.length > 0) {
                contact.actionRequired = contact?.communicationTypes[0]?.Contact_CommunicationType?.actionRequired === 1 ? true : false;
                contact.notificationOnly = contact?.communicationTypes[0]?.Contact_CommunicationType?.notificationOnly === 1 ? true : false;
              }
            });
          }

          if (store.tags && store.tags.length > 0) {
            const tagList: string[] = [];
            store.tags.forEach((tag: ITag) => {
              tagList.push(tag.description);
            });
            store.tagList = tagList;
          }
        });
        context.commit('updateStoreOptions', records.data.data);
        context.commit('updateLoadingStores', false);
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStores', false);
      }
    },

    saveStore(context: ActionContext<StoreState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveStore(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchStores');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    updateStore(context: ActionContext<StoreState, State>, payload: { payload: any; storeId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateStore(payload.payload, payload.storeId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchStores');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    deleteStore(context: ActionContext<StoreState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .deleteStore(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchStores');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    undeleteStore(context: ActionContext<StoreState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .undeleteStore(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchStores');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },
  },

  getters: {
    stores(state: StoreState): IStore[] {
      return state.stores;
    },
    storeOptions(state: StoreState): IStore[] {
      return state.storeOptions;
    },
    loadingStores(state: StoreState): boolean {
      return state.loadingStores;
    },
  },
};
