
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Tab from '../../molecules/Tab/Tab.vue';

export default defineComponent({
  components: {
    Tab,
  },

  mounted() {
    setTimeout(() => {
      console.log('USER: ', this.user);
    }, 1000);
  },

  computed: {
    ...mapGetters(['sidebarOpen', 'windowWidth', 'currentActiveRoute', 'modalOpen', 'modalType', 'modalComponent', 'user', 'access', 'permissions']),
    showOverlay(): boolean {
      if (this.sidebarOpen && this.windowWidth < 800) {
        return true;
      }
      return false;
    },
    sidebarState(): {
      'toggle--open': boolean;
      'toggle--closed': boolean;
    } {
      return {
        'toggle--open': this.sidebarOpen,
        'toggle--closed': !this.sidebarOpen,
      };
    },
  },

  methods: {
    ...mapActions([
      'openSidebar',
      'toggleSidebar',
      'closeSidebar',
      'openModal',
      'closeModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'updateCurrentUser',
      'clearSelectedRecruitmentCampaign',
      'clearSelectedMarketingCampaign',
      'clearSelectedMallMemo',
      'clearSelectedStoreMemo',
      'clearAllReports',
      'clearAllCommunications',
      'clearBrands',
      'clearCompanies',
      'clearStores',
      'clearMalls',
      'clearUsers',
    ]),

    logout() {
      this.updateCurrentUser(null);
      document.cookie = 'jwt=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      window.localStorage.removeItem('userId');
      this.clearSelectedMarketingCampaign();
      this.clearSelectedRecruitmentCampaign();
      this.clearSelectedMallMemo();
      this.clearSelectedStoreMemo();
      this.clearAllReports();
      this.clearAllCommunications();
      this.clearBrands();
      this.clearCompanies();
      this.clearMalls();
      this.clearStores();
      this.clearUsers();
    },

    toggle() {
      this.toggleSidebar();
    },

    hoverIn() {
      this.openSidebar();
    },

    hoverOut() {
      this.closeSidebar();
    },

    openCreate() {
      let type = 'system-admin';
      this.clearSelectedRecruitmentCampaign();
      this.clearSelectedMarketingCampaign();
      this.clearSelectedMallMemo();
      this.clearSelectedStoreMemo();
      this.updateModalData(null);
      this.updateModalComponent('select');
      this.updateModalType(type);
      this.openModal();
    },
  },
});
