
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    showValue: {
      type: Object,
      require: true,
    },

    pageValue: {
      type: Object,
      require: true,
    },

    totalRecords: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getCurrentPageValues(): any {
      return {
        start: (this.pageValue?.value - 1) * this.showValue?.value + 1,
        end: this.pageValue?.value * this.showValue?.value > this.totalRecords ? this.totalRecords : this.pageValue?.value * this.showValue?.value,
      };
    },
  },
});
