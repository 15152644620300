
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Table from '../../../../Table/Table.vue';
import { Tabs } from '../../../../../../utilities/Types/navigation.types';
import PageControls from '../../../../Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../../../../utilities/Types/table.types';
import { IDropdownOption } from '../../../../../../utilities/Interfaces/form.interfaces';
import { IContact } from '../../../../../../../../../interfaces/src/v2';
import Modal from '../../../../../atoms/Modal/Modal.vue';
import Button from '../../../../../atoms/Button/Button.vue';
import TextArea from '../../../../../atoms/Text-Area/Text-Area.vue';
import axios from 'axios';

export default defineComponent({
  components: {
    'arc-table': Table,
    'arc-page-controls': PageControls,
    'arc-modal': Modal,
    'arc-button': Button,
    'arc-text-area': TextArea,
  },
  computed: {
    ...mapGetters([
      'modalData',
      'marketingCampaignReview',
      'loadingMarketingCampaignReview',
      'recruitmentCampaignReview',
      'loadingRecruitmentCampaignReview',
      'mallMemoReview',
      'loadingMallMemoReview',
      'storeMemoReview',
      'loadingStoreMemoReview',
      'declineReasons',
      'user',
      'access',
      'permissions',
      'contactApprovals',
      'loadingContactApprovals',
    ]),
    isLoading(): boolean {
      return this.loadingContactApprovals;
    },
    reasons(): any[] {
      return this.declineReasons?.data;
    },
    tabs(): Tabs {
      return [
        {
          title: 'SENT',
          activeTabFlag: 'sent',
        },
        {
          title: 'APPROVED',
          activeTabFlag: 'approved',
        },
        {
          title: 'PENDING',
          activeTabFlag: 'pending',
        },
        {
          title: 'DECLINED',
          activeTabFlag: 'declined',
        },
      ];
    },
    columnDefinitions(): ColumnDefinition[] {
      let coldefs: any = [
        {
          type: 'text',
          columnName: 'ctype',
          displayName: 'Type',
          headerStyle: 'min-width: 150px;',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'communicationNumber',
          displayName: 'Communication Number',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'description',
          displayName: 'Campaign Name',
          headerStyle: 'min-width: 150px',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'brandDesc',
          displayName: 'Brand',
          headerStyle: 'min-width: 150px;',
          sortable: true,
        },
        {
          type: 'date',
          columnName: 'fromDate',
          displayName: 'Start Date',
          sortable: true,
        },
        {
          type: 'date',
          columnName: 'toDate',
          displayName: 'End Date',
          sortable: true,
        },
        {
          type: 'review',
          columnName: 'reviewPageUrl',
          displayName: 'Review Url',
          headerStyle: 'min-width: 150px',
        },
        {
          type: 'text',
          columnName: 'statusDesc',
          displayName: 'Status',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'responseTime',
          displayName: 'Response Time in Hours',
        },
      ];
      return coldefs;
    },
    tableData(): any {
      this.startLoading();
      let data: any = [];

      // ADD DATA BY TAB
      data = this.contactApprovals[this.activeTab];

      if (data && data?.length > 0) {
        data?.forEach((item: any) => {
          if (item?.statusId === 1) {
            item.statusDesc = 'Draft';
          }
          if (item?.statusId === 2) {
            item.statusDesc = 'Active';
          }
          if (item?.statusId === 3) {
            item.statusDesc = 'Upcoming';
          }
          if (item?.statusId === 4) {
            item.statusDesc = 'Expired';
          }
          if (item?.statusId === 5) {
            item.statusDesc = 'Deleted';
          }
          if (item?.statusId === 19) {
            item.statusDesc = 'Manually Expired';
          }
          item.brandDesc = item?.brand?.description;
          item.communicationNumber = item?.campaignNumber ?? item?.memoNumber;
          let type = '';
          if (item?.marketingCampaignId) {
            type = 'Marketing Campaign';
          }
          if (item?.recruitmentCampaignId) {
            type = 'Recruitment Campaign';
          }
          if (item?.memoId && item?.memoTypeId === 2) {
            type = 'Mall Memo';
          }
          if (item?.memoId && item?.memoTypeId === 1) {
            type = 'Mall Memo';
          }
          item.ctype = type;
        });
      }

      // filter by search string
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        data.forEach((element: any) => {
          let matchFound = false;
          if (typeof Object.values(element) === 'string') {
            if (Object.values(element).includes(this.searchFilter)) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'number') {
            if (
              Object.values(element)
                .toString()
                .includes(this.searchFilter)
            ) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'object' && Object.values(element)) {
            Object.values(element)
              .filter((value) => value)
              .forEach((nestedElement: any) => {
                if (typeof nestedElement === 'string') {
                  if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'number') {
                  if (nestedElement.toString().includes(this.searchFilter)) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'object' && nestedElement) {
                  Object.values(nestedElement)
                    .filter((value) => value)
                    .forEach((nestedElementL2: any) => {
                      if (typeof nestedElementL2 === 'string') {
                        if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'number') {
                        if (nestedElementL2.toString().includes(this.searchFilter)) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                        Object.values(nestedElementL2)
                          .filter((value) => value)
                          .forEach((nestedElementL3: any) => {
                            if (typeof nestedElementL3 === 'string') {
                              if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                matchFound = true;
                              }
                            }
                            if (typeof nestedElementL3 === 'number') {
                              if (nestedElementL3.toString().includes(this.searchFilter)) {
                                matchFound = true;
                              }
                            }
                          });
                      }
                    });
                }
              });
          }
          if (matchFound) {
            searchResults.push(element);
          }
        });
        data = searchResults;
      }
      // sorting
      if (data && data?.length > 0) {
        if (this.sortDefinition.direction === 'ASC') {
          if (this.sortDefinition.type === 'text') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column] > b[this.sortDefinition.column]
                ? 1
                : b[this.sortDefinition.column] > a[this.sortDefinition.column]
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'date') {
            data.sort((a: any, b: any) =>
              new Date(a[this.sortDefinition.column]) > new Date(b[this.sortDefinition.column])
                ? 1
                : new Date(b[this.sortDefinition.column]) > new Date(a[this.sortDefinition.column])
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'approval') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column].percentage > b[this.sortDefinition.column].percentage
                ? 1
                : b[this.sortDefinition.column].percentage > a[this.sortDefinition.column].percentage
                ? -1
                : 0,
            );
          }
        } else {
          if (this.sortDefinition.type === 'text') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column] < b[this.sortDefinition.column]
                ? 1
                : b[this.sortDefinition.column] < a[this.sortDefinition.column]
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'date') {
            data.sort((a: any, b: any) =>
              new Date(a[this.sortDefinition.column]) < new Date(b[this.sortDefinition.column])
                ? 1
                : new Date(b[this.sortDefinition.column]) < new Date(a[this.sortDefinition.column])
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'approval') {
            data.sort((a: any, b: any) =>
              a[this.sortDefinition.column].percentage < b[this.sortDefinition.column].percentage
                ? 1
                : b[this.sortDefinition.column].percentage < a[this.sortDefinition.column].percentage
                ? -1
                : 0,
            );
          }
        }
        // update all table data available for pagination to use
        this.updateAllTableData(data);
        // pagination
        let pageData: any[] = [];
        let startingPoint = 0;
        let pageValue: any = this.pageValue;
        let showValue: any = this.showValue;
        if (pageValue.value > 1) {
          startingPoint = showValue.value * (pageValue.value - 1);
        }
        for (let i = startingPoint; i < startingPoint + showValue.value && i < this.allTableData.length; i++) {
          pageData.push(data[i]);
        }
        data = pageData;
      }
      this.finishLoading();
      if (data && data.length > 0) {
        this.resetCheckboxes(data);
      }
      console.log('TABLE DATA: ', data);
      return data;
    },
    tableOptions(): any {
      return {
        selectable: false,
        actions: false,
      };
    },
    buttonOptions(): any {
      return {
        showCreate: false,
        showReports: false,
        showExport: true,
      };
    },
    pageOptions(): IDropdownOption[] {
      let tableData: any = this.allTableData;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(tableData.length / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let tableData: any = this.allTableData;
      let showValue: any = this.showValue.value;
      return Math.ceil(tableData.length / showValue);
    },
  },
  data(): {
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    allTableData: any;
    sortDefinition: SortDefinition;
    activeTab: string;
    checked: boolean[];
    allChecked: boolean;
    singleSelects: boolean[];
    selectedRows: any[];
    loading: boolean;
    showSelected: boolean;
    showMasterCheckbox: boolean;
    promptOpen: boolean;
    promptType: string;
    selectedReason: string;
    reasonDescription: string;
    declinedObject: any;
    questionObject: any;
    declining: boolean;
    campaignId: string;
    memoId: string;
    communication: any;
    communications: any;
    submitting: boolean;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
  } {
    return {
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      allTableData: [],
      sortDefinition: { column: 'commId', type: 'text', direction: 'DESC' },
      activeTab: 'sent',
      checked: [],
      allChecked: false,
      singleSelects: [],
      selectedRows: [],
      loading: true,
      showSelected: false,
      showMasterCheckbox: true,
      promptOpen: false,
      promptType: '',
      selectedReason: '',
      reasonDescription: '',
      declinedObject: null,
      questionObject: null,
      declining: false,
      campaignId: '',
      memoId: '',
      communication: null,
      communications: [],
      submitting: false,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
    };
  },
  async mounted(): Promise<void> {
    this.clearContactApprovals();
    this.activeTab = this.modalData.status;
    if (this.modalData?.row?.id) {
      this.fetchContactApprovals(this.modalData?.row?.id);
    }
  },
  methods: {
    ...mapActions([
      'fetchMarketingCampaigns',
      'fetchRecruitmentCampaigns',
      'fetchMallMemos',
      'fetchStoreMemos',
      'fetchMarketingCampaignReview',
      'fetchRecruitmentCampaignReview',
      'fetchMallMemoReview',
      'fetchStoreMemoReview',
      'setMarketingCampaignReviewStoreStatus',
      'setRecruitmentCampaignReviewStoreStatus',
      'setMallMemoReviewStoreStatus',
      'setStoreMemoReviewStoreStatus',
      'fetchDeclineReasons',
      'fetchContactApprovals',
      'clearContactApprovals',
      'exportContactApprovals',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    search(searchFilter: string): void {
      this.resetPageValue();
      this.searchFilter = searchFilter;
    },
    sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    updateActiveTab(tab: string) {
      this.activeTab = tab;
      this.resetPageValue();
    },
    toggleAllCheckboxes(): void {
      this.allChecked = !this.allChecked;
      let i = 0;
      this.checked.forEach(() => {
        this.checked[i] = this.allChecked;
        this.singleSelects[i] = this.allChecked;
        this.selectedRows = this.allChecked ? this.tableData : [];
        i++;
      });
    },
    resetCheckboxes(data: any): void {
      this.checked = [];
      this.singleSelects = [];
      this.allChecked = false;
      data.forEach(() => {
        this.checked.push(false);
        this.singleSelects.push(false);
      });
      this.selectedRows = [];
      this.showMasterCheckbox = false;
      setTimeout(() => {
        this.showMasterCheckbox = true;
      }, 50);
    },
    checkboxClicked(index: any): void {
      this.singleSelects[index] = !this.singleSelects[index];
    },
    async submitQuestions(): Promise<void> {
      let allQuestionsAnswered = true;
      this.communication?.questions?.forEach((question: any) => {
        if (question?.answer === '') {
          allQuestionsAnswered = false;
        }
      });
      if (allQuestionsAnswered) {
        this.submitting = true;
        this.questionObject.answers = this.communication?.questions;
        if (this.modalData.row.commType === 'mall-memo') {
          await this.setMallMemoReviewStoreStatus(this.questionObject);
          await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (this.modalData.row.commType === 'store-memo') {
          await this.setStoreMemoReviewStoreStatus(this.questionObject);
          await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        this.questionObject = null;
        this.submitting = false;
        this.closePrompt();
        this.fetchMarketingCampaigns();
        this.fetchRecruitmentCampaigns();
        this.fetchMallMemos();
        this.fetchStoreMemos();
      } else {
        this.$notify({
          title: 'WARNING',
          text: 'Please answer all questions to submit',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async actionClicked(event: any): Promise<void> {
      if (this.modalData.row.commType === 'marketing-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
          }
          await this.setMarketingCampaignReviewStoreStatus(obj);
          await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event.type === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
          }
          await this.setMarketingCampaignReviewStoreStatus(obj);
          await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event.type === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
          }
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event.type === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
          }
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event.type === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'mall-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setMallMemoReviewStoreStatus(obj);
            await this.fetchMallMemoReview({
              memoId: this.memoId,
              type: 'user',
            });
          }
        }
        if (event.type === 'reset') {
          obj.statusId = 16;
          await this.setMallMemoReviewStoreStatus(obj);
          await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (event.type === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'store-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: [event.row.storeId],
          reasonForDeclineDescription: '',
        };
        if (event.type === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setStoreMemoReviewStoreStatus(obj);
            await this.fetchStoreMemoReview({
              memoId: this.memoId,
              type: 'user',
            });
          }
        }
        if (event.type === 'reset') {
          obj.statusId = 16;
          await this.setStoreMemoReviewStoreStatus(obj);
          await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (event.type === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    async selectedActionClicked(event: string): Promise<void> {
      let selections: any = [];
      let index = 0;
      this.singleSelects.forEach((element) => {
        if (element) {
          selections.push(this.tableData[index].storeId);
        }
        index++;
      });
      if (this.modalData.row.commType === 'marketing-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
          }
          await this.setMarketingCampaignReviewStoreStatus(obj);
          await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
          }
          await this.setMarketingCampaignReviewStoreStatus(obj);
          await this.fetchMarketingCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        let obj: any = {
          campaignId: this.campaignId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 8;
          }
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event === 'reset') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 16;
          }
          await this.setRecruitmentCampaignReviewStoreStatus(obj);
          await this.fetchRecruitmentCampaignReview({
            campaignId: this.campaignId,
            type: 'user',
          });
        }
        if (event === 'declined') {
          if (this.modalData?.type === 'approval') {
            obj.statusId = 9;
          }
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'mall-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setMallMemoReviewStoreStatus(obj);
            await this.fetchMallMemoReview({
              memoId: this.memoId,
              type: 'user',
            });
          }
        }
        if (event === 'reset') {
          obj.statusId = 16;
          await this.setMallMemoReviewStoreStatus(obj);
          await this.fetchMallMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (event === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      if (this.modalData.row.commType === 'store-memo') {
        let obj: any = {
          memoId: this.memoId,
          storeIds: selections,
          reasonForDeclineDescription: '',
        };
        if (event === 'accepted') {
          obj.statusId = 8;
          if (this.communication?.questions && this.communication?.questions?.length > 0) {
            obj.answers = [];
            this.communication?.questions?.forEach((question: any) => {
              question.answer = '';
              question.answers = [];
              question.answers[0] = {
                memoQuestionId: question?.memoQuestionId,
                description: '',
                userId: this.user?.userId,
              };
            });
            this.questionObject = obj;
            this.openPrompt('question');
          } else {
            await this.setStoreMemoReviewStoreStatus(obj);
            await this.fetchStoreMemoReview({
              memoId: this.memoId,
              type: 'user',
            });
          }
        }
        if (event === 'reset') {
          obj.statusId = 16;
          await this.setStoreMemoReviewStoreStatus(obj);
          await this.fetchStoreMemoReview({
            memoId: this.memoId,
            type: 'user',
          });
        }
        if (event === 'declined') {
          obj.statusId = 9;
          this.declinedObject = obj;
          this.openPrompt('decline');
        }
      }
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    async decline() {
      this.declining = true;
      this.declinedObject.reasonForDeclineId = 0;
      this.declinedObject.reasonForDeclineDescription = `${this.selectedReason}${this.reasonDescription ? ' - ' + this.reasonDescription : ''}`;
      if (this.modalData.row.commType === 'marketing-campaign') {
        await this.setMarketingCampaignReviewStoreStatus(this.declinedObject);
        await this.fetchMarketingCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
        });
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        await this.setRecruitmentCampaignReviewStoreStatus(this.declinedObject);
        await this.fetchRecruitmentCampaignReview({
          campaignId: this.campaignId,
          type: 'user',
        });
      }
      if (this.modalData.row.commType === 'mall-memo') {
        await this.setMallMemoReviewStoreStatus(this.declinedObject);
        await this.fetchMallMemoReview({
          memoId: this.memoId,
          type: 'user',
        });
      }
      if (this.modalData.row.commType === 'store-memo') {
        await this.setStoreMemoReviewStoreStatus(this.declinedObject);
        await this.fetchStoreMemoReview({
          memoId: this.memoId,
          type: 'user',
        });
      }
      this.declining = false;
      this.closePrompt();
      this.fetchMarketingCampaigns();
      this.fetchRecruitmentCampaigns();
      this.fetchMallMemos();
      this.fetchStoreMemos();
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.selectedReason = '';
      this.reasonDescription = '';
      this.declinedObject = null;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    async export(): Promise<void> {
      let data: any = [];

      // ADD DATA BY TAB
      data = this.contactApprovals['sent'];

      if (data && data?.length > 0) {
        data?.forEach((item: any) => {
          if (item?.statusId === 1) {
            item.statusDesc = 'Draft';
          }
          if (item?.statusId === 2) {
            item.statusDesc = 'Active';
          }
          if (item?.statusId === 3) {
            item.statusDesc = 'Upcoming';
          }
          if (item?.statusId === 4) {
            item.statusDesc = 'Expired';
          }
          if (item?.statusId === 5) {
            item.statusDesc = 'Deleted';
          }
          if (item?.statusId === 19) {
            item.statusDesc = 'Manually Expired';
          }
          item.brandDesc = item?.brand?.description;
          item.communicationNumber = item?.campaignNumber ?? item?.memoNumber;
          let type = '';
          if (item?.marketingCampaignId) {
            type = 'Marketing Campaign';
          }
          if (item?.recruitmentCampaignId) {
            type = 'Recruitment Campaign';
          }
          if (item?.memoId && item?.memoTypeId === 2) {
            type = 'Mall Memo';
          }
          if (item?.memoId && item?.memoTypeId === 1) {
            type = 'Mall Memo';
          }
          item.ctype = type;
        });
      }
      this.$notify({
        title: 'Export is in progress.',
        text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
        type: 'info',
        duration: 12000,
      });
      setTimeout(() => {
        this.buttonFeedback.exportSubmitted = false;
      }, 12000);
      this.exportContactApprovals(JSON.stringify(data));
    },
  },
});
