import { UtilityState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';

export const UtilityModule = {
  state(): UtilityState {
    return {
      currentRoute: '',
      windowWidth: 0,
    };
  },

  mutations: {
    updateCurrentRoute(state: UtilityState, payload: string): void {
      state.currentRoute = payload;
    },
    updateWindowWidth(state: UtilityState, payload: number): void {
      state.windowWidth = payload;
    },
  },

  actions: {
    updateCurrentRoute(context: ActionContext<UtilityState, State>, payload: string): void {
      context.commit('updateCurrentRoute', payload);
    },
    updateWindowWidth(context: ActionContext<UtilityState, State>, payload: number): void {
      context.commit('updateWindowWidth', payload);
    },
  },

  getters: {
    currentActiveRoute(state: UtilityState): string {
      return state.currentRoute;
    },
    windowWidth(state: UtilityState): number {
      return state.windowWidth;
    },
  },
};
