
import { defineComponent } from 'vue';
import Loader from '../../atoms/Loader/Loader.vue';
import Tooltip from '../../atoms/Tooltip/Tooltip.vue';

export default defineComponent({
  components: {
    'arc-loader': Loader,
    'arc-tooltip': Tooltip,
  },
  props: {
    itemName: {
      type: String,
      required: true,
    },
    generateDescription: {
      type: Function,
      default: () => (item: any) => item.description,
    },
    itemOptions: {
      type: Array,
      required: true,
    },
    filteredItemSelections: {
      type: Array,
      required: true,
    },
    itemSelections: {
      type: Array,
      required: true,
    },
    attemptedSubmit: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isPreviewOpen: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    borderStyle(): string {
      return this.attemptedSubmit && (!this.itemSelections || this.itemSelections?.length === 0)
        ? 'border: 1px hsl(4, 76%, 59%) solid;'
        : 'border: 1px hsl(0, 0%, 84%) solid;';
    },
    loaderStyle(): string {
      let style = '';
      if (this.isPreviewOpen) {
        style = 'transform: translate(-21%, 4rem);';
      } else {
        style = 'transform: translate(1%, 4rem);';
      }
      return style;
    },
  },
  methods: {
    itemDescription(item: any): string {
      return this.generateDescription(item);
    },
  },
});
