export interface IanaTimezone {
  timezone: string;
  value: string;
  description: string;
}

export const IANA_TIMEZONES = [
  // UTC+14:00
  { timezone: 'UTC+14:00', value: 'Pacific/Kiritimati', description: 'Pacific/Kiritimati (+14)' },
  // UTC+13:00
  { timezone: 'UTC+13:00', value: 'Pacific/Apia', description: 'Pacific/Apia (+13)' },
  { timezone: 'UTC+13:00', value: 'Pacific/Enderbury', description: 'Pacific/Enderbury (+13)' },
  { timezone: 'UTC+13:00', value: 'Pacific/Fakaofo', description: 'Pacific/Fakaofo (+13)' },
  { timezone: 'UTC+13:00', value: 'Pacific/Tongatapu', description: 'Pacific/Tongatapu (+13)' },
  // UTC+12:45
  { timezone: 'UTC+12:45', value: 'Pacific/Chatham', description: 'Pacific/Chatham (+12:45)' },
  // UTC+12:00
  { timezone: 'UTC+12:00', value: 'Antarctica/McMurdo', description: 'Antarctica/McMurdo (NZST)' },
  { timezone: 'UTC+12:00', value: 'Asia/Anadyr', description: 'Asia/Anadyr (+12)' },
  { timezone: 'UTC+12:00', value: 'Asia/Kamchatka', description: 'Asia/Kamchatka (+12)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Auckland', description: 'Pacific/Auckland (NZST)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Fiji', description: 'Pacific/Fiji (+12)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Funafuti', description: 'Pacific/Funafuti (+12)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Kwajalein', description: 'Pacific/Kwajalein (+12)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Majuro', description: 'Pacific/Majuro (+12)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Nauru', description: 'Pacific/Nauru (+12)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Tarawa', description: 'Pacific/Tarawa (+12)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Wake', description: 'Pacific/Wake (+12)' },
  { timezone: 'UTC+12:00', value: 'Pacific/Wallis', description: 'Pacific/Wallis (+12)' },
  // UTC+11:00
  { timezone: 'UTC+11:00', value: 'Antarctica/Casey', description: 'Antarctica/Casey (+11)' },
  { timezone: 'UTC+11:00', value: 'Antarctica/Macquarie', description: 'Antarctica/Macquarie (+11)' },
  { timezone: 'UTC+11:00', value: 'Asia/Magadan', description: 'Asia/Magadan (+11)' },
  { timezone: 'UTC+11:00', value: 'Asia/Sakhalin', description: 'Asia/Sakhalin (+11)' },
  { timezone: 'UTC+11:00', value: 'Asia/Srednekolymsk', description: 'Asia/Srednekolymsk (+11)' },
  { timezone: 'UTC+11:00', value: 'Pacific/Bougainville', description: 'Pacific/Bougainville (+11)' },
  { timezone: 'UTC+11:00', value: 'Pacific/Efate', description: 'Pacific/Efate (+11)' },
  { timezone: 'UTC+11:00', value: 'Pacific/Guadalcanal', description: 'Pacific/Guadalcanal (+11)' },
  { timezone: 'UTC+11:00', value: 'Pacific/Kosrae', description: 'Pacific/Kosrae (+11)' },
  { timezone: 'UTC+11:00', value: 'Pacific/Norfolk', description: 'Pacific/Norfolk (+11)' },
  { timezone: 'UTC+11:00', value: 'Pacific/Noumea', description: 'Pacific/Noumea (+11)' },
  { timezone: 'UTC+11:00', value: 'Pacific/Pohnpei', description: 'Pacific/Pohnpei (+11)' },
  // UTC+10:30
  { timezone: 'UTC+10:30', value: 'Australia/Lord_Howe', description: 'Australia/Lord Howe (+10:30)' },
  // UTC+10:00
  { timezone: 'UTC+10:00', value: 'Antarctica/DumontDUrville', description: 'Antarctica/DumontDUrville (+10)' },
  { timezone: 'UTC+10:00', value: 'Asia/Ust-Nera', description: 'Asia/Ust-Nera (+10)' },
  { timezone: 'UTC+10:00', value: 'Asia/Vladivostok', description: 'Asia/Vladivostok (+10)' },
  { timezone: 'UTC+10:00', value: 'Australia/Brisbane', description: 'Australia/Brisbane (AEST)' },
  { timezone: 'UTC+10:00', value: 'Australia/Currie', description: 'Australia/Currie (AEST)' },
  { timezone: 'UTC+10:00', value: 'Australia/Hobart', description: 'Australia/Hobart (AEST)' },
  { timezone: 'UTC+10:00', value: 'Australia/Lindeman', description: 'Australia/Lindeman (AEST)' },
  { timezone: 'UTC+10:00', value: 'Australia/Melbourne', description: 'Australia/Melbourne (AEST)' },
  { timezone: 'UTC+10:00', value: 'Australia/Sydney', description: 'Australia/Sydney (AEST)' },
  { timezone: 'UTC+10:00', value: 'Pacific/Chuuk', description: 'Pacific/Chuuk (+10)' },
  { timezone: 'UTC+10:00', value: 'Pacific/Guam', description: 'Pacific/Guam (ChST)' },
  { timezone: 'UTC+10:00', value: 'Pacific/Port_Moresby', description: 'Pacific/Port Moresby (+10)' },
  { timezone: 'UTC+10:00', value: 'Pacific/Saipan', description: 'Pacific/Saipan (ChST)' },
  // UTC+09:30
  { timezone: 'UTC+09:30', value: 'Australia/Adelaide', description: 'Australia/Adelaide (ACST)' },
  { timezone: 'UTC+09:30', value: 'Australia/Broken_Hill', description: 'Australia/Broken Hill (ACST)' },
  { timezone: 'UTC+09:30', value: 'Australia/Darwin', description: 'Australia/Darwin (ACST)' },
  // UTC+09:00
  { timezone: 'UTC+09:00', value: 'Asia/Chita', description: 'Asia/Chita (+09)' },
  { timezone: 'UTC+09:00', value: 'Asia/Dili', description: 'Asia/Dili (+09)' },
  { timezone: 'UTC+09:00', value: 'Asia/Jayapura', description: 'Asia/Jayapura (WIT)' },
  { timezone: 'UTC+09:00', value: 'Asia/Khandyga', description: 'Asia/Khandyga (+09)' },
  { timezone: 'UTC+09:00', value: 'Asia/Seoul', description: 'Asia/Seoul (KST)' },
  { timezone: 'UTC+09:00', value: 'Asia/Tokyo', description: 'Asia/Tokyo (JST)' },
  { timezone: 'UTC+09:00', value: 'Asia/Yakutsk', description: 'Asia/Yakutsk (+09)' },
  { timezone: 'UTC+09:00', value: 'Pacific/Palau', description: 'Pacific/Palau (+09)' },
  // UTC+08:45
  { timezone: 'UTC+08:45', value: 'Australia/Eucla', description: 'Australia/Eucla (+08:45)' },
  // UTC+08:30
  { timezone: 'UTC+08:30', value: 'Asia/Pyongyang', description: 'Asia/Pyongyang (KST)' },
  // UTC+08:00
  { timezone: 'UTC+08:00', value: 'Asia/Brunei', description: 'Asia/Brunei (+08)' },
  { timezone: 'UTC+08:00', value: 'Asia/Choibalsan', description: 'Asia/Choibalsan (+08)' },
  { timezone: 'UTC+08:00', value: 'Asia/Hong_Kong', description: 'Asia/Hong Kong (HKT)' },
  { timezone: 'UTC+08:00', value: 'Asia/Irkutsk', description: 'Asia/Irkutsk (+08)' },
  { timezone: 'UTC+08:00', value: 'Asia/Kuala_Lumpur', description: 'Asia/Kuala Lumpur (+08)' },
  { timezone: 'UTC+08:00', value: 'Asia/Kuching', description: 'Asia/Kuching (+08)' },
  { timezone: 'UTC+08:00', value: 'Asia/Macau', description: 'Asia/Macau (CST)' },
  { timezone: 'UTC+08:00', value: 'Asia/Makassar', description: 'Asia/Makassar (WITA)' },
  { timezone: 'UTC+08:00', value: 'Asia/Manila', description: 'Asia/Manila (+08)' },
  { timezone: 'UTC+08:00', value: 'Asia/Shanghai', description: 'Asia/Shanghai (CST)' },
  { timezone: 'UTC+08:00', value: 'Asia/Singapore', description: 'Asia/Singapore (+08)' },
  { timezone: 'UTC+08:00', value: 'Asia/Taipei', description: 'Asia/Taipei (CST)' },
  { timezone: 'UTC+08:00', value: 'Asia/Ulaanbaatar', description: 'Asia/Ulaanbaatar (+08)' },
  { timezone: 'UTC+08:00', value: 'Australia/Perth', description: 'Australia/Perth (AWST)' },
  // UTC+07:00
  { timezone: 'UTC+07:00', value: 'Antarctica/Davis', description: 'Antarctica/Davis (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Bangkok', description: 'Asia/Bangkok (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Barnaul', description: 'Asia/Barnaul (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Ho_Chi_Minh', description: 'Asia/Ho Chi Minh (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Hovd', description: 'Asia/Hovd (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Jakarta', description: 'Asia/Jakarta (WIB)' },
  { timezone: 'UTC+07:00', value: 'Asia/Krasnoyarsk', description: 'Asia/Krasnoyarsk (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Novokuznetsk', description: 'Asia/Novokuznetsk (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Novosibirsk', description: 'Asia/Novosibirsk (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Phnom_Penh', description: 'Asia/Phnom_Penh (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Pontianak', description: 'Asia/Pontianak (WIB)' },
  { timezone: 'UTC+07:00', value: 'Asia/Tomsk', description: 'Asia/Tomsk (+07)' },
  { timezone: 'UTC+07:00', value: 'Asia/Vientiane', description: 'Asia/Vientiane (+07)' },
  { timezone: 'UTC+07:00', value: 'Indian/Christmas', description: 'Indian/Christmas (+07)' },
  // UTC+06:30
  { timezone: 'UTC+06:30', value: 'Asia/Yangon', description: 'Asia/Yangon (+06:30)' },
  { timezone: 'UTC+06:30', value: 'Indian/Cocos', description: 'Indian/Cocos (+06:30)' },
  // UTC+06:00
  { timezone: 'UTC+06:00', value: 'Antarctica/Vostok', description: 'Antarctica/Vostok (+06)' },
  { timezone: 'UTC+06:00', value: 'Asia/Almaty', description: 'Asia/Almaty (+06)' },
  { timezone: 'UTC+06:00', value: 'Asia/Bishkek', description: 'Asia/Bishkek (+06)' },
  { timezone: 'UTC+06:00', value: 'Asia/Dhaka', description: 'Asia/Dhaka (+06)' },
  { timezone: 'UTC+06:00', value: 'Asia/Omsk', description: 'Asia/Omsk (+06)' },
  { timezone: 'UTC+06:00', value: 'Asia/Qyzylorda', description: 'Asia/Qyzylorda (+06)' },
  { timezone: 'UTC+06:00', value: 'Asia/Thimphu', description: 'Asia/Thimphu (+06)' },
  { timezone: 'UTC+06:00', value: 'Asia/Urumqi', description: 'Asia/Urumqi (+06)' },
  { timezone: 'UTC+06:00', value: 'Indian/Chagos', description: 'Indian/Chagos (+06)' },
  // UTC+05:45
  { timezone: 'UTC+05:45', value: 'Asia/Kathmandu', description: 'Asia/Kathmandu (+05:45)' },
  // UTC+05:30
  { timezone: 'UTC+05:30', value: 'Asia/Colombo', description: 'Asia/Colombo (+05:30)' },
  { timezone: 'UTC+05:30', value: 'Asia/Kolkata', description: 'Asia/Kolkata (IST)' },
  // UTC+05:00
  { timezone: 'UTC+05:00', value: 'Antarctica/Mawson', description: 'Antarctica/Mawson (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Aqtau', description: 'Asia/Aqtau (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Aqtobe', description: 'Asia/Aqtobe (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Ashgabat', description: 'Asia/Ashgabat (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Atyrau', description: 'Asia/Atyrau (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Dushanbe', description: 'Asia/Dushanbe (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Karachi', description: 'Asia/Karachi (PKT)' },
  { timezone: 'UTC+05:00', value: 'Asia/Oral', description: 'Asia/Oral (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Samarkand', description: 'Asia/Samarkand (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Tashkent', description: 'Asia/Tashkent (+05)' },
  { timezone: 'UTC+05:00', value: 'Asia/Yekaterinburg', description: 'Asia/Yekaterinburg (+05)' },
  { timezone: 'UTC+05:00', value: 'Indian/Kerguelen', description: 'Indian/Kerguelen (+05)' },
  { timezone: 'UTC+05:00', value: 'Indian/Maldives', description: 'Indian/Maldives (+05)' },
  // UTC+04:30
  { timezone: 'UTC+04:30', value: 'Asia/Kabul', description: 'Asia/Kabul (+04:30)' },
  { timezone: 'UTC+04:30', value: 'Asia/Tehran', description: 'Asia/Tehran (+04:30)' },
  // UTC+04:00
  { timezone: 'UTC+04:00', value: 'Asia/Baku', description: 'Asia/Baku (+04)' },
  { timezone: 'UTC+04:00', value: 'Asia/Dubai', description: 'Asia/Dubai (+04)' },
  { timezone: 'UTC+04:00', value: 'Asia/Muscat', description: 'Asia/Muscat (+04)' },
  { timezone: 'UTC+04:00', value: 'Asia/Tbilisi', description: 'Asia/Tbilisi (+04)' },
  { timezone: 'UTC+04:00', value: 'Asia/Yerevan', description: 'Asia/Yerevan (+04)' },
  { timezone: 'UTC+04:00', value: 'Europe/Astrakhan', description: 'Europe/Astrakhan (+04)' },
  { timezone: 'UTC+04:00', value: 'Europe/Samara', description: 'Europe/Samara (+04)' },
  { timezone: 'UTC+04:00', value: 'Europe/Saratov', description: 'Europe/Saratov (+04)' },
  { timezone: 'UTC+04:00', value: 'Europe/Ulyanovsk', description: 'Europe/Ulyanovsk (+04)' },
  { timezone: 'UTC+04:00', value: 'Indian/Mahe', description: 'Indian/Mahe (+04)' },
  { timezone: 'UTC+04:00', value: 'Indian/Mauritius', description: 'Indian/Mauritius (+04)' },
  { timezone: 'UTC+04:00', value: 'Indian/Reunion', description: 'Indian/Reunion (+04)' },
  // UTC+03:00
  { timezone: 'UTC+03:00', value: 'Africa/Addis_Ababa', description: 'Africa/Addis Ababa (EAT)' },
  { timezone: 'UTC+03:00', value: 'Africa/Asmara', description: 'Africa/Asmara (EAT)' },
  { timezone: 'UTC+03:00', value: 'Africa/Dar_es_Salaam', description: 'Africa/Dar es Salaam (EAT)' },
  { timezone: 'UTC+03:00', value: 'Africa/Djibouti', description: 'Africa/Djibouti (EAT)' },
  { timezone: 'UTC+03:00', value: 'Africa/Juba', description: 'Africa/Juba (EAT)' },
  { timezone: 'UTC+03:00', value: 'Africa/Kampala', description: 'Africa/Kampala (EAT)' },
  { timezone: 'UTC+03:00', value: 'Africa/Khartoum', description: 'Africa/Khartoum (EAT)' },
  { timezone: 'UTC+03:00', value: 'Africa/Mogadishu', description: 'Africa/Mogadishu (EAT)' },
  { timezone: 'UTC+03:00', value: 'Africa/Nairobi', description: 'Africa/Nairobi (EAT)' },
  { timezone: 'UTC+03:00', value: 'Antarctica/Syowa', description: 'Antarctica/Syowa (+03)' },
  { timezone: 'UTC+03:00', value: 'Asia/Aden', description: 'Asia/Aden (+03)' },
  { timezone: 'UTC+03:00', value: 'Asia/Amman', description: 'Asia/Amman (EEST)' },
  { timezone: 'UTC+03:00', value: 'Asia/Baghdad', description: 'Asia/Baghdad (+03)' },
  { timezone: 'UTC+03:00', value: 'Asia/Bahrain', description: 'Asia/Bahrain (+03)' },
  { timezone: 'UTC+03:00', value: 'Asia/Beirut', description: 'Asia/Beirut (EEST)' },
  { timezone: 'UTC+03:00', value: 'Asia/Damascus', description: 'Asia/Damascus (EEST)' },
  { timezone: 'UTC+03:00', value: 'Asia/Famagusta', description: 'Asia/Famagusta (+03)' },
  { timezone: 'UTC+03:00', value: 'Asia/Gaza', description: 'Asia/Gaza (EEST)' },
  { timezone: 'UTC+03:00', value: 'Asia/Hebron', description: 'Asia/Hebron (EEST)' },
  { timezone: 'UTC+03:00', value: 'Asia/Jerusalem', description: 'Asia/Jerusalem (IDT)' },
  { timezone: 'UTC+03:00', value: 'Asia/Kuwait', description: 'Asia/Kuwait (+03)' },
  { timezone: 'UTC+03:00', value: 'Asia/Nicosia', description: 'Asia/Nicosia (EEST)' },
  { timezone: 'UTC+03:00', value: 'Asia/Qatar', description: 'Asia/Qatar (+03)' },
  { timezone: 'UTC+03:00', value: 'Asia/Riyadh', description: 'Asia/Riyadh (+03)' },
  { timezone: 'UTC+03:00', value: 'Europe/Athens', description: 'Europe/Athens (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Bucharest', description: 'Europe/Bucharest (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Chisinau', description: 'Europe/Chisinau (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Helsinki', description: 'Europe/Helsinki (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Istanbul', description: 'Europe/Istanbul (+03)' },
  { timezone: 'UTC+03:00', value: 'Europe/Kiev', description: 'Europe/Kiev (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Kirov', description: 'Europe/Kirov (+03)' },
  { timezone: 'UTC+03:00', value: 'Europe/Mariehamn', description: 'Europe/Mariehamn (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Minsk', description: 'Europe/Minsk (+03)' },
  { timezone: 'UTC+03:00', value: 'Europe/Moscow', description: 'Europe/Moscow (MSK)' },
  { timezone: 'UTC+03:00', value: 'Europe/Riga', description: 'Europe/Riga (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Simferopol', description: 'Europe/Simferopol (MSK)' },
  { timezone: 'UTC+03:00', value: 'Europe/Sofia', description: 'Europe/Sofia (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Tallinn', description: 'Europe/Tallinn (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Uzhgorod', description: 'Europe/Uzhgorod (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Vilnius', description: 'Europe/Vilnius (EEST)' },
  { timezone: 'UTC+03:00', value: 'Europe/Volgograd', description: 'Europe/Volgograd (+03)' },
  { timezone: 'UTC+03:00', value: 'Europe/Zaporozhye', description: 'Europe/Zaporozhye (EEST)' },
  { timezone: 'UTC+03:00', value: 'Indian/Antananarivo', description: 'Indian/Antananarivo (EAT)' },
  { timezone: 'UTC+03:00', value: 'Indian/Comoro', description: 'Indian/Comoro (EAT)' },
  { timezone: 'UTC+03:00', value: 'Indian/Mayotte', description: 'Indian/Mayotte (EAT)' },
  // UTC+02:00
  { timezone: 'UTC+02:00', value: 'Africa/Blantyre', description: 'Africa/Blantyre (CAT)' },
  { timezone: 'UTC+02:00', value: 'Africa/Bujumbura', description: 'Africa/Bujumbura (CAT)' },
  { timezone: 'UTC+02:00', value: 'Africa/Cairo', description: 'Africa/Cairo (EET)' },
  { timezone: 'UTC+02:00', value: 'Africa/Ceuta', description: 'Africa/Ceuta (CEST)' },
  { timezone: 'UTC+02:00', value: 'Africa/Gaborone', description: 'Africa/Gaborone (CAT)' },
  { timezone: 'UTC+02:00', value: 'Africa/Harare', description: 'Africa/Harare (CAT)' },
  { timezone: 'UTC+02:00', value: 'Africa/Johannesburg', description: 'Africa/Johannesburg (SAST)' },
  { timezone: 'UTC+02:00', value: 'Africa/Kigali', description: 'Africa/Kigali (CAT)' },
  { timezone: 'UTC+02:00', value: 'Africa/Lubumbashi', description: 'Africa/Lubumbashi (CAT)' },
  { timezone: 'UTC+02:00', value: 'Africa/Lusaka', description: 'Africa/Lusaka (CAT)' },
  { timezone: 'UTC+02:00', value: 'Africa/Maputo', description: 'Africa/Maputo (CAT)' },
  { timezone: 'UTC+02:00', value: 'Africa/Maseru', description: 'Africa/Maseru (SAST)' },
  { timezone: 'UTC+02:00', value: 'Africa/Mbabane', description: 'Africa/Mbabane (SAST)' },
  { timezone: 'UTC+02:00', value: 'Africa/Tripoli', description: 'Africa/Tripoli (EET)' },
  { timezone: 'UTC+02:00', value: 'Antarctica/Troll', description: 'Antarctica/Troll (+02)' },
  { timezone: 'UTC+02:00', value: 'Arctic/Longyearbyen', description: 'Arctic/Longyearbyen (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Amsterdam', description: 'Europe/Amsterdam (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Andorra', description: 'Europe/Andorra (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Belgrade', description: 'Europe/Belgrade (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Berlin', description: 'Europe/Berlin (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Bratislava', description: 'Europe/Bratislava (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Brussels', description: 'Europe/Brussels (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Budapest', description: 'Europe/Budapest (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Busingen', description: 'Europe/Busingen (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Copenhagen', description: 'Europe/Copenhagen (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Gibraltar', description: 'Europe/Gibraltar (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Kaliningrad', description: 'Europe/Kaliningrad (EET)' },
  { timezone: 'UTC+02:00', value: 'Europe/Ljubljana', description: 'Europe/Ljubljana (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Luxembourg', description: 'Europe/Luxembourg (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Madrid', description: 'Europe/Madrid (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Malta', description: 'Europe/Malta (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Monaco', description: 'Europe/Monaco (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Oslo', description: 'Europe/Oslo (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Paris', description: 'Europe/Paris (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Podgorica', description: 'Europe/Podgorica (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Prague', description: 'Europe/Prague (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Rome', description: 'Europe/Rome (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/San_Marino', description: 'Europe/San Marino (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Sarajevo', description: 'Europe/Sarajevo (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Skopje', description: 'Europe/Skopje (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Stockholm', description: 'Europe/Stockholm (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Tirane', description: 'Europe/Tirane (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Vaduz', description: 'Europe/Vaduz (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Vatican', description: 'Europe/Vatican (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Vienna', description: 'Europe/Vienna (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Warsaw', description: 'Europe/Warsaw (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Zagreb', description: 'Europe/Zagreb (CEST)' },
  { timezone: 'UTC+02:00', value: 'Europe/Zurich', description: 'Europe/Zurich (CEST)' },
  // UTC+01:00
  { timezone: 'UTC+01:00', value: 'Africa/Algiers', description: 'Africa/Algiers (CET)' },
  { timezone: 'UTC+01:00', value: 'Africa/Bangui', description: 'Africa/Bangui (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Brazzaville', description: 'Africa/Brazzaville (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Casablanca', description: 'Africa/Casablanca (WEST)' },
  { timezone: 'UTC+01:00', value: 'Africa/Douala', description: 'Africa/Douala (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/El_Aaiun', description: 'Africa/El Aaiun (WEST)' },
  { timezone: 'UTC+01:00', value: 'Africa/Kinshasa', description: 'Africa/Kinshasa (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Lagos', description: 'Africa/Lagos (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Libreville', description: 'Africa/Libreville (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Luanda', description: 'Africa/Luanda (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Malabo', description: 'Africa/Malabo (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Ndjamena', description: 'Africa/Ndjamena (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Niamey', description: 'Africa/Niamey (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Porto-Novo', description: 'Africa/Porto-Novo (WAT)' },
  { timezone: 'UTC+01:00', value: 'Africa/Tunis', description: 'Africa/Tunis (CET)' },
  { timezone: 'UTC+01:00', value: 'Africa/Windhoek', description: 'Africa/Windhoek (WAT)' },
  { timezone: 'UTC+01:00', value: 'Atlantic/Canary', description: 'Atlantic/Canary (WEST)' },
  { timezone: 'UTC+01:00', value: 'Atlantic/Faroe', description: 'Atlantic/Faroe (WEST)' },
  { timezone: 'UTC+01:00', value: 'Atlantic/Madeira', description: 'Atlantic/Madeira (WEST)' },
  { timezone: 'UTC+01:00', value: 'Europe/Dublin', description: 'Europe/Dublin (IST)' },
  { timezone: 'UTC+01:00', value: 'Europe/Guernsey', description: 'Europe/Guernsey (BST)' },
  { timezone: 'UTC+01:00', value: 'Europe/Isle_of_Man', description: 'Europe/Isle of Man (BST)' },
  { timezone: 'UTC+01:00', value: 'Europe/Jersey', description: 'Europe/Jersey (BST)' },
  { timezone: 'UTC+01:00', value: 'Europe/Lisbon', description: 'Europe/Lisbon (WEST)' },
  { timezone: 'UTC+01:00', value: 'Europe/London', description: 'Europe/London (BST)' },
  // UTC
  { timezone: 'UTC', value: 'Africa/Abidjan', description: 'Africa/Abidjan (GMT)' },
  { timezone: 'UTC', value: 'Africa/Accra', description: 'Africa/Accra (GMT)' },
  { timezone: 'UTC', value: 'Africa/Bamako', description: 'Africa/Bamako (GMT)' },
  { timezone: 'UTC', value: 'Africa/Banjul', description: 'Africa/Banjul (GMT)' },
  { timezone: 'UTC', value: 'Africa/Bissau', description: 'Africa/Bissau (GMT)' },
  { timezone: 'UTC', value: 'Africa/Conakry', description: 'Africa/Conakry (GMT)' },
  { timezone: 'UTC', value: 'Africa/Dakar', description: 'Africa/Dakar (GMT)' },
  { timezone: 'UTC', value: 'Africa/Freetown', description: 'Africa/Freetown (GMT)' },
  { timezone: 'UTC', value: 'Africa/Lome', description: 'Africa/Lome (GMT)' },
  { timezone: 'UTC', value: 'Africa/Monrovia', description: 'Africa/Monrovia (GMT)' },
  { timezone: 'UTC', value: 'Africa/Nouakchott', description: 'Africa/Nouakchott (GMT)' },
  { timezone: 'UTC', value: 'Africa/Ouagadougou', description: 'Africa/Ouagadougou (GMT)' },
  { timezone: 'UTC', value: 'Africa/Sao_Tome', description: 'Africa/Sao_Tome (GMT)' },
  { timezone: 'UTC', value: 'America/Danmarkshavn', description: 'America/Danmarkshavn (GMT)' },
  { timezone: 'UTC', value: 'America/Scoresbysund', description: 'America/Scoresbysund (+00)' },
  { timezone: 'UTC', value: 'Atlantic/Azores', description: 'Atlantic/Azores (+00)' },
  { timezone: 'UTC', value: 'Atlantic/Reykjavik', description: 'Atlantic/Reykjavik (GMT)' },
  { timezone: 'UTC', value: 'Atlantic/St_Helena', description: 'Atlantic/St_Helena (GMT)' },
  { timezone: 'UTC', value: 'UTC', description: 'UTC (UTC)' },
  // UTC-01:00
  { timezone: 'UTC-01:00', value: 'Atlantic/Cape_Verde', description: 'Atlantic/Cape Verde (-01)' },
  // UTC-02:00
  { timezone: 'UTC-02:00', value: 'America/Godthab', description: 'America/Godthab (-02)' },
  { timezone: 'UTC-02:00', value: 'America/Miquelon', description: 'America/Miquelon (-02)' },
  { timezone: 'UTC-02:00', value: 'America/Noronha', description: 'America/Noronha (-02)' },
  { timezone: 'UTC-02:00', value: 'Atlantic/South_Georgia', description: 'Atlantic/South Georgia (-02)' },
  // UTC-02:30
  { timezone: 'UTC-02:30', value: 'America/St_Johns', description: 'America/St Johns (NDT)' },
  // UTC-03:00
  { timezone: 'UTC-03:00', value: 'America/Araguaina', description: 'America/Araguaina (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Buenos_Aires', description: 'America/Argentina/Buenos Aires (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Catamarca', description: 'America/Argentina/Catamarca (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Cordoba', description: 'America/Argentina/Cordoba (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Jujuy', description: 'America/Argentina/Jujuy (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/La_Rioja', description: 'America/Argentina/La Rioja (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Mendoza', description: 'America/Argentina/Mendoza (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Rio_Gallegos', description: 'America/Argentina/Rio Gallegos (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Salta', description: 'America/Argentina/Salta (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/San_Juan', description: 'America/Argentina/San Juan (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/San_Luis', description: 'America/Argentina/San Luis (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Tucuman', description: 'America/Argentina/Tucuman (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Argentina/Ushuaia', description: 'America/Argentina/Ushuaia (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Bahia', description: 'America/Bahia (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Belem', description: 'America/Belem (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Cayenne', description: 'America/Cayenne (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Fortaleza', description: 'America/Fortaleza (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Glace_Bay', description: 'America/Glace_Bay (ADT)' },
  { timezone: 'UTC-03:00', value: 'America/Goose_Bay', description: 'America/Goose_Bay (ADT)' },
  { timezone: 'UTC-03:00', value: 'America/Halifax', description: 'America/Halifax (ADT)' },
  { timezone: 'UTC-03:00', value: 'America/Maceio', description: 'America/Maceio (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Moncton', description: 'America/Moncton (ADT)' },
  { timezone: 'UTC-03:00', value: 'America/Montevideo', description: 'America/Montevideo (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Paramaribo', description: 'America/Paramaribo (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Punta_Arenas', description: 'America/Punta Arenas (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Recife', description: 'America/Recife (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Santarem', description: 'America/Santarem (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Sao_Paulo', description: 'America/Sao Paulo (-03)' },
  { timezone: 'UTC-03:00', value: 'America/Thule', description: 'America/Thule (ADT)' },
  { timezone: 'UTC-03:00', value: 'Antarctica/Palmer', description: 'Antarctica/Palmer (-03)' },
  { timezone: 'UTC-03:00', value: 'Antarctica/Rothera', description: 'Antarctica/Rothera (-03)' },
  { timezone: 'UTC-03:00', value: 'Atlantic/Bermuda', description: 'Atlantic/Bermuda (ADT)' },
  { timezone: 'UTC-03:00', value: 'Atlantic/Stanley', description: 'Atlantic/Stanley (-03)' },
  // UTC-04:00
  { timezone: 'UTC-04:00', value: 'America/Anguilla', description: 'America/Anguilla (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Antigua', description: 'America/Antigua (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Aruba', description: 'America/Aruba (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Asuncion', description: 'America/Asuncion (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Barbados', description: 'America/Barbados (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Blanc-Sablon', description: 'America/Blanc-Sablon (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Boa_Vista', description: 'America/Boa Vista (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Campo_Grande', description: 'America/Campo Grande (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Caracas', description: 'America/Caracas (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Cuiaba', description: 'America/Cuiaba (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Curacao', description: 'America/Curacao (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Detroit', description: 'America/Detroit (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Dominica', description: 'America/Dominica (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Grand_Turk', description: 'America/Grand Turk (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Grenada', description: 'America/Grenada (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Guadeloupe', description: 'America/Guadeloupe (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Guyana', description: 'America/Guyana (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Havana', description: 'America/Havana (CDT)' },
  { timezone: 'UTC-04:00', value: 'America/Indiana/Indianapolis', description: 'America/Indiana/Indianapolis (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Indiana/Marengo', description: 'America/Indiana/Marengo (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Indiana/Petersburg', description: 'America/Indiana/Petersburg (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Indiana/Vevay', description: 'America/Indiana/Vevay (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Indiana/Vincennes', description: 'America/Indiana/Vincennes (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Indiana/Winamac', description: 'America/Indiana/Winamac (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Iqaluit', description: 'America/Iqaluit (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Kentucky/Louisville', description: 'America/Kentucky/Louisville (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Kentucky/Monticello', description: 'America/Kentucky/Monticello (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Kralendijk', description: 'America/Kralendijk (AST)' },
  { timezone: 'UTC-04:00', value: 'America/La_Paz', description: 'America/La_Paz (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Lower_Princes', description: 'America/Lower Princes (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Manaus', description: 'America/Manaus (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Marigot', description: 'America/Marigot (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Martinique', description: 'America/Martinique (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Montserrat', description: 'America/Montserrat (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Nassau', description: 'America/Nassau (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/New_York', description: 'America/New_York (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Nipigon', description: 'America/Nipigon (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Pangnirtung', description: 'America/Pangnirtung (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Port-au-Prince', description: 'America/Port-au-Prince (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Port_of_Spain', description: 'America/Port of Spain (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Porto_Velho', description: 'America/Porto Velho (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Puerto_Rico', description: 'America/Puerto Rico (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Santiago', description: 'America/Santiago (-04)' },
  { timezone: 'UTC-04:00', value: 'America/Santo_Domingo', description: 'America/Santo Domingo (AST)' },
  { timezone: 'UTC-04:00', value: 'America/St_Barthelemy', description: 'America/St Barthelemy (AST)' },
  { timezone: 'UTC-04:00', value: 'America/St_Kitts', description: 'America/St Kitts (AST)' },
  { timezone: 'UTC-04:00', value: 'America/St_Lucia', description: 'America/St Lucia (AST)' },
  { timezone: 'UTC-04:00', value: 'America/St_Thomas', description: 'America/St Thomas (AST)' },
  { timezone: 'UTC-04:00', value: 'America/St_Vincent', description: 'America/St Vincent (AST)' },
  { timezone: 'UTC-04:00', value: 'America/Thunder_Bay', description: 'America/Thunder Bay (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Toronto', description: 'America/Toronto (EDT)' },
  { timezone: 'UTC-04:00', value: 'America/Tortola', description: 'America/Tortola (AST)' },
  // UTC-05:00
  { timezone: 'UTC-05:00', value: 'America/Atikokan', description: 'America/Atikokan (EST)' },
  { timezone: 'UTC-05:00', value: 'America/Bahia_Banderas', description: 'America/Bahia Banderas (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Bogota', description: 'America/Bogota (-05)' },
  { timezone: 'UTC-05:00', value: 'America/Cancun', description: 'America/Cancun (EST)' },
  { timezone: 'UTC-05:00', value: 'America/Cayman', description: 'America/Cayman (EST)' },
  { timezone: 'UTC-05:00', value: 'America/Chicago', description: 'America/Chicago (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Eirunepe', description: 'America/Eirunepe (-05)' },
  { timezone: 'UTC-05:00', value: 'America/Guayaquil', description: 'America/Guayaquil (-05)' },
  { timezone: 'UTC-05:00', value: 'America/Indiana/Knox', description: 'America/Indiana/Knox (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Indiana/Tell_City', description: 'America/Indiana/Tell City (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Jamaica', description: 'America/Jamaica (EST)' },
  { timezone: 'UTC-05:00', value: 'America/Lima', description: 'America/Lima (-05)' },
  { timezone: 'UTC-05:00', value: 'America/Matamoros', description: 'America/Matamoros (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Menominee', description: 'America/Menominee (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Merida', description: 'America/Merida (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Mexico_City', description: 'America/Mexico City (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Monterrey', description: 'America/Monterrey (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/North_Dakota/Beulah', description: 'America/North Dakota/Beulah (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/North_Dakota/Center', description: 'America/North Dakota/Center (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/North_Dakota/New_Salem', description: 'America/North Dakota/New Salem (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Panama', description: 'America/Panama (EST)' },
  { timezone: 'UTC-05:00', value: 'America/Rainy_River', description: 'America/Rainy River (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Rankin_Inlet', description: 'America/Rankin Inlet (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Resolute', description: 'America/Resolute (CDT)' },
  { timezone: 'UTC-05:00', value: 'America/Rio_Branco', description: 'America/Rio Branco (-05)' },
  { timezone: 'UTC-05:00', value: 'America/Winnipeg', description: 'America/Winnipeg (CDT)' },
  // UTC-06:00
  { timezone: 'UTC-06:00', value: 'America/Belize', description: 'America/Belize (CST)' },
  { timezone: 'UTC-06:00', value: 'America/Boise', description: 'America/Boise (MDT)' },
  { timezone: 'UTC-06:00', value: 'America/Cambridge_Bay', description: 'America/Cambridge Bay (MDT)' },
  { timezone: 'UTC-06:00', value: 'America/Chihuahua', description: 'America/Chihuahua (MDT)' },
  { timezone: 'UTC-06:00', value: 'America/Costa_Rica', description: 'America/Costa Rica (CST)' },
  { timezone: 'UTC-06:00', value: 'America/Denver', description: 'America/Denver (MDT)' },
  { timezone: 'UTC-06:00', value: 'America/Edmonton', description: 'America/Edmonton (MDT)' },
  { timezone: 'UTC-06:00', value: 'America/El_Salvador', description: 'America/El Salvador (CST)' },
  { timezone: 'UTC-06:00', value: 'America/Guatemala', description: 'America/Guatemala (CST)' },
  { timezone: 'UTC-06:00', value: 'America/Inuvik', description: 'America/Inuvik (MDT)' },
  { timezone: 'UTC-06:00', value: 'America/Managua', description: 'America/Managua (CST)' },
  { timezone: 'UTC-06:00', value: 'America/Mazatlan', description: 'America/Mazatlan (MDT)' },
  { timezone: 'UTC-06:00', value: 'America/Ojinaga', description: 'America/Ojinaga (MDT)' },
  { timezone: 'UTC-06:00', value: 'America/Regina', description: 'America/Regina (CST)' },
  { timezone: 'UTC-06:00', value: 'America/Swift_Current', description: 'America/Swift Current (CST)' },
  { timezone: 'UTC-06:00', value: 'America/Tegucigalpa', description: 'America/Tegucigalpa (CST)' },
  { timezone: 'UTC-06:00', value: 'America/Yellowknife', description: 'America/Yellowknife (MDT)' },
  { timezone: 'UTC-06:00', value: 'Pacific/Easter', description: 'Pacific/Easter (-06)' },
  { timezone: 'UTC-06:00', value: 'Pacific/Galapagos', description: 'Pacific/Galapagos (-06)' },
  // UTC-07:00
  { timezone: 'UTC-07:00', value: 'America/Creston', description: 'America/Creston (MST)' },
  { timezone: 'UTC-07:00', value: 'America/Dawson', description: 'America/Dawson (PDT)' },
  { timezone: 'UTC-07:00', value: 'America/Dawson_Creek', description: 'America/Dawson Creek (MST)' },
  { timezone: 'UTC-07:00', value: 'America/Fort_Nelson', description: 'America/Fort Nelson (MST)' },
  { timezone: 'UTC-07:00', value: 'America/Hermosillo', description: 'America/Hermosillo (MST)' },
  { timezone: 'UTC-07:00', value: 'America/Los_Angeles', description: 'America/Los Angeles (PDT)' },
  { timezone: 'UTC-07:00', value: 'America/Phoenix', description: 'America/Phoenix (MST)' },
  { timezone: 'UTC-07:00', value: 'America/Tijuana', description: 'America/Tijuana (PDT)' },
  { timezone: 'UTC-07:00', value: 'America/Vancouver', description: 'America/Vancouver (PDT)' },
  { timezone: 'UTC-07:00', value: 'America/Whitehorse', description: 'America/Whitehorse (PDT)' },
  // UTC-08:00
  { timezone: 'UTC-08:00', value: 'America/Anchorage', description: 'America/Anchorage (AKDT)' },
  { timezone: 'UTC-08:00', value: 'America/Juneau', description: 'America/Juneau (AKDT)' },
  { timezone: 'UTC-08:00', value: 'America/Metlakatla', description: 'America/Metlakatla (AKDT)' },
  { timezone: 'UTC-08:00', value: 'America/Nome', description: 'America/Nome (AKDT)' },
  { timezone: 'UTC-08:00', value: 'America/Sitka', description: 'America/Sitka (AKDT)' },
  { timezone: 'UTC-08:00', value: 'America/Yakutat', description: 'America/Yakutat (AKDT)' },
  { timezone: 'UTC-08:00', value: 'Pacific/Pitcairn', description: 'Pacific/Pitcairn (-08)' },
  // UTC-09:00
  { timezone: 'UTC-09:00', value: 'America/Adak', description: 'America/Adak (HDT)' },
  { timezone: 'UTC-09:00', value: 'Pacific/Gambier', description: 'Pacific/Gambier (-09)' },
  // UTC-09:30
  { timezone: 'UTC-09:30', value: 'Pacific/Marquesas', description: 'Pacific/Marquesas (-09:30)' },
  // UTC-10:00
  { timezone: 'UTC-10:00', value: 'Pacific/Honolulu', description: 'Pacific/Honolulu (HST)' },
  { timezone: 'UTC-10:00', value: 'Pacific/Rarotonga', description: 'Pacific/Rarotonga (-10)' },
  { timezone: 'UTC-10:00', value: 'Pacific/Tahiti', description: 'Pacific/Tahiti (-10)' },
  // UTC-11:00
  { timezone: 'UTC-11:00', value: 'Pacific/Midway', description: 'Pacific/Midway (SST)' },
  { timezone: 'UTC-11:00', value: 'Pacific/Niue', description: 'Pacific/Niue (-11)' },
  { timezone: 'UTC-11:00', value: 'Pacific/Pago_Pago', description: 'Pacific/Pago Pago (SST)' },
] as IanaTimezone[];
