/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export class FormControl {
  public errors: {
    message: string;
    status: string;
  } = {
    message: '',
    status: '',
  };

  public constructor(public value: string | number | boolean | Date | any[], private validators: Function[] = []) {}

  public reset(): void {
    this.value = '';
    this.errors = {
      message: '',
      status: '',
    };
  }

  public resetErrors(): void {
    this.errors = {
      message: '',
      status: '',
    };
  }

  public validate(): void {
    this.errors = {
      message: '',
      status: '',
    };

    if (this.validators && this.validators.length) {
      this.validators.forEach((validator) => {
        const error = validator.call(this, this.value);

        if (error) {
          this.errors = error;
        }
      });
    }
  }
}
