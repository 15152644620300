
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { dateFormatter, dateFormatterLocalized, timeFormatterLocalized } from '../../../../utilities/Functions/formatting';
import Chart from '../../../molecules/Chart/Chart.vue';
import Chip from '../../../atoms/Chip/Chip.vue';
import Link from '../../../atoms/Link/Link.vue';
import Checkbox from '../../../atoms/Checkbox/Checkbox.vue';
import Button from '../../../atoms/Button/Button.vue';
import Tooltip from '../../../atoms/Tooltip/Tooltip.vue';
import dayjs from 'dayjs';

export default defineComponent({
  components: {
    'arc-chart': Chart,
    'arc-chip': Chip,
    'arc-link': Link,
    'arc-checkbox': Checkbox,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      required: true,
      default: '',
    },
    position: {
      type: String,
      default: 'left',
    },
    cellClasses: {
      type: String,
    },
    cellStyle: {
      type: String,
    },
    row: {},
    columnDefs: {},
    index: {},
  },
  computed: {
    ...mapGetters(['modalData']),
    date(): string {
      return (this.type as string) === 'date' ? dateFormatter(this.value as string) : '';
    },
    dateTimeLocalized(): string {
      return (this.type as string) === 'date-time-localized'
        ? `${dateFormatterLocalized(this.value as string)} at ${timeFormatterLocalized(this.value as string)}`
        : '';
    },
    tdClass(): string {
      return `${this.position ? this.position : ''} ${this.type === 'bar-chart' ? 'p0' : ''} ${this.cellClasses ? this.cellClasses : ''}`;
    },
    approvalChipType(): string {
      let value: any;
      let chipType = '';
      value = this.value;
      if (this.type === 'approval') {
        if ((value?.approved / value?.sent) * 100 <= 40) {
          chipType = 'alert-error';
        }
        if ((value?.approved / value?.sent) * 100 > 40 && (value?.approved / value?.sent) * 100 < 70) {
          chipType = 'alert-warning';
        }
        if ((value?.approved / value?.sent) * 100 >= 70) {
          chipType = 'alert-success';
        }
      }
      return chipType;
    },
    approvalAccepted(): string {
      let value: any;
      value = this.value;
      return `width: ${(value?.approved / value?.sent) * 100}%; border-radius: 0${
        value?.pending <= 0 && value?.declined <= 0 ? ' 50px 50px 0;' : ';'
      } padding-right: ${(value.approved / value.sent) * 100 >= 10 ? '10px' : '5px;'};`;
    },
    approvalPending(): string {
      let value: any;
      value = this.value;
      return `width: ${(value?.pending / value?.sent) * 100}%; border-radius: 0${value?.declined <= 0 ? ' 50px 50px 0;' : ';'} padding-right: ${
        (value.pending / value.sent) * 100 >= 10 ? '10px' : '5px;'
      };`;
    },
    approvalDeclined(): string {
      let value: any;
      value = this.value;
      return `width: ${(value?.declined / value?.sent) * 100}%; border-radius: 0 50px 50px 0;
        padding-right: ${(value.declined / value.sent) * 100 >= 10 ? '10px' : '5px;'};`;
    },
    mailto(): string {
      let mailto = '';
      if (this.modalData?.type === 'approval') {
        let row: any = this.row;
        mailto = `mailto:${this.getContact()}?cc=${this.formatStringArray(row?.cc)}&subject=${this.getSubject()}&body=${row?.reviewUrl ?? ''}`; // update body for memos
      }
      return mailto;
    },
  },
  methods: {
    tryCellClicked(): void {
      if (this.type !== 'approval' && this.type !== 'questions' && this.type !== 'url' && this.type !== 'website' && this.type !== 'contact') {
        this.$emit('cellClicked');
      }
    },
    formatStringArray(array: string[]): string {
      return array.toString().replace(/,/g, ', ');
    },
    getContact(): string {
      let contact = '';
      if (this.modalData?.type === 'approval') {
        let row: any = this.row;
        let colDef: any = this.columnDefs;
        let contactColumnType = colDef.filter((def: any) => def.columnName === 'primaryContact' || def.columnName === 'socialContact');
        contact = row[contactColumnType[0].columnName]?.email;
      }
      return contact;
    },
    getSubject(): string {
      return this.modalData?.type === 'approval'
        ? `Regarding%20${this.modalData?.row?.description}%20|%20${this.modalData?.row?.brandText}%20|%20${this.communicationType()}%20#${
            this.modalData?.row?.id
          }`
        : '';
    },
    communicationType(): string {
      let type = '';
      if (this.modalData.row.commType === 'marketing-campaign') {
        type = `Marketing Campaign`;
      }
      if (this.modalData.row.commType === 'recruitment-campaign') {
        type = `Recruitment Campaign`;
      }
      if (this.modalData.row.commType === 'store-memo') {
        type = `Store Memo`;
      }
      if (this.modalData.row.commType === 'mall-memo') {
        type = `Mall Memo`;
      }
      return type;
    },
    getBody(): string {
      let body = '';

      return body;
    },
    checkboxClicked(): void {
      this.$emit('checkboxClicked', this.index);
    },
    timestampToDate(timeStamp: number | null) {
      return timeStamp ? dateFormatterLocalized(dayjs.unix(timeStamp).toString()) : '';
    },
    approvalPercentage(approved: number, sent: number): number {
      const percentage = (approved / sent) * 100;
      if (percentage >= 99 && percentage < 100) {
        return Math.floor(percentage);
      }
      return Math.ceil(percentage);
    },
    formatDateLocalized(date: string, showTime = false): string {
      if (showTime) {
        return `${dateFormatterLocalized(date)} at ${timeFormatterLocalized(date)}`;
      }
      return dateFormatterLocalized(date);
    },
    formatParsedChannel(channel: any): string {
      return `${channel.contactName ? `${channel.contactName} approved` : 'Approved'} for ${channel.channelDescription}${
        channel.createdAt ? ` on ${this.formatDateLocalized(channel.createdAt, true)}` : ''
      }`;
    },
  },
  data() {
    return {
      isAdmin: true,
      chartOptions: {
        responsive: true,
        plugins: {
          title: {
            display: false,
          },
          tooltip: {
            display: false,
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              display: false,
            },
            min: 0,
            max: 100,
          },
        },
      },
    };
  },
});
