
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { NotificationType } from '@/enums/NotificationType';

export default defineComponent({
  props: {
    theme: {
      type: String,
      default: 'notification--error',
    },
    type: {
      type: String,
      default: 'alert',
    },
    title: {
      type: String,
      default: 'ERROR',
    },
    description: {
      type: String,
      default:
        'Something went wrong. Please try again. If you continue to experience an issue, please either contact your Engagement Agents account manager, or call (416) 577-7326',
    },
    icon: {
      type: String,
      default: 'error',
    },
    size: {
      type: String,
      default: 'small',
    },
    notificationType: {
      type: Number,
    },
    notificationId: {
      type: Number,
    },
  },

  computed: {
    NotificationType() {
      return NotificationType;
    },
    ...mapGetters(['activeAnnouncement', 'activeTip']),

    cardClass(): string {
      return `notification-card-${this.size}`;
    },
    iconClass(): string {
      return `notification-card-${this.size}__svg`;
    },
    titleClass(): string {
      return `notification-card-${this.size}__title`;
    },
    descriptionClass(): string {
      return `notification-card-${this.size}__description`;
    },

    notificationTitle(): string {
      if (
        this.notificationType === NotificationType.Global ||
        this.notificationType === NotificationType.Deadline ||
        this.notificationType === NotificationType.Holiday ||
        this.notificationType === NotificationType.FreeOpportunity ||
        this.notificationType === NotificationType.PaidOpportunity
      ) {
        return this.title;
      } else if (this.type === 'alert' || this.type === 'multi') {
        return this.title;
      } else {
        return this.activeAnnouncement ? this.activeAnnouncement.title : this.activeTip.title;
      }
    },

    notificationIcon(): string {
      if (this.type === 'alert' || this.type === 'multi') {
        return this.icon;
      } else {
        return this.activeAnnouncement ? this.activeAnnouncement.icon : this.activeTip.icon;
      }
    },

    notificationDescription(): string {
      if (
        this.notificationType === NotificationType.Global ||
        this.notificationType === NotificationType.Deadline ||
        this.notificationType === NotificationType.Holiday ||
        this.notificationType === NotificationType.FreeOpportunity ||
        this.notificationType === NotificationType.PaidOpportunity
      ) {
        return this.description;
      } else if (this.type === 'alert' || this.type === 'multi') {
        return this.description;
      } else {
        return this.activeAnnouncement ? this.activeAnnouncement.description : this.activeTip.description;
      }
    },

    notificationTheme(): string {
      if (this.notificationType === NotificationType.Global) {
        return 'notification--info-blue';
      } else if (this.notificationType === NotificationType.Deadline) {
        return 'notification--info-pink';
      } else if (this.notificationType === NotificationType.Holiday) {
        return 'notification--info-purple';
      } else if (this.notificationType === NotificationType.FreeOpportunity || this.notificationType === NotificationType.PaidOpportunity) {
        return 'notification--info-green';
      } else {
        return this.theme;
      }
    },
  },

  methods: {
    ...mapActions([
      'updateWindowWidth',
      'updateCurrentRoute',
      'openModal',
      'closeModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'dismissUserNotification',
    ]),
    async dismiss(): Promise<void> {
      await this.dismissUserNotification({ notificationId: this.notificationId });
    },
  },
});
