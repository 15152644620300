import * as Sentry from '@sentry/vue';
import HttpService from '@/services/HttpService';

type ErrorInfo = {
  message?: string;
  info?: any;
  userId?: string;
  environment?: string;
  payload?: any;
};

async function captureExceptionInSentry(errorInfo: ErrorInfo) {
  if (!errorInfo.message || errorInfo.environment !== 'production') return;
  if (errorInfo.message.includes('canceled')) return;

  Sentry.captureMessage(errorInfo.message, {
    tags: {
      userId: errorInfo.userId,
      environment: errorInfo.environment,
    },
  });

  await new HttpService().postSentryException(errorInfo);
}

export default captureExceptionInSentry;
