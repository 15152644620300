
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { ILanguage } from '../../../../../../interfaces/src/v1/interfaces';

export default defineComponent({
  props: {
    languages: {},
    activeLanguage: {},
  },
  methods: {
    languageClass(language: string): string {
      const activeLanguage: any = this.activeLanguage;
      return language === activeLanguage?.description ? 'language--active' : '';
    },
    languageSelected(language: ILanguage) {
      this.$emit('languageSelected', language);
    },
  },
});
