/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
// import SocketService from '@/services/SocketService';
import { AuthenticationState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';

let errorMessage: string;
export const AuthenticationModule = {
  state(): AuthenticationState {
    return {
      isAuthenticated: false,
      emailAddress: '',
      emailVerificationCode: '',
    };
  },

  mutations: {
    authenticate(state: AuthenticationState): void {
      state.isAuthenticated = true;
    },
    unauthenticate(state: AuthenticationState): void {
      state.isAuthenticated = false;
    },
    setEmail(state: AuthenticationState, payload: string): void {
      state.emailAddress = payload;
    },
  },

  actions: {
    async authenticate(
      context: ActionContext<AuthenticationState, State>,
      payload: {
        email: string;
        password: string;
      },
    ): Promise<void | any> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .login(payload)
          .then((records: AxiosResponse<any>) => {
            context.commit('authenticate');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            context.commit('unauthenticate');
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response));
          });
      });
    },

    unauthenticate(context: ActionContext<AuthenticationState, State>): void {
      context.commit('unauthenticate');
    },

    async sendResetCode(context: ActionContext<AuthenticationState, State>, email: string): Promise<boolean> {
      try {
        const results: any = await new HttpService().requestResetPassword({ email });
        context.commit('setEmail', email);

        if (results.data.success) {
          return true;
        }

        return false;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${email}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        return false;
      }
    },

    async verifyCode(context: ActionContext<AuthenticationState, State>, payload: { code: string; email: string }): Promise<boolean> {
      try {
        const results: any = await new HttpService().verifyVerificationCode(payload);

        if (results.data.success) {
          return true;
        }

        return false;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        return false;
      }
    },

    async setPassword(context: ActionContext<AuthenticationState, State>, payload: { email: string; password: string }): Promise<boolean> {
      try {
        const results: any = await new HttpService().setPassword(payload);

        if (results.data.success) {
          return true;
        }

        return false;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        return false;
      }
    },
  },

  getters: {
    isAuthenticated(state: AuthenticationState): boolean {
      return state.isAuthenticated;
    },

    emailAddress(state: AuthenticationState): string {
      return state.emailAddress;
    },
  },
};
