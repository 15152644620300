import { AccessTypes } from '@/enums/AccessTypes';
import { PermissionTypes } from '@/enums/PermissionTypes';
import { IUser, IAccess, IPermissions } from '../../../../../interfaces/src/v2';

const access: IAccess = {
  marketingCampaignsAll: false,
  marketingCampaignsCompany: false,
  marketingCampaignsBrand: false,
  marketingCampaignsStore: false,
  marketingCampaignsNone: true,
  recruitmentCampaignsAll: false,
  recruitmentCampaignsCompany: false,
  recruitmentCampaignsBrand: false,
  recruitmentCampaignsStore: false,
  recruitmentCampaignsNone: true,
  mallMemosAll: false,
  mallMemosCompany: false,
  mallMemosBrand: false,
  mallMemosStore: false,
  mallMemosNone: true,
  mallsAll: false,
  mallsCompany: false,
  mallsBrand: false,
  mallsStore: false,
  mallsNone: true,
  storesAll: false,
  storesCompany: false,
  storesBrand: false,
  storesStore: false,
  storesCustom: false,
  companiesAll: false,
  companiesCustom: false,
  companiesNone: true,
  companiesExtra1: false,
  companiesExtra2: false,
  brandsAll: false,
  brandsCompany: false,
  brandsBrand: false,
  brandsCustom: false,
  brandsNone: true,
  usersAll: false,
  usersCompany: false,
  usersBrand: false,
  usersCustom: false,
  usersNone: true,
  superAdminAccess: false,
  storeMemosAll: false,
  storeMemosCompany: false,
  storeMemosBrand: false,
  storeMemosStore: false,
  storeMemosNone: true,
  marketingCampaignsMall: false,
  recruitmentCampaignsMall: false,
  mallsMall: false,
  storesMall: false,
  companiesMall: false,
  brandsMall: false,
  usersMall: false,
  mallMemosMall: false,
  storeMemosMall: false,
  opportunitiesAll: false,
  opportunitiesCompany: false,
  opportunitiesBrand: false,
  opportunitiesMall: false,
  opportunitiesStore: false,
  opportunitiesNone: true,
};

const permissions: IPermissions = {
  canCreateMarketingCampaigns: false,
  canSubmitMarketingCampaigns: false,
  canReadAllMarketingCampaigns: false,
  canReadOwnedMarketingCampaigns: false,
  canUpdateAllMarketingCampaigns: false,
  canUpdateOwnedMarketingCampaigns: false,
  canDeleteAllMarketingCampaigns: false,
  canDeleteOwnedMarketingCampaigns: false,
  canCreateRecruitmentCampaigns: false,
  canSubmitRecruitmentCampaigns: false,
  canReadAllRecruitmentCampaigns: false,
  canReadOwnedRecruitmentCampaigns: false,
  canUpdateAllRecruitmentCampaigns: false,
  canUpdateOwnedRecruitmentCampaigns: false,
  canDeleteAllRecruitmentCampaigns: false,
  canDeleteOwnedRecruitmentCampaigns: false,
  canCreateMallMemos: false,
  canSubmitMallMemos: false,
  canReadAllMallMemos: false,
  canReadOwnedMallMemos: false,
  canUpdateAllMallMemos: false,
  canUpdateOwnedMallMemos: false,
  canDeleteAllMallMemos: false,
  canDeleteOwnedMallMemos: false,
  canCreateStoreMemos: false,
  canSubmitStoreMemos: false,
  canReadAllStoreMemos: false,
  canReadOwnedStoreMemos: false,
  canUpdateAllStoreMemos: false,
  canUpdateOwnedStoreMemos: false,
  canDeleteAllStoreMemos: false,
  canDeleteOwnedStoreMemos: false,
  canCreateMalls: false,
  canReadAllMalls: false,
  canReadOwnedMalls: false,
  canUpdateAllMalls: false,
  canUpdateOwnedMalls: false,
  canDeleteAllMalls: false,
  canDeleteOwnedMalls: false,
  canCreateStores: false,
  canReadAllStores: false,
  canReadOwnedStores: false,
  canUpdateAllStores: false,
  canUpdateOwnedStores: false,
  canDeleteAllStores: false,
  canDeleteOwnedStores: false,
  canCreateCompanies: false,
  canReadAllCompanies: false,
  canReadOwnedCompanies: false,
  canUpdateAllCompanies: false,
  canUpdateOwnedCompanies: false,
  canDeleteAllCompanies: false,
  canDeleteOwnedCompanies: false,
  canCreateBrands: false,
  canReadAllBrands: false,
  canReadOwnedBrands: false,
  canUpdateAllBrands: false,
  canUpdateOwnedBrands: false,
  canDeleteAllBrands: false,
  canDeleteOwnedBrands: false,
  canCreateUsers: false,
  canReadAllUsers: false,
  canReadOwnedUsers: false,
  canUpdateAllUsers: false,
  canUpdateOwnedUsers: false,
  canDeleteAllUsers: false,
  canDeleteOwnedUsers: false,
  superAdminAccess: false,
};

export function setAccess(user: IUser): IAccess {
  for (const [key] of Object.entries(access)) {
    access[key] = checkPermission('access', user, AccessTypes[key]);
  }
  return access;
}

export function setPermissions(user: IUser): IPermissions {
  for (const [key] of Object.entries(permissions)) {
    permissions[key] = checkPermission('permissions', user, PermissionTypes[key]);
  }
  return permissions;
}

export function checkPermission(type: string, user: IUser, id: number): boolean {
  if (type === 'access') {
    return user?.dataAccess?.some(
      (item: { createdAt: string; description: string; dataAccessId: number; updatedAt: string }) => item?.dataAccessId === id,
    );
  } else {
    return user?.permissions?.some(
      (item: { createdAt: string; description: string; permissionId: number; updatedAt: string }) => item?.permissionId === id,
    );
  }
}

export function getPermissionAndAccessIds(
  object: any,
): {
  accessIds: number[];
  permissionIds: number[];
} {
  const formattedObject: any = {
    accessIds: [],
    permissionIds: [],
  };
  // Super Admin
  if (object['superAdminAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['superAdminAccess']);
    formattedObject.permissionIds.push(PermissionTypes['superAdminAccess']);
  }
  // Access
  if (object['marketingCampaignsDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['marketingCampaignsAll']);
  }
  if (object['marketingCampaignsDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['marketingCampaignsCompany']);
  }
  if (object['marketingCampaignsDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['marketingCampaignsBrand']);
  }
  if (object['marketingCampaignsDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['marketingCampaignsStore']);
  }
  if (object['marketingCampaignsDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['marketingCampaignsNone']);
  }
  if (object['marketingCampaignsDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['marketingCampaignsMall']);
  }
  if (object['recruitmentCampaignsDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['recruitmentCampaignsAll']);
  }
  if (object['recruitmentCampaignsDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['recruitmentCampaignsCompany']);
  }
  if (object['recruitmentCampaignsDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['recruitmentCampaignsBrand']);
  }
  if (object['recruitmentCampaignsDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['recruitmentCampaignsStore']);
  }
  if (object['recruitmentCampaignsDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['recruitmentCampaignsNone']);
  }
  if (object['recruitmentCampaignsDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['recruitmentCampaignsMall']);
  }
  if (object['mallMemosDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['mallMemosAll']);
  }
  if (object['mallMemosDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['mallMemosCompany']);
  }
  if (object['mallMemosDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['mallMemosBrand']);
  }
  if (object['mallMemosDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['mallMemosStore']);
  }
  if (object['mallMemosDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['mallMemosNone']);
  }
  if (object['mallMemosDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['mallMemosMall']);
  }
  if (object['storeMemosDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['storeMemosAll']);
  }
  if (object['storeMemosDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['storeMemosCompany']);
  }
  if (object['storeMemosDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['storeMemosBrand']);
  }
  if (object['storeMemosDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['storeMemosStore']);
  }
  if (object['storeMemosDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['storeMemosNone']);
  }
  if (object['storeMemosDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['storeMemosMall']);
  }
  if (object['mallsDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['mallsAll']);
  }
  if (object['mallsDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['mallsCompany']);
  }
  if (object['mallsDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['mallsBrand']);
  }
  if (object['mallsDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['mallsStore']);
  }
  if (object['mallsDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['mallsNone']);
  }
  if (object['mallsDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['mallsMall']);
  }
  if (object['storesDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['storesAll']);
  }
  if (object['storesDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['storesCompany']);
  }
  if (object['storesDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['storesBrand']);
  }
  if (object['storesDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['storesStore']);
  }
  if (object['storesDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['storesCustom']);
  }
  if (object['storesDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['storesMall']);
  }
  if (object['companiesDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['companiesAll']);
  }
  if (object['companiesDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['companiesCustom']);
  }
  if (object['companiesDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['companiesNone']);
  }
  if (object['companiesDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['companiesExtra1']);
  }
  if (object['companiesDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['companiesExtra2']);
  }
  if (object['companiesDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['companiesMall']);
  }
  if (object['brandsDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['brandsAll']);
  }
  if (object['brandsDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['brandsCompany']);
  }
  if (object['brandsDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['brandsBrand']);
  }
  if (object['brandsDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['brandsCustom']);
  }
  if (object['brandsDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['brandsNone']);
  }
  if (object['brandsDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['brandsMall']);
  }
  if (object['usersDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['usersAll']);
  }
  if (object['usersDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['usersCompany']);
  }
  if (object['usersDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['usersBrand']);
  }
  if (object['usersDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['usersCustom']);
  }
  if (object['usersDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['usersNone']);
  }
  if (object['usersDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['usersMall']);
  }
  if (object['opportunitiesDataAccess']?.value === 1) {
    formattedObject.accessIds.push(AccessTypes['opportunitiesAll']);
  }
  if (object['opportunitiesDataAccess']?.value === 2) {
    formattedObject.accessIds.push(AccessTypes['opportunitiesCompany']);
  }
  if (object['opportunitiesDataAccess']?.value === 3) {
    formattedObject.accessIds.push(AccessTypes['opportunitiesBrand']);
  }
  if (object['opportunitiesDataAccess']?.value === 4) {
    formattedObject.accessIds.push(AccessTypes['opportunitiesStore']);
  }
  if (object['opportunitiesDataAccess']?.value === 5) {
    formattedObject.accessIds.push(AccessTypes['opportunitiesNone']);
  }
  if (object['opportunitiesDataAccess']?.value === 6) {
    formattedObject.accessIds.push(AccessTypes['opportunitiesMall']);
  }
  // Permissions
  if (object['createMarketingCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateMarketingCampaigns']);
  }
  if (object['submitMarketingCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canSubmitMarketingCampaigns']);
  }
  if (object['readMarketingCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllMarketingCampaigns']);
  }
  if (object['readMarketingCampaigns']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedMarketingCampaigns']);
  }
  if (object['updateMarketingCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllMarketingCampaigns']);
  }
  if (object['updateMarketingCampaigns']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedMarketingCampaigns']);
  }
  if (object['deleteMarketingCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllMarketingCampaigns']);
  }
  if (object['deleteMarketingCampaigns']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedMarketingCampaigns']);
  }
  if (object['createRecruitmentCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateRecruitmentCampaigns']);
  }
  if (object['submitRecruitmentCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canSubmitRecruitmentCampaigns']);
  }
  if (object['readRecruitmentCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllRecruitmentCampaigns']);
  }
  if (object['readRecruitmentCampaigns']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedRecruitmentCampaigns']);
  }
  if (object['updateRecruitmentCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllRecruitmentCampaigns']);
  }
  if (object['updateRecruitmentCampaigns']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedRecruitmentCampaigns']);
  }
  if (object['deleteRecruitmentCampaigns']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllRecruitmentCampaigns']);
  }
  if (object['deleteRecruitmentCampaigns']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedRecruitmentCampaigns']);
  }
  if (object['createMallMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateMallMemos']);
  }
  if (object['submitMallMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canSubmitMallMemos']);
  }
  if (object['readMallMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllMallMemos']);
  }
  if (object['readMallMemos']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedMallMemos']);
  }
  if (object['updateMallMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllMallMemos']);
  }
  if (object['updateMallMemos']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedMallMemos']);
  }
  if (object['deleteMallMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllMallMemos']);
  }
  if (object['deleteMallMemos']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedMallMemos']);
  }
  if (object['createStoreMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateStoreMemos']);
  }
  if (object['submitStoreMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canSubmitStoreMemos']);
  }
  if (object['readStoreMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllStoreMemos']);
  }
  if (object['readStoreMemos']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedStoreMemos']);
  }
  if (object['updateStoreMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllStoreMemos']);
  }
  if (object['updateStoreMemos']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedStoreMemos']);
  }
  if (object['deleteStoreMemos']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllStoreMemos']);
  }
  if (object['deleteStoreMemos']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedStoreMemos']);
  }
  if (object['createMalls']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateMalls']);
  }
  if (object['readMalls']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllMalls']);
  }
  if (object['readMalls']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedMalls']);
  }
  if (object['updateMalls']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllMalls']);
  }
  if (object['updateMalls']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedMalls']);
  }
  if (object['deleteMalls']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllMalls']);
  }
  if (object['deleteMalls']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedMalls']);
  }
  if (object['createStores']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateStores']);
  }
  if (object['readStores']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllStores']);
  }
  if (object['readStores']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedStores']);
  }
  if (object['updateStores']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllStores']);
  }
  if (object['updateStores']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedStores']);
  }
  if (object['deleteStores']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllStores']);
  }
  if (object['deleteStores']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedStores']);
  }
  if (object['createCompanies']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateCompanies']);
  }
  if (object['readCompanies']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllCompanies']);
  }
  if (object['readCompanies']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedCompanies']);
  }
  if (object['updateCompanies']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllCompanies']);
  }
  if (object['updateCompanies']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedCompanies']);
  }
  if (object['deleteCompanies']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllCompanies']);
  }
  if (object['deleteCompanies']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedCompanies']);
  }
  if (object['createBrands']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateBrands']);
  }
  if (object['readBrands']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllBrands']);
  }
  if (object['readBrands']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedBrands']);
  }
  if (object['updateBrands']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllBrands']);
  }
  if (object['updateBrands']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedBrands']);
  }
  if (object['deleteBrands']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllBrands']);
  }
  if (object['deleteBrands']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedBrands']);
  }
  if (object['createUsers']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canCreateUsers']);
  }
  if (object['readUsers']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canReadAllUsers']);
  }
  if (object['readUsers']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canReadOwnedUsers']);
  }
  if (object['updateUsers']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateAllUsers']);
  }
  if (object['updateUsers']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canUpdateOwnedUsers']);
  }
  if (object['deleteUsers']?.value === 1) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteAllUsers']);
  }
  if (object['deleteUsers']?.value === 2) {
    formattedObject.permissionIds.push(PermissionTypes['canDeleteOwnedUsers']);
  }
  return formattedObject;
}

export async function getPermissionAndAccessDropdownState(object: any) {
  const formattedObject: any = {};
  // Super Admin
  if (object?.accessIds?.includes(AccessTypes['superAdminAccess']) || object?.permissionIds?.includes(PermissionTypes['superAdminAccess'])) {
    formattedObject.superAdminAccess = { value: 1, description: 'Yes' };
  } else {
    formattedObject.superAdminAccess = { value: 2, description: 'No' };
  }
  // Access
  if (object?.accessIds?.includes(AccessTypes['marketingCampaignsAll'])) {
    formattedObject.marketingCampaignsDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['marketingCampaignsCompany'])) {
    formattedObject.marketingCampaignsDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['marketingCampaignsBrand'])) {
    formattedObject.marketingCampaignsDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['marketingCampaignsStore'])) {
    formattedObject.marketingCampaignsDataAccess = { value: 4, description: 'Store' };
  } else if (object?.accessIds?.includes(AccessTypes['marketingCampaignsNone'])) {
    formattedObject.marketingCampaignsDataAccess = { value: 5, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['marketingCampaignsMall'])) {
    formattedObject.marketingCampaignsDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['recruitmentCampaignsAll'])) {
    formattedObject.recruitmentCampaignsDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['recruitmentCampaignsCompany'])) {
    formattedObject.recruitmentCampaignsDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['recruitmentCampaignsBrand'])) {
    formattedObject.recruitmentCampaignsDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['recruitmentCampaignsStore'])) {
    formattedObject.recruitmentCampaignsDataAccess = { value: 4, description: 'Store' };
  } else if (object?.accessIds?.includes(AccessTypes['recruitmentCampaignsNone'])) {
    formattedObject.recruitmentCampaignsDataAccess = { value: 5, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['recruitmentCampaignsMall'])) {
    formattedObject.recruitmentCampaignsDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['mallMemosAll'])) {
    formattedObject.mallMemosDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['mallMemosCompany'])) {
    formattedObject.mallMemosDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['mallMemosBrand'])) {
    formattedObject.mallMemosDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['mallMemosStore'])) {
    formattedObject.mallMemosDataAccess = { value: 4, description: 'Store' };
  } else if (object?.accessIds?.includes(AccessTypes['mallMemosNone'])) {
    formattedObject.mallMemosDataAccess = { value: 5, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['mallMemosMall'])) {
    formattedObject.mallMemosDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['storeMemosAll'])) {
    formattedObject.storeMemosDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['storeMemosCompany'])) {
    formattedObject.storeMemosDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['storeMemosBrand'])) {
    formattedObject.storeMemosDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['storeMemosStore'])) {
    formattedObject.storeMemosDataAccess = { value: 4, description: 'Store' };
  } else if (object?.accessIds?.includes(AccessTypes['storeMemosNone'])) {
    formattedObject.storeMemosDataAccess = { value: 5, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['storeMemosMall'])) {
    formattedObject.storeMemosDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['mallsAll'])) {
    formattedObject.mallsDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['mallsCompany'])) {
    formattedObject.mallsDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['mallsBrand'])) {
    formattedObject.mallsDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['mallsStore'])) {
    formattedObject.mallsDataAccess = { value: 4, description: 'Store' };
  } else if (object?.accessIds?.includes(AccessTypes['mallsNone'])) {
    formattedObject.mallsDataAccess = { value: 5, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['mallsMall'])) {
    formattedObject.mallsDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['storesAll'])) {
    formattedObject.storesDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['storesCompany'])) {
    formattedObject.storesDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['storesBrand'])) {
    formattedObject.storesDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['storesStore'])) {
    formattedObject.storesDataAccess = { value: 4, description: 'Store' };
  } else if (object?.accessIds?.includes(AccessTypes['storesCustom'])) {
    formattedObject.storesDataAccess = { value: 5, description: 'Custom' };
  } else if (object?.accessIds?.includes(AccessTypes['storesMall'])) {
    formattedObject.storesDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['companiesAll'])) {
    formattedObject.companiesDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['companiesCustom'])) {
    formattedObject.companiesDataAccess = { value: 2, description: 'Custom' };
  } else if (object?.accessIds?.includes(AccessTypes['companiesNone'])) {
    formattedObject.companiesDataAccess = { value: 3, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['companiesExtra1'])) {
    formattedObject.companiesDataAccess = { value: 4, description: 'Extra1' };
  } else if (object?.accessIds?.includes(AccessTypes['companiesExtra2'])) {
    formattedObject.companiesDataAccess = { value: 5, description: 'Extra2' };
  } else if (object?.accessIds?.includes(AccessTypes['companiesMall'])) {
    formattedObject.companiesDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['brandsAll'])) {
    formattedObject.brandsDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['brandsCompany'])) {
    formattedObject.brandsDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['brandsBrand'])) {
    formattedObject.brandsDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['brandsCustom'])) {
    formattedObject.brandsDataAccess = { value: 4, description: 'Custom' };
  } else if (object?.accessIds?.includes(AccessTypes['brandsNone'])) {
    formattedObject.brandsDataAccess = { value: 5, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['brandsMall'])) {
    formattedObject.brandsDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['usersAll'])) {
    formattedObject.usersDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['usersCompany'])) {
    formattedObject.usersDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['usersBrand'])) {
    formattedObject.usersDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['usersCustom'])) {
    formattedObject.usersDataAccess = { value: 4, description: 'Custom' };
  } else if (object?.accessIds?.includes(AccessTypes['usersNone'])) {
    formattedObject.usersDataAccess = { value: 5, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['usersMall'])) {
    formattedObject.usersDataAccess = { value: 6, description: 'Mall' };
  }
  if (object?.accessIds?.includes(AccessTypes['opportunitiesAll'])) {
    formattedObject.opportunitiesDataAccess = { value: 1, description: 'All' };
  } else if (object?.accessIds?.includes(AccessTypes['opportunitiesCompany'])) {
    formattedObject.opportunitiesDataAccess = { value: 2, description: 'Company' };
  } else if (object?.accessIds?.includes(AccessTypes['opportunitiesBrand'])) {
    formattedObject.opportunitiesDataAccess = { value: 3, description: 'Brand' };
  } else if (object?.accessIds?.includes(AccessTypes['opportunitiesStore'])) {
    formattedObject.opportunitiesDataAccess = { value: 4, description: 'Store' };
  } else if (object?.accessIds?.includes(AccessTypes['opportunitiesNone'])) {
    formattedObject.opportunitiesDataAccess = { value: 5, description: 'None' };
  } else if (object?.accessIds?.includes(AccessTypes['opportunitiesMall'])) {
    formattedObject.opportunitiesDataAccess = { value: 6, description: 'Mall' };
  }
  // Permissions
  if (object?.permissionIds?.includes(PermissionTypes['canCreateMarketingCampaigns'])) {
    formattedObject.createMarketingCampaigns = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createMarketingCampaigns = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canSubmitMarketingCampaigns'])) {
    formattedObject.submitMarketingCampaigns = { value: 1, description: 'Yes' };
  } else {
    formattedObject.submitMarketingCampaigns = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canCreateRecruitmentCampaigns'])) {
    formattedObject.createRecruitmentCampaigns = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createRecruitmentCampaigns = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canSubmitRecruitmentCampaigns'])) {
    formattedObject.submitRecruitmentCampaigns = { value: 1, description: 'Yes' };
  } else {
    formattedObject.submitRecruitmentCampaigns = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canCreateMallMemos'])) {
    formattedObject.createMallMemos = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createMallMemos = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canSubmitMallMemos'])) {
    formattedObject.submitMallMemos = { value: 1, description: 'Yes' };
  } else {
    formattedObject.submitMallMemos = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canCreateStoreMemos'])) {
    formattedObject.createStoreMemos = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createStoreMemos = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canSubmitStoreMemos'])) {
    formattedObject.submitStoreMemos = { value: 1, description: 'Yes' };
  } else {
    formattedObject.submitStoreMemos = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canCreateMalls'])) {
    formattedObject.createMalls = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createMalls = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canCreateStores'])) {
    formattedObject.createStores = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createStores = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canCreateCompanies'])) {
    formattedObject.createCompanies = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createCompanies = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canCreateBrands'])) {
    formattedObject.createBrands = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createBrands = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canCreateUsers'])) {
    formattedObject.createUsers = { value: 1, description: 'Yes' };
  } else {
    formattedObject.createUsers = { value: 2, description: 'No' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllMarketingCampaigns'])) {
    formattedObject.readMarketingCampaigns = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedMarketingCampaigns'])) {
    formattedObject.readMarketingCampaigns = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readMarketingCampaigns = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllMarketingCampaigns'])) {
    formattedObject.updateMarketingCampaigns = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedMarketingCampaigns'])) {
    formattedObject.updateMarketingCampaigns = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateMarketingCampaigns = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllMarketingCampaigns'])) {
    formattedObject.deleteMarketingCampaigns = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedMarketingCampaigns'])) {
    formattedObject.deleteMarketingCampaigns = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteMarketingCampaigns = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllRecruitmentCampaigns'])) {
    formattedObject.readRecruitmentCampaigns = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedRecruitmentCampaigns'])) {
    formattedObject.readRecruitmentCampaigns = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readRecruitmentCampaigns = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllRecruitmentCampaigns'])) {
    formattedObject.updateRecruitmentCampaigns = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedRecruitmentCampaigns'])) {
    formattedObject.updateRecruitmentCampaigns = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateRecruitmentCampaigns = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllRecruitmentCampaigns'])) {
    formattedObject.deleteRecruitmentCampaigns = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedRecruitmentCampaigns'])) {
    formattedObject.deleteRecruitmentCampaigns = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteRecruitmentCampaigns = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllMallMemos'])) {
    formattedObject.readMallMemos = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedMallMemos'])) {
    formattedObject.readMallMemos = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readMallMemos = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllMallMemos'])) {
    formattedObject.updateMallMemos = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedMallMemos'])) {
    formattedObject.updateMallMemos = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateMallMemos = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllMallMemos'])) {
    formattedObject.deleteMallMemos = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedMallMemos'])) {
    formattedObject.deleteMallMemos = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteMallMemos = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllStoreMemos'])) {
    formattedObject.readStoreMemos = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedStoreMemos'])) {
    formattedObject.readStoreMemos = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readStoreMemos = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllStoreMemos'])) {
    formattedObject.updateStoreMemos = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedStoreMemos'])) {
    formattedObject.updateStoreMemos = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateStoreMemos = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllStoreMemos'])) {
    formattedObject.deleteStoreMemos = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedStoreMemos'])) {
    formattedObject.deleteStoreMemos = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteStoreMemos = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllMalls'])) {
    formattedObject.readMalls = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedMalls'])) {
    formattedObject.readMalls = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readMalls = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllMalls'])) {
    formattedObject.updateMalls = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedMalls'])) {
    formattedObject.updateMalls = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateMalls = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllMalls'])) {
    formattedObject.deleteMalls = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedMalls'])) {
    formattedObject.deleteMalls = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteMalls = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllStores'])) {
    formattedObject.readStores = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedStores'])) {
    formattedObject.readStores = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readStores = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllStores'])) {
    formattedObject.updateStores = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedStores'])) {
    formattedObject.updateStores = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateStores = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllStores'])) {
    formattedObject.deleteStores = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedStores'])) {
    formattedObject.deleteStores = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteStores = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllCompanies'])) {
    formattedObject.readCompanies = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedCompanies'])) {
    formattedObject.readCompanies = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readCompanies = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllCompanies'])) {
    formattedObject.updateCompanies = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedCompanies'])) {
    formattedObject.updateCompanies = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateCompanies = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllCompanies'])) {
    formattedObject.deleteCompanies = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedCompanies'])) {
    formattedObject.deleteCompanies = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteCompanies = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllBrands'])) {
    formattedObject.readBrands = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedBrands'])) {
    formattedObject.readBrands = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readBrands = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllBrands'])) {
    formattedObject.updateBrands = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedBrands'])) {
    formattedObject.updateBrands = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateBrands = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllBrands'])) {
    formattedObject.deleteBrands = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedBrands'])) {
    formattedObject.deleteBrands = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteBrands = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canReadAllUsers'])) {
    formattedObject.readUsers = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canReadOwnedUsers'])) {
    formattedObject.readUsers = { value: 2, description: 'Owned' };
  } else {
    formattedObject.readUsers = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canUpdateAllUsers'])) {
    formattedObject.updateUsers = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canUpdateOwnedUsers'])) {
    formattedObject.updateUsers = { value: 2, description: 'Owned' };
  } else {
    formattedObject.updateUsers = { value: 3, description: 'None' };
  }
  if (object?.permissionIds?.includes(PermissionTypes['canDeleteAllUsers'])) {
    formattedObject.deleteUsers = { value: 1, description: 'All' };
  } else if (object?.permissionIds?.includes(PermissionTypes['canDeleteOwnedUsers'])) {
    formattedObject.deleteUsers = { value: 2, description: 'Owned' };
  } else {
    formattedObject.deleteUsers = { value: 3, description: 'None' };
  }
  return formattedObject;
}
