
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import Chip from '../../atoms/Chip/Chip.vue';

export default defineComponent({
  components: {
    'arc-chip': Chip,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Object,
    },
    status: {
      type: String,
    },
    message: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: String,
    },
    inputTitle: {
      type: String,
    },
    initialValue: {
      type: String,
    },
    ableToAdd: {
      type: Boolean,
      default: true,
    },
    allowShowOptions: {
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data(): {
    showOptions: boolean;
    currentValue: string;
    suggestedOptions: IDropdownOption[];
    selectedValues: IDropdownOption[];
  } {
    return {
      showOptions: false,
      currentValue: '',
      suggestedOptions: [],
      selectedValues: [],
    };
  },

  computed: {
    open(): string {
      return this.showOptions ? 'open' : '';
    },
    filteredOptions(): any {
      const titles = this.options
        ?.map((option: any) => option?.description)
        .filter((option: any) => option?.toLowerCase()?.includes(this.currentValue?.toLowerCase()));
      let selections = this.options?.filter((option: any) => {
        return titles?.includes(option?.description);
      });
      return selections?.length > 0 ? selections : this.options;
    },
    valueNotInSuggestions(): boolean {
      const suggestions: any[] | [] = this.options?.filter((el: any) => el?.description === this.currentValue);

      if (!this.options || (this.options && this.options?.length === 0)) {
        return true;
      }

      if (suggestions.length > 0) {
        return false;
      }

      return true;
    },
    inputStatus(): {
      'input--success': boolean;
      'input--warn': boolean;
      'input--error': boolean;
    } {
      return {
        'input--success': this.status === 'success',
        'input--warn': this.status === 'warn',
        'input--error': this.status === 'error',
      };
    },
    inputStyles(): string {
      return this.showOptions ? '' : 'border-radius: 6px; border-color: hsl(0, 0%, 84%); box-shadow: none;';
    },
  },
  methods: {
    toggleDropdownOptionsState(): void {
      this.showOptions = !this.showOptions;
    },
    emitValueChange(value: string): void {
      this.currentValue = value;
      this.$emit('update:modelValue', value);
    },
    selectItem(option: IDropdownOption): void {
      this.selectedValues = [];
      this.currentValue = option?.description;
      this.selectedValues.push(option);
      this.$emit('update:modelValue', option);
      this.hideDropdownOptions();
    },
    deselect(option: IDropdownOption): void {
      this.selectedValues = this.selectedValues.filter((el: IDropdownOption): boolean => {
        return el?.description !== option?.description;
      });
    },
    hideDropdownOptions(): void {
      setTimeout(() => {
        this.showOptions = false;
      }, 100);
    },
    openOptions(): void {
      setTimeout(() => {
        this.showOptions = true;
      }, 100);
    },
    removeChip(description: string): void {
      this.selectedValues = this.selectedValues.filter((el: IDropdownOption) => el.description !== description);
    },
    addItem(): void {
      this.selectedValues = [];

      this.selectedValues.push({
        description: this.currentValue,
        value: this.currentValue,
      });

      this.$emit('newItemAdded', this.currentValue);
      this.$emit('update:modelValue', this.selectedValues[0]);

      this.hideDropdownOptions();
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler(value) {
        this.currentValue = value?.description;
      },
    },
    currentValue: {
      deep: true,
      handler() {
        // if (this.allowShowOptions) {
        //   this.showOptions = true;
        // }
        this.$emit('currentValueChange', this.currentValue);
        // setTimeout(() => {
        //   this.showOptions = false;
        // }, 10000);
      },
    },
  },
  mounted(): void {
    if (this.initialValue) {
      this.currentValue = this.initialValue;
      setTimeout(() => {
        this.showOptions = false;
      }, 55);
    }
  },
});
