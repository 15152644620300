
import { defineComponent } from 'vue';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
  props: {
    type: {
      required: true,
    },
    chartData: {
      required: true,
    },
    options: {
      required: true,
    },
  },

  emits: {
    created: (): boolean => true,
  },

  data(): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chart: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvas: any;
  } {
    return {
      chart: null,
      canvas: null,
    };
  },

  mounted(): void {
    Chart.register(...registerables);
    this.createChart(this.chartData as ChartData, this.options as ChartOptions);
    this.$emit('created');
  },

  computed: {
    id(): string {
      return uuidv4();
    },
  },

  watch: {
    chartData: function(): void {
      // this.updateChart();
    },
  },

  methods: {
    updateChart(): void {
      if (this.canvas && this.chart) {
        this.chart.destroy();
        this.createChart(this.chartData as ChartData, this.options as ChartOptions);
      }
    },

    createChart(chartData: ChartData, chartOptions: ChartOptions): void {
      this.canvas = document.getElementById(this.id as string);

      this.chart = new Chart(this.canvas as HTMLCanvasElement, {
        type: this.type as 'bar' | 'line' | 'scatter' | 'bubble' | 'pie' | 'doughnut' | 'polarArea' | 'radar',
        data: chartData,
        options: chartOptions,
      });
    },
  },
});
