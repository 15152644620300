
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    theme: {
      type: String,
      default: 'grey-dark',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    saving: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonType(): {
      'button--icon': boolean;
      'button--primary-blue': boolean;
      'button--secondary-blue': boolean;
      'button--tertiary-blue': boolean;
      'button--link-blue': boolean;
      'button--primary-pink': boolean;
      'button--secondary-pink': boolean;
      'button--tertiary-pink': boolean;
      'button--primary-purple': boolean;
      'button--secondary-purple': boolean;
      'button--tertiary-purple': boolean;
      'button--primary-info-purple': boolean;
      'button--secondary-info-purple': boolean;
      'button--tertiary-info-purple': boolean;
      'button--primary-success': boolean;
      'button--secondary-success': boolean;
      'button--tertiary-success': boolean;
      'button--primary-error': boolean;
      'button--secondary-error': boolean;
      'button--tertiary-error': boolean;
      'button--primary-warn': boolean;
      'button--secondary-warn': boolean;
      'button--tertiary-warn': boolean;
      'button--grey-dark': boolean;
    } {
      return {
        'button--icon': this.theme === 'icon',
        'button--primary-blue': this.theme === 'primary-blue',
        'button--secondary-blue': this.theme === 'secondary-blue',
        'button--tertiary-blue': this.theme === 'tertiary-blue',
        'button--link-blue': this.theme === 'link-blue',
        'button--primary-pink': this.theme === 'primary-pink',
        'button--secondary-pink': this.theme === 'secondary-pink',
        'button--tertiary-pink': this.theme === 'tertiary-pink',
        'button--primary-purple': this.theme === 'primary-purple',
        'button--secondary-purple': this.theme === 'secondary-purple',
        'button--tertiary-purple': this.theme === 'tertiary-purple',
        'button--primary-info-purple': this.theme === 'primary-info-purple',
        'button--secondary-info-purple': this.theme === 'secondary-info-purple',
        'button--tertiary-info-purple': this.theme === 'tertiary-info-purple',
        'button--primary-success': this.theme === 'primary-success',
        'button--secondary-success': this.theme === 'secondary-success',
        'button--tertiary-success': this.theme === 'tertiary-success',
        'button--primary-error': this.theme === 'primary-error',
        'button--secondary-error': this.theme === 'secondary-error',
        'button--tertiary-error': this.theme === 'tertiary-error',
        'button--primary-warn': this.theme === 'primary-warn',
        'button--secondary-warn': this.theme === 'secondary-warn',
        'button--tertiary-warn': this.theme === 'tertiary-warn',
        'button--grey-dark': this.theme === 'grey-dark',
      };
    },
  },
});
