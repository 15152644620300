export enum CommunicationTypes {
  'Sales / Promo' = 1,
  'Events' = 2,
  'New Arrival / Featured Product' = 3,
  'Social Media' = 4,
  'Loyalty Campaign' = 5,
  'Recruitment Campaign' = 6,
  'Mall Memo' = 7,
  'Brand Update' = 8,
  'Store Memo' = 9,
  'Retailer Memo' = 10,
}
