
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Button from '../Button/Button.vue';
import { AbortHandler } from '@/services/AbortHandler';

export default defineComponent({
  emits: {
    closeModal: Boolean,
    hasChangesMade: Boolean,
  },
  components: {
    'arc-button': Button,
  },
  props: {
    type: {
      type: String,
      default: 'default',
    },
    backgroundType: {
      type: String,
      default: 'default',
    },
    prompt: {
      type: Boolean,
      default: true,
    },
    hasUnsavedChanges: {
      type: Boolean,
      default: true,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['closingModal']),
    contentClass(): string {
      return this.type === 'prompt' ? 'prompt__content' : 'modal__content';
    },
    backgroundClass(): string {
      return this.backgroundType === 'full-screen' ? 'full-screen__background' : this.type === 'prompt' ? 'prompt__background' : 'modal__background';
    },
    openingContentAnimation(): string {
      return this.opening ? 'opening-content' : '';
    },
    openingBackgroundAnimation(): string {
      return this.opening ? 'opening-background' : '';
    },
    closingAnimation(): string {
      return this.closing || this.closingModal ? 'closing' : '';
    },
  },
  data(): {
    opening: boolean;
    closing: boolean;
    showClosePrompt: boolean;
  } {
    return {
      opening: false,
      closing: false,
      showClosePrompt: false,
    };
  },
  mounted(): void {
    const abortController = AbortHandler.getInstance();
    abortController.kill();

    setTimeout(() => {
      this.opening = true;
    }, 50);
  },
  methods: {
    ...mapActions(['updateModalData']),
    close(confirm?: boolean) {
      if (!this.hasUnsavedChanges) {
        this.$emit('closeModal', 'closing the modal');
        return;
      }
      if (confirm || this.prompt === false) {
        this.closing = true;
        this.showClosePrompt = false;
        // setTimeout(() => {
        this.$emit('closeModal', 'closing the modal');
        // }, 1000);
      }
      if (!confirm && this.prompt) {
        this.showClosePrompt = true;
      }
    },
    closePrompt() {
      this.showClosePrompt = false;
    },
    backgroundClick() {
      if (this.closeable) {
        this.close();
      }
    },
  },
});
