export enum ContactType {
  Store = 1,
  Mall = 2,
  'Portal Contact' = 5,
  'Local Contact' = 6,
  'Corporate Contact' = 7,
  'Agency Contact' = 8,
  'District Manager' = 9,
  'Store Manager' = 10,
  Employee = 11,
  'Temp Contact' = 12,
}
