
import Global from './Global.vue';
import axios from 'axios';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401 && window.location.pathname !== '/login') {
      window.location.href = '/login';
    }

    return Promise.reject(error);
  },
);

export default {
  components: {
    'app-root': Global,
  },
};
