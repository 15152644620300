/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommunicationState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';
import { IMarketingCampaign, IRecruitmentCampaign, IMemo } from '../../../../interfaces/src/v2/index';
import HttpService from '@/services/HttpService';
import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';
import { Status } from '@/enums/Status';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const CommunicationModule = {
  state(): CommunicationState {
    return {
      marketingCampaigns: [],
      selectedMarketingCampaign: null,
      loadingMarketingCampaigns: false,
      recruitmentCampaigns: [],
      selectedRecruitmentCampaign: null,
      loadingRecruitmentCampaigns: false,
      mallMemos: [],
      selectedMallMemo: null,
      loadingMallMemos: false,
      storeMemos: [],
      selectedStoreMemo: null,
      loadingStoreMemos: false,
      allCommunications: null,
      loadingAllCommunications: false,
      selectedImageHeight: 0,
      selectedImageWidth: 0,
      currentImageType: 'primary',
      uploadingMarketingPrimaryImage: false,
      uploadingMarketingSecondaryImage: false,
      uploadingRecruitmentImage: false,
    };
  },

  mutations: {
    clearAllCommunications(state: CommunicationState): void {
      state.allCommunications = null;
      state.marketingCampaigns = [];
      state.recruitmentCampaigns = [];
      state.mallMemos = [];
      state.storeMemos = [];
    },
    updateAllCommunications(state: CommunicationState, payload: any[] | []): void {
      state.allCommunications = payload;
    },
    refreshAllCommunications(state: CommunicationState): void {
      let data: any[] = [];
      data = [...data, ...state.marketingCampaigns];
      data = [...data, ...state.recruitmentCampaigns];
      data = [...data, ...state.mallMemos];
      data = [...data, ...state.storeMemos];
      state.allCommunications = data;
    },
    updateMarketingCampaigns(state: CommunicationState, payload: IMarketingCampaign[] | []): void {
      state.marketingCampaigns = payload;
    },
    updateMarketingCampaign(state: CommunicationState, payload: IMarketingCampaign | null): void {
      state.selectedMarketingCampaign = payload;
    },
    updateLoadingMarketingCampaigns(state: CommunicationState, payload: boolean): void {
      state.loadingMarketingCampaigns = payload;
    },
    updateRecruitmentCampaigns(state: CommunicationState, payload: IRecruitmentCampaign[] | []): void {
      state.recruitmentCampaigns = payload;
    },
    updateRecruitmentCampaign(state: CommunicationState, payload: IRecruitmentCampaign | null): void {
      state.selectedRecruitmentCampaign = payload;
    },
    updateLoadingRecruitmentCampaigns(state: CommunicationState, payload: boolean): void {
      state.loadingRecruitmentCampaigns = payload;
    },
    updateMallMemos(state: CommunicationState, payload: IMemo[] | []): void {
      state.mallMemos = payload;
    },
    updateMallMemo(state: CommunicationState, payload: IMemo | null): void {
      state.selectedMallMemo = payload;
    },
    updateLoadingMallMemos(state: CommunicationState, payload: boolean): void {
      state.loadingMallMemos = payload;
    },
    updateStoreMemos(state: CommunicationState, payload: IMemo[] | []): void {
      state.storeMemos = payload;
    },
    updateStoreMemo(state: CommunicationState, payload: IMemo | null): void {
      state.selectedStoreMemo = payload;
    },
    updateLoadingStoreMemos(state: CommunicationState, payload: boolean): void {
      state.loadingStoreMemos = payload;
    },
    updateLoadingAllCommunications(state: CommunicationState, payload: boolean): void {
      state.loadingAllCommunications = payload;
    },
    updateSelectedImageHeight(state: CommunicationState, payload: number): void {
      state.selectedImageHeight = payload;
    },
    updateSelectedImageWidth(state: CommunicationState, payload: number): void {
      state.selectedImageWidth = payload;
    },
    updateCurrentImageType(state: CommunicationState, payload: string): void {
      state.currentImageType = payload;
    },
    updateUploadingMarketingPrimaryImage(state: CommunicationState, payload: boolean): void {
      state.uploadingMarketingPrimaryImage = payload;
    },
    updateUploadingMarketingSecondaryImage(state: CommunicationState, payload: boolean): void {
      state.uploadingMarketingSecondaryImage = payload;
    },
    updateUploadingRecruitmentImage(state: CommunicationState, payload: boolean): void {
      state.uploadingRecruitmentImage = payload;
    },
  },

  actions: {
    clearAllCommunications(context: ActionContext<CommunicationState, State>): any {
      context.commit('clearAllCommunications');
    },
    updateAllCommunications(context: ActionContext<CommunicationState, State>, payload: any[] | []): any {
      context.commit('updateAllCommunications', payload);
    },
    refreshAllCommunications(context: ActionContext<CommunicationState, State>): any {
      context.commit('refreshAllCommunications');
    },
    async fetchCommunicationsByMonth(
      context: ActionContext<CommunicationState, State>,
      payload: { firstDay: string; lastDay: string },
    ): Promise<any> {
      context.commit('updateLoadingAllCommunications', true);
      try {
        const records: AxiosResponse<Response> = await new HttpService().getCommunicationsByMonth(payload.firstDay, payload.lastDay);
        records.data.data.forEach((communication: any) => {
          let status = '';
          communication.commSubTypeText = communication?.type?.description;
          communication.brandText = communication?.brand?.description;
          communication.timezone = communication?.timezone ? `| ${communication?.timezone}` : '';
          communication.start = communication?.fromDate ? communication?.fromDate.slice(0, 10) : communication?.publishByDate?.slice(0, 10);
          let td = new Date(communication?.toDate);
          td = new Date(td.setDate(td.getDate() + 1));
          communication.end = td.toISOString()?.slice(0, 10);
          const tdd = td.toDateString();
          communication.uniqueId = communication.campaignNumber ?? communication.memoNumber;

          if (communication?.marketingCampaignId) {
            communication.communicationId = communication?.marketingCampaignId;
            communication.commType = 'marketing-campaign';

            communication.backgroundColor = '#c7f6ff';
            communication.borderColor = '#c7f6ff';
            communication.textColor = '#24a8da';
            if (communication.statusId === Status.Draft) {
              communication.borderColor = '#24a8da';
            }
          }
          if (communication?.recruitmentCampaignId) {
            communication.communicationId = communication?.recruitmentCampaignId;
            communication.commType = 'recruitment-campaign';

            communication.backgroundColor = '#dfccff';
            communication.borderColor = '#dfccff';
            communication.textColor = '#5e17c9';
            if (communication.statusId === Status.Draft) {
              communication.borderColor = '#5e17c9';
            }
          }
          if (communication?.memoTypeId === 1) {
            communication.communicationId = communication?.memoId;
            communication.commType = 'store-memo';
            communication.questionsLength = communication?.questions?.length;

            communication.backgroundColor = '#ffe2cc';
            communication.borderColor = '#ffe2cc';
            communication.textColor = '#d57b20';
            if (communication.statusId === Status.Draft) {
              communication.borderColor = '#d57b20';
            }
          }
          if (communication?.memoTypeId === 2) {
            communication.communicationId = communication?.memoId;
            communication.commType = 'mall-memo';

            communication.backgroundColor = '#ffccff';
            communication.borderColor = '#ffccff';
            communication.textColor = '#db2be7';
            if (communication.statusId === Status.Draft) {
              communication.borderColor = '#db2be7';
            }
          }
          // change the colour of the communication based on the brand if user specified one
          if (communication.brand?.backgroundColour) {
            communication.backgroundColor = communication.brand.backgroundColour;
            communication.borderColor = communication.brand.backgroundColour;

            if (communication.statusId === Status.Draft) {
              communication.textColor = communication.brand.backgroundColour;
            } else {
              const c = communication.backgroundColor.substring(1); // strip #
              const rgb = parseInt(c, 16); // convert rrggbb to decimal
              const r = (rgb >> 16) & 0xff; // extract red
              const g = (rgb >> 8) & 0xff; // extract green
              const b = (rgb >> 0) & 0xff; // extract blue

              const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
              if (luma < 40) {
                communication.textColor = '#ffffff';
              } else {
                communication.textColor = '#000000';
              }
            }
          }
          if (communication.statusId === Status.Draft) {
            communication.backgroundColor = '#FFFFFF';
            status = '[DRAFT] ';
          } else if (communication.statusId === Status.Expired || communication.statusId === Status['Manually Expired']) {
            communication.backgroundColor = '#FFFFFF';
            communication.borderColor = '#E23D6F';
            communication.textColor = '#E23D6F';
            status = '[EXPIRED] ';
          }
          communication.title = `${status}${communication?.brandText} - #${communication?.campaignNumber ?? communication?.memoNumber} - ${
            communication?.description
          } | ends ${tdd} ${communication?.timezone}`;
          if (communication.tags && communication.tags.length > 0) {
            const tagDescriptions = communication.tags
              .map((tag: any) => tag.description)
              .sort((a: string, b: string) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
            communication.title = `[${tagDescriptions.join(', ')}] | ${communication.title}`;
          }
        });
        context.commit('updateAllCommunications', records?.data?.data);
        context.commit('updateLoadingAllCommunications', false);
        return records?.data?.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingAllCommunications', false);
      }
    },

    async fetchCommunicationMallsInformationViaPublicId(context: ActionContext<CommunicationState, State>, payload: string): Promise<any> {
      try {
        const records: AxiosResponse<Response> = await new HttpService().getLinkInformationViaPublicId(payload);
        return {
          valid: records?.data?.success,
          data: records?.data?.data,
        };
      } catch (err) {
        const errorAsAny = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async fetchMarketingCampaignApprovals(
      context: ActionContext<CommunicationState, State>,
      payload: { marketingCampaignIds: string[] },
    ): Promise<any> {
      try {
        await Promise.all(
          payload.marketingCampaignIds.map((el: string) =>
            new HttpService().getMarketingCampaignApprovals(el).then((data: any) => {
              const approvalData = data.data.data;

              for (const communication of context.state.marketingCampaigns) {
                const approvalInformation = approvalData.marketingCampaignId === communication.marketingCampaignId;

                if (!approvalInformation) continue;

                communication.stats = approvalData.stats;
                communication.approvalAmount = Math.round((communication?.stats?.approved / communication?.stats?.sent) * 100);
              }
            }),
          ),
        );
      } catch (error) {
        return;
      }
    },

    async fetchMallMarketingCampaignApprovals(
      context: ActionContext<CommunicationState, State>,
      payload: { contactGroupId: string; marketingCampaignIds: string[] },
    ): Promise<any> {
      try {
        await Promise.all(
          payload.marketingCampaignIds.map((el: string) =>
            new HttpService().getMallMarketingCampaignApprovals(payload.contactGroupId, el).then((data: any) => {
              const approvalData = data.data.data;

              for (const communication of context.state.marketingCampaigns) {
                const approvalInformation = approvalData.marketingCampaignId === communication.marketingCampaignId;

                if (!approvalInformation) continue;

                communication.stats = approvalData.stats;
                communication.approvalAmount = Math.round((communication?.stats?.approved / communication?.stats?.sent) * 100);
                const declineAmount = Math.round((communication?.stats?.declined / communication?.stats?.sent) * 100);

                if (communication.statusId === Status['Manually Expired']) {
                  communication.secondaryStatusText = 'Removal Needed';
                } else if (communication.approvalAmount >= 100 || declineAmount >= 100) {
                  communication.secondaryStatusText = 'Actioned';
                } else if (communication.createdAt !== communication.updatedAt && communication.versionNumber !== 1) {
                  communication.secondaryStatusText = 'Change Needed';
                } else {
                  communication.secondaryStatusText = 'New Campaign';
                }
              }
            }),
          ),
        );
        context.dispatch('sortMallMarketingCampaigns');
      } catch (error) {
        return;
      }
    },

    sortMallMarketingCampaigns(context: ActionContext<CommunicationState, State>): void {
      try {
        const statusOrder = ['Removal Needed', 'Change Needed', 'New Campaign', 'Actioned'];

        const marketingCampaigns = context.state.marketingCampaigns.sort((a: any, b: any) => {
          const indexA = statusOrder.indexOf(a.secondaryStatusText);
          const indexB = statusOrder.indexOf(b.secondaryStatusText);
          return indexA - indexB;
        });

        context.commit('updateMarketingCampaigns', marketingCampaigns);
        context.commit('updateLoadingMarketingCampaigns', false);
      } catch (error) {
        return;
      }
    },

    async fetchMarketingCampaigns(context: ActionContext<CommunicationState, State>): Promise<any> {
      context.commit('updateLoadingMarketingCampaigns', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMarketingCampaigns(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
          payload?.status,
          payload?.tags,
        );
        const marketingCampaignIds = records.data.data.map((el: any) => el.marketingCampaignId);

        for (const [index, communication] of records.data.data.entries()) {
          communication.communicationId = communication?.marketingCampaignId;
          communication.commSubTypeText = communication?.type?.description;
          communication.brandText = communication?.brand?.description;
          communication.statusText = communication?.status?.description;
          communication.imageUrl = communication?.images?.[0]?.url;
          communication.startDate = communication?.fromDate;
          communication.uniqueId = communication?.campaignNumber;
          communication.commType = 'marketing-campaign';
          communication.tagDescriptions = communication?.tags
            ?.map((tag: any) => tag.description)
            .sort((a: string, b: string) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
        }

        context.dispatch('fetchMarketingCampaignApprovals', { marketingCampaignIds });

        context.commit('updateMarketingCampaigns', records?.data?.data);
        context.commit('updateLoadingMarketingCampaigns', false);

        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMarketingCampaigns', false);
      }
    },

    async fetchMallMarketingCampaigns(context: ActionContext<CommunicationState, State>, contactGroupId: string): Promise<any> {
      context.commit('updateLoadingMarketingCampaigns', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallMarketingCampaigns(
          contactGroupId,
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey === 'id' ? 'statusId' : payload?.sortKey,
          payload?.sortDirection,
        );
        const marketingCampaignIds = records.data.data.map((el: any) => el.marketingCampaignId);

        for (const [index, communication] of records.data.data.entries()) {
          communication.communicationId = communication?.marketingCampaignId;
          communication.commSubTypeText = communication?.type?.description;
          communication.brandText = communication?.brand?.description;
          communication.statusText = communication?.status?.description;
          communication.imageUrl = communication?.images?.[0]?.url;
          communication.startDate = communication?.fromDate;
          communication.uniqueId = communication?.campaignNumber;
          communication.commType = 'marketing-campaign';
          let parsedHeadline = '';
          const languages = communication?.headline?.split('::: ');
          languages?.forEach((language: any) => {
            if (language.substring(0, 3) === 'en=') {
              parsedHeadline = language.substring(3, language.length);
            } else if (parsedHeadline === '') {
              parsedHeadline = language.substring(3, language.length);
            }
          });
          communication.parsedHeadline = parsedHeadline;
        }

        context.dispatch('fetchMallMarketingCampaignApprovals', { contactGroupId, marketingCampaignIds });
        context.commit('updateMarketingCampaigns', records?.data?.data);

        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMarketingCampaigns', false);
      }
    },

    async fetchMarketingCampaign(context: ActionContext<CommunicationState, State>, payload: { campaignId: string }): Promise<void> {
      const records: AxiosResponse<Response> = await new HttpService().getMarketingCampaign(payload.campaignId);
      context.commit('updateMarketingCampaign', records.data.data);
    },

    clearSelectedMarketingCampaign(context: ActionContext<CommunicationState, State>): void {
      context.commit('updateMarketingCampaign', null);
    },

    async saveMarketingCampaign(context: ActionContext<CommunicationState, State>, payload: IMarketingCampaign): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().saveMarketingCampaign(payload);
      return records?.data?.data;
    },

    async updateMarketingCampaign(
      context: ActionContext<CommunicationState, State>,
      payload: { body: IMarketingCampaign; campaignId: string },
    ): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().updateMarketingCampaign(payload.body, payload.campaignId);

      return records;
    },

    async deleteMarketingCampaign(context: ActionContext<CommunicationState, State>, payload: { campaignId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().deleteMarketingCampaign(payload.campaignId);
      return records;
    },

    async fetchRecruitmentCampaigns(context: ActionContext<CommunicationState, State>): Promise<any> {
      context.commit('updateLoadingRecruitmentCampaigns', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getRecruitmentCampaigns(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records?.data?.data?.forEach((communication: any) => {
          communication.communicationId = communication?.recruitmentCampaignId;
          communication.commSubTypeText = communication?.type?.description;
          communication.brandText = communication?.brand?.description;
          communication.statusText = communication?.status?.description;
          communication.imageUrl = communication?.images?.[0]?.url;
          communication.uniqueId = communication?.campaignNumber;
          communication.approvalAmount = Math.round((communication?.stats?.approved / communication?.stats?.sent) * 100);
          communication.startDate = communication?.publishByDate;
          communication.commType = 'recruitment-campaign';
          let englishHeadline = '';
          const languages = communication?.headline?.split('::: ');
          languages?.forEach((language: any) => {
            if (language.substring(0, 3) === 'en=') {
              englishHeadline = language.substring(3, language.length);
            }
          });
          if (communication.statusId === 1) {
            communication.backgroundColor = '#f3f3f3';
            communication.borderColor = '#f3f3f3';
            communication.textColor = '#8C17C9';
            communication.title = `[DRAFT] ${communication?.brandText ?? ''}${englishHeadline !== '' ? ' - ' + englishHeadline : ''}`;
          } else {
            communication.backgroundColor = '#F9EDFF';
            communication.borderColor = '#F9EDFF';
            communication.textColor = '#8C17C9';
            communication.title = `${communication?.brandText ?? ''}${englishHeadline !== '' ? ' - ' + englishHeadline : ''}`;
          }
          communication.start = communication?.publishByDate?.slice(0, 10);
          const utc = new Date(communication?.toDate).toUTCString();
          let td = new Date(utc);
          td = new Date(td.setDate(td.getDate() + 1));
          communication.end = td.toISOString()?.slice(0, 10);
          communication.tagDescriptions = communication?.tags
            ?.map((tag: any) => tag.description)
            .sort((a: string, b: string) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
        });
        context.commit('updateRecruitmentCampaigns', records?.data?.data);
        context.commit('updateLoadingRecruitmentCampaigns', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingRecruitmentCampaigns', false);
      }
    },

    async fetchMallRecruitmentCampaigns(context: ActionContext<CommunicationState, State>, contactGroupId: string): Promise<any> {
      context.commit('updateLoadingRecruitmentCampaigns', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallRecruitmentCampaigns(
          contactGroupId,
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey === 'id' ? 'statusId' : payload?.sortKey,
          payload?.sortDirection,
        );
        records?.data?.data?.forEach((communication: any) => {
          communication.communicationId = communication?.recruitmentCampaignId;
          communication.commSubTypeText = communication?.type?.description;
          communication.brandText = communication?.brand?.description;
          communication.statusText = communication?.status?.description;
          communication.imageUrl = communication?.images?.[0]?.url;
          communication.uniqueId = communication?.campaignNumber;
          communication.approvalAmount = Math.round((communication?.stats?.approved / communication?.stats?.sent) * 100);
          communication.startDate = communication?.publishByDate;
          communication.commType = 'recruitment-campaign';
          let parsedHeadline = '';
          const languages = communication?.headline?.split('::: ');
          languages?.forEach((language: any) => {
            if (language.substring(0, 3) === 'en=') {
              parsedHeadline = language.substring(3, language.length);
            } else if (parsedHeadline === '') {
              parsedHeadline = language.substring(3, language.length);
            }
          });
          communication.parsedHeadline = parsedHeadline;
          communication.start = communication?.publishByDate?.slice(0, 10);
          const utc = new Date(communication?.toDate).toUTCString();
          let td = new Date(utc);
          td = new Date(td.setDate(td.getDate() + 1));
          communication.end = td.toISOString()?.slice(0, 10);

          const declineAmount = Math.round((communication?.stats?.declined / communication?.stats?.sent) * 100);
          if (communication.statusId === Status['Manually Expired']) {
            communication.secondaryStatusText = 'Removal Needed';
          } else if (communication.approvalAmount >= 100 || declineAmount >= 100) {
            communication.secondaryStatusText = 'Actioned';
          } else if (communication.createdAt !== communication.updatedAt && communication.versionNumber !== 1) {
            communication.secondaryStatusText = 'Change Needed';
          } else {
            communication.secondaryStatusText = 'New Campaign';
          }
        });

        const statusOrder = ['Removal Needed', 'Change Needed', 'New Campaign', 'Actioned'];

        const recruitmentCampaigns = records?.data?.data.sort((a: any, b: any) => {
          const indexA = statusOrder.indexOf(a.secondaryStatusText);
          const indexB = statusOrder.indexOf(b.secondaryStatusText);
          return indexA - indexB;
        });

        context.commit('updateRecruitmentCampaigns', recruitmentCampaigns);
        context.commit('updateLoadingRecruitmentCampaigns', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingRecruitmentCampaigns', false);
      }
    },

    async fetchRecruitmentCampaign(context: ActionContext<CommunicationState, State>, payload: { campaignId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().getRecruitmentCampaign(payload.campaignId);
      context.commit('updateRecruitmentCampaign', records.data.data);
      return records;
    },

    clearSelectedRecruitmentCampaign(context: ActionContext<CommunicationState, State>): void {
      context.commit('updateRecruitmentCampaign', null);
    },

    async saveRecruitmentCampaign(context: ActionContext<CommunicationState, State>, payload: IRecruitmentCampaign): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().saveRecruitmentCampaign(payload);
      return records?.data?.data;
    },

    async updateRecruitmentCampaign(
      context: ActionContext<CommunicationState, State>,
      payload: { body: IRecruitmentCampaign; campaignId: string },
    ): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().updateRecruitmentCampaign(payload.body, payload.campaignId);

      return records;
    },

    async deleteRecruitmentCampaign(context: ActionContext<CommunicationState, State>, payload: { campaignId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().deleteRecruitmentCampaign(payload.campaignId);
      return records;
    },

    async fetchMallMemos(context: ActionContext<CommunicationState, State>): Promise<any> {
      context.commit('updateLoadingMallMemos', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallMemos(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((communication: any) => {
          communication.communicationId = communication?.memoId;
          communication.commSubTypeText = communication?.type?.description;
          communication.brandText = communication?.brand?.description;
          communication.statusText = communication?.status?.description;
          communication.startDate = communication?.fromDate;
          communication.companyId = communication?.brand?.companyId;
          communication.uniqueId = communication?.memoNumber;
          communication.approvalAmount = Math.round((communication?.stats?.approved / communication?.stats?.sent) * 100);
          communication.commType = 'mall-memo';
          communication.questionsLength = communication?.questions?.length;
          let englishHeadline = '';
          const languages = communication?.headline?.split('::: ');
          languages.forEach((language: any) => {
            if (language.substring(0, 3) === 'en=') {
              englishHeadline = language.substring(3, language.length);
            }
          });
          if (communication.statusId === 1) {
            communication.backgroundColor = '#f3f3f3';
            communication.borderColor = '#f3f3f3';
            communication.textColor = '#E23D6F';
            communication.title = `[DRAFT] ${communication?.brandText ?? ''}${englishHeadline !== '' ? ' - ' + englishHeadline : ''}`;
          } else {
            communication.backgroundColor = '#FFEBF1';
            communication.borderColor = '#FFEBF1';
            communication.textColor = '#E23D6F';
            communication.title = `${communication?.brandText ?? ''}${englishHeadline !== '' ? ' - ' + englishHeadline : ''}`;
          }
          communication.start = communication?.fromDate?.slice(0, 10);
          const utc = new Date(communication?.toDate).toUTCString();
          let td = new Date(utc);
          td = new Date(td.setDate(td.getDate() + 1));
          communication.end = td.toISOString()?.slice(0, 10);
          communication.tagDescriptions = communication?.tags
            ?.map((tag: any) => tag.description)
            .sort((a: string, b: string) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
        });
        context.commit('updateMallMemos', records?.data?.data);
        context.commit('updateLoadingMallMemos', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMallMemos', false);
      }
    },

    async fetchMallMallMemos(context: ActionContext<CommunicationState, State>, contactGroupId: string): Promise<any> {
      context.commit('updateLoadingMallMemos', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallMallMemos(
          contactGroupId,
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey === 'id' ? 'statusId' : payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((communication: any) => {
          communication.communicationId = communication?.memoId;
          communication.commSubTypeText = communication?.type?.description;
          communication.brandText = communication?.brand?.description;
          communication.statusText = communication?.status?.description;
          communication.startDate = communication?.fromDate;
          communication.companyId = communication?.brand?.companyId;
          communication.uniqueId = communication?.memoNumber;
          communication.approvalAmount = Math.round((communication?.stats?.approved / communication?.stats?.sent) * 100);
          communication.commType = 'mall-memo';
          communication.questionsLength = communication?.questions?.length;
          let parsedHeadline = '';
          const languages = communication?.headline?.split('::: ');
          languages?.forEach((language: any) => {
            if (language.substring(0, 3) === 'en=') {
              parsedHeadline = language.substring(3, language.length);
            } else if (parsedHeadline === '') {
              parsedHeadline = language.substring(3, language.length);
            }
          });
          communication.parsedHeadline = parsedHeadline;
          communication.start = communication?.fromDate?.slice(0, 10);
          const utc = new Date(communication?.toDate).toUTCString();
          let td = new Date(utc);
          td = new Date(td.setDate(td.getDate() + 1));
          communication.end = td.toISOString()?.slice(0, 10);

          const declineAmount = Math.round((communication?.stats?.declined / communication?.stats?.sent) * 100);
          if (communication.statusId === Status['Manually Expired']) {
            communication.secondaryStatusText = 'Removal Needed';
          } else if (communication.approvalAmount >= 100 || declineAmount >= 100) {
            communication.secondaryStatusText = 'Actioned';
          } else if (communication.createdAt !== communication.updatedAt && communication.versionNumber !== 1) {
            communication.secondaryStatusText = 'Change Needed';
          } else {
            communication.secondaryStatusText = 'New Campaign';
          }
        });

        const statusOrder = ['Removal Needed', 'Change Needed', 'New Campaign', 'Actioned'];

        const mallMemos = records?.data?.data.sort((a: any, b: any) => {
          const indexA = statusOrder.indexOf(a.secondaryStatusText);
          const indexB = statusOrder.indexOf(b.secondaryStatusText);
          return indexA - indexB;
        });

        context.commit('updateMallMemos', mallMemos);
        context.commit('updateLoadingMallMemos', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMallMemos', false);
      }
    },

    async fetchMallMemo(context: ActionContext<CommunicationState, State>, payload: { memoId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().getMallMemo(payload.memoId);
      if (records?.data?.data && records?.data?.data?.length > 0) {
        context.commit('updateMallMemo', records?.data?.data[0]);
        return records?.data?.data[0];
      }
    },

    clearSelectedMallMemo(context: ActionContext<CommunicationState, State>): void {
      context.commit('updateMallMemo', null);
    },

    async saveMallMemo(context: ActionContext<CommunicationState, State>, payload: IMemo): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().saveMallMemo(payload);
      return records?.data?.data;
    },

    async updateMallMemo(context: ActionContext<CommunicationState, State>, payload: { body: IMemo; memoId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().updateMallMemo(payload.body, payload.memoId);

      return records;
    },

    async deleteMallMemo(context: ActionContext<CommunicationState, State>, payload: { memoId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().deleteMallMemo(payload.memoId);
      return records;
    },

    async fetchStoreMemos(context: ActionContext<CommunicationState, State>): Promise<any> {
      context.commit('updateLoadingStoreMemos', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getStoreMemos(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((communication: any) => {
          communication.communicationId = communication?.memoId;
          communication.commSubTypeText = communication?.type?.description;
          communication.commMemoSubTypeText = communication?.subtype?.description;
          communication.brandText = communication?.brand?.description;
          communication.mallText = communication?.mall?.description;
          communication.statusText = communication?.status?.description;
          communication.startDate = communication?.fromDate;
          communication.companyId = communication?.brand?.companyId;
          communication.uniqueId = communication?.memoNumber;
          communication.approvalAmount = Math.round((communication?.stats?.approved / communication?.stats?.sent) * 100);
          communication.commType = 'store-memo';
          communication.questionsLength = communication?.questions?.length;
          let englishHeadline = '';
          const languages = communication?.headline?.split('::: ');
          languages.forEach((language: any) => {
            if (language.substring(0, 3) === 'en=') {
              englishHeadline = language.substring(3, language.length);
            }
          });
          if (communication.statusId === 1) {
            communication.backgroundColor = '#f3f3f3';
            communication.borderColor = '#f3f3f3';
            communication.textColor = '#FDA047';
            communication.title = `[DRAFT] ${communication?.brandText ?? ''}${englishHeadline !== '' ? ' - ' + englishHeadline : ''}`;
          } else {
            communication.backgroundColor = '#FFE8D3';
            communication.borderColor = '#FFE8D3';
            communication.textColor = '#FDA047';
            communication.title = `${communication?.brandText ?? ''}${englishHeadline !== '' ? ' - ' + englishHeadline : ''}`;
          }
          communication.start = communication?.fromDate?.slice(0, 10);
          const utc = new Date(communication?.toDate).toUTCString();
          let td = new Date(utc);
          td = new Date(td.setDate(td.getDate() + 1));
          communication.end = td.toISOString()?.slice(0, 10);
          communication.tagDescriptions = communication?.tags
            ?.map((tag: any) => tag.description)
            .sort((a: string, b: string) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
        });
        context.commit('updateStoreMemos', records?.data?.data);
        context.commit('updateLoadingStoreMemos', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStoreMemos', false);
      }
    },

    async fetchStoreMemo(context: ActionContext<CommunicationState, State>, payload: { memoId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().getStoreMemo(payload.memoId);
      if (records?.data?.data && records?.data?.data?.length > 0) {
        context.commit('updateStoreMemo', records?.data?.data[0]);
        return records?.data?.data[0];
      }
    },

    clearSelectedStoreMemo(context: ActionContext<CommunicationState, State>): void {
      context.commit('updateStoreMemo', null);
    },

    async saveStoreMemo(context: ActionContext<CommunicationState, State>, payload: IMemo): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().saveStoreMemo(payload);
      return records?.data?.data;
    },

    async updateStoreMemo(context: ActionContext<CommunicationState, State>, payload: { body: IMemo; memoId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().updateStoreMemo(payload.body, payload.memoId);

      return records;
    },

    async deleteStoreMemo(context: ActionContext<CommunicationState, State>, payload: { memoId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().deleteStoreMemo(payload.memoId);
      return records;
    },
    updateSelectedImageHeight(context: ActionContext<CommunicationState, State>, payload: number): void {
      context.commit('updateSelectedImageHeight', payload);
    },
    updateSelectedImageWidth(context: ActionContext<CommunicationState, State>, payload: number): void {
      context.commit('updateSelectedImageWidth', payload);
    },
    updateCurrentImageType(context: ActionContext<CommunicationState, State>, payload: string): void {
      context.commit('updateCurrentImageType', payload);
    },
    updateUploadingMarketingPrimaryImage(context: ActionContext<CommunicationState, State>, payload: string): void {
      context.commit('updateUploadingMarketingPrimaryImage', payload);
    },
    updateUploadingMarketingSecondaryImage(context: ActionContext<CommunicationState, State>, payload: string): void {
      context.commit('updateUploadingMarketingSecondaryImage', payload);
    },
    updateUploadingRecruitmentImage(context: ActionContext<CommunicationState, State>, payload: string): void {
      context.commit('updateUploadingRecruitmentImage', payload);
    },
  },

  getters: {
    allCommunications(state: CommunicationState): any[] | null {
      return state.allCommunications;
    },
    loadingAllCommunications(state: CommunicationState): boolean {
      return state.loadingAllCommunications;
    },
    marketingCampaigns(state: CommunicationState): IMarketingCampaign[] | [] {
      return state.marketingCampaigns;
    },
    selectedMarketingCampaign(state: CommunicationState): IMarketingCampaign | null {
      return state.selectedMarketingCampaign;
    },
    loadingMarketingCampaigns(state: CommunicationState): boolean {
      return state.loadingMarketingCampaigns;
    },
    recruitmentCampaigns(state: CommunicationState): IRecruitmentCampaign[] | [] {
      return state.recruitmentCampaigns;
    },
    selectedRecruitmentCampaign(state: CommunicationState): IRecruitmentCampaign | null {
      return state.selectedRecruitmentCampaign;
    },
    loadingRecruitmentCampaigns(state: CommunicationState): boolean {
      return state.loadingRecruitmentCampaigns;
    },
    mallMemos(state: CommunicationState): IMemo[] | [] {
      return state.mallMemos;
    },
    selectedMallMemo(state: CommunicationState): IMemo | null {
      return state.selectedMallMemo;
    },
    loadingMallMemos(state: CommunicationState): boolean {
      return state.loadingMallMemos;
    },
    storeMemos(state: CommunicationState): IMemo[] | [] {
      return state.storeMemos;
    },
    selectedStoreMemo(state: CommunicationState): IMemo | null {
      return state.selectedStoreMemo;
    },
    loadingStoreMemos(state: CommunicationState): boolean {
      return state.loadingStoreMemos;
    },
    selectedImageHeight(state: CommunicationState): number {
      return state.selectedImageHeight;
    },
    selectedImageWidth(state: CommunicationState): number {
      return state.selectedImageWidth;
    },
    currentImageType(state: CommunicationState): string {
      return state.currentImageType;
    },
    uploadingMarketingPrimaryImage(state: CommunicationState): boolean {
      return state.uploadingMarketingPrimaryImage;
    },
    uploadingMarketingSecondaryImage(state: CommunicationState): boolean {
      return state.uploadingMarketingSecondaryImage;
    },
    uploadingRecruitmentImage(state: CommunicationState): boolean {
      return state.uploadingRecruitmentImage;
    },
  },
};
