
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    overlay: {
      type: Boolean,
      default: true,
    },
    message: {
      type: String,
    },
  },
});
