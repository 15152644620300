
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import Checkbox from '../../../atoms/Checkbox/Checkbox.vue';
import Tooltip from '../../../atoms/Tooltip/Tooltip.vue';

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'text',
    },
    columnName: {
      type: String,
    },
    displayName: {
      type: String,
    },
    headerClasses: {
      type: String,
    },
    headerStyle: {
      type: String,
    },
    width: {
      type: String,
      default: 'w-auto',
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'left',
    },
    tooltip: {
      type: String,
    },
    tooltipStyles: {
      type: String,
    },
  },
  components: {
    'arc-checkbox': Checkbox,
    'arc-tooltip': Tooltip,
  },
  computed: {
    headerClass(): string {
      let header: any = '';
      if (this.type === 'select') {
        header = `${this.position ?? ''} mw-5r`;
      } else {
        header = `${this.width ?? ''} ${this.position ?? ''}
        ${this.headerClasses ?? ''} ${this.sortable ? 'sortable' : ''}`;
      }
      return header;
    },
    sortIcon(): string {
      return this.sortDirection === 'DESC' ? 'chevron-down' : 'chevron-up';
    },
  },
  data(): {
    sortDirection: string;
  } {
    return {
      sortDirection: 'DESC',
    };
  },
  methods: {
    trySort(): void {
      if (this.sortable) {
        this.sortDirection = this.sortDirection === 'ASC' ? 'DESC' : 'ASC';
        const payload = { column: this.columnName, type: this.type, direction: this.sortDirection };
        this.$emit('sort', payload);
      }
    },
    toggleAllCheckboxes(): void {
      this.$emit('toggleAllCheckboxes');
    },
  },
});
