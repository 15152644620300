
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    theme: {
      type: String,
      default: 'primary-blue',
    },
    route: {
      type: String,
    },
  },

  computed: {
    linkType(): {
      'link--primary-blue': boolean;
      'link--primary-pink': boolean;
      'link--primary-purple': boolean;
      'link--info-purple': boolean;
      'link--success': boolean;
      'link--error': boolean;
      'link--warn': boolean;
    } {
      return {
        'link--primary-blue': this.theme === 'primary-blue',
        'link--primary-pink': this.theme === 'primary-pink',
        'link--primary-purple': this.theme === 'primary-purple',
        'link--info-purple': this.theme === 'info-purple',
        'link--success': this.theme === 'success',
        'link--error': this.theme === 'error',
        'link--warn': this.theme === 'warn',
      };
    },
  },
});
