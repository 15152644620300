export enum SocketEvent {
  'USER_CONNECTED' = 'userConnected',
  'USER_DISCONNECTED' = 'userDisconnected',
  'SOCKET_CONNECTED' = 'connection',
  'SOCKET_DISCONNECTED' = 'disconnect',
  'MARKETING_CAMPAIGN_UPDATED' = 'marketingCampaignUpdate',
  'RECRUITMENT_CAMPAIGN_UPDATED' = 'recruitmentCampaignUpdate',
  'STORE_MEMO_UPDATED' = 'storeMemoUpdate',
  'MALL_MEMO_UPDATED' = 'mallMemoUpdate',
  'PRIMARY_IMAGE_PROGRESS_UPDATE' = 'primaryImageProgressUpdate',
  'SECONDARY_IMAGE_PROGRESS_UPDATE' = 'secondaryImageProgressUpdate',
  'ATTACHMENT_PROGRESS_UPDATE' = 'attachmentProgressUpdate',
  'DETACH_SOCKET_FROM_MODAL' = 'detachSocketFromModal',
}
