import { io, Socket } from 'socket.io-client';

import { SocketEvent } from '@/enums/SocketEvent';

export default class SocketService {
  private apiUrl: string = process.env.VUE_APP_WEB_SOCKET_URI as string;

  public connectWithUser(userId: string): void {
    this.imageUploadSocket.emit(SocketEvent.USER_CONNECTED, userId);
    this.marketingCampaignSocket.emit(SocketEvent.USER_CONNECTED, userId);
  }

  public imageUploadSocket: Socket = io(process.env.VUE_APP_URI?.includes('localhost') ? 'ws://localhost' : this.apiUrl, {
    path: '/images/ws',
    withCredentials: true,
    reconnection: false,
    secure: process.env.VUE_APP_URI?.includes('localhost') ? false : true,
    transports: ['websocket'],
  });

  public marketingCampaignSocket: Socket = io(process.env.VUE_APP_URI?.includes('localhost') ? 'ws://localhost' : this.apiUrl, {
    path: '/marketing-campaigns/ws',
    withCredentials: true,
    reconnection: false,
    secure: process.env.VUE_APP_URI?.includes('localhost') ? false : true,
    transports: ['websocket'],
  });

  public recruitmentCampaignSocket: Socket = io(process.env.VUE_APP_URI?.includes('localhost') ? 'ws://localhost' : this.apiUrl, {
    path: '/recruitment-campaigns/ws',
    withCredentials: true,
    reconnection: false,
    secure: process.env.VUE_APP_URI?.includes('localhost') ? false : true,
    transports: ['websocket'],
  });

  public memosSocket: Socket = io(process.env.VUE_APP_URI?.includes('localhost') ? 'ws://localhost' : this.apiUrl, {
    path: '/memos/ws',
    withCredentials: true,
    reconnection: false,
    secure: process.env.VUE_APP_URI?.includes('localhost') ? false : true,
    transports: ['websocket'],
  });
}
