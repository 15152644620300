export enum AccessTypes {
  'marketingCampaignsAll' = 1,
  'marketingCampaignsCompany' = 2,
  'marketingCampaignsBrand' = 3,
  'marketingCampaignsStore' = 4,
  'marketingCampaignsNone' = 5,
  'recruitmentCampaignsAll' = 6,
  'recruitmentCampaignsCompany' = 7,
  'recruitmentCampaignsBrand' = 8,
  'recruitmentCampaignsStore' = 9,
  'recruitmentCampaignsNone' = 10,
  'mallMemosAll' = 11,
  'mallMemosCompany' = 12,
  'mallMemosBrand' = 13,
  'mallMemosStore' = 14,
  'mallMemosNone' = 15,
  'mallsAll' = 16,
  'mallsCompany' = 17,
  'mallsBrand' = 18,
  'mallsStore' = 19,
  'mallsNone' = 20,
  'storesAll' = 21,
  'storesCompany' = 22,
  'storesBrand' = 23,
  'storesStore' = 24,
  'storesCustom' = 25,
  'companiesAll' = 26,
  'companiesCustom' = 27,
  'companiesNone' = 28,
  'companiesExtra1' = 29,
  'companiesExtra2' = 30,
  'brandsAll' = 31,
  'brandsCompany' = 32,
  'brandsBrand' = 33,
  'brandsCustom' = 34,
  'brandsNone' = 35,
  'usersAll' = 36,
  'usersCompany' = 37,
  'usersBrand' = 38,
  'usersCustom' = 39,
  'usersNone' = 40,
  'superAdminAccess' = 41,
  'storeMemosAll' = 42,
  'storeMemosCompany' = 43,
  'storeMemosBrand' = 44,
  'storeMemosStore' = 45,
  'storeMemosNone' = 46,
  'marketingCampaignsMall' = 47,
  'recruitmentCampaignsMall' = 48,
  'mallsMall' = 49,
  'storesMall' = 50,
  'companiesMall' = 51,
  'brandsMall' = 52,
  'usersMall' = 53,
  'mallMemosMall' = 54,
  'storeMemosMall' = 55,
  'opportunitiesAll' = 56,
  'opportunitiesCompany' = 57,
  'opportunitiesBrand' = 58,
  'opportunitiesMall' = 59,
  'opportunitiesStore' = 60,
  'opportunitiesNone' = 61,
}
