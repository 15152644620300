
import { defineComponent } from 'vue';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';

export default defineComponent({
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
    },
    status: {
      type: String,
    },
    message: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    auto: {
      type: String,
    },
    inputTitle: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Select',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data(): {
    showOptions: boolean;
  } {
    return {
      showOptions: false,
    };
  },

  computed: {
    open(): string {
      return this.showOptions ? 'open' : '';
    },
    selectedValue(): IDropdownOption {
      return (this.modelValue as IDropdownOption) ?? { description: this.placeholder, value: '' };
    },
    valueStyling(): string {
      return this.selectedValue?.value === '' ? 'font-size: 1.2rem; font-weight: lighter; color: hsl(0, 0%, 84%)' : '';
    },
    disabledStyling(): string {
      return this.disabled ? 'cursor: not-allowed; background-color: rgba(0, 0, 0, 0.01);' : '';
    },
    inputStatus(): {
      'input--success': boolean;
      'input--warn': boolean;
      'input--error': boolean;
    } {
      return {
        'input--success': this.status === 'success',
        'input--warn': this.status === 'warn',
        'input--error': this.status === 'error',
      };
    },
  },
  methods: {
    toggleDropdownOptionsState(): void {
      if (!this.disabled) {
        this.showOptions = !this.showOptions;
      }
    },
    showDropdownOptions(): void {
      this.showOptions = true;
    },
    hideDropdownOptions(): void {
      this.showOptions = false;
    },
    selectItem(value: IDropdownOption): void {
      this.$emit('itemSelected', value);
    },
    isSelected(option: IDropdownOption): string {
      return option?.value === this.selectedValue?.value ? 'selected' : '';
    },
  },
});
