import { LoadingState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';

export const LoadingModule = {
  state(): LoadingState {
    return {
      isLoading: false,
      resetPagination: false,
    };
  },

  mutations: {
    SET_LOADING_FALSE(state: LoadingState): void {
      state.isLoading = false;
    },

    SET_LOADING_TRUE(state: LoadingState): void {
      state.isLoading = true;
    },

    RESET_PAGINATION(state: LoadingState): void {
      state.resetPagination = true;
      setTimeout(() => {
        state.resetPagination = false;
      }, 0);
    },
  },

  actions: {
    setLoading(context: ActionContext<LoadingState, State>): void {
      if (context.state.isLoading) {
        context.commit('SET_LOADING_FALSE');
      } else {
        context.commit('SET_LOADING_TRUE');
      }
    },
    resetPagination(context: ActionContext<LoadingState, State>): void {
      context.commit('RESET_PAGINATION');
    },
  },

  getters: {
    isLoading(state: LoadingState): boolean {
      return state.isLoading;
    },
    resetPagination(state: LoadingState): boolean {
      return state.resetPagination;
    },
  },
};
