/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { BrandState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { IBrand } from '../../../../interfaces/src/v2/index';
import captureExceptionInSentry from './errorHandler';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const BrandModule = {
  state(): BrandState {
    return {
      brands: [],
      loadingBrands: false,
    };
  },

  mutations: {
    clearBrands(state: BrandState): void {
      state.brands = [];
    },
    updateBrands(state: BrandState, payload: IBrand[]): void {
      state.brands = payload;
    },
    updateLoadingBrands(state: BrandState, payload: boolean): void {
      state.loadingBrands = payload;
    },
  },

  actions: {
    clearBrands(context: ActionContext<BrandState, State>): void {
      context.commit('clearBrands');
    },

    async fetchBrands(context: ActionContext<BrandState, State>): Promise<any> {
      context.commit('updateLoadingBrands', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getBrands(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((brand: IBrand) => {
          brand.companyDesc = brand?.company?.description;
          brand.statusDesc = brand?.status?.description;

          if (brand.contacts) {
            brand.contacts.forEach((contact: any) => {
              if (contact?.contactType?.description === 'Primary Contact') {
                brand.primaryContact = contact;
                brand.primaryContact.address = {
                  streetAddress: brand.primaryContact?.streetAddress,
                  city: brand.primaryContact?.city,
                  province: brand.primaryContact?.province,
                  country: brand.primaryContact?.country,
                  postalCode: brand.primaryContact?.postalCode,
                };
              }

              if (contact?.contactType?.description === 'Secondary Contact') {
                brand.secondaryContact = contact;
                brand.secondaryContact.address = {
                  streetAddress: brand.secondaryContact?.streetAddress,
                  city: brand.secondaryContact?.city,
                  province: brand.secondaryContact?.province,
                  country: brand.secondaryContact?.country,
                  postalCode: brand.secondaryContact?.postalCode,
                };
              }

              if (contact?.contactType?.description === 'Senior Contact') {
                brand.seniorContact = contact;
                brand.seniorContact.address = {
                  streetAddress: brand.seniorContact?.streetAddress,
                  city: brand.seniorContact?.city,
                  province: brand.seniorContact?.province,
                  country: brand.seniorContact?.country,
                  postalCode: brand.seniorContact?.postalCode,
                };
              }
            });
          }
        });
        context.commit(
          'updateBrands',
          records.data.data.filter((brand: IBrand) => brand.statusId !== 5),
        );
        context.commit('updateLoadingBrands', false);
        return records.data;
      } catch (err) {
        context.commit('updateLoadingBrands', false);
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
      }
    },

    async fetchBrandById(context: ActionContext<BrandState, State>, brandId: string): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().getBrandById(brandId);
      return records?.data?.data;
    },

    saveBrand(context: ActionContext<BrandState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveBrand(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchBrands');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            reject();
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          });
      });
    },

    updateBrand(context: ActionContext<BrandState, State>, payload: { payload: any; brandId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateBrand(payload.payload, payload.brandId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchBrands');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            reject();
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          });
      });
    },

    deleteBrand(context: ActionContext<BrandState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .deleteBrand(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchBrands');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            reject();
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          });
      });
    },

    saveBrandEmailTemplate(context: ActionContext<BrandState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveBrandEmailTemplate(payload)
          .then((records: AxiosResponse<Response>) => {
            resolve(records.data.data);
          })
          .catch((err: any) => {
            reject();
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          });
      });
    },
  },

  getters: {
    brands(state: BrandState): IBrand[] {
      return state.brands;
    },
    loadingBrands(state: BrandState): boolean {
      return state.loadingBrands;
    },
  },
};
