
import { defineComponent } from 'vue';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import Button from '../../atoms/Button/Button.vue';
import Dropdown from '../../atoms/Dropdown/Dropdown.vue';
import PaginationRecords from './../Pagination-Records/PaginationRecords.vue';
import { AbortHandler } from '@/services/AbortHandler';

export default defineComponent({
  props: {
    showValue: {
      type: Object,
      require: true,
    },
    showOptions: {
      type: Array,
      require: true,
    },
    pageValue: {
      type: Object,
      require: true,
    },
    totalPages: {
      type: Number,
      require: true,
    },
    pageOptions: {
      type: Array,
      require: true,
    },
    isModal: {
      default: false,
    },
    disableShowValue: {
      default: false,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
  },
  components: {
    'arc-button': Button,
    'arc-dropdown': Dropdown,
    'pagination-records': PaginationRecords,
  },
  computed: {
    modalLabelClass(): string {
      return this.isModal ? 'modal-label' : '';
    },
    modalButtonClass(): string {
      return this.isModal ? 'modal-nav-buttons' : '';
    },
  },
  methods: {
    updateShow(value: IDropdownOption): void {
      this.abortRequests();
      this.$emit('updateShow', value);
    },
    updatePage(value: IDropdownOption): void {
      this.abortRequests();
      this.$emit('updatePage', value);
    },
    previousPage(): void {
      this.abortRequests();
      this.$emit('previousPage');
    },
    nextPage(): void {
      this.abortRequests();
      this.$emit('nextPage');
    },
    abortRequests(): void {
      const abortController = AbortHandler.getInstance();
      abortController.kill();
    },
  },
});
