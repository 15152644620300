
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import SingleSelectInputSuggestion from '../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import ImageUploader from '../../../../organisms/Image-Uploader/Image-Uploader.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import { IBrand, IStore } from '../../../../../../../../interfaces/src/v2/index';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';
import jimp from 'jimp';
import Jimp from 'jimp';
import Divider from '../../../../atoms/Divider/Divider.vue';
import HttpService from '../../../../../services/HttpService';
import { AxiosResponse } from 'axios';
import { getPermissionAndAccessDropdownState, getPermissionAndAccessIds } from '../../../../../utilities/Permissions/permission-utillty';
import LocationSelector from '../../../../molecules/Locations-Selector/Location-Selector.vue';
import Search from '../../../../atoms/Search/Search.vue';
import { Role } from '@/enums/Role';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';
import { Tabs } from '@/utilities/Types/navigation.types';
import NavTabs from '@/atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import Checkbox from '@/atomic/atoms/Checkbox/Checkbox.vue';
import DatePicker from '@/atomic/molecules/DatePicker/DatePicker.vue';

interface IUploadedImage {
  url: string;
}

interface IUserPermissions {
  superAdminAccess: IDropdownOption;
  marketingCampaignsDataAccess: IDropdownOption;
  recruitmentCampaignsDataAccess: IDropdownOption;
  mallMemosDataAccess: IDropdownOption;
  storeMemosDataAccess: IDropdownOption;
  mallsDataAccess: IDropdownOption;
  storesDataAccess: IDropdownOption;
  companiesDataAccess: IDropdownOption;
  brandsDataAccess: IDropdownOption;
  usersDataAccess: IDropdownOption;
  opportunitiesDataAccess: IDropdownOption;
  createMarketingCampaigns: IDropdownOption;
  submitMarketingCampaigns: IDropdownOption;
  readMarketingCampaigns: IDropdownOption;
  updateMarketingCampaigns: IDropdownOption;
  deleteMarketingCampaigns: IDropdownOption;
  createRecruitmentCampaigns: IDropdownOption;
  submitRecruitmentCampaigns: IDropdownOption;
  readRecruitmentCampaigns: IDropdownOption;
  updateRecruitmentCampaigns: IDropdownOption;
  deleteRecruitmentCampaigns: IDropdownOption;
  createMallMemos: IDropdownOption;
  submitMallMemos: IDropdownOption;
  readMallMemos: IDropdownOption;
  updateMallMemos: IDropdownOption;
  deleteMallMemos: IDropdownOption;
  createStoreMemos: IDropdownOption;
  submitStoreMemos: IDropdownOption;
  readStoreMemos: IDropdownOption;
  updateStoreMemos: IDropdownOption;
  deleteStoreMemos: IDropdownOption;
  createMalls: IDropdownOption;
  readMalls: IDropdownOption;
  updateMalls: IDropdownOption;
  deleteMalls: IDropdownOption;
  createStores: IDropdownOption;
  readStores: IDropdownOption;
  updateStores: IDropdownOption;
  deleteStores: IDropdownOption;
  createCompanies: IDropdownOption;
  readCompanies: IDropdownOption;
  updateCompanies: IDropdownOption;
  deleteCompanies: IDropdownOption;
  createBrands: IDropdownOption;
  readBrands: IDropdownOption;
  updateBrands: IDropdownOption;
  deleteBrands: IDropdownOption;
  createUsers: IDropdownOption;
  readUsers: IDropdownOption;
  updateUsers: IDropdownOption;
  deleteUsers: IDropdownOption;
}

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-search': Search,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-button': Button,
    'arc-modal': Modal,
    'arc-image-uploader': ImageUploader,
    'arc-tooltip': Tooltip,
    'arc-divider': Divider,
    'arc-location-selector': LocationSelector,
    'arc-multi-select-input': MultiSelectInput,
    'arc-nav-tabs': NavTabs,
    'arc-checkbox': Checkbox,
    'arc-date-picker': DatePicker,
  },

  computed: {
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'userStatuses',
      'languageOptions',
      'brandOptions',
      'companyOptions',
      'mallOptions',
      'metaStoreOptions',
      'permissionTemplateOptions',
      'permissionTemplates',
      'storeOptions',
      'loadingStores',
      'availableBrandSpecificTags',
      'metaEmailTemplates',
      'ccUsersOptions',
      'bccUsersOptions',
    ]),
    filteredPermissionTemplateOptions(): any {
      if (this.permissions?.superAdminAccess) {
        return this.permissionTemplateOptions;
      } else if (this.user.role?.roleId === Role.MallAdmin) {
        return this.permissionTemplateOptions?.filter((template: any) => template?.description === 'Mall User');
      } else {
        return this.permissionTemplateOptions?.filter(
          (template: any) => template?.description !== 'System Admin' && template?.description !== 'Mall Admin',
        );
      }
    },
    filteredLocations(): any {
      // set all location options
      let locations = this.storeOptions;

      if (this.formData?.controls?.campaignType?.value) {
        const campaignTypeFilter: any[] = [];
        locations?.forEach((location: any) => {
          location?.mall?.communicationTypes?.forEach((commType: any) => {
            if (commType?.communicationTypeId === this.formData?.controls?.campaignType?.value) {
              campaignTypeFilter.push(location);
            }
          });
        });
        locations = campaignTypeFilter;
      }

      let tagCount: any = this.tagValues?.length;

      // filter tags
      if (tagCount > 0) {
        const tagIds = this.tagValues?.map((tag: any) => tag.value);
        // filter locations by tags
        locations = locations.filter((location: any) => {
          const locationTags = location?.tags?.map((tag: any) => tag.tagId);
          if (this.tagComboType.value === 'or') {
            return tagIds?.some((lt: number) => locationTags?.includes(lt));
          } else if (this.tagComboType.value === 'and') {
            return tagIds?.every((lt: number) => locationTags?.includes(lt));
          }
        });
      }

      // filter search params
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        locations.forEach((element: any) => {
          let matchFound = false;
          if (typeof Object.values(element) === 'string') {
            if (Object.values(element).includes(this.searchFilter)) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'number') {
            if (
              Object.values(element)
                .toString()
                .includes(this.searchFilter)
            ) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'object' && Object.values(element)) {
            Object.values(element)
              .filter((value) => value)
              .forEach((nestedElement: any) => {
                if (typeof nestedElement === 'string') {
                  if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'number') {
                  if (nestedElement.toString().includes(this.searchFilter)) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'object' && nestedElement) {
                  Object.values(nestedElement)
                    .filter((value) => value)
                    .forEach((nestedElementL2: any) => {
                      if (typeof nestedElementL2 === 'string') {
                        if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'number') {
                        if (nestedElementL2.toString().includes(this.searchFilter)) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                        Object.values(nestedElementL2)
                          .filter((value) => value)
                          .forEach((nestedElementL3: any) => {
                            if (typeof nestedElementL3 === 'string') {
                              if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                matchFound = true;
                              }
                            }
                            if (typeof nestedElementL3 === 'number') {
                              if (nestedElementL3.toString().includes(this.searchFilter)) {
                                matchFound = true;
                              }
                            }
                          });
                      }
                    });
                }
              });
          }
          if (matchFound) {
            searchResults.push(element);
          }
        });
        locations = searchResults;
      }
      locations = locations?.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      return locations;
    },
    filteredLocationsAvailable(): any {
      return this.filteredLocations.filter((location: any) => !this.selectedLocationIds?.includes(location?.storeId));
    },
    filteredLocationsSelected(): any {
      return this.filteredLocations.filter((location: any) => this.selectedLocationIds?.includes(location?.storeId));
    },
    containerType(): string {
      return this.permissions?.superAdminAccess ? 'admin-container' : 'user-container';
    },
    selectBrandsPlaceholder(): string {
      return this.brandOptions && this.brandOptions.length > 0 ? 'Select' : 'Select a Company with Brands';
    },
    updateDisabled(): boolean {
      return this.permissions && this.modalData?.row && !this.permissions?.canUpdateAllUsers;
    },
    deleteDisabled(): boolean {
      return this.permissions && this.modalData?.row && !this.permissions?.canDeleteAllUsers;
      // return (this.permissions && this.modalData?.row && !this.permissions?.canDeleteAllUsers && (!this.permissions?.superAdminAccess && this.userPermissions['superAdminAccess']?.value === 1)) || !this.modalData?.row?.canBeDeleted;
    },
    brandWidth(): string {
      let width = '';
      if (this.permissions?.superAdminAccess && !this.isMallUser()) {
        width = this.roleValue?.value === 4 ? 'grid-column-end: span 10;' : '';
      } else {
        width = this.roleValue?.value === 4 ? '' : 'grid-column-end: span 30;';
      }
      return width;
    },
    tabs(): Tabs {
      return [
        {
          title: 'GENERAL',
          activeTabFlag: 'general',
        },
        {
          title: 'USER PREFERENCES',
          activeTabFlag: 'user-preferences',
        },
      ];
    },
    minDate(): Date {
      return new Date();
    },
  },

  async mounted(): Promise<void> {
    if (this.modalData?.userId) {
      new HttpService().getUser(this.modalData?.userId).then((results: AxiosResponse<any>) => {
        this.modalData.row = results?.data?.data;
        this.clearStores();
        this.loadLanguageOptions();
        this.loadUserStatuses();
        this.loadMallOptions();
        this.loadPermissionTemplateOptions();
        this.fetchPermissionTemplates();
        this.loadCompanies();
        this.setForm();
        this.getEmailTemplates('user');
        this.loadCcUsers({ companyId: this.modalData.row.companyId, excludeUserId: this.modalData?.userId });
        this.loadBccUsers({ companyId: this.modalData.row.companyId, excludeUserId: this.modalData?.userId });
        this.loaded = true;
      });
    } else {
      this.clearStores();
      this.loadLanguageOptions();
      this.loadUserRoles();
      this.loadUserStatuses();
      this.loadMallOptions();
      this.loadPermissionTemplateOptions();
      this.fetchPermissionTemplates();
      this.loadCompanies();
      this.setForm();
      this.loaded = true;
    }
  },

  data(): {
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    roleValue: IDropdownOption | null;
    templateValue: IDropdownOption | null;
    companyValue: IDropdownOption | null;
    statusValue: IDropdownOption | null;
    languageValue: IDropdownOption | null;
    brandValues: IDropdownOption[];
    mallValue: IDropdownOption | null;
    storeValue: IDropdownOption | null;
    formData: any;
    loaded: boolean;
    profileImage: IUploadedImage[];
    profileImageUploading: boolean;
    signature: IUploadedImage[];
    signatureUploading: boolean;
    allowShowOptions: boolean;
    onboardingValue: IDropdownOption;
    notifyStoreContactsValue: IDropdownOption;
    yesNoOptions: IDropdownOption[];
    POType1: IDropdownOption[];
    POType2: IDropdownOption[];
    POType3: IDropdownOption[];
    POType4: IDropdownOption[];
    POType5: IDropdownOption[];
    POType6: IDropdownOption[];
    POType7: IDropdownOption[];
    POType8: IDropdownOption[];
    POType9: IDropdownOption[];
    POType10: IDropdownOption[];
    userPermissions: IUserPermissions;
    attemptedSubmit: boolean;
    tagValues: IDropdownOption[] | null;
    searchFilter: string;
    selectedLocationIds: string[];
    tagComboTypes: IDropdownOption[] | null;
    tagComboType: IDropdownOption;
    mallValues: IDropdownOption[];
    activeTabFlag: string;
    emailTemplates: any[];
    snooze: boolean;
    redirectTo: IDropdownOption | null;
    redirectEndDate: Date | null;
  } {
    return {
      mallValue: null,
      storeValue: null,
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      formData: null,
      loaded: false,
      profileImage: [],
      profileImageUploading: false,
      signature: [],
      signatureUploading: false,
      roleValue: null,
      templateValue: { value: 2, description: 'Brand Admin' },
      companyValue: null,
      statusValue: null,
      languageValue: null,
      brandValues: [],
      mallValues: [],
      allowShowOptions: false,
      onboardingValue: { value: 1, description: 'Yes' },
      notifyStoreContactsValue: { value: 0, description: 'No' },
      yesNoOptions: [
        { value: 1, description: 'Yes' },
        { value: 0, description: 'No' },
      ],
      POType1: [
        { value: 1, description: 'All' },
        { value: 2, description: 'Company' },
        { value: 3, description: 'Brand' },
        { value: 6, description: 'Mall' },
        { value: 4, description: 'Store' },
        // { value: 5, description: 'Custom', },
      ],
      POType2: [
        { value: 1, description: 'Yes' },
        { value: 2, description: 'No' },
      ],
      POType3: [
        { value: 1, description: 'All' },
        { value: 2, description: 'Owned' },
        { value: 3, description: 'None' },
      ],
      POType4: [
        { value: 1, description: 'All' },
        { value: 2, description: 'Company' },
        { value: 3, description: 'Brand' },
        { value: 4, description: 'Store' },
        { value: 5, description: 'None' },
      ],
      POType5: [
        { value: 1, description: 'All' },
        // { value: 2, description: 'Custom' },
        { value: 3, description: 'None' },
      ],
      POType6: [
        { value: 1, description: 'All' },
        { value: 2, description: 'Company' },
        { value: 3, description: 'Brand' },
        // { value: 4, description: 'Custom' },
        { value: 5, description: 'None' },
      ],
      POType7: [
        { value: 1, description: 'All' },
        { value: 2, description: 'Company' },
        { value: 3, description: 'Brand' },
        { value: 6, description: 'Mall' },
        // { value: 4, description: 'Custom' },
        { value: 5, description: 'None' },
      ],
      POType8: [
        { value: 1, description: 'All' },
        { value: 6, description: 'Mall' },
        // { value: 2, description: 'Custom' },
        { value: 3, description: 'None' },
      ],
      POType9: [
        { value: 1, description: 'All' },
        { value: 2, description: 'Owned' },
        // { value: 3, description: 'None', },
      ],
      POType10: [
        { value: 1, description: 'All' },
        { value: 2, description: 'Company' },
        { value: 3, description: 'Brand' },
        { value: 6, description: 'Mall' },
        { value: 4, description: 'Store' },
        { value: 5, description: 'None' },
      ],
      userPermissions: {
        superAdminAccess: { value: 2, description: 'No' },
        marketingCampaignsDataAccess: { value: 3, description: 'Brand' },
        recruitmentCampaignsDataAccess: { value: 5, description: 'None' },
        mallMemosDataAccess: { value: 3, description: 'Brand' },
        storeMemosDataAccess: { value: 5, description: 'None' },
        mallsDataAccess: { value: 3, description: 'Brand' },
        storesDataAccess: { value: 3, description: 'Brand' },
        companiesDataAccess: { value: 3, description: 'None' },
        brandsDataAccess: { value: 3, description: 'Brand' },
        usersDataAccess: { value: 5, description: 'None' },
        opportunitiesDataAccess: { value: 5, description: 'None' },
        createMarketingCampaigns: { value: 1, description: 'Yes' },
        submitMarketingCampaigns: { value: 1, description: 'Yes' },
        readMarketingCampaigns: { value: 1, description: 'All' },
        updateMarketingCampaigns: { value: 1, description: 'All' },
        deleteMarketingCampaigns: { value: 3, description: 'None' },
        createRecruitmentCampaigns: { value: 2, description: 'No' },
        submitRecruitmentCampaigns: { value: 1, description: 'Yes' },
        readRecruitmentCampaigns: { value: 3, description: 'None' },
        updateRecruitmentCampaigns: { value: 3, description: 'None' },
        deleteRecruitmentCampaigns: { value: 3, description: 'None' },
        createMallMemos: { value: 1, description: 'Yes' },
        submitMallMemos: { value: 1, description: 'Yes' },
        readMallMemos: { value: 1, description: 'All' },
        updateMallMemos: { value: 1, description: 'All' },
        deleteMallMemos: { value: 3, description: 'None' },
        createStoreMemos: { value: 2, description: 'No' },
        submitStoreMemos: { value: 1, description: 'Yes' },
        readStoreMemos: { value: 3, description: 'None' },
        updateStoreMemos: { value: 3, description: 'None' },
        deleteStoreMemos: { value: 3, description: 'None' },
        createMalls: { value: 2, description: 'No' },
        readMalls: { value: 3, description: 'None' },
        updateMalls: { value: 3, description: 'None' },
        deleteMalls: { value: 3, description: 'None' },
        createStores: { value: 1, description: 'Yes' },
        readStores: { value: 1, description: 'All' },
        updateStores: { value: 1, description: 'All' },
        deleteStores: { value: 3, description: 'None' },
        createCompanies: { value: 2, description: 'No' },
        readCompanies: { value: 3, description: 'None' },
        updateCompanies: { value: 3, description: 'None' },
        deleteCompanies: { value: 3, description: 'None' },
        createBrands: { value: 2, description: 'No' },
        readBrands: { value: 3, description: 'None' },
        updateBrands: { value: 3, description: 'None' },
        deleteBrands: { value: 3, description: 'None' },
        createUsers: { value: 1, description: 'Yes' },
        readUsers: { value: 1, description: 'All' },
        updateUsers: { value: 1, description: 'All' },
        deleteUsers: { value: 3, description: 'None' },
      },
      attemptedSubmit: false,
      tagValues: [],
      searchFilter: '',
      selectedLocationIds: [],
      tagComboTypes: [
        {
          value: 'or',
          description: 'OR',
        },
        {
          value: 'and',
          description: 'AND',
        },
      ],
      tagComboType: {
        value: 'or',
        description: 'OR',
      },
      // activeTabFlag: 'user-preferences',
      activeTabFlag: 'general',
      emailTemplates: [],
      snooze: false,
      redirectTo: null,
      redirectEndDate: null,
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadLanguageOptions',
      'loadUserRoles',
      'loadCompanies',
      'loadUserStatuses',
      'loadBrands',
      'saveUser',
      'updateUser',
      'deleteUser',
      'clearBrands',
      'uploadImage',
      'loadMallOptions',
      'loadStoresByMallId',
      'loadPermissionTemplateOptions',
      'fetchPermissionTemplates',
      'fetchStoresByBrandIds',
      'clearStores',
      'loadBrandTags',
      'clearBrandTags',
      'saveUserEmailTemplate',
      'getEmailTemplates',
      'loadCcUsers',
      'loadBccUsers',
      'saveUserEmailPreference',
    ]),
    async setForm() {
      // initializers
      let company = '';
      let mall = '';
      let role = '';
      let firstName = '';
      let lastName = '';
      let email = '';
      let secondaryEmail = '';
      let mobileNumber = '';
      let officeNumber = '';
      let title = '';
      let status = '';
      let language = null;
      let currentBrands: string[] = [];
      let locations = [];
      let store = '';
      let roleDesc = '';
      let currentMalls: string[] = [];
      let onboarding = 1;
      let notifyStoreContacts = 0;

      // set values if editing existing campaign
      if (this.modalData?.row) {
        firstName = this.modalData?.row?.firstName;
        lastName = this.modalData?.row?.lastName;
        email = this.modalData?.row?.email.trim();
        secondaryEmail = this.modalData?.row?.secondaryEmail.trim();
        mobileNumber = this.modalData?.row?.mobileNumber;
        officeNumber = this.modalData?.row?.officeNumber;
        title = this.modalData?.row?.title;
        company = this.modalData?.row?.companyId;
        role = this.modalData?.row?.roleId;
        status = this.modalData?.row?.statusId;
        language = this.modalData?.row?.languageId;
        locations = this.modalData?.row?.stores;
        onboarding = this.modalData?.row?.onboarding;
        notifyStoreContacts = this.modalData?.row?.notifyStoreContacts;
        this.snooze = this.modalData?.row?.emailPreference?.snooze;
        this.redirectTo = this.modalData?.row?.emailPreference?.redirectTo;
        this.redirectEndDate = this.modalData?.row?.emailPreference?.redirectEndDate;
        this.extractDataFromLocations(locations);
        this.modalData?.row?.brands.forEach((brand: IBrand) => {
          currentBrands.push(brand?.brandId);
        });
        this.modalData?.row?.malls.forEach((mall: any) => {
          currentMalls.push(mall?.mallId);
        });
        if (this.modalData?.row?.roleId === 1) {
          roleDesc = 'System Admin';
        }
        if (this.modalData?.row?.roleId === 2) {
          roleDesc = 'Brand Admin';
        }
        if (this.modalData?.row?.roleId === 3) {
          roleDesc = 'Brand User';
        }
        if (this.modalData?.row?.roleId === 4) {
          roleDesc = 'Mall User';
        }
        if (this.modalData?.row?.roleId === 5) {
          roleDesc = 'Mall Admin';
        }
        if (this.modalData?.row?.roleId === 6) {
          roleDesc = 'Onboarding';
        }
        setTimeout(() => {
          if (this.isMallUser()) {
            store = this.modalData?.row?.stores[0]?.storeId;
            mall = this.modalData?.row?.malls[0]?.mallId;
            this.mallValue = { value: mall, description: this.modalData?.row?.malls[0]?.description };
            this.formData.controls.mall.value = this.mallValue?.value;
            this.formData.controls.store.value = store;
            if (mall) {
              this.loadStoresByMallId({ mallId: mall });
            }
          }
        }, 1000);
        this.profileImage = [
          {
            url: this.modalData?.row?.profileImageUrl,
          },
        ];
        this.signature = [
          {
            url: this.modalData?.row?.signatureUrl,
          },
        ];
        this.languageOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.languageId === item?.value) {
            this.languageValue = item;
          }
        });
        this.yesNoOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.onboarding === item?.value) {
            this.onboardingValue = item;
          }
          if (this.modalData?.row?.notifyStoreContacts === item?.value) {
            this.notifyStoreContactsValue = item;
          }
        });
        this.brandValues = [];
        this.brandOptions.forEach((item: IDropdownOption) => {
          this.modalData?.row?.brands.forEach((brand: IBrand) => {
            if (item.value === brand.brandId) {
              this.brandValues.push(item);
            }
          });
        });
        this.mallValues = [];
        this.mallOptions.forEach((item: IDropdownOption) => {
          this.modalData?.row?.malls.forEach((mall: any) => {
            if (item.value === mall.mallId) {
              this.mallValues.push(item);
            }
          });
        });
      } else {
        if (this.permissions && !this.permissions?.superAdminAccess && this.userPermissions['superAdminAccess']?.value !== 1) {
          company = this.user?.companyId;
          this.loadBrands({ companyId: company });
        } else {
          this.loadBrands({ companyId: '0' });
        }
      }
      // set form
      this.formData = new FormBuilder({
        company: new FormControl(company),
        mall: new FormControl(mall),
        role: new FormControl(role),
        firstName: new FormControl(firstName, [Validators.required]),
        lastName: new FormControl(lastName),
        email: new FormControl(email.trim(), [Validators.email, Validators.required]),
        secondaryEmail: new FormControl(secondaryEmail.trim()),
        mobileNumber: new FormControl(mobileNumber),
        officeNumber: new FormControl(officeNumber),
        title: new FormControl(title),
        status: new FormControl(status, [Validators.required]),
        language: new FormControl(language, [Validators.required]),
        brands: new FormControl(currentBrands),
        locations: new FormControl(locations),
        store: new FormControl(store),
        malls: new FormControl(currentMalls),
        onboarding: new FormControl(onboarding),
        notifyStoreContacts: new FormControl(notifyStoreContacts),
      });
      setTimeout(() => {
        if (this.userPermissions['superAdminAccess']?.value !== 1) {
          // update
          this.fetchStoresByBrandIds({ brandIds: currentBrands?.map((item: string) => item) });
          this.loadBrandTags({ brandIds: currentBrands?.map((item: string) => item) });
        }

        this.companyOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.companyId === item?.value) {
            this.companyValue = item;
            this.formData.controls.company.value = item.value;
            this.loadBrands({ companyId: this.companyValue.value });
          }
        });
      }, 250);

      if (this.modalData?.row) {
        await this.updateTemplate({ value: this.modalData?.row?.roleId, description: roleDesc }, true);
        if (this.isMallUser()) {
          store = this.modalData?.row?.stores[0]?.storeId;
          mall = this.modalData?.row?.malls[0]?.mallId;
          this.mallValue = { value: mall, description: this.modalData?.row?.malls[0]?.description };
          this.formData.controls.mall.value = this.mallValue?.value;
          if (mall) {
            await this.loadStoresByMallId({ mallId: mall });
          }
        }
        // set permissions
        this.userPermissions = await getPermissionAndAccessDropdownState({
          accessIds: this.modalData?.row?.dataAccess?.map((item: any) => item?.dataAccessId),
          permissionIds: this.modalData?.row?.permissions?.map((item: any) => item?.permissionId),
        });
      } else if (this.user.role?.roleId === Role.MallAdmin) {
        await this.updateTemplate({ value: Role.MallUser, description: 'Mall User' }, true);
      } else {
        await this.updateTemplate({ value: Role.BrandAdmin, description: 'Brand Admin' }, true);
      }
    },
    extractDataFromLocations(locations: IStore[]) {
      this.selectedLocationIds = [];
      locations.forEach((location: IStore) => {
        this.selectedLocationIds.push(location.storeId);
      });
    },
    updateSearchLocationsFilter(value: string) {
      this.searchFilter = value;
    },
    locationAdded(value: any): void {
      this.formData.controls.locations.value.push(value);
      this.formData.controls.locations.value.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      this.setChangesMade();
    },
    locationRemoved(value: any): void {
      const index = this.formData.controls.locations.value.findIndex((item: any) => item.storeId === value.storeId);
      this.formData.controls.locations.value.splice(index, 1);
      this.setChangesMade();
    },
    addAllLocations(): void {
      this.filteredLocationsAvailable.forEach((location: any) => {
        this.locationAdded(location);
      });
    },
    removeAllLocations(): void {
      this.filteredLocationsSelected.forEach((location: any) => {
        this.locationRemoved(location);
      });
    },
    updateCompany(value: IDropdownOption, clicked?: boolean) {
      this.companyValue = value;
      this.formData.controls.company.value = this.companyValue.value;
      this.setChangesMade();
      this.validateControl('company');
      this.loadBrands({ companyId: value.value });
      if (clicked) {
        this.clearStores();
        this.clearBrandTags();
        this.selectedLocationIds = [];
        this.brandValues = [];
        this.formData.controls.brands.value = [];
      }
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.setChangesMade();
        this.validateControl('company');
      }
    },
    async updateTemplate(value: IDropdownOption, initialLoad = false) {
      this.templateValue = value;
      this.roleValue = value;
      this.formData.controls.role.value = this.roleValue.value;
      this.updateFormValidators(this.formData.controls.role.value);
      if (value?.description === 'System Admin') {
        this.updateUserPermission(
          {
            superAdminAccess: { value: 1, description: 'Yes' },
            marketingCampaignsDataAccess: { value: 1, description: 'All' },
            recruitmentCampaignsDataAccess: { value: 1, description: 'All' },
            mallMemosDataAccess: { value: 1, description: 'All' },
            storeMemosDataAccess: { value: 1, description: 'All' },
            mallsDataAccess: { value: 1, description: 'All' },
            storesDataAccess: { value: 1, description: 'All' },
            companiesDataAccess: { value: 1, description: 'All' },
            brandsDataAccess: { value: 1, description: 'All' },
            usersDataAccess: { value: 1, description: 'All' },
            opportunitiesDataAccess: { value: 1, description: 'All' },
            createMarketingCampaigns: { value: 1, description: 'Yes' },
            submitMarketingCampaigns: { value: 1, description: 'Yes' },
            readMarketingCampaigns: { value: 1, description: 'All' },
            updateMarketingCampaigns: { value: 1, description: 'All' },
            deleteMarketingCampaigns: { value: 1, description: 'All' },
            createRecruitmentCampaigns: { value: 1, description: 'Yes' },
            submitRecruitmentCampaigns: { value: 1, description: 'Yes' },
            readRecruitmentCampaigns: { value: 1, description: 'All' },
            updateRecruitmentCampaigns: { value: 1, description: 'All' },
            deleteRecruitmentCampaigns: { value: 1, description: 'All' },
            createMallMemos: { value: 1, description: 'Yes' },
            submitMallMemos: { value: 1, description: 'Yes' },
            readMallMemos: { value: 1, description: 'All' },
            updateMallMemos: { value: 1, description: 'All' },
            deleteMallMemos: { value: 1, description: 'All' },
            createStoreMemos: { value: 1, description: 'Yes' },
            submitStoreMemos: { value: 1, description: 'Yes' },
            readStoreMemos: { value: 1, description: 'All' },
            updateStoreMemos: { value: 1, description: 'All' },
            deleteStoreMemos: { value: 1, description: 'All' },
            createMalls: { value: 1, description: 'Yes' },
            readMalls: { value: 1, description: 'All' },
            updateMalls: { value: 1, description: 'All' },
            deleteMalls: { value: 1, description: 'All' },
            createStores: { value: 1, description: 'Yes' },
            readStores: { value: 1, description: 'All' },
            updateStores: { value: 1, description: 'All' },
            deleteStores: { value: 1, description: 'All' },
            createCompanies: { value: 1, description: 'Yes' },
            readCompanies: { value: 1, description: 'All' },
            updateCompanies: { value: 1, description: 'All' },
            deleteCompanies: { value: 1, description: 'All' },
            createBrands: { value: 1, description: 'Yes' },
            readBrands: { value: 1, description: 'All' },
            updateBrands: { value: 1, description: 'All' },
            deleteBrands: { value: 1, description: 'All' },
            createUsers: { value: 1, description: 'Yes' },
            readUsers: { value: 1, description: 'All' },
            updateUsers: { value: 1, description: 'All' },
            deleteUsers: { value: 1, description: 'All' },
          },
          'template',
          initialLoad,
        );
      }
      if (value?.description === 'Brand Admin') {
        this.updateUserPermission(
          {
            superAdminAccess: { value: 2, description: 'No' },
            marketingCampaignsDataAccess: { value: 3, description: 'Brand' },
            recruitmentCampaignsDataAccess: { value: 5, description: 'None' },
            mallMemosDataAccess: { value: 3, description: 'Brand' },
            storeMemosDataAccess: { value: 5, description: 'None' },
            mallsDataAccess: { value: 3, description: 'Brand' },
            storesDataAccess: { value: 3, description: 'Brand' },
            companiesDataAccess: { value: 3, description: 'None' },
            brandsDataAccess: { value: 3, description: 'Brand' },
            usersDataAccess: { value: 5, description: 'None' },
            opportunitiesDataAccess: { value: 5, description: 'None' },
            createMarketingCampaigns: { value: 1, description: 'Yes' },
            submitMarketingCampaigns: { value: 1, description: 'Yes' },
            readMarketingCampaigns: { value: 1, description: 'All' },
            updateMarketingCampaigns: { value: 1, description: 'All' },
            deleteMarketingCampaigns: { value: 3, description: 'None' },
            createRecruitmentCampaigns: { value: 2, description: 'No' },
            submitRecruitmentCampaigns: { value: 2, description: 'No' },
            readRecruitmentCampaigns: { value: 3, description: 'None' },
            updateRecruitmentCampaigns: { value: 3, description: 'None' },
            deleteRecruitmentCampaigns: { value: 3, description: 'None' },
            createMallMemos: { value: 1, description: 'Yes' },
            submitMallMemos: { value: 1, description: 'Yes' },
            readMallMemos: { value: 1, description: 'All' },
            updateMallMemos: { value: 1, description: 'All' },
            deleteMallMemos: { value: 3, description: 'None' },
            createStoreMemos: { value: 2, description: 'No' },
            submitStoreMemos: { value: 2, description: 'No' },
            readStoreMemos: { value: 3, description: 'None' },
            updateStoreMemos: { value: 3, description: 'None' },
            deleteStoreMemos: { value: 3, description: 'None' },
            createMalls: { value: 2, description: 'No' },
            readMalls: { value: 3, description: 'All' },
            updateMalls: { value: 3, description: 'None' },
            deleteMalls: { value: 3, description: 'None' },
            createStores: { value: 1, description: 'Yes' },
            readStores: { value: 1, description: 'All' },
            updateStores: { value: 1, description: 'All' },
            deleteStores: { value: 3, description: 'None' },
            createCompanies: { value: 2, description: 'No' },
            readCompanies: { value: 3, description: 'None' },
            updateCompanies: { value: 3, description: 'None' },
            deleteCompanies: { value: 3, description: 'None' },
            createBrands: { value: 2, description: 'No' },
            readBrands: { value: 3, description: 'None' },
            updateBrands: { value: 3, description: 'None' },
            deleteBrands: { value: 3, description: 'None' },
            createUsers: { value: 2, description: 'No' },
            readUsers: { value: 3, description: 'None' },
            updateUsers: { value: 3, description: 'None' },
            deleteUsers: { value: 3, description: 'None' },
          },
          'template',
          initialLoad,
        );
      }
      if (value?.description === 'Brand User') {
        this.updateUserPermission(
          {
            superAdminAccess: { value: 2, description: 'No' },
            marketingCampaignsDataAccess: { value: 3, description: 'Brand' },
            recruitmentCampaignsDataAccess: { value: 5, description: 'None' },
            mallMemosDataAccess: { value: 5, description: 'None' },
            storeMemosDataAccess: { value: 5, description: 'None' },
            mallsDataAccess: { value: 3, description: 'Brand' },
            storesDataAccess: { value: 3, description: 'Brand' },
            companiesDataAccess: { value: 3, description: 'None' },
            brandsDataAccess: { value: 3, description: 'Brand' },
            usersDataAccess: { value: 5, description: 'None' },
            opportunitiesDataAccess: { value: 5, description: 'None' },
            createMarketingCampaigns: { value: 1, description: 'Yes' },
            submitMarketingCampaigns: { value: 2, description: 'No' },
            readMarketingCampaigns: { value: 2, description: 'Owned' },
            updateMarketingCampaigns: { value: 2, description: 'Owned' },
            deleteMarketingCampaigns: { value: 3, description: 'None' },
            createRecruitmentCampaigns: { value: 2, description: 'No' },
            submitRecruitmentCampaigns: { value: 2, description: 'No' },
            readRecruitmentCampaigns: { value: 3, description: 'None' },
            updateRecruitmentCampaigns: { value: 3, description: 'None' },
            deleteRecruitmentCampaigns: { value: 3, description: 'None' },
            createMallMemos: { value: 2, description: 'No' },
            submitMallMemos: { value: 2, description: 'No' },
            readMallMemos: { value: 3, description: 'None' },
            updateMallMemos: { value: 3, description: 'None' },
            deleteMallMemos: { value: 3, description: 'None' },
            createStoreMemos: { value: 2, description: 'No' },
            submitStoreMemos: { value: 2, description: 'No' },
            readStoreMemos: { value: 3, description: 'None' },
            updateStoreMemos: { value: 3, description: 'None' },
            deleteStoreMemos: { value: 3, description: 'None' },
            createMalls: { value: 2, description: 'No' },
            readMalls: { value: 1, description: 'All' },
            updateMalls: { value: 3, description: 'None' },
            deleteMalls: { value: 3, description: 'None' },
            createStores: { value: 1, description: 'Yes' },
            readStores: { value: 2, description: 'All' },
            updateStores: { value: 2, description: 'Owned' },
            deleteStores: { value: 3, description: 'None' },
            createCompanies: { value: 2, description: 'No' },
            readCompanies: { value: 3, description: 'None' },
            updateCompanies: { value: 3, description: 'None' },
            deleteCompanies: { value: 3, description: 'None' },
            createBrands: { value: 2, description: 'No' },
            readBrands: { value: 3, description: 'None' },
            updateBrands: { value: 3, description: 'None' },
            deleteBrands: { value: 3, description: 'None' },
            createUsers: { value: 2, description: 'No' },
            readUsers: { value: 3, description: 'None' },
            updateUsers: { value: 3, description: 'None' },
            deleteUsers: { value: 3, description: 'None' },
          },
          'template',
          initialLoad,
        );
      }
      if (value?.description === 'Mall Admin') {
        this.updateUserPermission(
          {
            superAdminAccess: { value: 2, description: 'No' },
            marketingCampaignsDataAccess: { value: 6, description: 'Mall' },
            recruitmentCampaignsDataAccess: { value: 6, description: 'Mall' },
            mallMemosDataAccess: { value: 5, description: 'None' },
            storeMemosDataAccess: { value: 5, description: 'None' },
            mallsDataAccess: { value: 6, description: 'Mall' },
            storesDataAccess: { value: 6, description: 'Mall' },
            companiesDataAccess: { value: 3, description: 'None' },
            brandsDataAccess: { value: 5, description: 'None' },
            usersDataAccess: { value: 6, description: 'Mall' },
            opportunitiesDataAccess: { value: 5, description: 'None' },
            createMarketingCampaigns: { value: 1, description: 'Yes' },
            submitMarketingCampaigns: { value: 1, description: 'Yes' },
            readMarketingCampaigns: { value: 1, description: 'All' },
            updateMarketingCampaigns: { value: 1, description: 'All' },
            deleteMarketingCampaigns: { value: 1, description: 'All' },
            createRecruitmentCampaigns: { value: 1, description: 'Yes' },
            submitRecruitmentCampaigns: { value: 1, description: 'Yes' },
            readRecruitmentCampaigns: { value: 1, description: 'All' },
            updateRecruitmentCampaigns: { value: 1, description: 'All' },
            deleteRecruitmentCampaigns: { value: 1, description: 'All' },
            createMallMemos: { value: 2, description: 'No' },
            submitMallMemos: { value: 2, description: 'No' },
            readMallMemos: { value: 3, description: 'None' },
            updateMallMemos: { value: 3, description: 'None' },
            deleteMallMemos: { value: 3, description: 'None' },
            createStoreMemos: { value: 2, description: 'No' },
            submitStoreMemos: { value: 2, description: 'No' },
            readStoreMemos: { value: 3, description: 'None' },
            updateStoreMemos: { value: 3, description: 'None' },
            deleteStoreMemos: { value: 3, description: 'None' },
            createMalls: { value: 2, description: 'No' },
            readMalls: { value: 1, description: 'All' },
            updateMalls: { value: 1, description: 'All' },
            deleteMalls: { value: 1, description: 'All' },
            createStores: { value: 1, description: 'Yes' },
            readStores: { value: 1, description: 'All' },
            updateStores: { value: 1, description: 'All' },
            deleteStores: { value: 1, description: 'All' },
            createCompanies: { value: 2, description: 'No' },
            readCompanies: { value: 3, description: 'None' },
            updateCompanies: { value: 3, description: 'None' },
            deleteCompanies: { value: 3, description: 'None' },
            createBrands: { value: 2, description: 'No' },
            readBrands: { value: 3, description: 'None' },
            updateBrands: { value: 3, description: 'None' },
            deleteBrands: { value: 3, description: 'None' },
            createUsers: { value: 1, description: 'Yes' },
            readUsers: { value: 1, description: 'All' },
            updateUsers: { value: 1, description: 'All' },
            deleteUsers: { value: 1, description: 'All' },
          },
          'template',
          initialLoad,
        );
      }
      if (value?.description === 'Mall User') {
        this.updateUserPermission(
          {
            superAdminAccess: { value: 2, description: 'No' },
            marketingCampaignsDataAccess: { value: 4, description: 'Store' },
            recruitmentCampaignsDataAccess: { value: 4, description: 'Store' },
            mallMemosDataAccess: { value: 5, description: 'None' },
            storeMemosDataAccess: { value: 5, description: 'None' },
            mallsDataAccess: { value: 5, description: 'None' },
            storesDataAccess: { value: 4, description: 'Store' },
            companiesDataAccess: { value: 3, description: 'None' },
            brandsDataAccess: { value: 5, description: 'None' },
            usersDataAccess: { value: 5, description: 'None' },
            opportunitiesDataAccess: { value: 5, description: 'None' },
            createMarketingCampaigns: { value: 1, description: 'Yes' },
            submitMarketingCampaigns: { value: 2, description: 'No' },
            readMarketingCampaigns: { value: 2, description: 'Owned' },
            updateMarketingCampaigns: { value: 2, description: 'Owned' },
            deleteMarketingCampaigns: { value: 2, description: 'Owned' },
            createRecruitmentCampaigns: { value: 1, description: 'Yes' },
            submitRecruitmentCampaigns: { value: 2, description: 'No' },
            readRecruitmentCampaigns: { value: 2, description: 'Owned' },
            updateRecruitmentCampaigns: { value: 2, description: 'Owned' },
            deleteRecruitmentCampaigns: { value: 2, description: 'Owned' },
            createMallMemos: { value: 2, description: 'No' },
            submitMallMemos: { value: 2, description: 'No' },
            readMallMemos: { value: 3, description: 'None' },
            updateMallMemos: { value: 3, description: 'None' },
            deleteMallMemos: { value: 3, description: 'None' },
            createStoreMemos: { value: 2, description: 'No' },
            submitStoreMemos: { value: 2, description: 'No' },
            readStoreMemos: { value: 3, description: 'None' },
            updateStoreMemos: { value: 3, description: 'None' },
            deleteStoreMemos: { value: 3, description: 'None' },
            createMalls: { value: 2, description: 'No' },
            readMalls: { value: 3, description: 'None' },
            updateMalls: { value: 3, description: 'None' },
            deleteMalls: { value: 3, description: 'None' },
            createStores: { value: 2, description: 'No' },
            readStores: { value: 1, description: 'All' },
            updateStores: { value: 3, description: 'None' },
            deleteStores: { value: 3, description: 'None' },
            createCompanies: { value: 2, description: 'No' },
            readCompanies: { value: 3, description: 'None' },
            updateCompanies: { value: 3, description: 'None' },
            deleteCompanies: { value: 3, description: 'None' },
            createBrands: { value: 2, description: 'No' },
            readBrands: { value: 3, description: 'None' },
            updateBrands: { value: 3, description: 'None' },
            deleteBrands: { value: 3, description: 'None' },
            createUsers: { value: 2, description: 'No' },
            readUsers: { value: 3, description: 'None' },
            updateUsers: { value: 3, description: 'None' },
            deleteUsers: { value: 3, description: 'None' },
          },
          'template',
          initialLoad,
        );
      }
    },
    updateFormValidators(role: Role): void {
      if (role === Role.SystemAdmin) {
        this.formData.controls.company.validators = [];
        this.formData.controls.brands.validators = [];
        this.formData.controls.mall.validators = [];
        this.formData.controls.store.validators = [];
        this.formData.controls.malls.validators = [];
      } else if (role === Role.BrandAdmin || role === Role.BrandUser) {
        this.formData.controls.company.validators = [Validators.required];
        this.formData.controls.brands.validators = [Validators.required];
        this.formData.controls.mall.validators = [];
        this.formData.controls.store.validators = [];
        this.formData.controls.malls.validators = [];
      } else if (role === Role.MallUser) {
        this.formData.controls.company.validators = [];
        this.formData.controls.brands.validators = [];
        this.formData.controls.mall.validators = [Validators.required];
        this.formData.controls.store.validators = [Validators.required];
        this.formData.controls.malls.validators = [];
      } else if (role === Role.MallAdmin) {
        this.formData.controls.company.validators = [];
        this.formData.controls.brands.validators = [];
        this.formData.controls.mall.validators = [];
        this.formData.controls.store.validators = [];
        this.formData.controls.malls.validators = [Validators.required];
      }

      // if (this.userPermissions['storesDataAccess']?.value === 4) {
      //   this.formData.controls.locations.validators = [Validators.required, ];
      // }
    },
    updateUserPermission(data: any, type: string, initialLoad: boolean) {
      if (type === 'template') {
        this.userPermissions = data;
      } else {
        this.userPermissions[type] = data;
        if (type === 'marketingCampaignsDataAccess' && data?.value === 5) {
          this.userPermissions['createMarketingCampaigns'] = this.userPermissions['submitMarketingCampaigns'] = { value: 2, description: 'No' };
          this.userPermissions['readMarketingCampaigns'] = this.userPermissions['updateMarketingCampaigns'] = this.userPermissions[
            'deleteMarketingCampaigns'
          ] = { value: 3, description: 'None' };
        }
        if (type === 'createMarketingCampaigns' && data?.value === 2) {
          this.userPermissions['submitMarketingCampaigns'] = { value: 2, description: 'No' };
        }
        if (type === 'recruitmentCampaignsDataAccess' && data?.value === 5) {
          this.userPermissions['createRecruitmentCampaigns'] = this.userPermissions['submitRecruitmentCampaigns'] = { value: 2, description: 'No' };
          this.userPermissions['readRecruitmentCampaigns'] = this.userPermissions['updateRecruitmentCampaigns'] = this.userPermissions[
            'deleteRecruitmentCampaigns'
          ] = { value: 3, description: 'None' };
        }
        if (type === 'createRecruitmentCampaigns' && data?.value === 2) {
          this.userPermissions['submitRecruitmentCampaigns'] = { value: 2, description: 'No' };
        }
        if (type === 'mallMemosDataAccess' && data?.value === 5) {
          this.userPermissions['createMallMemos'] = this.userPermissions['submitMallMemos'] = { value: 2, description: 'No' };
          this.userPermissions['readMallMemos'] = this.userPermissions['updateMallMemos'] = this.userPermissions['deleteMallMemos'] = {
            value: 3,
            description: 'None',
          };
        }
        if (type === 'createMallMemos' && data?.value === 2) {
          this.userPermissions['submitMallMemos'] = { value: 2, description: 'No' };
        }
        if (type === 'storeMemosDataAccess' && data?.value === 5) {
          this.userPermissions['createStoreMemos'] = this.userPermissions['submitStoreMemos'] = { value: 2, description: 'No' };
          this.userPermissions['readStoreMemos'] = this.userPermissions['updateStoreMemos'] = this.userPermissions['deleteStoreMemos'] = {
            value: 3,
            description: 'None',
          };
        }
        if (type === 'createStoreMemos' && data?.value === 2) {
          this.userPermissions['submitStoreMemos'] = { value: 2, description: 'No' };
        }
        if (type === 'mallsDataAccess' && data?.value === 5) {
          this.userPermissions['createMalls'] = { value: 2, description: 'No' };
          this.userPermissions['readMalls'] = this.userPermissions['updateMalls'] = this.userPermissions['deleteMalls'] = {
            value: 3,
            description: 'None',
          };
        }
        if (type === 'companiesDataAccess' && data?.value === 3) {
          this.userPermissions['createCompanies'] = { value: 2, description: 'No' };
          this.userPermissions['readCompanies'] = this.userPermissions['updateCompanies'] = this.userPermissions['deleteCompanies'] = {
            value: 3,
            description: 'None',
          };
        }
        if (type === 'storesDataAccess' && [1, 2, 3].includes(data?.value)) {
          this.selectedLocationIds = [];
        }
        if (type === 'brandsDataAccess' && data?.value === 5) {
          this.userPermissions['createBrands'] = { value: 2, description: 'No' };
          this.userPermissions['readBrands'] = this.userPermissions['updateBrands'] = this.userPermissions['deleteBrands'] = {
            value: 3,
            description: 'None',
          };
        }
        if (type === 'usersDataAccess' && data?.value === 5) {
          this.userPermissions['createUsers'] = { value: 2, description: 'No' };
          this.userPermissions['readUsers'] = this.userPermissions['updateUsers'] = this.userPermissions['deleteUsers'] = {
            value: 3,
            description: 'None',
          };
        }
        if (type === 'superAdminAccess' && data?.value === 1) {
          this.roleValue = { value: 1, description: 'System Admin' };
          this.userPermissions['storesDataAccess'] = { value: 1, description: 'All' };
          this.userPermissions['createMalls'] = { value: 1, description: 'Yes' };
          this.userPermissions['readMalls'] = this.userPermissions['updateMalls'] = this.userPermissions['deleteMalls'] = {
            value: 1,
            description: 'All',
          };
        }
        if (type === 'superAdminAccess' && data?.value === 2) {
          if (this.roleValue?.value === 1) {
            this.roleValue = { value: 2, description: 'Brand Admin' };
          }
          this.userPermissions['createMalls'] = { value: 2, description: 'No' };
          this.userPermissions['readMalls'] = this.userPermissions['updateMalls'] = this.userPermissions['deleteMalls'] = {
            value: 3,
            description: 'None',
          };
        }
      }
      if (!initialLoad) {
        this.setChangesMade();
      }
    },
    tagsUpdated(value: any): void {
      this.tagValues = value;
    },
    updateTagComboType(value: IDropdownOption) {
      this.tagComboType = value;
      this.setChangesMade();
      this.validateControl('campaignType');
    },
    updateStatus(value: IDropdownOption) {
      this.statusValue = value;
      this.formData.controls.status.value = this.statusValue.value;
      this.setChangesMade();
      this.validateControl('status');
    },
    updateOnboarding(value: IDropdownOption) {
      this.onboardingValue = value;
      this.formData.controls.onboarding.value = this.onboardingValue.value;
      this.setChangesMade();
      this.validateControl('onboarding');
    },
    updateNotifyStoreContacts(value: IDropdownOption) {
      this.notifyStoreContactsValue = value;
      this.formData.controls.notifyStoreContacts.value = this.notifyStoreContactsValue.value;
      this.setChangesMade();
      this.validateControl('notifyStoreContacts');
    },
    updateMalls(value: any) {
      this.mallValues = value;
      this.formData.controls.malls.value = this.mallValues;
      this.setChangesMade();
      this.validateControl('mall');
      this.clearStores();
      this.storeValue = null;
      this.formData.controls.store.value = null;
    },
    updateMall(value: IDropdownOption) {
      this.mallValue = value;
      this.formData.controls.mall.value = value.value;
      this.setChangesMade();
      this.validateControl('mall');
      this.clearStores();
      this.loadStoresByMallId({ mallId: value.value });
      this.storeValue = null;
      this.formData.controls.store.value = null;
    },
    updateStore(value: IDropdownOption) {
      this.storeValue = value;
      this.formData.controls.store.value = value.value;
      this.setChangesMade();
      this.validateControl('store');
    },
    updateLanguage(value: IDropdownOption) {
      this.languageValue = value;
      this.formData.controls.language.value = this.languageValue.value;
      this.setChangesMade();
      this.validateControl('language');
    },
    clearLanguage(value: any) {
      if (value === '') {
        this.languageValue = null;
        this.formData.controls.language.value = null;
        this.setChangesMade();
        this.validateControl('language');
      }
    },
    brandsUpdated(value: any): void {
      this.brandValues = value;
      this.formData.controls.brands.value = this.brandValues;
      this.setChangesMade();
      this.validateControl('brands');

      // update to support multiple
      if (this.userPermissions['superAdminAccess']?.value !== 1) {
        console.log(
          'about to fetch: ',
          this.brandValues?.map((item: IDropdownOption) => item?.value),
        );
        this.fetchStoresByBrandIds({ brandIds: this.brandValues?.map((item: IDropdownOption) => item?.value) });
        this.loadBrandTags({ brandIds: this.brandValues?.map((item: IDropdownOption) => item?.value) });
      }
    },
    updateRedirectTo(value: IDropdownOption) {
      this.redirectTo = value;
      this.validateControl('redirectTo');
    },
    clearRedirectTo(value: any) {
      if (value === '') {
        this.validateControl('redirectTo');
      }
    },
    async profileImageFileChange(images: Blob[]): Promise<void> {
      const image = images[0];
      this.profileImage = [];
      this.loadingHandler({
        type: 'profile-image',
        overRide: true,
        overRideValue: true,
      });

      try {
        const url = await this.uploadImage(image);
        this.profileImage.push({ url });
        this.setChangesMade();
        this.loadingHandler({
          type: 'profile-image',
          overRide: true,
          overRideValue: false,
        });
      } catch (err) {
        this.loadingHandler({
          type: 'profile-image',
          overRide: true,
          overRideValue: false,
        });
      }
    },
    async signatureFileChange(images: Blob[]): Promise<void> {
      const image = images[0];
      this.signature = [];
      this.loadingHandler({
        type: 'signature',
        overRide: true,
        overRideValue: true,
      });

      try {
        const url = await this.uploadImage(image);
        this.signature.push({ url });
        this.setChangesMade();
        this.loadingHandler({
          type: 'signature',
          overRide: true,
          overRideValue: false,
        });
      } catch (err) {
        this.loadingHandler({
          type: 'signature',
          overRide: true,
          overRideValue: false,
        });
      }
    },
    loadingHandler({ type, overRide, overRideValue }: { type: 'profile-image' | 'signature'; overRide: boolean; overRideValue: boolean }): boolean {
      if (type === 'profile-image') {
        if (overRide) {
          return (this.profileImageUploading = overRideValue);
        }
        return (this.profileImageUploading = !this.profileImageUploading);
      } else {
        if (overRide) {
          return (this.signatureUploading = overRideValue);
        }
        return (this.signatureUploading = !this.signatureUploading);
      }
    },
    async readFile(file: Blob): Promise<Jimp> {
      return new Promise((resolve) => {
        const fileReader: FileReader = new FileReader();
        fileReader.onloadend = async (event): Promise<any> => {
          const image: Jimp = await jimp.read(event?.target?.result as string);
          return resolve(image);
        };
        fileReader.readAsDataURL(file);
      });
    },
    setChangesMade() {
      this.changesMade = true;
    },
    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      this.deleting = true;
      this.promptOpen = false;

      try {
        await this.deleteUser(this.modalData?.row?.userId);
        this.deleting = false;
        this.promptOpen = false;
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'User deleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.deleting = false;
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to delete user.',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      this.attemptedSubmit = true;
      this.formData.markAllAsTouched();
      const ids = await getPermissionAndAccessIds(this.userPermissions);
      console.log('IDs: ', ids);
      if (!this.formData.hasErrors) {
        let profileImageUrl = '';
        let signatureUrl = '';

        if (this.profileImage && this.profileImage.length > 0) {
          profileImageUrl = this.profileImage[0].url;
        }

        if (this.signature && this.signature.length > 0) {
          signatureUrl = this.signature[0].url;
        }

        if (this.formData?.controls?.role?.value === '') {
          this.formData.controls.role.value = 1;
        }

        let mallIds = [];
        if (this.isMallUser()) {
          mallIds = [this.formData?.controls?.mall?.value];
        } else if (this.isMallAdmin()) {
          mallIds = this.formData?.controls?.malls?.value.map((el: any) => el.value);
        }

        let payload = {
          roleId: this.formData?.controls?.role?.value,
          firstName: this.formData?.controls?.firstName?.value,
          lastName: this.formData?.controls?.lastName?.value,
          email: this.formData?.controls?.email?.value?.trim(),
          secondaryEmail: this.formData?.controls?.secondaryEmail?.value?.trim(),
          mobileNumber: this.formData?.controls?.mobileNumber?.value,
          officeNumber: this.formData?.controls?.officeNumber?.value,
          companyId: this.isMallUserOrAdmin() ? '' : this.formData?.controls?.company?.value,
          title: this.formData?.controls?.title?.value,
          languageId: this.formData?.controls?.language?.value,
          brandIds: this.isMallUserOrAdmin() ? [] : this.formData?.controls?.brands?.value.map((el: any) => el.value),
          statusId: this.formData?.controls?.status?.value,
          storeIds: this.isMallAdmin() ? '' : this.isMallUser() ? [this.storeValue?.value] : this.selectedLocationIds,
          profileImageUrl,
          signatureUrl,
          accessIds: ids?.accessIds,
          permissionIds: ids?.permissionIds,
          mallIds: mallIds,
          onboarding: this.formData?.controls?.onboarding?.value,
          notifyStoreContacts: this.formData?.controls?.notifyStoreContacts?.value,
        };

        this.saving = true;
        try {
          await this.saveUser(payload);
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'User saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          let errorText = 'Unable to create User';
          if (err === 'The resource you are trying to create already exists.') {
            errorText = 'User with this email already exists';
          } else if (err === 'Invalid email address') {
            errorText = 'Invalid email address';
          }
          this.$notify({
            title: 'ERROR',
            text: errorText,
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        console.log('FORM ERRORS: ', this.formData);
        this.$notify({
          title: 'INVALID FORM',
          text: 'User form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      this.attemptedSubmit = true;
      this.formData.markAllAsTouched();
      const ids = await getPermissionAndAccessIds(this.userPermissions);
      console.log('IDs: ', ids);
      if (!this.formData.hasErrors) {
        let profileImageUrl = '';
        let signatureUrl = '';

        if (this.profileImage && this.profileImage.length > 0) {
          profileImageUrl = this.profileImage[0].url;
        }

        if (this.signature && this.signature.length > 0) {
          signatureUrl = this.signature[0].url;
        }

        let mallIds = [];
        if (this.isMallUser()) {
          mallIds = [this.formData?.controls?.mall?.value];
        } else if (this.isMallAdmin()) {
          mallIds = this.formData?.controls?.malls?.value.map((el: any) => el.value);
        }

        let payload = {
          roleId: this.formData?.controls?.role?.value,
          firstName: this.formData?.controls?.firstName?.value,
          lastName: this.formData?.controls?.lastName?.value,
          email: this.formData?.controls?.email?.value?.trim(),
          secondaryEmail: this.formData?.controls?.secondaryEmail?.value?.trim(),
          mobileNumber: this.formData?.controls?.mobileNumber?.value,
          officeNumber: this.formData?.controls?.officeNumber?.value,
          companyId: this.isMallUserOrAdmin() ? '' : this.formData?.controls?.company?.value,
          title: this.formData?.controls?.title?.value,
          languageId: this.formData?.controls?.language?.value,
          brandIds: this.isMallUserOrAdmin() ? [] : this.formData?.controls?.brands?.value.map((el: any) => el.value),
          statusId: this.formData?.controls?.status?.value,
          storeIds: this.isMallAdmin() ? '' : this.isMallUser() ? [this.storeValue?.value] : this.selectedLocationIds,
          profileImageUrl,
          signatureUrl,
          accessIds: ids?.accessIds,
          permissionIds: ids?.permissionIds,
          mallIds: mallIds,
          onboarding: this.formData?.controls?.onboarding?.value,
          notifyStoreContacts: this.formData?.controls?.notifyStoreContacts?.value,
        };

        this.saving = true;

        try {
          await this.updateUser({ payload, userId: this.modalData?.row?.userId });
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'User updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          let errorText = 'Unable to update User';
          if (err === 'Invalid email address') {
            errorText = 'Invalid email address';
          }
          this.$notify({
            title: 'ERROR',
            text: errorText,
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'User form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    isMallUserOrAdmin(): boolean {
      return this.roleValue?.value === Role.MallUser || this.roleValue?.value === Role.MallAdmin;
    },
    isMallUser(): boolean {
      return this.roleValue?.value === Role.MallUser;
    },
    isMallAdmin(): boolean {
      return this.roleValue?.value === Role.MallAdmin;
    },
    tabClicked(tab: string): void {
      this.activeTabFlag = tab;
      if (tab === 'user-preferences') {
        this.populateEmailPreferencesCCs();
        this.populateEmailPreferencesBCCs();
      }
    },
    ccUsersUpdated(value: any, reminderTypeId: number): void {
      this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId).ccUsersValues = value;
    },
    bccUsersUpdated(value: any, reminderTypeId: number): void {
      this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId).bccUsersValues = value;
    },
    async updateEmailTemplate(reminderTypeId: number): Promise<void> {
      const emailTemplate = this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId);
      const payload = {
        userId: this.modalData?.row?.userId,
        reminderTypeId,
        cc: emailTemplate.ccUsersValues?.map((item: IDropdownOption) => item.value).join(','),
        bcc: emailTemplate.bccUsersValues?.map((item: IDropdownOption) => item.value).join(','),
        optIn: emailTemplate.optIn,
      };
      await this.saveUserEmailTemplate(payload);
    },
    async updateEmailPreferences(): Promise<void> {
      this.saving = true;
      if (this.snooze && !this.redirectTo) {
        this.$notify({
          title: 'ERROR',
          text: 'Please select a redirect to option.',
          type: 'error',
          duration: 5000,
        });
        this.saving = false;
        return;
      }
      const payload = {
        userId: this.modalData?.row?.userId,
        snooze: this.snooze,
        redirectTo: this.redirectTo?.value,
        redirectEndDate: this.redirectEndDate,
      };
      try {
        await this.saveUserEmailPreference(payload);
        for (const emailTemplate of this.emailTemplates) {
          await this.updateEmailTemplate(emailTemplate.reminderTypeId);
        }
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: 'User Email Preferences saved successfully!',
          type: 'success',
          duration: 5000,
        });
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: 'Unable to save User Email Preferences.',
          type: 'error',
          duration: 5000,
        });
      }
    },
    toggleSnooze() {
      this.snooze = !this.snooze;
      if (!this.snooze) {
        this.redirectTo = null;
        this.redirectEndDate = null;
      }
    },
    toggleOptIn(reminderTypeId: number) {
      const emailTemplate = this.emailTemplates.find((template) => template.reminderTypeId === reminderTypeId);
      emailTemplate.optIn = !emailTemplate.optIn;
    },
    populateEmailPreferencesCCs() {
      if (this.modalData?.row?.emailTemplates) {
        this.modalData?.row?.emailTemplates?.forEach((emailTemplate: any) => {
          const et = this.emailTemplates.find((template) => template.reminderTypeId === emailTemplate.reminderTypeId);
          let ccUsersValues: any[] = [];
          if (emailTemplate.cc) {
            ccUsersValues = emailTemplate.cc?.split(',').map((cc: string) => {
              return this.ccUsersOptions.find((u: any) => u.value === cc);
            });
            et.ccUsersValues = ccUsersValues;
          }
          setTimeout(() => {
            if (ccUsersValues.length > 0) {
              this.ccUsersUpdated([...ccUsersValues], et?.reminderTypeId);
            } else {
              this.ccUsersUpdated([], et?.reminderTypeId);
            }
          }, 500);
        });
      }
    },
    populateEmailPreferencesBCCs() {
      if (this.modalData?.row?.emailTemplates) {
        this.modalData?.row?.emailTemplates?.forEach((emailTemplate: any) => {
          const et = this.emailTemplates.find((template) => template.reminderTypeId === emailTemplate.reminderTypeId);
          let bccUsersValues: any[] = [];
          if (emailTemplate.bcc) {
            bccUsersValues = emailTemplate.bcc?.split(',').map((bcc: string) => {
              return this.bccUsersOptions.find((u: any) => u.value === bcc);
            });
            et.bccUsersValues = bccUsersValues;
          }
          setTimeout(() => {
            if (bccUsersValues.length > 0) {
              this.bccUsersUpdated([...bccUsersValues], et?.reminderTypeId);
            } else {
              this.bccUsersUpdated([], et?.reminderTypeId);
            }
          }, 500);
        });
      }
    },
  },

  watch: {
    'formData.controls.locations.value': {
      deep: true,
      async handler(locations) {
        this.extractDataFromLocations(locations);
      },
    },
    companyOptions: {
      deep: true,
      handler() {
        this.companyOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.companyId === item?.value) {
            this.companyValue = item;
            this.formData.controls.company.value = item.value;
            this.loadBrands({ companyId: this.companyValue.value });
          }
        });
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 2000);
      },
    },
    languageOptions: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 250);
        this.languageOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.languageId === item?.value) {
            this.languageValue = item;
          }
        });
      },
    },
    userStatuses: {
      deep: true,
      handler() {
        this.userStatuses.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.statusId === item?.value) {
            this.statusValue = item;
          }
        });
      },
    },
    brandOptions: {
      deep: true,
      handler() {
        this.brandValues = [];
        this.formData.controls.brands.value = [];
        if (this.userPermissions['superAdminAccess']?.value === 1) {
          this.brandValues.push({
            description: 'Engagement Agents',
            value: '88e5d96d-cc93-4b4f-b640-0398f1e20c2d',
          });
          this.formData.controls.brands.value.push({
            description: 'Engagement Agents',
            value: '88e5d96d-cc93-4b4f-b640-0398f1e20c2d',
          });
        } else {
          this.brandOptions.forEach((item: IDropdownOption) => {
            this.modalData?.row?.brands.forEach((brand: IBrand) => {
              if (item.value === brand.brandId) {
                this.brandValues.push(item);
                this.formData.controls.brands.value.push(item);
              }
            });
          });
          this.loadBrandTags({ brandIds: this.brandValues?.map((item: IDropdownOption) => item?.value) });
        }
      },
    },
    mallOptions: {
      deep: true,
      handler() {
        this.mallValues = [];
        this.formData.controls.malls.value = [];
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 250);
        this.mallOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.mallId === item?.value) {
            this.mallValue = item;
          }
          this.modalData?.row?.malls.forEach((mall: any) => {
            if (item.value === mall.mallId) {
              this.mallValues.push(item);
              this.formData.controls.malls.value.push(item);
            }
          });
        });
      },
    },
    metaStoreOptions: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 250);
        this.metaStoreOptions.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.stores[0]?.storeId === item?.value) {
            this.storeValue = item;
          }
        });
      },
    },
    roleValue: {
      deep: true,
      handler() {
        // this.companyValue = null;
        // this.formData.controls.company.value = null;
        // this.brandValues = [];
        // this.formData.controls.brands.value = [];
        if (this.roleValue?.value === 1 && this.loaded) {
          setTimeout(() => {
            const ea = {
              description: 'Engagement Agents Inc.',
              value: 'a9e96d2f-d6a4-418e-bf09-532fc91cbc5a',
            };
            this.companyValue = ea;
            this.formData.controls.company.value = ea.value;
          }, 500);
        }
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
    permissionTemplateOptions: {
      deep: true,
      handler() {
        console.log('PERMISSION TEMPLATE OPTIONS: ', this.permissionTemplateOptions);
      },
    },
    permissionTemplates: {
      deep: true,
      handler() {
        console.log('PERMISSION TEMPLATES: ', this.permissionTemplates);
      },
    },
    metaEmailTemplates: {
      deep: true,
      handler() {
        const userEmailTemplates = this.modalData?.row?.emailTemplates;
        this.metaEmailTemplates.forEach((emailTemplate: any) => {
          const userEmailTemplate = userEmailTemplates?.find((template: any) => template.reminderTypeId === emailTemplate.reminderTypeId);
          if (userEmailTemplate) {
            this.emailTemplates.push({
              reminderTypeId: emailTemplate.reminderTypeId,
              title: emailTemplate.reminderType.description,
              // ccUsersValues: [],
              // bccUsersValues: [],
              optIn: userEmailTemplate.optIn,
            });
          } else {
            this.emailTemplates.push({
              reminderTypeId: emailTemplate.reminderTypeId,
              title: emailTemplate.reminderType.description,
              ccUsersValues: [],
              bccUsersValues: [],
              optIn: true,
            });
          }
        });
      },
    },
    ccUsersOptions: {
      deep: true,
      handler() {
        this.populateEmailPreferencesCCs();
        if (this.snooze) {
          this.redirectTo = this.ccUsersOptions.find((u: any) => u.value === this.modalData?.row?.emailPreference?.redirectTo);
        }
      },
    },
    bccUsersOptions: {
      deep: true,
      handler() {
        this.populateEmailPreferencesBCCs();
      },
    },
  },
});
