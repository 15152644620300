import { Socket } from 'socket.io-client';

export class SocketUtility {
  private static socket: Socket;
  private static marketingCampaignSocket: Socket;
  private static recruitmentCampaignSocket: Socket;
  private static memosSocket: Socket;

  static getSocket(): Socket {
    return this.socket;
  }

  static setSocket(socket: Socket) {
    this.socket = socket;
  }

  static getMarketingCampaignSocket(): Socket {
    return this.marketingCampaignSocket;
  }

  static setMarketingCampaignSocket(socket: Socket) {
    this.marketingCampaignSocket = socket;
  }

  static getRecruitmentCampaignSocket(): Socket {
    return this.recruitmentCampaignSocket;
  }

  static setRecruitmentCampaignSocket(socket: Socket) {
    this.recruitmentCampaignSocket = socket;
  }

  static getMemosSocket(): Socket {
    return this.memosSocket;
  }

  static setMemosSocket(socket: Socket) {
    this.memosSocket = socket;
  }
}
