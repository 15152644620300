
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../../atoms/Input/Input.vue';
import Search from '../../../../../atoms/Search/Search.vue';
import Dropdown from '../../../../../atoms/Dropdown/Dropdown.vue';
import MultiSelectDropdown from '../../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import TextArea from '../../../../../atoms/Text-Area/Text-Area.vue';
import Checkbox from '../../../../../atoms/Checkbox/Checkbox.vue';
import Button from '../../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../../atoms/Modal/Modal.vue';
import RecommendedImages from '../../../../../molecules/Recommended-Images/Recommended-Images.vue';
import DatePicker from '../../../../../molecules/DatePicker/DatePicker.vue';
import SingleSelectInputSuggestion from '../../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import LocationSelector from '../../../../../molecules/Locations-Selector/Location-Selector.vue';
import LanguageSelector from '../../../../../molecules/Language-Selector/Language-Selector.vue';
import Carousel from '../../../../../molecules/Carousel/Carousel.vue';
import ImageUploader from '../../../../../organisms/Image-Uploader/Image-Uploader.vue';
import { ILanguage, IMallImageRequirement, IStore, ITag } from '../../../../../../../../../interfaces/src/v2/index';
import { FormBuilder, FormControl } from '../../../../../../utilities/Forms';
import { Validators } from '../../../../../../utilities/Validators/Validators';
import jimp from 'jimp';
import { dateFormatter, dateFormatterLocalized, timeFormatterLocalized } from '../../../../../../utilities/Functions/formatting';
import Jimp from 'jimp';
import { IDropdownOption } from '../../../../../../utilities/Interfaces/form.interfaces';
import HttpService from '../../../../../../services/HttpService';
import Loader from '../../../../../atoms/Loader/Loader.vue';
import datepicker from 'vue3-datepicker';
import FileUploader from '../../../../../molecules/File-Uploader/File-Uploader.vue';
import Chip from '../../../../../atoms/Chip/Chip.vue';
import Attachment from '../../../../../atoms/Attachment-Downloader/Attachment-Downloader.vue';
import SocketService from '../../../../../../services/SocketService';
import { SocketEvent } from '../../../../../../enums/SocketEvent';
import { SocketUtility } from '../../../../../../utilities/Sockets/SocketUtility';
import { IANA_TIMEZONES } from '../../../../../../utilities/Interfaces/timezone.interface';
import dayjs from 'dayjs';
import { Status } from '@/enums/Status';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import MultiSelectInput from '@/atomic/molecules/Multi-SelectInput/MultiSelectInput.vue';
import { Role } from '@/enums/Role';
import { AbortHandler } from '@/services/AbortHandler';
import { SystemEventType } from '@/enums/SystemEventType';

dayjs.extend(timezone);
dayjs.extend(utc);

interface IUploadedImage {
  type: any;
  orientation: 'portrait' | 'landscape' | 'square';
  isMaster: boolean;
  language: string;
  languageId?: number;
  height: number;
  width: number;
  aspectRatio: string;
  url: string;
  updatedAt?: any;
  generated?: number;
  oversized?: number;
}

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-search': Search,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-text-area': TextArea,
    'arc-checkbox': Checkbox,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-recommended-images': RecommendedImages,
    'arc-image-uploader': ImageUploader,
    'arc-language-selector': LanguageSelector,
    'arc-date-picker': DatePicker,
    'arc-carousel': Carousel,
    'arc-location-selector': LocationSelector,
    'arc-loader': Loader,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-file-uploader': FileUploader,
    'arc-chip': Chip,
    'arc-attachment': Attachment,
    datepicker,
    'arc-multi-select-input': MultiSelectInput,
  },

  computed: {
    SystemEventType() {
      return SystemEventType;
    },
    Status() {
      return Status;
    },
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'selectedMarketingCampaign',
      'companyOptions',
      'brandOptions',
      'marketingCampaignTypes',
      'userOptions',
      'storeOptions',
      'loadingStores',
      'availableBrandSpecificTags',
      'firstDay',
      'lastDay',
      'uploadingMarketingPrimaryImage',
      'uploadingMarketingSecondaryImage',
      'channels',
    ]),
    filteredLocations(): any {
      // set all location options
      let locations = this.storeOptions;

      if (this.formData?.controls?.campaignType?.value) {
        const campaignTypeFilter: any[] = [];
        locations?.forEach((location: any) => {
          location?.mall?.communicationTypes?.forEach((commType: any) => {
            if (commType?.communicationTypeId === this.formData?.controls?.campaignType?.value) {
              campaignTypeFilter.push(location);
            }
          });
        });
        locations = campaignTypeFilter;
      }

      let tagCount: any = this.tagValues?.length;

      // filter tags
      if (tagCount > 0) {
        const tagIds = this.tagValues?.map((tag: any) => tag.value);
        // filter locations by tags
        locations = locations.filter((location: any) => {
          const locationTags = location?.tags?.map((tag: any) => tag.tagId);
          if (this.tagComboType.value === 'or') {
            return tagIds?.some((lt: number) => locationTags?.includes(lt));
          } else if (this.tagComboType.value === 'and') {
            return tagIds?.every((lt: number) => locationTags?.includes(lt));
          }
        });
      }

      // filter search params
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        locations.forEach((element: any) => {
          let matchFound = false;
          if (typeof Object.values(element) === 'string') {
            if (Object.values(element).includes(this.searchFilter)) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'number') {
            if (
              Object.values(element)
                .toString()
                .includes(this.searchFilter)
            ) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'object' && Object.values(element)) {
            Object.values(element)
              .filter((value) => value)
              .forEach((nestedElement: any) => {
                if (typeof nestedElement === 'string') {
                  if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'number') {
                  if (nestedElement.toString().includes(this.searchFilter)) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'object' && nestedElement) {
                  Object.values(nestedElement)
                    .filter((value) => value)
                    .forEach((nestedElementL2: any) => {
                      if (typeof nestedElementL2 === 'string') {
                        if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'number') {
                        if (nestedElementL2.toString().includes(this.searchFilter)) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                        Object.values(nestedElementL2)
                          .filter((value) => value)
                          .forEach((nestedElementL3: any) => {
                            if (typeof nestedElementL3 === 'string') {
                              if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                matchFound = true;
                              }
                            }
                            if (typeof nestedElementL3 === 'number') {
                              if (nestedElementL3.toString().includes(this.searchFilter)) {
                                matchFound = true;
                              }
                            }
                          });
                      }
                    });
                }
              });
          }
          if (matchFound) {
            searchResults.push(element);
          }
        });
        locations = searchResults;
      }
      locations = locations?.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      return locations;
    },
    filteredLocationsAvailable(): any {
      return this.filteredLocations.filter((location: any) => !this.selectedLocationIds?.includes(location?.storeId));
    },
    filteredLocationsSelected(): any {
      return this.filteredLocations.filter((location: any) => this.selectedLocationIds?.includes(location?.storeId));
    },
    updateDisabled(): boolean {
      // for campaigns that need approval, don't allow submit user to update after approval
      // this.campaign?.statusId !== Status.Draft
      if (this.approvalRequested && this.user?.userId === this.submittedBy) {
        if (this.initialLoad) {
          this.$notify({
            title: 'UPDATE DISABLED',
            text: 'This campaign has been submitted for approval. You can no longer update this campaign.',
            type: 'warn',
            duration: 5000,
          });
        }
        return true;
      }
      const userStoreIds = this.user?.stores.map((store: any) => store.storeId);
      let ds = false;
      if (userStoreIds?.length > 0) {
        if (this.formData && this.formData?.controls?.locations?.value?.length > 0) {
          this.formData?.controls?.locations?.value?.forEach((store: IStore) => {
            if (!userStoreIds.includes(store.storeId)) {
              ds = true;
            }
          });
        }
        if (
          (this.permissions &&
            this.modalData?.row &&
            !this.permissions?.canUpdateAllMarketingCampaigns &&
            (!this.permissions?.canUpdateOwnedMarketingCampaigns ||
              (this.permissions?.canUpdateOwnedMarketingCampaigns && this.user?.userId !== this.modalData?.row?.createdByUserId))) ||
          ds
        ) {
          if (this.initialLoad) {
            this.$notify({
              title: 'UPDATE PERMISSIONS DISABLED',
              text: 'There are locations in this campaign that you do not have permission to update.',
              type: 'warn',
              duration: 5000,
            });
          }
        }
      }
      return (
        (this.permissions &&
          this.modalData?.row &&
          !this.permissions?.canUpdateAllMarketingCampaigns &&
          (!this.permissions?.canUpdateOwnedMarketingCampaigns ||
            (this.permissions?.canUpdateOwnedMarketingCampaigns && this.user?.userId !== this.modalData?.row?.createdByUserId))) ||
        ds
      );
    },
    deleteDisabled(): boolean {
      const userStoreIds = this.user?.stores.map((store: any) => store.storeId);
      let ds = false;
      if (userStoreIds?.length > 0) {
        if (this.formData && this.formData?.controls?.locations?.value?.length > 0) {
          this.formData?.controls?.locations?.value?.forEach((store: IStore) => {
            if (!userStoreIds.includes(store.storeId)) {
              ds = true;
            }
          });
        }
      }
      return (
        (this.permissions &&
          this.modalData?.row &&
          !this.permissions?.canDeleteAllMarketingCampaigns &&
          (!this.permissions?.canDeleteOwnedMarketingCampaigns ||
            (this.permissions?.canDeleteOwnedMarketingCampaigns && this.user?.userId !== this.modalData?.row?.createdByUserId))) ||
        ds
      );
    },
    duplicateDisabled(): boolean {
      let oldEaExists = false;
      const creationDate = new Date(this.campaign?.createdAt);
      const ea3Date = new Date('June 06, 2022');
      if (creationDate < ea3Date) {
        oldEaExists = true;
      }
      return oldEaExists;
    },
    notes(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.notes?.value, this.activeLanguage?.isoCode);
    },
    headline(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.headline?.value, this.activeLanguage?.isoCode);
    },
    description(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.description.value, this.activeLanguage?.isoCode);
    },
    twitterDesc(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.twitterDesc.value, this.activeLanguage?.isoCode);
    },
    instagramDesc(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.instagramDesc.value, this.activeLanguage?.isoCode);
    },
    facebookDesc(): string {
      return this.getLanguageSpecificValue(this.formData?.controls?.facebookDesc.value, this.activeLanguage?.isoCode);
    },
    brandText(): string {
      const brand: any = this.brandOptions.filter((i: any) => {
        return i.value === `${this.formData?.controls?.brand?.value}`;
      });
      return brand[0]?.title === 'Select' ? '' : brand[0]?.title;
    },
    selectCompanyPlaceholder(): string {
      return this.brandOptions && this.brandOptions.length > 0 ? 'Select' : 'Select Company First';
    },
    selectBrandPlaceholder(): string {
      return this.brandOptions && this.brandOptions.length > 0 ? 'Select' : 'Select Brand First';
    },
    duplicationTooltipText(): string {
      return this.duplicating ? 'Duplicating' : 'Nothing to Duplicate';
    },
    containerType(): string {
      if (this.permissions?.superAdminAccess) {
        return 'admin-container';
      } else if (this.isMallAdmin()) {
        return 'mall-admin-container';
      } else {
        return 'user-container';
      }
    },
    contentContainerStyle(): string {
      return this.previewOpen ? 'width: 70%;' : 'width: 100%;';
    },
    datesStyle(): string {
      return this.storeOptions?.length > 0 ? '' : 'margin-top: -2rem;';
    },
    previewContainerStyle(): string {
      return this.previewOpen
        ? 'width: 30%; border-left: 1px solid #D6D6D6; opacity: 1; margin: 2rem 0; padding: 0 0 0 2rem;'
        : 'width: 0%; opacity: 0;';
    },
    imageDescription(): string {
      return 'Please note that it could take several minutes for the images to generate all required sizes. Our image logic works as follows: Uploading multiple images at once will remove any duplicate image sizes, and always use the closest uploaded image size when generating a required image. If you add or remove an image, all generated images will be regenerated using the new set of uploaded images. If a new uploaded image is exactly the same size as an existing uploaded image, it will take it\'s place and all generated images that used the existing image will be regenerated. Uploaded images can only generate images smaller than itself, so make sure you upload an image equal to or greater than the size of the biggest required image of it\'s respective orientation. You can remove any uploaded images, and when you do the generated images will regenerate, replacing any generated images that were using said image if there is another larger uploaded image with the same orientation, other wise the generated image is just removed.';
    },
    imageSizeRequirements(): { square: string; landscape: string; portrait: string } {
      const statuses = {
        multi: '',
        square: 'Not Required',
        landscape: 'Not Required',
        portrait: 'Not Required',
      };

      const masterSquareImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height === el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
      const masterPortraitImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height > el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
      const masterPortraitImageRatio = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height > el.width && Number(el.aspectRatio.split(':')[1]) < 3)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
      const masterLandscapeImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height < el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.width - a.width)[0];
      const masterLandscapeImageRatio = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height < el.width && Number(el.aspectRatio.split(':')[0]) < 3)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.width - a.width)[0];

      if (masterSquareImage) {
        statuses.square = `${masterSquareImage.width}w x ${masterSquareImage.height}h or larger recommended`;
      }

      if (masterPortraitImageRatio) {
        statuses.portrait = `${masterPortraitImageRatio.width}w x ${masterPortraitImageRatio.height}h or larger recommended`;
      } else if (masterPortraitImage) {
        statuses.portrait = `${masterPortraitImage.width}w x ${masterPortraitImage.height}h or larger recommended`;
      }

      if (masterLandscapeImageRatio) {
        statuses.landscape = `${masterLandscapeImageRatio.width}w x ${masterLandscapeImageRatio.height}h or larger recommended`;
      } else if (masterLandscapeImage) {
        statuses.landscape = `${masterLandscapeImage.width}w x ${masterLandscapeImage.height}h or larger recommended`;
      }

      return statuses;
    },
    primaryImageStatus(): {
      square: {
        required: boolean;
        uploaded: boolean;
        warning: boolean;
      };
      landscape: {
        required: boolean;
        uploaded: boolean;
        warning: boolean;
      };
      portrait: {
        required: boolean;
        uploaded: boolean;
        warning: boolean;
      };
    } {
      let status = {
        square: {
          required: false,
          uploaded: false,
          warning: false,
        },
        landscape: {
          required: false,
          uploaded: false,
          warning: false,
        },
        portrait: {
          required: false,
          uploaded: false,
          warning: false,
        },
      };
      let squareValid = true;
      let landscapeValid = true;
      let portraitValid = true;
      let squareWarning = false;
      let landscapeWarning = false;
      let portraitWarning = false;
      const masterPrimaryImages = this.uploadedPrimaryImages?.filter((image: IUploadedImage) => image?.generated === 0);
      const maxSizeIncrease = 1.15;

      const masterPortraitImageRatio = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height > el.width && Number(el.aspectRatio.split(':')[1]) < 3)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
      const masterLandscapeImageRatio = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height < el.width && Number(el.aspectRatio.split(':')[0]) < 3)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.width - a.width)[0];

      this.requiredImageSizes?.forEach((requirement: any) => {
        if (requirement?.height === requirement?.width) {
          status.square.required = true;
          if (this.uploadedPrimaryImages?.length > 0) {
            const requirementMet = masterPrimaryImages.some(
              (image: IUploadedImage) => image?.orientation === 'square' && image?.height >= requirement?.height,
            );
            const imageGenerated = this.uploadedPrimaryImages.some(
              (image: IUploadedImage) =>
                image?.orientation === 'square' && image?.height === requirement?.height && image?.width === requirement?.width,
            );

            if (!requirementMet) {
              squareValid = false;
              if (imageGenerated) {
                squareWarning = true;
              }
            }
            if (!imageGenerated) {
              const requirementMetIfResize = masterPrimaryImages.some(
                (image: IUploadedImage) => image?.orientation === 'square' && image?.height * maxSizeIncrease >= requirement?.height,
              );
              if (requirementMetIfResize) {
                this.imageResizeRequired = true;
              }
            }
          } else {
            squareValid = false;
          }
        } else if (requirement?.height < requirement?.width) {
          status.landscape.required = true;
          if (this.uploadedPrimaryImages?.length > 0) {
            const requirementMet = masterPrimaryImages.some(
              (image: IUploadedImage) => image?.orientation === 'landscape' && image?.width >= requirement?.width,
            );
            const requirementMetRatio = masterPrimaryImages.some(
              (image: IUploadedImage) => image?.orientation === 'landscape' && image?.height >= masterLandscapeImageRatio?.height,
            );
            const imageGenerated = this.uploadedPrimaryImages.some(
              (image: IUploadedImage) =>
                image?.orientation === 'landscape' && image?.height === requirement?.height && image?.width === requirement?.width,
            );

            if (!requirementMet && !requirementMetRatio) {
              landscapeValid = false;
              if (imageGenerated) {
                landscapeWarning = true;
              }
            }
            if (!imageGenerated) {
              const requirementMetIfResize = masterPrimaryImages.some(
                (image: IUploadedImage) => image?.orientation === 'landscape' && image?.width * maxSizeIncrease >= requirement?.width,
              );
              if (requirementMetIfResize) {
                this.imageResizeRequired = true;
              }
            }
          } else {
            landscapeValid = false;
          }
        } else if (requirement?.height > requirement?.width) {
          status.portrait.required = true;
          if (this.uploadedPrimaryImages?.length > 0) {
            const requirementMet = masterPrimaryImages.some(
              (image: IUploadedImage) => image?.orientation === 'portrait' && image?.height >= requirement?.height,
            );
            const requirementMetRatio = masterPrimaryImages.some(
              (image: IUploadedImage) => image?.orientation === 'portrait' && image?.height >= masterPortraitImageRatio?.height,
            );
            const imageGenerated = this.uploadedPrimaryImages.some(
              (image: IUploadedImage) =>
                image?.orientation === 'portrait' && image?.height === requirement?.height && image?.width === requirement?.width,
            );

            if (!requirementMet && !requirementMetRatio) {
              portraitValid = false;
              if (imageGenerated) {
                portraitWarning = true;
              }
            }
            if (!imageGenerated) {
              const requirementMetIfResize = masterPrimaryImages.some(
                (image: IUploadedImage) => image?.orientation === 'portrait' && image?.height * maxSizeIncrease >= requirement?.height,
              );
              if (requirementMetIfResize) {
                this.imageResizeRequired = true;
              }
            }
          } else {
            portraitValid = false;
          }
        }
      });

      status.square.uploaded = squareValid;
      status.landscape.uploaded = landscapeValid;
      status.portrait.uploaded = portraitValid;
      status.square.warning = squareWarning;
      status.landscape.warning = landscapeWarning;
      status.portrait.warning = portraitWarning;

      return status;
    },
    secondaryImageStatus(): {
      square: {
        required: boolean;
        uploaded: boolean;
      };
      landscape: {
        required: boolean;
        uploaded: boolean;
      };
      portrait: {
        required: boolean;
        uploaded: boolean;
      };
    } {
      let status = {
        square: {
          required: false,
          uploaded: false,
        },
        landscape: {
          required: false,
          uploaded: false,
        },
        portrait: {
          required: false,
          uploaded: false,
        },
      };

      this.uploadedSecondaryImages?.forEach((image: any) => {
        if (
          (image?.type?.description === 'Secondary' || image.type === 'secondary') &&
          image.orientation === 'square' &&
          (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode)
        ) {
          status.square.uploaded = true;
        } else if (
          (image?.type?.description === 'Secondary' || image.type === 'secondary') &&
          image.orientation === 'landscape' &&
          (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode)
        ) {
          status.landscape.uploaded = true;
        } else if (
          (image?.type?.description === 'Secondary' || image.type === 'secondary') &&
          image.orientation === 'portrait' &&
          (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode)
        ) {
          status.portrait.uploaded = true;
        }
      });

      return status;
    },
    uploadedPrimaryImages(): IUploadedImage[] {
      let uploadedImages;
      let sorted;
      if (this.uploadedImages && this.uploadedImages.length > 0) {
        uploadedImages = [...this.uploadedImages];
      }

      // filter list to have only the largest image of each unique aspect ratio

      const sortingFunction = (a: IUploadedImage, b: IUploadedImage) => {
        const mappings = {
          aIsMaster: a.isMaster,
          bIsMaster: b.isMaster,
          aIsGenerated: !a.isMaster,
          bIsGenerated: !b.isMaster,
          aIsUploaded: !a.isMaster && a.isMaster,
          bIsUploaded: !b.isMaster && b.isMaster,
        };

        if (mappings.bIsMaster) {
          return 1;
        }

        if (mappings.aIsMaster && mappings.bIsUploaded) {
          return 0;
        }

        if (mappings.bIsUploaded) {
          return 1;
        }

        if (mappings.bIsGenerated) {
          return -1;
        }

        return -1;
      };

      let sortedSquareImages;
      let sortedLandscapeImages;
      let sortedPortraitImages;

      if (uploadedImages && uploadedImages.length > 0) {
        sortedSquareImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              (image?.type?.description === 'Primary' || image.type === 'primary') &&
              image.orientation === 'square' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sortedLandscapeImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              (image?.type?.description === 'Primary' || image.type === 'primary') &&
              image.orientation === 'landscape' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sortedPortraitImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              (image?.type?.description === 'Primary' || image.type === 'primary') &&
              image.orientation === 'portrait' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sorted = [...sortedSquareImages, ...sortedLandscapeImages, ...sortedPortraitImages];
      }

      return sorted as IUploadedImage[];
    },
    uploadedSecondaryImages(): IUploadedImage[] {
      let uploadedImages;
      let sorted;
      if (this.uploadedImages && this.uploadedImages.length > 0) {
        uploadedImages = [...this.uploadedImages];
      }

      const sortingFunction = (a: IUploadedImage, b: IUploadedImage) => {
        const mappings = {
          aIsMaster: a.isMaster,
          bIsMaster: b.isMaster,
          aIsGenerated: !a.isMaster,
          bIsGenerated: !b.isMaster,
          aIsUploaded: !a.isMaster && a.isMaster,
          bIsUploaded: !b.isMaster && b.isMaster,
        };

        if (mappings.bIsMaster) {
          return 1;
        }

        if (mappings.aIsMaster && mappings.bIsUploaded) {
          return 0;
        }

        if (mappings.bIsUploaded) {
          return 1;
        }

        if (mappings.bIsGenerated) {
          return -1;
        }

        return -1;
      };

      let sortedSquareImages;
      let sortedLandscapeImages;
      let sortedPortraitImages;

      if (uploadedImages && uploadedImages.length > 0) {
        sortedSquareImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              (image?.type?.description === 'Secondary' || image.type === 'secondary') &&
              image.orientation === 'square' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sortedLandscapeImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              (image?.type?.description === 'Secondary' || image.type === 'secondary') &&
              image.orientation === 'landscape' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sortedPortraitImages = uploadedImages
          .filter(
            (image: IUploadedImage) =>
              (image?.type?.description === 'Secondary' || image.type === 'secondary') &&
              image.orientation === 'portrait' &&
              (image?.languageId === this.activeLanguage?.languageId || image.language === this.activeLanguage?.isoCode),
          )
          .sort(sortingFunction);
        sorted = [...sortedSquareImages, ...sortedLandscapeImages, ...sortedPortraitImages];
      }

      return sorted as IUploadedImage[];
    },
    searchLocationsStyle(): string {
      return this.availableBrandSpecificTags?.length > 0 ? 'grid-column-end: span 15;' : 'grid-column-end: span 30;';
    },
  },

  watch: {
    'formData.controls.locations.value': {
      deep: true,
      async handler(locations) {
        this.extractDataFromLocations(locations);
      },
    },
    selectedLocationIds: {
      handler(newSelectedLocationIds, oldSelectedLocationIds) {
        if (
          !this.initialLoad &&
          this.formData?.controls?.images?.value?.length > 0 &&
          this.formData?.controls?.locations?.value?.length > 0 &&
          newSelectedLocationIds.length !== oldSelectedLocationIds.length
        ) {
          this.imageResizeRequired = true;
        }
      },
    },
    'formData.controls.startDate.value': {
      deep: true,
      handler() {
        if (this.formData?.controls?.startDate?.value && this.formData?.controls?.endDate?.value && this.canCheckDates) {
          const d1: any = new Date(this.formData?.controls?.startDate?.value);
          const d2: any = new Date(this.formData?.controls?.endDate?.value);
          if (this.daysBetween(d1, d2) >= 30) {
            this.$notify({
              title: 'CHECK YOUR DATES',
              text:
                'We noticed the time between your Start Date and End Date are greater than one month. While you can submit a campaign for any length, as a courtesy, if your campaign is supposed to be less than one month, please re-check your dates entered. If your dates are correct, please continue.',
              type: 'warn',
              duration: 11000,
            });
          }
        }
      },
    },
    'formData.controls.endDate.value': {
      deep: true,
      handler() {
        if (this.formData?.controls?.startDate?.value && this.formData?.controls?.endDate?.value && this.canCheckDates) {
          const d1: any = new Date(this.formData?.controls?.startDate?.value);
          const d2: any = new Date(this.formData?.controls?.endDate?.value);
          if (this.campaign?.statusId === 2 && this.isToday(d2)) {
            this.$notify({
              title: 'EXPIRING CAMPAIGN',
              text:
                'If you would like to expire the campaign now, please click the expire button below. If you would like the campaign to expire EOD, please click the udpate button below when you are finished your updates.',
              type: 'warn',
              duration: 15000,
            });
          }
          if (this.daysBetween(d1, d2) >= 30) {
            this.$notify({
              title: 'CHECK YOUR DATES',
              text:
                'We noticed the time between your Start Date and End Date are greater than one month. While you can submit a campaign for any length, as a courtesy, if your campaign is supposed to be less than one month, please re-check your dates entered. If your dates are correct, please continue.',
              type: 'warn',
              duration: 15000,
            });
          }
        }
      },
    },
    uploadedPrimaryImages: {
      deep: true,
      handler() {
        this.primaryImagesByAspectRatio();
      },
    },
    uploadedSecondaryImages: {
      deep: true,
      handler() {
        this.secondaryImagesByAspectRatio();
      },
    },
    backgroundBlurry: {
      deep: true,
      async handler() {
        if (!this.initialLoad) {
          this.setChangesMade('backgroundBlurry');
          this.imageResizeRequired = true;
        }
      },
    },
    companyOptions: {
      deep: true,
      handler() {
        this.allowShowOptions = true;
        this.companyOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.company?.value
              ? this.formData?.controls?.company?.value === item?.value
              : this.selectedMarketingCampaign?.companyId === item?.value
          ) {
            this.updateCompany(item);
          }
        });
      },
    },
    brandOptions: {
      deep: true,
      handler() {
        this.brandOptions.forEach((item: IDropdownOption) => {
          if (
            this.formData?.controls?.brand?.value
              ? this.formData?.controls?.brand?.value === item?.value
              : this.selectedMarketingCampaign?.brandId === item?.value
          ) {
            this.brandValue = item;
            this.formData.controls.brand.value = this.brandValue?.value;
            this.loadBrandTags({ brandIds: this.formData.controls.brand.value });
          }
        });
      },
    },
    userOptions: {
      deep: true,
      handler() {
        this.userOptions.forEach((item: IDropdownOption) => {
          if (this.formData?.controls?.user?.value && this.formData?.controls?.user?.value === item?.value) {
            this.updateUser(item);
          } else if (this.selectedMarketingCampaign?.submittedBy === item?.value) {
            this.updateUser(item);
          } else if (this.selectedMarketingCampaign?.createdByUserId === item?.value) {
            this.updateUser(item);
          }
        });
      },
    },
    marketingCampaignTypes: {
      deep: true,
      handler() {
        this.marketingCampaignTypes.forEach((item: IDropdownOption) => {
          if (this.selectedMarketingCampaign?.marketingCampaignTypeId === item?.value) {
            this.typeValue = item;
            this.formData.controls.campaignType.value = this.typeValue.value;
            this.fetchStoresByCommunicationType({
              communicationType: this.formData.controls.campaignType.value,
              brandId: this.formData.controls.brand.value,
            });
          }
        });
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
    user: {
      deep: true,
      handler() {
        if (this.permissions?.superAdminAccess) {
          this.formData.controls.company.validators = [Validators.required];
          this.formData.controls.user.validators = [Validators.required];
        }
      },
    },
    socialContacts: {
      deep: true,
      handler() {
        if (this.formData) {
          if (this.socialContacts) {
            this.formData.controls.twitterDesc.validators = [Validators.required];
            this.formData.controls.instagramDesc.validators = [Validators.required];
            this.formData.controls.facebookDesc.validators = [Validators.required];
          } else {
            this.formData.controls.twitterDesc.validators = [];
            this.formData.controls.instagramDesc.validators = [];
            this.formData.controls.facebookDesc.validators = [];
          }
        }
        if (this.attemptedSubmit) {
          this.formData.markAllAsTouched();
          this.validateControl('headline');
          this.validateControl('description');
          this.validateControl('twitterDesc');
          this.validateControl('instagramDesc');
          this.validateControl('facebookDesc');
          this.checkLanguageErrors();
        }
      },
    },
    noImages: {
      deep: true,
      handler() {
        if (this.formData) {
          if (!this.noImages) {
            this.formData.controls.images.validators = [Validators.required];
          } else {
            this.formData.controls.images.validators = [];
          }
        }
        if (this.attemptedSubmit) {
          this.formData.markAllAsTouched();
          this.checkLanguageErrors();
        }
      },
    },
    availableBrandSpecificTags: {
      deep: true,
      handler() {
        this.marketingCampaignTagValues = [];
        this.availableBrandSpecificTags.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.tags?.map((tag: ITag) => tag.tagId).includes(item.value)) {
            this.marketingCampaignTagValues.push(item);
          }
        });
      },
    },
    channels: {
      deep: true,
      handler() {
        if (this.selectedMarketingCampaign) {
          const locations = this.selectedMarketingCampaign?.stores;
          this.extractDataFromLocations(locations);
          this.campaignChannelValues = this.channelOptions.filter((channel: IDropdownOption) =>
            this.selectedMarketingCampaign?.channels?.includes(channel.value),
          );
        }
      },
    },
  },

  // beforeUnmount(): void {
  //   this.socketService?.imageUploadSocket?.emit(SocketEvent.DETACH_SOCKET_FROM_MODAL);
  // },
  async beforeUnmount(): Promise<void> {
    if (SocketUtility.getSocket()) {
      SocketUtility.getSocket().off(SocketEvent.PRIMARY_IMAGE_PROGRESS_UPDATE);
      SocketUtility.getSocket().off(SocketEvent.SECONDARY_IMAGE_PROGRESS_UPDATE);
    }

    const controller = AbortHandler.getInstance();
    controller.voidControllers();

    this.fetchMarketingCampaigns();
  },

  async mounted(): Promise<void> {
    if (!SocketUtility.getSocket()) {
      SocketUtility.setSocket(new SocketService().imageUploadSocket);
    }

    SocketUtility.getSocket().on(
      SocketEvent.PRIMARY_IMAGE_PROGRESS_UPDATE,
      (percentage: number) => (this.primaryUploadPercentage = percentage === 0 ? 100 : percentage),
    );
    SocketUtility.getSocket().on(
      SocketEvent.SECONDARY_IMAGE_PROGRESS_UPDATE,
      (percentage: number) => (this.secondaryUploadPercentage = percentage === 0 ? 100 : percentage),
    );

    if (this.user?.onboarding) {
      if (this.initialLoad) {
        this.$notify({
          title: 'SUBMIT PERMISSIONS DISABLED',
          text:
            'You are currently unable to submit communications as your account activation remains incomplete. To finalize your account activation, kindly contact our team at team@engagementagents.com.',
          type: 'warn',
          duration: 5000,
        });
      }
    }

    if (this.modalData?.campaignId) {
      // open the preview sidebar if data exists
      this.previewOpen = true;
      await this.fetchMarketingCampaign({ campaignId: this.modalData?.campaignId });
      this.status = 'Edit';
      this.clearStores();
      this.loadChannels();
      this.setForm();
      this.selectEnglishAsDefaultLanguage();
      this.loadCompanies();
      this.loadMarketingCampaignTypes();
      this.loaded = true;
    } else {
      this.status = 'New';
      this.clearStores();
      this.loadChannels();
      this.setForm();
      this.selectEnglishAsDefaultLanguage();
      this.loadCompanies();
      this.loadMarketingCampaignTypes();
      this.loaded = true;
    }
  },
  data(): {
    status: string;
    previewOpen: boolean;
    changesMade: boolean;
    previewable: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    duplicating: boolean;
    companyValue: IDropdownOption | null;
    userValue: IDropdownOption | null;
    brandValue: IDropdownOption | null;
    typeValue: IDropdownOption | null;
    customSearchValue: IDropdownOption | null;
    customSearchOptions: IDropdownOption[] | [];
    searchLocationsFilter: string;
    tagValues: IDropdownOption[];
    searchFilter: string;
    selectedLocationIds: string[];
    primaryImages: any[];
    secondaryImages: any[];
    languages: ILanguage[];
    activeLanguage: ILanguage;
    requiredImageSizes: IMallImageRequirement[];
    uploadedImages: IUploadedImage[];
    primaryUniqueRatioImages: IUploadedImage[];
    secondaryUniqueRatioImages: IUploadedImage[];
    formData: any;
    loaded: boolean;
    storeContacts: boolean;
    socialContacts: boolean;
    sameDescription: boolean;
    googleBusiness: boolean;
    appleBusiness: boolean;
    publishDateUpdatedCount: number;
    startDateUpdatedCount: number;
    endDateUpdatedCount: number;
    primaryImagesUploading: boolean;
    secondaryImagesUploading: boolean;
    attemptedSubmit: boolean;
    showSameDescription: boolean;
    campaign: any;
    canCheckDates: boolean;
    fromCalendar: boolean;
    hasPrimaryImageChange: boolean;
    hasSecondaryImageChange: boolean;
    hasAttachmentChange: boolean;
    initialLoad: boolean;
    filesUploading: boolean;
    primaryUploadPercentage: number;
    secondaryUploadPercentage: number;
    attachmentUploadPercentage: number;
    backgroundBlurry: boolean;
    timeout: any;
    oversizedImage: boolean;
    allowShowOptions: boolean;
    timezones: any;
    timezone: IDropdownOption | null;
    selectedTimezone: string;
    savedTimezone: IDropdownOption | null;
    selectedReason: string;
    reasonMessage: string;
    reasonDescription: string;
    requirementsOpen: boolean;
    noImages: boolean;
    imageResizeRequired: boolean;
    tagComboTypes: IDropdownOption[] | null;
    tagComboType: IDropdownOption;
    languageSpecificImagesRequired: boolean;
    submittedBy: string;
    approvalRequested: false;
    marketingCampaignTagValues: IDropdownOption[];
    channelOptions: IDropdownOption[];
    campaignChannelValues: IDropdownOption[];
    channelsTouched: boolean;
  } {
    return {
      status: 'New',
      previewOpen: false,
      changesMade: false,
      previewable: false,
      formValid: false,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      duplicating: false,
      formData: null,
      loaded: false,
      storeContacts: false,
      socialContacts: true,
      sameDescription: true,
      googleBusiness: false,
      appleBusiness: false,
      publishDateUpdatedCount: 0,
      startDateUpdatedCount: 0,
      endDateUpdatedCount: 0,
      companyValue: null,
      userValue: null,
      brandValue: null,
      typeValue: null,
      customSearchValue: null,
      customSearchOptions: [],
      searchLocationsFilter: '',
      tagValues: [],
      searchFilter: '',
      selectedLocationIds: [],
      primaryImages: [],
      secondaryImages: [],
      languages: [
        {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
          required: true,
        },
      ],
      activeLanguage: {
        languageId: 37,
        description: 'English',
        isoCode: 'en',
        updatedAt: '',
        createdAt: '',
        required: true,
      },
      requiredImageSizes: [],
      uploadedImages: [],
      primaryImagesUploading: false,
      secondaryImagesUploading: false,
      primaryUniqueRatioImages: [],
      secondaryUniqueRatioImages: [],
      attemptedSubmit: false,
      showSameDescription: true,
      campaign: null,
      canCheckDates: false,
      fromCalendar: false,
      hasPrimaryImageChange: false,
      hasSecondaryImageChange: false,
      hasAttachmentChange: false,
      initialLoad: true,
      filesUploading: false,
      primaryUploadPercentage: 0,
      secondaryUploadPercentage: 0,
      attachmentUploadPercentage: 0,
      backgroundBlurry: true,
      timeout: null,
      oversizedImage: false,
      allowShowOptions: false,
      timezones: IANA_TIMEZONES,
      timezone: null,
      selectedTimezone: dayjs.tz.guess(),
      savedTimezone: null,
      selectedReason: '',
      reasonMessage: '',
      reasonDescription: '',
      requirementsOpen: false,
      noImages: false,
      imageResizeRequired: false,
      tagComboTypes: [
        {
          value: 'or',
          description: 'OR',
        },
        {
          value: 'and',
          description: 'AND',
        },
      ],
      tagComboType: {
        value: 'or',
        description: 'OR',
      },
      languageSpecificImagesRequired: true,
      submittedBy: '',
      approvalRequested: false,
      marketingCampaignTagValues: [],
      channelOptions: [],
      campaignChannelValues: [],
      channelsTouched: false,
    };
  },

  //
  // ███╗   ███╗███████╗████████╗██╗  ██╗ ██████╗ ██████╗ ███████╗
  // ████╗ ████║██╔════╝╚══██╔══╝██║  ██║██╔═══██╗██╔══██╗██╔════╝
  // ██╔████╔██║█████╗     ██║   ███████║██║   ██║██║  ██║███████╗
  // ██║╚██╔╝██║██╔══╝     ██║   ██╔══██║██║   ██║██║  ██║╚════██║
  // ██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝██████╔╝███████║
  // ╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝ ╚═════╝ ╚══════╝
  //
  methods: {
    dateFormatterLocalized,
    timeFormatterLocalized,
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadCompanies',
      'loadBrands',
      'loadMarketingCampaignTypes',
      'loadUsers',
      'fetchStoresByCommunicationType',
      'clearStores',
      'loadBrandTags',
      'clearBrandTags',
      'fetchMarketingCampaign',
      'refreshCalendar',
      'saveMarketingCampaign',
      'updateMarketingCampaign',
      'updateModalData',
      'updateModalType',
      'openModal',
      'updateModalComponent',
      'showUpdateDrafts',
      'hideUpdateDrafts',
      'refreshAllCommunications',
      'uploadImage',
      'fetchBrandById',
      'fetchMarketingCampaigns',
      'saveSystemEvent',
      'loadChannels',
    ]),

    //
    // ███████╗███████╗████████╗    ███████╗ ██████╗ ██████╗ ███╗   ███╗
    // ██╔════╝██╔════╝╚══██╔══╝    ██╔════╝██╔═══██╗██╔══██╗████╗ ████║
    // ███████╗█████╗     ██║       █████╗  ██║   ██║██████╔╝██╔████╔██║
    // ╚════██║██╔══╝     ██║       ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║
    // ███████║███████╗   ██║       ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║
    // ╚══════╝╚══════╝   ╚═╝       ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝
    //
    setForm(): void {
      // initializers
      let notes: any = [];
      let headline: any = [];
      let description: any = [];
      let twitterDesc: any = [];
      let instagramDesc: any = [];
      let facebookDesc: any = [];
      let images: any = [];
      let attachments: any = [];
      let notesParsed: any;
      let headlineParsed: any;
      let descriptionParsed: any;
      let twitterDescParsed: any;
      let instagramDescParsed: any;
      let facebookDescParsed: any;
      let publishDate = '';
      let startDate = '';
      let endDate = '';
      let company = this.permissions?.superAdminAccess || this.isMallAdmin() ? '' : this.user?.companyId;
      let user = '';
      let brand = this.access?.marketingCampaignsStore ? this.user?.brands[0].brandId : '';
      let campaignName = '';
      let campaignType = '';
      let locations = [];
      let storeContacts = this.user?.notifyStoreContacts === 1;
      this.storeContacts = this.user?.notifyStoreContacts === 1;
      let socialContacts = true;
      let sameDescription = true;
      let noImages = false;
      let languageSpecificImagesRequired = true;
      this.googleBusiness = this.user?.googleBusiness;
      this.appleBusiness = this.user?.appleBusiness;
      let googleBusiness = this.user?.googleBusiness;
      let appleBusiness = this.user?.appleBusiness;
      this.timezone = this.timezones.find((timezone: any) => timezone.value === dayjs.tz.guess());

      // set values if editing existing campaign
      if (this.selectedMarketingCampaign) {
        let campaign = this.selectedMarketingCampaign;
        this.campaign = campaign;

        this.uploadedImages = campaign?.images;

        // parse multi language capable fields
        notesParsed = this.languageParser(campaign?.notes);
        headlineParsed = this.languageParser(campaign?.headline);
        descriptionParsed = this.languageParser(campaign?.longDescription);
        twitterDescParsed = this.languageParser(campaign?.twitterDescription);
        instagramDescParsed = this.languageParser(campaign?.instagramDescription);
        facebookDescParsed = this.languageParser(campaign?.facebookDescription);

        images = this.selectedMarketingCampaign?.images;
        attachments = this.selectedMarketingCampaign?.attachments ?? [];
        company = this.selectedMarketingCampaign?.companyId;
        user = this.selectedMarketingCampaign?.createdByUserId;
        brand = this.selectedMarketingCampaign?.brandId;
        campaignName = this.selectedMarketingCampaign?.description;
        campaignType = this.selectedMarketingCampaign?.marketingCampaignTypeId;
        locations = this.selectedMarketingCampaign?.stores;
        storeContacts = this.selectedMarketingCampaign?.sendToStoreContacts;
        socialContacts = this.selectedMarketingCampaign?.sendToSocialContacts;
        sameDescription = this.selectedMarketingCampaign?.sameDescription;
        noImages = this.selectedMarketingCampaign?.noImages;
        languageSpecificImagesRequired = this.selectedMarketingCampaign?.languageSpecificImagesRequired ?? true;
        googleBusiness = this.selectedMarketingCampaign?.googleBusiness ?? false;
        appleBusiness = this.selectedMarketingCampaign?.appleBusiness ?? false;
        this.backgroundBlurry = this.selectedMarketingCampaign?.backgroundBlurry ?? true;
        this.storeContacts = storeContacts;
        this.socialContacts = socialContacts;
        this.sameDescription = sameDescription;
        this.noImages = noImages;
        this.languageSpecificImagesRequired = languageSpecificImagesRequired;
        this.googleBusiness = googleBusiness;
        this.appleBusiness = appleBusiness;
        this.submittedBy = this.selectedMarketingCampaign?.submittedBy;
        this.approvalRequested = this.selectedMarketingCampaign?.approvalRequested;

        if (this.selectedMarketingCampaign?.publishByDate) {
          publishDate = this.selectedMarketingCampaign?.publishByDate;
        }
        if (this.selectedMarketingCampaign?.fromDate) {
          startDate = this.selectedMarketingCampaign?.fromDate;
        }
        if (this.selectedMarketingCampaign?.toDate) {
          endDate = this.selectedMarketingCampaign?.toDate;
        }

        if (this.campaign.statusId === 1) {
          if (
            dayjs(publishDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            publishDate = '';
          }
          if (
            dayjs(startDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            startDate = '';
          }
          if (
            dayjs(endDate) <
            dayjs()
              .utcOffset(0)
              .startOf('day')
          ) {
            endDate = '';
          }
        }

        // extract languages from locations
        this.extractDataFromLocations(locations);
        this.timezone = this.timezones.find((timezone: any) => timezone.value === this.selectedMarketingCampaign?.timezone);
        this.selectedTimezone = this.timezones.find((timezone: any) => timezone.value === this.selectedMarketingCampaign?.timezone)?.value;
        this.channelsTouched = true;
      }

      // set multi language objects for new form
      this.languages?.forEach((language) => {
        let notesLang = '';
        let headlineLang = '';
        let descriptionLang = '';
        let twitterDescLang = '';
        let instagramDescLang = '';
        let facebookDescLang = '';
        if (this.selectedMarketingCampaign) {
          notesParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              notesLang = item[language?.isoCode];
            }
          });
          headlineParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              headlineLang = item[language?.isoCode];
            }
          });
          descriptionParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              descriptionLang = item[language?.isoCode];
            }
          });
          twitterDescParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              twitterDescLang = item[language?.isoCode];
            }
          });
          instagramDescParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              instagramDescLang = item[language?.isoCode];
            }
          });
          facebookDescParsed?.forEach((item: any) => {
            if (item[language?.isoCode]) {
              facebookDescLang = item[language?.isoCode];
            }
          });
        }
        if (
          this.selectedMarketingCampaign?.notes.includes(':::') ||
          this.selectedMarketingCampaign?.notes.includes('en=') ||
          this.selectedMarketingCampaign?.notes.includes('fr=')
        ) {
          notes.push({ language: language?.isoCode, value: notesLang ?? '' });
        } else {
          notes.push({ language: language?.isoCode, value: this.selectedMarketingCampaign?.notes ?? '' });
        }
        headline.push({ language: language?.isoCode, value: headlineLang });
        description.push({
          language: language?.isoCode,
          value: descriptionLang,
        });
        twitterDesc.push({
          language: language?.isoCode,
          value: twitterDescLang,
        });
        instagramDesc.push({
          language: language?.isoCode,
          value: instagramDescLang,
        });
        facebookDesc.push({
          language: language?.isoCode,
          value: facebookDescLang,
        });
      });

      // set form based on user type
      this.formData = new FormBuilder({
        company: new FormControl(company),
        user: new FormControl(user ?? ''),
        brand: new FormControl(brand, [Validators.required]),
        campaignName: new FormControl(campaignName, [Validators.required]),
        campaignType: new FormControl(campaignType, [Validators.required]),
        locations: new FormControl(locations, [Validators.required]),
        publishDate: new FormControl(publishDate, [Validators.required]),
        startDate: new FormControl(startDate, [Validators.required]),
        endDate: new FormControl(endDate, [Validators.required]),
        notes: new FormControl(notes),
        storeContacts: new FormControl(storeContacts),
        socialContacts: new FormControl(socialContacts),
        sameDescription: new FormControl(sameDescription),
        headline: new FormControl(headline, [Validators.required]),
        description: new FormControl(description, [Validators.required]),
        twitterDesc: new FormControl(twitterDesc),
        instagramDesc: new FormControl(instagramDesc),
        facebookDesc: new FormControl(facebookDesc),
        noImages: new FormControl(noImages),
        images: new FormControl(images),
        attachments: new FormControl(attachments),
        timezone: new FormControl(dayjs.tz.guess()),
        languageSpecificImagesRequired: new FormControl(languageSpecificImagesRequired),
        googleBusiness: new FormControl(googleBusiness),
        appleBusiness: new FormControl(appleBusiness),
      });

      this.languages?.forEach((language: any) => {
        let languageExists = false;
        this.formData?.controls?.headline?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.headline?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.notes?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.notes?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.description?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.description?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.facebookDesc?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.facebookDesc?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.twitterDesc?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.twitterDesc?.value?.push({ language: language?.isoCode, value: '' });
        }

        languageExists = false;
        this.formData?.controls?.instagramDesc?.value?.forEach((lang: any) => {
          if (language?.isoCode === lang?.language) {
            languageExists = true;
          }
        });
        if (!languageExists) {
          this.formData?.controls?.instagramDesc?.value?.push({ language: language?.isoCode, value: '' });
        }
      });
      setTimeout(() => {
        this.formData.controls.publishDate.value = publishDate;
        this.formData.controls.startDate.value = startDate;
        this.formData.controls.endDate.value = endDate;
        if (this.formData.controls.campaignType.value) {
          this.fetchStoresByCommunicationType({
            communicationType: this.formData.controls.campaignType.value,
            brandId: this.formData.controls.brand.value,
          });
        }
        if (this.permissions?.superAdminAccess) {
          this.formData.controls.company.validators = [Validators.required];
          this.formData.controls.user.validators = [Validators.required];
        }
        if (this.isMallAdmin()) {
          this.formData.controls.company.validators = [Validators.required];
        }
        if (this.formData.controls.socialContacts.value) {
          this.formData.controls.twitterDesc.validators = [Validators.required];
          this.formData.controls.instagramDesc.validators = [Validators.required];
          this.formData.controls.facebookDesc.validators = [Validators.required];
        } else {
          this.formData.controls.twitterDesc.validators = [];
          this.formData.controls.instagramDesc.validators = [];
          this.formData.controls.facebookDesc.validators = [];
        }
        if (!this.formData.controls.noImages.value) {
          this.formData.controls.images.validators = [Validators.required];
          this.formData.controls.attachments.validators = [];
        } else {
          this.formData.controls.images.validators = [];
          this.formData.controls.attachments.validators = [Validators.attachmentsRequired];
        }
        if (this.modalData?.endStr) {
          this.updateModalData(null);
        }
      }, 0);
      setTimeout(() => {
        this.canCheckDates = true;
        if (this.brandOptions && this.brandOptions?.length === 1) {
          this.updateBrand(this.brandOptions[0]);
        }
      }, 1000);

      setTimeout(() => {
        this.initialLoad = false;
      }, 3000);
    },
    gcd(a: number, b: number): number {
      return b === 0 ? a : this.gcd(b, a % b);
    },
    getAspectRatio(a: number, b: number): string {
      let ratio = '';
      let firstStep: string;
      if (a > b) {
        firstStep = a / b + '';
        if (firstStep.includes('.')) {
          ratio = firstStep.slice(0, firstStep.indexOf('.') + 2) + ':' + b / b;
        } else {
          ratio = a / this.gcd(a, b) + ':' + b / this.gcd(a, b);
        }
      }
      if (a < b) {
        firstStep = b / a + '';
        if (firstStep.includes('.')) {
          ratio = a / a + ':' + firstStep.slice(0, firstStep.indexOf('.') + 2);
        } else {
          ratio = a / this.gcd(b, a) + ':' + b / this.gcd(b, a);
        }
      }
      return ratio;
    },
    parseAspectRatio(a: string): { width: number; height: number } {
      let aspectRatio: any = {};
      const parse = a.split(':');
      aspectRatio.width = +parse[0];
      aspectRatio.height = +parse[1];
      return aspectRatio;
    },
    primaryImagesByAspectRatio() {
      if (this.uploadedPrimaryImages && this.uploadedPrimaryImages?.length > 0) {
        const uploadedImages = [...this.uploadedPrimaryImages];
        this.primaryUniqueRatioImages = uploadedImages.filter((image: any) => image?.generated === 1);
      }
    },
    secondaryImagesByAspectRatio() {
      if (this.uploadedSecondaryImages && this.uploadedSecondaryImages?.length > 0) {
        const uploadedImages = [...this.uploadedSecondaryImages];
        this.secondaryUniqueRatioImages = uploadedImages.filter((image: any) => image?.generated === 1);
      }
    },
    async uploadDocuments(files: Blob[]): Promise<void> {
      this.filesUploading = true;
      const MAX_ATTACHMENT_SIZE_IN_BYTES = 250_000_000;

      let singleAbove250mb = false;

      files.forEach((doc: any) => {
        if (doc?.size >= MAX_ATTACHMENT_SIZE_IN_BYTES) {
          singleAbove250mb = true;
        }
      });

      if (singleAbove250mb) {
        this.$notify({
          title: 'LARGE FILE SIZE DETECTED',
          text:
            'We recommend keeping documents below 50mb, and videos under 250mb. Please note that you will not be able to create/update a campaign with more that 2GB of total uploads including campaign images!',
          type: 'warn',
          duration: 11000,
        });
      }

      try {
        this.attachmentUploadPercentage = 0;
        this.filesUploading = true;
        let oneFilePercent = Math.round(100 / files.length);

        for (const file of files) {
          if (file.size >= 1_000_000) {
            const largeFilePercentSplit = Math.round((oneFilePercent / file.size) * 1_000_000);
            let largeFilePercentAdded = 0;
            let intervalId = window.setInterval(() => {
              if (largeFilePercentAdded + largeFilePercentSplit < oneFilePercent) {
                largeFilePercentAdded += largeFilePercentSplit;
                this.attachmentUploadPercentage += largeFilePercentSplit;
              }
            }, 1000);
            const url = await this.uploadImage(file);
            this.formData.controls.attachments.value.push({
              name: file?.name,
              url,
            });
            this.setChangesMade('DOCUMENTS UPLOADED');
            clearInterval(intervalId);
            this.attachmentUploadPercentage += oneFilePercent - largeFilePercentAdded;
          } else {
            const url = await this.uploadImage(file);
            this.formData.controls.attachments.value.push({
              name: file?.name,
              url,
            });
            this.setChangesMade('DOCUMENTS UPLOADED');
            this.attachmentUploadPercentage += oneFilePercent;
          }
        }
      } finally {
        this.filesUploading = false;
        this.validateControl('attachments');
        this.hasAttachmentChange = true;
      }
    },
    async deleteImage(image: any) {
      const languageId = this.activeLanguage?.languageId;
      this.setChangesMade('deleteImage');
      const formData: FormData = new FormData();
      formData.append('requirements', JSON.stringify(this.requiredImageSizes));
      formData.append(
        'processedImages',
        JSON.stringify(
          this.uploadedImages?.filter(
            (img: any) =>
              img?.generated === 0 &&
              img?.url !== image?.url &&
              img?.url !== image?.oversizedImageUrl &&
              img?.languageId === this.activeLanguage.languageId &&
              img?.marketingCampaignImageTypeId === image?.marketingCampaignImageTypeId,
          ),
        ),
      );
      formData.append('backgroundBlurry', `${this.backgroundBlurry}`);
      formData.append('imageTypeId', image?.marketingCampaignImageTypeId);
      formData.append('languageId', `${languageId}`);
      formData.append('imageToDelete', `${image?.url}`);
      this.loadingHandler({ type: image?.marketingCampaignImageTypeId === 1 ? 'primary' : 'secondary', overRide: true, overRideValue: true });

      if (!SocketUtility.getSocket().id) {
        const socketService = new SocketService();
        SocketUtility.setSocket(socketService.imageUploadSocket);
      }

      formData.append('socketId', SocketUtility.getSocket().id);

      const images = await new HttpService().generateImages(formData);
      let primaryImages: any = [];
      let secondaryImages: any = [];

      if (image?.marketingCampaignImageTypeId === 1) {
        primaryImages = [
          ...this.uploadedImages.filter(
            (image: any) => image?.marketingCampaignImageTypeId === 1 && image?.languageId !== this.activeLanguage?.languageId,
          ),
          ...images.data.data.filter(
            (image: any) => image?.marketingCampaignImageTypeId === 1 && image?.languageId === this.activeLanguage.languageId,
          ),
        ];
        secondaryImages = [...this.uploadedImages.filter((image: any) => image?.marketingCampaignImageTypeId === 2)];
      }

      if (image?.marketingCampaignImageTypeId === 2) {
        primaryImages = [...this.uploadedImages.filter((image: any) => image?.marketingCampaignImageTypeId === 1)];
        secondaryImages = [
          ...this.uploadedImages.filter(
            (image: any) => image?.marketingCampaignImageTypeId === 2 && image?.languageId !== this.activeLanguage?.languageId,
          ),
          ...images.data.data.filter(
            (image: any) => image?.marketingCampaignImageTypeId === 2 && image?.languageId === this.activeLanguage.languageId,
          ),
        ];
      }

      this.uploadedImages = [...primaryImages, ...secondaryImages];
      this.formData.controls.images.value = this.uploadedImages;
      this.loadingHandler({ type: image?.marketingCampaignImageTypeId === 1 ? 'primary' : 'secondary', overRide: true, overRideValue: false });
    },
    removeAttachment(attachment: any) {
      this.formData.controls.attachments.value = this.formData?.controls?.attachments?.value.filter((item: any) => item?.url !== attachment?.url);
      this.setChangesMade('REMOVE ATTACHMENT');
      this.hasAttachmentChange = true;
    },
    async downloadAttachment(attachment: any) {
      let blob = await fetch(attachment?.url, { mode: 'cors' }).then((r) => r.blob());
      let objectUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectUrl;
      a.setAttribute('download', attachment?.name);
      a.setAttribute('crossorigin', 'anonymous');
      a.setAttribute('target', '_blank');
      a.click();
      window.URL.revokeObjectURL(objectUrl);
    },
    loadingHandler({ type, overRide, overRideValue }: { type: string; overRide: boolean; overRideValue: boolean }): boolean {
      if (type === 'primary') {
        if (overRide) {
          return (this.primaryImagesUploading = overRideValue);
        }

        return (this.primaryImagesUploading = !this.primaryImagesUploading);
      }

      if (overRide) {
        return (this.secondaryImagesUploading = overRideValue);
      }

      return (this.secondaryImagesUploading = !this.secondaryImagesUploading);
    },
    async readFile(file: Blob): Promise<Jimp> {
      return new Promise((resolve) => {
        const fileReader: FileReader = new FileReader();

        fileReader.onloadend = async (event): Promise<any> => {
          const image: Jimp = await jimp.read(event?.target?.result as string);

          return resolve(image);
        };

        fileReader.readAsDataURL(file);
      });
    },
    resizeImage({ image, requiredImageSize }: { image: Jimp; requiredImageSize: any }): Jimp {
      const { height: requiredHeight, width: requiredWidth } = requiredImageSize;
      let imageIsBound = false;

      while (!imageIsBound) {
        let { height: imageHeight, width: imageWidth } = image.bitmap;

        const imageBoundries = {
          heightOutOfBound: imageHeight > requiredHeight,
          heightInBound: imageHeight <= requiredHeight,
          widthOutOfBound: imageWidth > requiredWidth,
          widthInBound: imageWidth <= requiredWidth,
        };

        if (imageBoundries.heightInBound && imageBoundries.widthInBound) {
          const distanceForWidth = requiredWidth - imageWidth;
          const distanceForHeight = requiredHeight - imageHeight;

          if (distanceForWidth > distanceForHeight) {
            image.resize(jimp.AUTO, requiredHeight);
          } else {
            image.resize(requiredWidth, jimp.AUTO);
          }

          imageIsBound = true;
        }

        if (imageBoundries.heightOutOfBound) {
          image.resize(jimp.AUTO, requiredHeight);
        }

        if (imageBoundries.widthOutOfBound) {
          image.resize(requiredWidth, jimp.AUTO);
        }
      }

      return image;
    },
    getOffsets({ image, backgroundImage }: { image: any; backgroundImage: any }): { xOffset: number; yOffset: number } {
      const { width: imageWidth, height: imageHeight } = image.bitmap;
      const { width: backgroundWidth, height: backgroundHeight } = backgroundImage.bitmap;

      const offsets = {
        xOffset: 0,
        yOffset: 0,
      };

      if (imageHeight === backgroundHeight) {
        offsets.xOffset = (backgroundWidth - imageWidth) / 2;
      } else {
        offsets.yOffset = (backgroundHeight - imageHeight) / 2;
      }

      return {
        xOffset: offsets.xOffset,
        yOffset: offsets.yOffset,
      };
    },
    getImageOrientation(height: number, width: number): 'landscape' | 'portrait' | 'square' {
      if (height === width) return 'square';
      if (height > width) return 'portrait';

      return 'landscape';
    },
    isMasterImage(height: number, width: number, type: 'primary' | 'secondary'): boolean {
      const orientation = this.getImageOrientation(height, width);

      if (orientation === 'square') {
        const masterSquareImage: IUploadedImage | null = this.uploadedImages
          .filter(
            (el: IUploadedImage) => el.isMaster && el.type === type && el.orientation === 'square' && el.language === this.activeLanguage?.isoCode,
          )
          .sort((a: IUploadedImage, b: IUploadedImage) => b.height * b.width - a.height * a.width)[0];
        const isPerfectImageSize = this.requiredImageSizes.some((el: IMallImageRequirement) => el.height === height && el.width === width);

        if (!masterSquareImage) {
          return true;
        }

        if (isPerfectImageSize) {
          return false;
        }

        if (height * width > masterSquareImage.height * masterSquareImage.width) {
          return true;
        }
      }

      if (orientation === 'portrait') {
        const masterPortraitImage: IUploadedImage | null = this.uploadedImages
          .filter(
            (el: IUploadedImage) => el.isMaster && el.type === type && el.orientation === 'portrait' && el.language === this.activeLanguage?.isoCode,
          )
          .sort((a: IUploadedImage, b: IUploadedImage) => b.height * b.width - a.height * a.width)[0];
        const isPerfectImageSize = this.requiredImageSizes.some((el: IMallImageRequirement) => el.height === height && el.width === width);

        if (!masterPortraitImage) {
          return true;
        }

        if (isPerfectImageSize) {
          return false;
        }

        if (height * width > masterPortraitImage.height * masterPortraitImage.width) {
          return true;
        }
      }

      if (orientation === 'landscape') {
        const masterLandscapeImage: IUploadedImage | null = this.uploadedImages
          .filter(
            (el: IUploadedImage) => el.isMaster && el.type === type && el.orientation === 'landscape' && el.language === this.activeLanguage?.isoCode,
          )
          .sort((a: IUploadedImage, b: IUploadedImage) => b.height * b.width - a.height * a.width)[0];
        const isPerfectImageSize = this.requiredImageSizes.some((el: IMallImageRequirement) => el.height === height && el.width === width);

        if (!masterLandscapeImage) {
          return true;
        }

        if (isPerfectImageSize) {
          return false;
        }

        if (height * width > masterLandscapeImage.height * masterLandscapeImage.width) {
          return true;
        }
      }

      return false;
    },
    // todo, not used? remove?
    async uploadMasterImage(event: { file: Blob; type: string }, mainType: 'primary' | 'secondary'): Promise<void> {
      this.setChangesMade('MASTER IMAGE');
      if (mainType === 'primary') {
        this.hasPrimaryImageChange = true;
      } else if (mainType === 'secondary') {
        this.hasSecondaryImageChange = true;
      }

      const image: Jimp = await this.readFile(event.file);
      const { height, width } = image.bitmap;

      const imageOrientation = this.getImageOrientation(height, width);
      const parsedRequirement = this.imageSizeRequirements[imageOrientation].split('x');

      if (parsedRequirement[0] !== 'Not Required') {
        const parsedRequirementWidth = parsedRequirement[0].split('w')[0];
        const parsedRequirementHeight = parsedRequirement[1].split('h')[0];
        const requiredOrientation = this.getImageOrientation(+parsedRequirementHeight, +parsedRequirementWidth);

        if (requiredOrientation !== imageOrientation || event.type !== imageOrientation) {
          this.loadingHandler({ type: mainType, overRide: true, overRideValue: false });
          return this.$notify({
            title: 'INVALID SIZE',
            text: 'Ensure the orientation of your image matches what you would like to upload.',
            type: 'error',
            duration: 5000,
          });
        }
      }

      const formData: FormData = new FormData();

      formData.append('images', event.file);

      formData.append('requirements', JSON.stringify(this.requiredImageSizes));
      formData.append('imageTypeId', mainType === 'primary' ? '1' : '2');
      formData.append('languageId', `${this.activeLanguage?.languageId}`);
      formData.append('processedImages', JSON.stringify(this.uploadedImages));

      if (!SocketUtility.getSocket().id) {
        const socketService = new SocketService();
        SocketUtility.setSocket(socketService.imageUploadSocket);
      }

      formData.append('socketId', SocketUtility.getSocket().id);

      this.loadingHandler({ type: mainType, overRide: true, overRideValue: true });

      const images = await new HttpService().generateImages(formData);

      this.loadingHandler({ type: mainType, overRide: true, overRideValue: false });
      this.uploadedImages = images.data.data;
      this.formData.controls.images.value = this.uploadedImages;
      this.imageResizeRequired = false;
    },
    // todo, not used? remove?
    async uploadSingleFile(image: Blob, type: string): Promise<void> {
      if (type === 'primary') {
        this.hasPrimaryImageChange = true;
      } else if (type === 'secondary') {
        this.hasSecondaryImageChange = true;
      }
      this.setChangesMade('uploadSingleFile');
      const formData: FormData = new FormData();

      formData.append('images', image);

      formData.append('requirements', JSON.stringify(this.requiredImageSizes));
      formData.append('imageTypeId', type === 'primary' ? '1' : '2');
      formData.append('languageId', `${this.activeLanguage?.languageId}`);
      formData.append('processedImages', JSON.stringify(this.uploadedImages));
      formData.append('isExactSize', 'true');

      if (!SocketUtility.getSocket().id) {
        const socketService = new SocketService();
        SocketUtility.setSocket(socketService.imageUploadSocket);
      }

      formData.append('socketId', SocketUtility.getSocket().id);

      this.loadingHandler({ type, overRide: true, overRideValue: true });

      const images = await new HttpService().generateImages(formData);

      this.uploadedImages = images.data.data;
      this.formData.controls.images.value = this.uploadedImages;

      this.loadingHandler({ type, overRide: true, overRideValue: false });
      this.imageResizeRequired = false;
    },

    async checkForOversized(image: any): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          let reader = new FileReader();
          const masterSquareImage = this.requiredImageSizes
            .filter((el: IMallImageRequirement) => el.height === el.width)
            .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
          const masterPortraitImage = this.requiredImageSizes
            .filter((el: IMallImageRequirement) => el.height > el.width)
            .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
          const masterLandscapeImage = this.requiredImageSizes
            .filter((el: IMallImageRequirement) => el.height < el.width)
            .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.width - a.width)[0];

          //Read the contents of Image File.
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            //Initiate the JavaScript Image object.
            let i: any = new Image();

            //Set the Base64 string return from FileReader as source.
            i.src = reader?.result;

            const trickery = (height: number, width: number) => {
              if (height > width) {
                if (height > masterPortraitImage?.height && width > masterPortraitImage?.width) {
                  this.oversizedImage = true;
                }
              }
              if (height < width) {
                if (height > masterLandscapeImage?.height && width > masterLandscapeImage?.width) {
                  this.oversizedImage = true;
                }
              }
              if (height === width) {
                if (height > masterSquareImage?.height && width > masterSquareImage?.width) {
                  this.oversizedImage = true;
                }
              }
              resolve(true);
            };

            //Validate the File Height and Width.
            i.onload = function() {
              trickery(this?.height, this?.width);
            };
          };
        } catch (error) {
          reject(error);
        }
      });
    },
    async primaryFileChange(images: FileList): Promise<void> {
      this.hasPrimaryImageChange = true;
      this.setChangesMade('primaryFileChange');

      if (this.languageSpecificImagesRequired) {
        await this.generateImagesHelper(images, [this.activeLanguage?.languageId], '1');
      } else {
        await this.generateImagesHelper(
          images,
          this.languages.map((el: ILanguage) => el.languageId),
          '1',
        );
      }
    },
    async generateImagesHelper(images: any, languageIds: number[], imageTypeId: string): Promise<void> {
      // let singleAbove10mb = false;
      // for (const image of images) {
      //   await this.checkForOversized(image);
      //   if (image?.size >= 10000000) {
      //     singleAbove10mb = true;
      //   }
      // }
      //
      // if (singleAbove10mb) {
      //   this.$notify({
      //     title: 'LARGE FILE SIZE DETECTED',
      //     text: 'We recommend keeping any uploaded image below 10mb, and for the best performance below 1mb!',
      //     type: 'warn',
      //     duration: 11000,
      //   });
      // }
      //
      // if (this.oversizedImage) {
      //   this.$notify({
      //     title: 'LARGE IMAGE SIZE DETECTED',
      //     text:
      //       'While you can continue to upload these images "as is" reducing the file size of these and future images will increase the responsiveness and load times. If you keep these images, please wait patiently as it could take several minutes to generate your required images. Please see our recommended image sizes for your campaign OR if you need assistance with resizing, please email your images to team@engagementagents.com and we will resize for you. Alternatively, if you have any questions, please click here to chat live OR contact your Account Manager. ',
      //     type: 'warn',
      //     duration: 11000,
      //   });
      // }

      const formData: FormData = new FormData();

      for (let imageNumber = 0; imageNumber < images.length; imageNumber++) {
        formData.append('images', images[imageNumber]);
      }

      formData.append('requirements', JSON.stringify(this.requiredImageSizes));
      formData.append('imageTypeId', imageTypeId);
      if (languageIds.length === 1) {
        formData.append('languageId', `${languageIds[0]}`);
        formData.append(
          'processedImages',
          JSON.stringify(
            this.uploadedImages.filter((image: any) => image?.marketingCampaignImageTypeId === +imageTypeId && image?.languageId === languageIds[0]),
          ),
        );
      } else {
        formData.append('languageIds', JSON.stringify(languageIds));
        formData.append(
          'processedImages',
          JSON.stringify(this.uploadedImages.filter((image: any) => image?.marketingCampaignImageTypeId === +imageTypeId)),
        );
      }
      formData.append('backgroundBlurry', `${this.backgroundBlurry}`);

      if (!SocketUtility.getSocket().id) {
        const socketService = new SocketService();
        SocketUtility.setSocket(socketService.imageUploadSocket);
      }

      formData.append('socketId', SocketUtility.getSocket().id);

      if (imageTypeId === '1') {
        this.loadingHandler({ type: 'primary', overRide: true, overRideValue: true });
      } else if (imageTypeId === '2') {
        this.loadingHandler({ type: 'secondary', overRide: true, overRideValue: true });
      }

      try {
        const images = await new HttpService().generateImages(formData);
        let primaryImages: IUploadedImage[] = [];
        let secondaryImages: IUploadedImage[] = [];
        if (imageTypeId === '1') {
          if (languageIds.length === 1) {
            primaryImages = [
              ...this.uploadedImages.filter((image: any) => image?.marketingCampaignImageTypeId === 1 && image?.languageId !== languageIds[0]),
              ...images.data.data.filter((image: any) => image?.marketingCampaignImageTypeId === 1 && image?.languageId === languageIds[0]),
            ];
          } else {
            primaryImages = [...images.data.data.filter((image: any) => image?.marketingCampaignImageTypeId === 1)];
          }
          secondaryImages = this.uploadedImages.filter((image: any) => image?.marketingCampaignImageTypeId === 2);
        } else if (imageTypeId === '2') {
          primaryImages = this.uploadedImages.filter((image: any) => image?.marketingCampaignImageTypeId === 1);
          if (languageIds.length === 1) {
            secondaryImages = [
              ...this.uploadedImages.filter((image: any) => image?.marketingCampaignImageTypeId === 2 && image?.languageId !== languageIds[0]),
              ...images.data.data.filter((image: any) => image?.marketingCampaignImageTypeId === 2 && image?.languageId === languageIds[0]),
            ];
          } else {
            secondaryImages = [...images.data.data.filter((image: any) => image?.marketingCampaignImageTypeId === 2)];
          }
        }
        this.uploadedImages = [...primaryImages, ...secondaryImages];
        this.formData.controls.images.value = this.uploadedImages;
      } catch (err) {
        if (this.oversizedImage) {
          this.$notify({
            title: 'ERROR: IMAGE SIZES TOO LARGE',
            text:
              'The uploaded images are too large, and the upload/generation process has failed. Please see our recommended image sizes for your campaign, resize them and try again OR if you need assistance with resizing, please email your images to team@engagementagents.com and we will resize for you.',
            type: 'error',
            duration: 11000,
          });
        } else {
          this.$notify({
            title: 'ERROR',
            text:
              'An unknown error has occurred and your images failed to upload & generate. Please check your file sizes and try again, or contact us at team@engagementagents.com for support.',
            type: 'error',
            duration: 11000,
          });
        }
      }

      if (imageTypeId === '1') {
        this.loadingHandler({ type: 'primary', overRide: true, overRideValue: false });
      } else if (imageTypeId === '2') {
        this.loadingHandler({ type: 'secondary', overRide: true, overRideValue: false });
      }
      this.oversizedImage = false;
      this.imageResizeRequired = false;
    },
    async secondaryFileChange(images: Blob[]): Promise<void> {
      this.hasSecondaryImageChange = true;
      this.setChangesMade('SECONDARY IMAGE');

      if (this.languageSpecificImagesRequired) {
        await this.generateImagesHelper(images, [this.activeLanguage?.languageId], '2');
      } else {
        await this.generateImagesHelper(
          images,
          this.languages.map((el: ILanguage) => el.languageId),
          '2',
        );
      }
    },
    languageParser(value: string) {
      const parsedData: any = [];
      const languages = value?.split('::: ');
      languages.forEach((language) => {
        parsedData.push({
          [language.substring(0, language.indexOf('='))]: language.substring(language.indexOf('=') + 1, language.length),
        });
      });
      return parsedData;
    },
    languageSpecificImages(language: ILanguage) {
      let primary;
      let secondary;
      if (this.uploadedImages && this.uploadedImages.length > 0 && this.uploadedImages[0].languageId) {
        primary = this.primaryUniqueRatioImages.filter((image: any) => image.languageId === language.languageId);
        secondary = this.secondaryUniqueRatioImages.filter((image: any) => image.languageId === language.languageId);
      } else {
        primary = this.primaryUniqueRatioImages.filter((image: any) => image.language === language.isoCode);
        secondary = this.secondaryUniqueRatioImages.filter((image: any) => image.language === language.isoCode);
      }
      const images = [...primary, ...secondary];
      return images;
    },
    getLanguageSpecificValue(item: any, isoCode: string) {
      let value: any = '';
      item?.forEach((i: any) => {
        if (i?.language === isoCode) {
          value = i?.value;
        }
      });
      return value;
    },
    setLanguageSpecificValue(item: any, value: any, isoCode: string) {
      item?.forEach((i: any) => {
        if (i?.language === isoCode) {
          i.value = value;
        }
      });
    },
    languageSelected(value: ILanguage, fromForm = false) {
      this.activeLanguage = value;
      if (fromForm) {
        this.setChangesMade('LANGUAGE SELECTED');
      }
      if (this.activeLanguage?.required && this.attemptedSubmit) {
        this.validateControl('headline');
        this.validateControl('description');
        this.validateControl('twitterDesc');
        this.validateControl('instagramDesc');
        this.validateControl('facebookDesc');
      } else {
        this.formData.controls.headline.errors = [];
        this.formData.controls.description.errors = [];
        this.formData.controls.twitterDesc.errors = [];
        this.formData.controls.instagramDesc.errors = [];
        this.formData.controls.facebookDesc.errors = [];
      }
    },
    checkLanguageErrors() {
      this.languages.forEach((language: ILanguage) => {
        let hasErrors = false;
        if (language.required) {
          this.formData.controls.headline.value.forEach((el: any) => {
            if (el.language === language.isoCode && el.value === '') {
              hasErrors = true;
            }
          });
          this.formData.controls.description.value.forEach((el: any) => {
            if (el.language === language.isoCode && el.value === '') {
              hasErrors = true;
            }
          });
          if (this.formData.controls.socialContacts.value === true) {
            this.formData.controls.twitterDesc.value.forEach((el: any) => {
              if (el.language === language.isoCode && el.value === '') {
                hasErrors = true;
              }
            });
            this.formData.controls.instagramDesc.value.forEach((el: any) => {
              if (el.language === language.isoCode && el.value === '') {
                hasErrors = true;
              }
            });
            this.formData.controls.facebookDesc.value.forEach((el: any) => {
              if (el.language === language.isoCode && el.value === '') {
                hasErrors = true;
              }
            });
          }
        }
        language.hasErrors = hasErrors;
      });
    },
    languageButtonStyle(language: ILanguage) {
      let style = '';
      if (language === this.activeLanguage) {
        style = language?.hasErrors
          ? 'color: hsl(0, 0%, 100%); background-color: hsl(138, 50%, 61%); border-color: red;'
          : 'color: hsl(0, 0%, 100%); background-color: hsl(138, 50%, 61%);';
      } else {
        style = language?.hasErrors ? 'color: hsl(0, 0%, 44%); border-color: red;' : 'color: hsl(0, 0%, 44%)';
      }
      return style;
    },
    daysBetween(d1: any, d2: any): number {
      let diff = Math.abs(d1.getTime() - d2.getTime());
      return diff / (1000 * 60 * 60 * 24);
    },
    isToday(date: any): boolean {
      const today = new Date();
      return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    },
    extractDataFromLocations(locations: IStore[]) {
      this.selectedLocationIds = [];
      if (locations?.length > 0) {
        const languageExtraction: any = [...new Set(locations.map((el: IStore) => el?.mall?.languages))];
        const mergedLanguages: ILanguage[] = [].concat.apply([], languageExtraction);

        const extractedRequirements: any = locations.map((el: IStore) => el?.mall?.requirements);
        const mergedRequirements: IMallImageRequirement[] = [].concat.apply([], extractedRequirements);

        const extractedChannels: any = locations.map((el: IStore) => el?.mall?.channels);
        const mergedChannels: any[] = [].concat.apply([], extractedChannels);
        const uniqueChannels = [...new Set(mergedChannels.map((el: any) => el?.channelId))];
        this.channelOptions = this.channels.filter((channel: IDropdownOption) => uniqueChannels.includes(channel?.value));

        let uniqueLanguages: any[] = [];
        const map = new Map();
        for (const item of mergedLanguages) {
          if (!map.has(item.isoCode)) {
            map.set(item.isoCode, true);
            item.required = item?.Mall_Language?.languageTypeId === 1 || item?.Mall_Language?.languageTypeId === 2;
            uniqueLanguages.push(item);
          } else {
            if (item?.Mall_Language?.languageTypeId === 1 || item?.Mall_Language?.languageTypeId === 2) {
              uniqueLanguages = uniqueLanguages.filter((el: any) => item?.isoCode !== el?.isoCode);
              uniqueLanguages.push({ ...item, required: true });
            }
          }
        }

        const requirements: any = mergedRequirements
          .map((el: IMallImageRequirement) => ({
            ...el,
            dimensions: `${el.width}x${el.height}`,
            aspectRatio: `${this.getAspectRatio(el.width, el.height)}`,
          }))
          .filter((thing, index, self) => index === self.findIndex((t) => t.dimensions === thing.dimensions));

        this.requiredImageSizes = requirements;

        this.languages = uniqueLanguages;
        this.languages?.forEach((language: ILanguage) => {
          this.activeLanguage = language;

          let languageExists = false;
          this.formData?.controls?.headline?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.headline?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.description?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.description?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.notes?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.notes?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.facebookDesc?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.facebookDesc?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.twitterDesc?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.twitterDesc?.value?.push({ language: language?.isoCode, value: '' });
          }

          languageExists = false;
          this.formData?.controls?.instagramDesc?.value?.forEach((lang: any) => {
            if (language?.isoCode === lang?.language) {
              languageExists = true;
            }
          });
          if (!languageExists) {
            this.formData?.controls?.instagramDesc?.value?.push({ language: language?.isoCode, value: '' });
          }
        });

        const englishIndex = this.languages.findIndex((item) => item.isoCode === 'en');
        if (englishIndex > -1) {
          this.activeLanguage = this.languages[englishIndex];
        }

        if (this.attemptedSubmit) {
          this.checkLanguageErrors();
        }

        locations.forEach((location: IStore) => {
          this.selectedLocationIds.push(location.storeId);
        });
      } else {
        this.languages = [
          {
            languageId: 37,
            description: 'English',
            isoCode: 'en',
            updatedAt: '',
            createdAt: '',
            required: true,
          },
        ];
        this.activeLanguage = {
          languageId: 37,
          description: 'English',
          isoCode: 'en',
          updatedAt: '',
          createdAt: '',
          required: true,
        };
      }
    },
    selectEnglishAsDefaultLanguage() {
      const englishIndex = this.languages.findIndex((item) => item.isoCode === 'en');
      if (englishIndex > -1) {
        this.languageSelected(this.languages[englishIndex]);
      } else {
        this.languageSelected(this.languages[0]);
      }
    },
    addAllChannels(): void {
      this.campaignChannelValues = this.channelOptions;
    },
    removeChannelsNotInOptions(): void {
      this.campaignChannelValues = this.campaignChannelValues.filter((channel: IDropdownOption) =>
        this.channelOptions.some((option: IDropdownOption) => option.value === channel.value),
      );
    },
    locationAdded(value: any): void {
      this.formData.controls.locations.value.push(value);
      this.formData.controls.locations.value.sort((a: IStore, b: IStore) =>
        a.storeNumber.toString().localeCompare(b.storeNumber.toString(), undefined, { numeric: true }),
      );
      setTimeout(() => {
        if (!this.channelsTouched) {
          this.addAllChannels();
        }
      }, 1000);
      this.setChangesMade('LOCATION ADDED');
    },
    locationRemoved(value: any): void {
      const index = this.formData.controls.locations.value.findIndex((item: any) => item.storeId === value.storeId);
      this.formData.controls.locations.value.splice(index, 1);
      setTimeout(() => {
        this.removeChannelsNotInOptions();
        if (!this.channelsTouched) {
          this.addAllChannels();
        }
      }, 1000);
      this.setChangesMade('LOCATION REMOVED');
    },
    addAllLocations(): void {
      this.filteredLocationsAvailable.forEach((location: any) => {
        this.locationAdded(location);
      });
    },
    removeAllLocations(): void {
      this.filteredLocationsSelected.forEach((location: any) => {
        this.locationRemoved(location);
      });
    },
    async updateCompany(value: IDropdownOption, clicked?: boolean) {
      this.companyValue = value;
      this.formData.controls.company.value = this.companyValue.value;
      await this.loadBrands({ companyId: value.value });
      await this.loadUsers({ companyId: value.value });
      if (clicked) {
        this.setChangesMade('UPDATE COMPANY');
        this.brandValue = null;
        this.formData.controls.brand.value = '';
        this.userValue = null;
        this.formData.controls.user.value = '';
        this.formData.controls.locations.value = [];
        this.selectedLocationIds = [];
        this.clearStores();
        this.clearBrandTags();
        this.validateControl('company');
        setTimeout(() => {
          if (this.brandOptions && this.brandOptions?.length === 1) {
            this.updateBrand(this.brandOptions[0]);
          }
        }, 3000);
      }
    },
    clearCompany(value: any) {
      if (value === '') {
        this.companyValue = null;
        this.formData.controls.company.value = null;
        this.setChangesMade('CLEAR COMPANY');
        this.validateControl('company');
      }
    },
    updateUser(value: IDropdownOption, fromForm = false) {
      this.userValue = value;
      this.formData.controls.user.value = this.userValue.value;
      if (fromForm) {
        this.setChangesMade('USER');
        this.validateControl('user');
      }
    },
    async updateBrand(value: IDropdownOption, fromForm = false) {
      if (value?.value) {
        const brand = await this.fetchBrandById(value.value);
        if (fromForm) {
          this.backgroundBlurry = brand?.backgroundBlurry ?? true;
        }
      }
      if (fromForm) {
        this.setChangesMade('BRAND');
        this.setPreviewable();
        this.validateControl('brand');
        this.formData.controls.locations.value = [];
        this.selectedLocationIds = [];
        if (value?.value && this.formData.controls.campaignType.value) {
          this.fetchStoresByCommunicationType({ communicationType: this.formData.controls.campaignType.value, brandId: value?.value });
        }
      }
      this.brandValue = value;
      this.formData.controls.brand.value = this.brandValue?.value;
      this.loadBrandTags({ brandIds: this.formData.controls.brand.value });
    },
    updateType(value: IDropdownOption) {
      this.typeValue = value;
      this.formData.controls.campaignType.value = this.typeValue.value;

      if (this.formData.controls.brand.value && this.formData.controls.campaignType.value) {
        this.fetchStoresByCommunicationType({
          communicationType: this.formData.controls.campaignType.value,
          brandId: this.formData.controls.brand.value,
        });
      }

      this.setChangesMade('TYPE');
      this.validateControl('campaignType');
      this.formData.controls.locations.value = [];
      this.selectedLocationIds = [];
    },
    updateTimezone(value: IDropdownOption) {
      this.timezone = value;
      this.formData.controls.timezone.value = this.timezone.value;
      this.selectedTimezone = this.timezones.find((timezone: any) => timezone.value === value.value)?.value;
      this.formData.controls.startDate.value = null;
      this.formData.controls.endDate.value = null;
      this.formData.controls.publishDate.value = null;
    },
    clearTimezone() {
      if (this.timezone) {
        this.savedTimezone = this.timezone;
        this.timezone = null;
      }
    },
    fixTimezone() {
      setTimeout(() => {
        if (!this.timezone) {
          this.timezone = this.savedTimezone;
        }
      }, 100);
    },
    tagsUpdated(value: any): void {
      this.tagValues = value;
    },
    marketingCampaignTagsUpdated(value: any): void {
      this.marketingCampaignTagValues = value;
      this.setChangesMade();
    },
    updateTagComboType(value: IDropdownOption) {
      this.tagComboType = value;
      this.setChangesMade('TAG COMBO TYPE');
      this.validateControl('campaignType');
    },
    setChangesMade(type?: string) {
      this.changesMade = true;
    },
    setPreviewable() {
      this.previewable = true;
      if (!this.modalData) {
        this.previewOpen = true;
      }
    },
    updateNotes(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.notes.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade('NOTES');
      this.setPreviewable();
    },
    updateHeadline(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.headline.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade('HEADLINE');
      this.setPreviewable();
      this.validateControl('headline');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateDescription(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.description.value, value, this.activeLanguage?.isoCode);
      if (this.formData?.controls?.socialContacts?.value && this.formData?.controls?.sameDescription?.value) {
        this.updateTwitterDesc(value);
        this.updateInstagramDesc(value);
        this.updateFacebookDesc(value);
      }
      this.setChangesMade('DESCRIPTION');
      this.setPreviewable();
      this.validateControl('description');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateTwitterDesc(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.twitterDesc.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade('TWITTER');
      this.setPreviewable();
      this.validateControl('twitterDesc');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateInstagramDesc(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.instagramDesc.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade('INSTAGRAM');
      this.setPreviewable();
      this.validateControl('instagramDesc');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    updateFacebookDesc(value: string) {
      this.setLanguageSpecificValue(this.formData.controls.facebookDesc.value, value, this.activeLanguage?.isoCode);
      this.setChangesMade('FACEBOOK');
      this.setPreviewable();
      this.validateControl('facebookDesc');
      if (this.attemptedSubmit) {
        this.checkLanguageErrors();
      }
    },
    toggleStoreContacts() {
      this.formData.controls.storeContacts.value = !this.formData.controls.storeContacts.value;
      this.setChangesMade('STORE CONTACTS');
    },
    toggleSocialContacts() {
      this.formData.controls.socialContacts.value = !this.formData.controls.socialContacts.value;
      if (!this.formData.controls.socialContacts.value) {
        this.formData.controls.sameDescription.value = false;
        this.sameDescription = false;
        this.showSameDescription = false;
        setTimeout(() => {
          this.showSameDescription = true;
        }, 0);
        // reset multi language social fields
        const twitterDesc: any = [];
        const instagramDesc: any = [];
        const facebookDesc: any = [];
        this.languages?.forEach((language) => {
          twitterDesc.push({
            language: language?.isoCode,
            value: '',
          });
          instagramDesc.push({
            language: language?.isoCode,
            value: '',
          });
          facebookDesc.push({
            language: language?.isoCode,
            value: '',
          });
        });
        this.formData.controls.twitterDesc.value = twitterDesc;
        this.formData.controls.instagramDesc.value = instagramDesc;
        this.formData.controls.facebookDesc.value = facebookDesc;
      }
      this.setChangesMade('SOCIAL CONTACTS');
      if (this.formData.controls.socialContacts.value) {
        if (this.formData.controls.sameDescription.value) {
          this.formData.controls.twitterDesc.value = this.formData.controls.description.value;
          this.formData.controls.instagramDesc.value = this.formData.controls.description.value;
          this.formData.controls.facebookDesc.value = this.formData.controls.description.value;
        }
        this.formData.controls.twitterDesc.validators = [Validators.required];
        this.formData.controls.instagramDesc.validators = [Validators.required];
        this.formData.controls.facebookDesc.validators = [Validators.required];
      } else {
        this.formData.controls.twitterDesc.validators = [];
        this.formData.controls.instagramDesc.validators = [];
        this.formData.controls.facebookDesc.validators = [];
      }
    },
    toggleSameDescription() {
      this.formData.controls.sameDescription.value = !this.formData.controls.sameDescription.value;
      if (this.formData.controls.sameDescription.value) {
        this.formData.controls.twitterDesc.value = this.formData.controls.description.value;
        this.formData.controls.instagramDesc.value = this.formData.controls.description.value;
        this.formData.controls.facebookDesc.value = this.formData.controls.description.value;
      } else {
        // reset multi language social fields
        const twitterDesc: any = [];
        const instagramDesc: any = [];
        const facebookDesc: any = [];
        this.languages?.forEach((language) => {
          twitterDesc.push({
            language: language?.isoCode,
            value: '',
          });
          instagramDesc.push({
            language: language?.isoCode,
            value: '',
          });
          facebookDesc.push({
            language: language?.isoCode,
            value: '',
          });
        });
        this.formData.controls.twitterDesc.value = twitterDesc;
        this.formData.controls.instagramDesc.value = instagramDesc;
        this.formData.controls.facebookDesc.value = facebookDesc;
      }
      this.setChangesMade('SAME DESCRIPTION');
    },
    toggleGoogleBusiness() {
      this.googleBusiness = !this.googleBusiness;
      this.formData.controls.googleBusiness.value = !this.formData.controls.googleBusiness.value;
      this.setChangesMade('GOOGLE BUSINESS');
    },
    toggleAppleBusiness() {
      this.appleBusiness = !this.appleBusiness;
      this.formData.controls.appleBusiness.value = !this.formData.controls.appleBusiness.value;
      this.setChangesMade('APPLE BUSINESS');
    },
    toggleNoImages() {
      this.noImages = !this.noImages;
      this.formData.controls.noImages.value = !this.formData.controls.noImages.value;
      if (!this.formData.controls.noImages.value) {
        this.formData.controls.images.validators = [Validators.required];
        this.formData.controls.attachments.validators = [];
      } else {
        this.formData.controls.images.validators = [];
        this.formData.controls.attachments.validators = [Validators.attachmentsRequired];
      }
      this.setChangesMade('NO IMAGES');
    },
    toggleLanguageSpecificImagesRequired() {
      this.languageSpecificImagesRequired = !this.languageSpecificImagesRequired;
      this.formData.controls.languageSpecificImagesRequired.value = !this.formData.controls.languageSpecificImagesRequired.value;
      if (!this.languageSpecificImagesRequired && this.formData.controls.images.value.length > 0) {
        this.imageResizeRequired = true;
      }
      this.setChangesMade('languageSpecificImagesRequired');
    },
    updateSearchLocationsFilter(value: string) {
      this.searchFilter = value;
    },
    validateDateControl(controlName: string): void {
      if (controlName === 'publishDate') {
        this.publishDateUpdatedCount++;
        if (
          !this.initialLoad &&
          (this.publishDateUpdatedCount > 1 || !this.modalData?.campaignId || !this.selectedMarketingCampaign?.publishByDate)
        ) {
          this.setChangesMade('PUBLISH DATE');
          this.setPreviewable();
        }
      }
      if (controlName === 'startDate') {
        this.startDateUpdatedCount++;
        if (!this.initialLoad && (this.startDateUpdatedCount > 1 || !this.modalData?.campaignId || !this.selectedMarketingCampaign?.startDate)) {
          this.setChangesMade('START DATE');
          this.setPreviewable();
        }
      }
      if (controlName === 'endDate') {
        this.endDateUpdatedCount++;
        if (!this.initialLoad && (this.endDateUpdatedCount > 1 || !this.modalData?.campaignId || !this.selectedMarketingCampaign?.endDate)) {
          this.setChangesMade('END DATE');
          this.setPreviewable();
        }
      }
      setTimeout(() => {
        this.formData.controls[controlName].validate();
      }, 0);
    },
    validateRequiredImages(): boolean {
      if (this.noImages) {
        return true;
      }

      const masterSquareImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height === el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
      const masterPortraitImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height > el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.height - a.height)[0];
      const masterLandscapeImage = this.requiredImageSizes
        .filter((el: IMallImageRequirement) => el.height < el.width)
        .sort((a: IMallImageRequirement, b: IMallImageRequirement) => b.width - a.width)[0];

      const requiredLanguages = this.languages?.filter((language: ILanguage) => language?.required);
      for (const language of requiredLanguages) {
        const languageImages = this.formData?.controls?.images?.value?.filter((image: any) => image?.languageId === language?.languageId);

        if (languageImages?.length === 0) {
          return false;
        }

        let squareExists = languageImages.some(
          (image: any) => image?.orientation === 'square' && image?.width >= masterSquareImage?.width && image?.height >= masterSquareImage?.height,
        );
        if (this.primaryImageStatus?.square?.required && !squareExists) {
          return false;
        }

        let landscapeExists = languageImages.some(
          (image: any) =>
            image?.orientation === 'landscape' && image?.width >= masterLandscapeImage?.width && image?.height >= masterLandscapeImage?.height,
        );
        if (this.primaryImageStatus?.landscape?.required && !landscapeExists) {
          return false;
        }

        let portraitExists = languageImages.some(
          (image: any) =>
            image?.orientation === 'portrait' && image?.width >= masterPortraitImage?.width && image?.height >= masterPortraitImage?.height,
        );
        if (this.primaryImageStatus?.portrait?.required && !portraitExists) {
          return false;
        }
      }
      return true;
    },
    validateControl(controlName: string): void {
      this.setChangesMade('VALIDATE CONTROL: ' + controlName);
      if (controlName === 'headline') {
        if (this.activeLanguage.required) {
          this.formData.controls.headline.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.headline.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.headline.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.headline.errors = [];
        }
      } else if (controlName === 'description') {
        if (this.activeLanguage.required) {
          this.formData.controls.description.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.description.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.description.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.description.errors = [];
        }
      } else if (controlName === 'twitterDesc') {
        if (this.activeLanguage.required && this.formData.controls.socialContacts.value) {
          this.formData.controls.twitterDesc.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.twitterDesc.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.twitterDesc.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.twitterDesc.errors = [];
        }
      } else if (controlName === 'instagramDesc') {
        if (this.activeLanguage.required && this.formData.controls.socialContacts.value) {
          this.formData.controls.instagramDesc.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.instagramDesc.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.instagramDesc.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.instagramDesc.errors = [];
        }
      } else if (controlName === 'facebookDesc') {
        if (this.activeLanguage.required && this.formData.controls.socialContacts.value) {
          this.formData.controls.facebookDesc.value.forEach((value: any) => {
            if (value.language === this.activeLanguage.isoCode) {
              if (value.value === '') {
                this.formData.controls.facebookDesc.errors = {
                  message: 'Field is required',
                  status: 'error',
                };
              } else {
                this.formData.controls.facebookDesc.errors = [];
              }
            }
          });
        } else {
          this.formData.controls.facebookDesc.errors = [];
        }
      } else {
        setTimeout(() => {
          this.formData.controls[controlName].validate();
        }, 0);
      }
    },
    formatDate(date: string): string {
      return dateFormatter(date);
    },
    closeRequirements(): void {
      this.requirementsOpen = false;
    },
    openRequirements(): void {
      this.requirementsOpen = true;
    },
    copyRequirements(): void {
      let requirements = 'Location,Image Size Requirements\n';
      this.formData.controls.locations.value.forEach((store: any) => {
        requirements += `${store?.mall?.description}${store.storeIdentifier ? ` (${store.storeIdentifier})` : ''}`;
        if (store?.mall?.requirements?.length === 0) {
          requirements += 'No Requirements\n';
        } else {
          requirements += ',';
          store?.mall?.requirements?.forEach((requirement: any, index: any) => {
            requirements += requirement?.width + 'x' + requirement?.height + (index < store?.mall?.requirements?.length - 1 ? ',' : '\n');
          });
        }
      });
      const blob = new Blob([requirements], { type: 'text/csv;charset=utf-8;' });
      let link = document.createElement('a');
      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'image_requirements.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.$notify({
        title: 'SUCCESS',
        text: `Requirements CSV Downloaded!`,
        type: 'info',
        duration: 5000,
      });
    },
    closePrompt(): void {
      this.promptOpen = false;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
      this.selectedReason = '';
      this.reasonDescription = '';
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      const payload = this.campaign;
      payload.statusId = 5;
      this.saving = true;
      try {
        await this.updateMarketingCampaign({ body: payload, campaignId: this.campaign?.marketingCampaignId });
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Marketing Campaign deleted successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.refreshCalendar();
        await this.fetchMarketingCampaigns();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.saving = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to delete Marketing Campaign!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    declinePrompt(): void {
      this.openPrompt('decline');
    },
    async decline(): Promise<void> {
      const payload = this.campaign;
      payload.reasonForExpire = `${this.selectedReason}${this.reasonDescription ? ' - ' + this.reasonDescription : ''}`;
      payload.expiredByUserId = this.user?.userId;
      payload.approvalRequested = 0;
      payload.statusId = Status['Declined'];
      this.saving = true;
      try {
        await this.updateMarketingCampaign({ body: payload, campaignId: this.campaign?.marketingCampaignId });
        await this.saveSystemEvent({
          systemEventTypeId: SystemEventType.Declined,
          userId: this.user?.userId,
          communicationTypeId: 1,
          communicationId: this.campaign?.marketingCampaignId,
        });
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Marketing Campaign declined successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.refreshCalendar();
        await this.fetchMarketingCampaigns();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.saving = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to decline Marketing Campaign!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    expirePrompt(): void {
      this.openPrompt('expire');
    },
    async expire(): Promise<void> {
      const payload = this.campaign;
      payload.statusId = Status['Manually Expired'];
      payload.reasonForExpire = `${this.selectedReason}${this.reasonDescription ? ' - ' + this.reasonDescription : ''}`;
      payload.expiredByUserId = this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '';
      this.saving = true;
      try {
        await this.updateMarketingCampaign({ body: payload, campaignId: this.campaign?.marketingCampaignId });
        await this.saveSystemEvent({
          systemEventTypeId: SystemEventType.Expired,
          userId: this.user?.userId,
          communicationTypeId: 1,
          communicationId: this.campaign?.marketingCampaignId,
        });
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Marketing Campaign expired successfully!`,
          type: 'success',
          duration: 5000,
        });
        this.refreshCalendar();
        await this.fetchMarketingCampaigns();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.saving = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to expire Marketing Campaign!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    async save(submit?: boolean): Promise<void> {
      if (this.formData?.controls?.brand?.value) {
        let upcoming = false;

        const publishByDate = this.formData?.controls?.publishDate?.value ?? '';

        const fromDate = this.formData?.controls?.startDate?.value ?? '';
        if (this.formData?.controls?.startDate?.value) {
          let fd = new Date(this.formData?.controls?.startDate?.value);
          upcoming = new Date().setHours(23, 59, 59, 999) < fd.setHours(0, 0, 0, 0);
        }

        const toDate = this.formData?.controls?.endDate?.value ?? '';

        let { notes, headline, longDescription, facebookDesc, twitterDesc, instagramDesc } = this.extractMultiLangFields();

        if (this.formData?.controls?.sameDescription?.value && this.formData?.controls?.socialContacts) {
          instagramDesc = longDescription;
          facebookDesc = longDescription;
          twitterDesc = longDescription;
        }
        const payload = {
          marketingCampaignTypeId: this.formData?.controls?.campaignType?.value,
          description: this.formData?.controls?.campaignName?.value,
          facebookDescription: facebookDesc,
          twitterDescription: twitterDesc,
          instagramDescription: instagramDesc,
          notes: notes,
          companyId: this.formData?.controls?.company?.value,
          brandId: this.formData?.controls?.brand?.value,
          createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '',
          headline: headline,
          longDescription: longDescription,
          publishByDate: publishByDate,
          fromDate: fromDate,
          toDate: toDate,
          sameDescription: this.formData?.controls?.sameDescription?.value,
          // sendToSocialContacts: this.formData?.controls?.socialContacts?.value,
          sendToStoreContacts: this.formData?.controls?.storeContacts?.value,
          statusId: submit === true ? (upcoming ? 3 : 2) : 1,
          storeIds: this.selectedLocationIds,
          images: !this.formData?.controls?.noImages?.value ? this.formData?.controls?.images?.value : null,
          attachments: this.formData?.controls?.attachments?.value,
          hasPrimaryImageChange: this.hasPrimaryImageChange,
          hasSecondaryImageChange: this.hasSecondaryImageChange,
          hasAttachmentChange: this.hasAttachmentChange,
          backgroundBlurry: this.backgroundBlurry,
          timezone: this.timezone?.value,
          noImages: this.formData?.controls?.noImages?.value,
          languageSpecificImagesRequired: this.formData?.controls?.languageSpecificImagesRequired?.value,
          googleBusiness: this.formData?.controls?.googleBusiness?.value,
          appleBusiness: this.formData?.controls?.appleBusiness?.value,
          tagIds: this.marketingCampaignTagValues.map((tag) => tag.value),
          channels: this.campaignChannelValues.map((channel) => channel.value),
        };

        this.saving = true;
        try {
          if (submit === true) {
            this.formData.markAllAsTouched();
            this.validateControl('headline');
            this.validateControl('description');
            this.validateControl('twitterDesc');
            this.validateControl('instagramDesc');
            this.validateControl('facebookDesc');
            this.checkLanguageErrors();
            this.attemptedSubmit = true;
          }
          let savedCampaign;
          if (this.campaign?.marketingCampaignId) {
            await this.updateMarketingCampaign({ body: payload, campaignId: this.campaign?.marketingCampaignId });
            if (submit === true) {
              await this.saveSystemEvent({
                systemEventTypeId: SystemEventType.Submitted,
                userId: this.user?.userId,
                communicationTypeId: 1,
                communicationId: this.campaign?.marketingCampaignId,
              });
            }
          } else {
            savedCampaign = await this.saveMarketingCampaign(payload);
            if (submit === true) {
              await this.saveSystemEvent({
                systemEventTypeId: SystemEventType.Submitted,
                userId: this.user?.userId,
                communicationTypeId: 1,
                communicationId: savedCampaign?.marketingCampaignId,
              });
            }
            this.status = 'Edit';
          }
          this.refreshCalendar();
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: `Marketing Campaign ${submit === true ? 'submitted' : 'saved'} successfully!`,
            type: 'success',
            duration: 5000,
          });
          if (this.fromCalendar && !(submit === true)) {
            this.hideUpdateDrafts();
            setTimeout(() => {
              this.showUpdateDrafts();
            }, 5000);
          }
          this.changesMade = false;
          await this.fetchMarketingCampaigns();
          if (submit === true) {
            this.closeModal();
            this.clearMetadata();
            this.updateClosingModal(false);
          } else {
            this.campaign = savedCampaign;
          }
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: `Unable to ${submit === true ? 'submit' : 'save'} Marketing Campaign!`,
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'ERROR',
          text: `Brand is required!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    getLanguageId(isoCode: string): number {
      let id = 0;
      this.languages.forEach((language: ILanguage) => {
        if (language?.isoCode === isoCode) {
          id = language?.languageId;
        }
      });
      return id;
    },
    async updatePrompt(): Promise<void> {
      this.formData.markAllAsTouched();
      this.validateControl('headline');
      this.validateControl('description');
      this.validateControl('twitterDesc');
      this.validateControl('instagramDesc');
      this.validateControl('facebookDesc');
      this.checkLanguageErrors();
      this.attemptedSubmit = true;
      let languageErrors = false;
      this.languages?.forEach((language: any) => {
        if (language?.hasErrors) {
          languageErrors = true;
        }
      });
      let requiredImagesValid = this.validateRequiredImages();
      if (!this.formData.hasErrors && !languageErrors && requiredImagesValid) {
        this.openPrompt('update');
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Marketing Campaign form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      const publishByDate = this.formData?.controls?.publishDate?.value ?? '';
      const fromDate = this.formData?.controls?.startDate?.value ?? '';
      const toDate = this.formData?.controls?.endDate?.value ?? '';

      let { notes, headline, longDescription, facebookDesc, twitterDesc, instagramDesc } = this.extractMultiLangFields();

      if (this.formData?.controls?.sameDescription?.value && this.formData?.controls?.socialContacts) {
        instagramDesc = longDescription;
        facebookDesc = longDescription;
        twitterDesc = longDescription;
      }

      const payload = {
        marketingCampaignTypeId: this.formData?.controls?.campaignType?.value,
        description: this.formData?.controls?.campaignName?.value,
        facebookDescription: facebookDesc,
        twitterDescription: twitterDesc,
        instagramDescription: instagramDesc,
        notes: notes,
        companyId: this.formData?.controls?.company?.value,
        brandId: this.formData?.controls?.brand?.value,
        createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value ?? '' : this.user?.userId ?? '',
        headline: headline,
        longDescription: longDescription,
        publishByDate: publishByDate,
        fromDate: fromDate,
        toDate: toDate,
        sameDescription: this.formData?.controls?.sameDescription?.value,
        // sendToSocialContacts: this.formData?.controls?.socialContacts?.value,
        sendToStoreContacts: this.formData?.controls?.storeContacts?.value,
        statusId: this.campaign?.statusId,
        storeIds: this.selectedLocationIds,
        images: !this.formData?.controls?.noImages?.value ? this.formData?.controls?.images?.value : null,
        attachments: this.formData?.controls?.attachments?.value,
        hasPrimaryImageChange: this.hasPrimaryImageChange,
        hasSecondaryImageChange: this.hasSecondaryImageChange,
        hasAttachmentChange: this.hasAttachmentChange,
        backgroundBlurry: this.backgroundBlurry,
        timezone: this.timezone?.value,
        noImages: this.formData?.controls?.noImages?.value,
        languageSpecificImagesRequired: this.formData?.controls?.languageSpecificImagesRequired?.value,
        googleBusiness: this.formData?.controls?.googleBusiness?.value,
        appleBusiness: this.formData?.controls?.appleBusiness?.value,
        tagIds: this.marketingCampaignTagValues.map((tag) => tag.value),
        channels: this.campaignChannelValues.map((channel) => channel.value),
      };
      this.saving = true;
      try {
        await this.updateMarketingCampaign({ body: payload, campaignId: this.campaign?.marketingCampaignId });
        if (this.campaign?.statusId !== Status.Draft) {
          await this.saveSystemEvent({
            systemEventTypeId: SystemEventType.Updated,
            userId: this.user?.userId,
            communicationTypeId: 1,
            communicationId: this.campaign?.marketingCampaignId,
          });
        }
        this.saving = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Marketing Campaign updated successfully!`,
          type: 'success',
          duration: 5000,
        });
        await this.refreshAllCommunications();
        this.changesMade = false;
        await this.fetchMarketingCampaigns();
        this.closePrompt();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      } catch (err) {
        this.saving = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to update Marketing Campaign!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    async submitPrompt(): Promise<void> {
      console.log('IN SUBMIT PROMPT');
      this.formData.markAllAsTouched();
      console.log('markAllAsTouched');
      this.validateControl('headline');
      this.validateControl('description');
      this.validateControl('twitterDesc');
      this.validateControl('instagramDesc');
      this.validateControl('facebookDesc');
      console.log('made it past validateControl');
      this.checkLanguageErrors();
      console.log('made it past checkLanguageErrors');
      this.attemptedSubmit = true;
      let languageErrors = false;
      this.languages?.forEach((language: any) => {
        if (language?.hasErrors) {
          languageErrors = true;
        }
      });
      console.log('made it past LFE');
      let requiredImagesValid = this.validateRequiredImages();
      console.log('made it past validateRequiredImages');
      if (!this.formData.hasErrors && !languageErrors && requiredImagesValid) {
        console.log('made it past if statement');
        this.openPrompt('submit');
      } else {
        console.log('made it past else statement');
        if (requiredImagesValid) {
          this.$notify({
            title: 'INVALID FORM',
            text: 'Marketing Campaign form is incomplete or invalid!',
            type: 'warn',
            duration: 11000,
          });
        } else {
          this.$notify({
            title: 'INVALID FORM',
            text: 'Marketing Campaign form is incomplete or invalid, please check all required image sizes for all required languages!',
            type: 'warn',
            duration: 11000,
          });
        }
      }
    },
    async duplicate(): Promise<void> {
      this.duplicating = true;
      const publishByDate = null;
      const fromDate = null;
      const toDate = null;

      let { notes, headline, longDescription, facebookDesc, twitterDesc, instagramDesc } = this.extractMultiLangFields();

      const payload = {
        marketingCampaignTypeId: this.formData?.controls?.campaignType?.value,
        description: this.formData?.controls?.campaignName?.value,
        facebookDescription: facebookDesc,
        twitterDescription: twitterDesc,
        instagramDescription: instagramDesc,
        notes: notes,
        companyId: this.formData?.controls?.company?.value,
        brandId: this.formData?.controls?.brand?.value,
        createdByUserId: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '',
        headline: headline,
        longDescription: longDescription,
        publishByDate: publishByDate,
        fromDate: fromDate,
        toDate: toDate,
        sameDescription: this.formData?.controls?.sameDescription?.value,
        // sendToSocialContacts: this.formData?.controls?.socialContacts?.value,
        sendToStoreContacts: this.formData?.controls?.storeContacts?.value,
        statusId: Status.Draft,
        storeIds: this.selectedLocationIds,
        images: !this.formData?.controls?.noImages?.value ? this.formData?.controls?.images?.value : null,
        attachments: this.formData?.controls?.attachments?.value,
        backgroundBlurry: this.backgroundBlurry ?? true,
        timezone: this.timezone?.value,
        noImages: this.formData?.controls?.noImages?.value,
        languageSpecificImagesRequired: this.formData?.controls?.languageSpecificImagesRequired?.value,
        googleBusiness: this.formData?.controls?.googleBusiness?.value,
        appleBusiness: this.formData?.controls?.appleBusiness?.value,
        tagIds: this.marketingCampaignTagValues.map((tag) => tag.value),
        channels: this.campaignChannelValues.map((channel) => channel.value),
      };
      try {
        const response = await this.saveMarketingCampaign(payload);
        this.duplicating = false;
        this.$notify({
          title: 'SUCCESS',
          text: `Marketing Campaign duplicated successfully!`,
          type: 'success',
          duration: 5000,
        });
        await this.refreshAllCommunications();
        this.changesMade = false;
        await this.fetchMarketingCampaigns();
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
        setTimeout(() => {
          this.updateModalComponent('marketing-campaign');
          this.updateModalType('large');
          this.updateModalData({ campaignId: response?.marketingCampaignId, row: response, mode: 'edit' });
          this.openModal();
        }, 500);
      } catch (err) {
        this.duplicating = false;
        this.$notify({
          title: 'ERROR',
          text: `Unable to duplicate Marketing Campaign!`,
          type: 'error',
          duration: 5000,
        });
      }
    },
    async requestApproval(): Promise<void> {
      console.log('IN REQUEST APPROVAL');
      this.formData.markAllAsTouched();
      this.validateControl('headline');
      this.validateControl('description');
      this.validateControl('twitterDesc');
      this.validateControl('instagramDesc');
      this.validateControl('facebookDesc');
      this.checkLanguageErrors();
      this.attemptedSubmit = true;
      let languageErrors = false;
      this.languages?.forEach((language: any) => {
        if (language?.hasErrors) {
          languageErrors = true;
        }
      });
      let requiredImagesValid = this.validateRequiredImages();
      if (!this.formData.hasErrors && !languageErrors && requiredImagesValid) {
        let upcoming = false;

        const publishByDate = this.formData?.controls?.publishDate?.value ?? '';

        const fromDate = this.formData?.controls?.startDate?.value ?? '';
        if (this.formData?.controls?.startDate?.value) {
          let fd = new Date(this.formData?.controls?.startDate?.value);
          upcoming = new Date().setHours(23, 59, 59, 999) < fd.setHours(0, 0, 0, 0);
        }

        const toDate = this.formData?.controls?.endDate?.value ?? '';

        let { notes, headline, longDescription, facebookDesc, twitterDesc, instagramDesc } = this.extractMultiLangFields();

        if (this.formData?.controls?.sameDescription?.value && this.formData?.controls?.socialContacts) {
          instagramDesc = longDescription;
          facebookDesc = longDescription;
          twitterDesc = longDescription;
        }
        const payload = {
          marketingCampaignTypeId: this.formData?.controls?.campaignType?.value,
          description: this.formData?.controls?.campaignName?.value,
          facebookDescription: facebookDesc,
          twitterDescription: twitterDesc,
          instagramDescription: instagramDesc,
          notes: notes,
          companyId: this.formData?.controls?.company?.value,
          brandId: this.formData?.controls?.brand?.value,
          createdByUserId: '', // no createdByUserId instead submittedBy is used
          headline: headline,
          longDescription: longDescription,
          publishByDate: publishByDate,
          fromDate: fromDate,
          toDate: toDate,
          sameDescription: this.formData?.controls?.sameDescription?.value,
          // sendToSocialContacts: this.formData?.controls?.socialContacts?.value,
          sendToStoreContacts: this.formData?.controls?.storeContacts?.value,
          statusId: Status.Draft,
          storeIds: this.selectedLocationIds,
          images: !this.formData?.controls?.noImages?.value ? this.formData?.controls?.images?.value : null,
          attachments: this.formData?.controls?.attachments?.value,
          hasPrimaryImageChange: this.hasPrimaryImageChange,
          hasSecondaryImageChange: this.hasSecondaryImageChange,
          hasAttachmentChange: this.hasAttachmentChange,
          backgroundBlurry: this.backgroundBlurry,
          timezone: this.timezone?.value,
          noImages: this.formData?.controls?.noImages?.value,
          languageSpecificImagesRequired: this.formData?.controls?.languageSpecificImagesRequired?.value,
          googleBusiness: this.formData?.controls?.googleBusiness?.value,
          appleBusiness: this.formData?.controls?.appleBusiness?.value,
          submittedBy: this.permissions?.superAdminAccess ? this.formData?.controls?.user?.value : this.user?.userId ?? '',
          approvalRequested: 1,
          tagIds: this.marketingCampaignTagValues.map((tag) => tag.value),
          channels: this.campaignChannelValues.map((channel) => channel.value),
        };

        this.saving = true;
        try {
          if (this.campaign?.marketingCampaignId) {
            await this.updateMarketingCampaign({ body: payload, campaignId: this.campaign?.marketingCampaignId });
            await this.saveSystemEvent({
              systemEventTypeId: SystemEventType.RequestedApproval,
              userId: this.user?.userId,
              communicationTypeId: 1,
              communicationId: this.campaign?.marketingCampaignId,
            });
          } else {
            const campaign = await this.saveMarketingCampaign(payload);
            await this.saveSystemEvent({
              systemEventTypeId: SystemEventType.RequestedApproval,
              userId: this.user?.userId,
              communicationTypeId: 1,
              communicationId: campaign?.marketingCampaignId,
            });
            this.status = 'Edit';
          }
          this.refreshCalendar();
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: `Marketing Campaign saved successfully!`,
            type: 'success',
            duration: 5000,
          });
          if (this.fromCalendar) {
            this.hideUpdateDrafts();
            setTimeout(() => {
              this.showUpdateDrafts();
            }, 5000);
          }
          this.changesMade = false;
          await this.fetchMarketingCampaigns();
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: `Unable to save Marketing Campaign!`,
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        if (requiredImagesValid) {
          this.$notify({
            title: 'INVALID FORM',
            text: 'Marketing Campaign form is incomplete or invalid!',
            type: 'warn',
            duration: 11000,
          });
        } else {
          this.$notify({
            title: 'INVALID FORM',
            text: 'Marketing Campaign form is incomplete or invalid, please check all required image sizes for all required languages!',
            type: 'warn',
            duration: 11000,
          });
        }
      }
    },
    preview(): void {
      this.previewOpen = !this.previewOpen;
    },
    expireReason() {
      switch (this.selectedReason) {
        case 'Knowledge/Training Issue':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
        case 'PR Opportunities':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
        case 'Other':
          this.reasonMessage = 'Please help us understand by providing more details.';
          break;
      }
    },
    async resizeImages(): Promise<void> {
      if (this.languageSpecificImagesRequired) {
        for (let language of this.languages) {
          await this.generateImagesHelper([], [language.languageId], '1');
          if (this.uploadedSecondaryImages.length > 0) {
            await this.generateImagesHelper([], [language.languageId], '2');
          }
        }
      } else {
        await this.generateImagesHelper(
          [],
          this.languages.map((el: ILanguage) => el.languageId),
          '1',
        );
        if (this.uploadedSecondaryImages.length > 0) {
          await this.generateImagesHelper(
            [],
            this.languages.map((el: ILanguage) => el.languageId),
            '2',
          );
        }
      }

      this.imageResizeRequired = false;
    },
    isMallAdmin(): boolean {
      return this.user.role?.roleId === Role.MallAdmin;
    },
    // todo probably should be done in the backend but isnt working for some reason?
    systemEventsSorted(): any[] {
      return this.campaign?.systemEvents?.sort((a: any, b: any) => {
        return a.systemEventId - b.systemEventId;
      });
    },
    updateIsDate(update: any): boolean {
      return update.field === 'fromDate' || update.field === 'toDate' || update.field === 'publishByDate';
    },
    extractMultiLangFields(): {
      notes: string;
      headline: string;
      longDescription: string;
      twitterDesc: string;
      instagramDesc: string;
      facebookDesc: string;
    } {
      let notes = '';
      if (this.formData?.controls?.notes?.value) {
        let items = [...this.formData?.controls?.notes?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          if (this.languages.some((lang: any) => lang.isoCode === el.language)) {
            notes += `${el.language}=${el?.value}::: `;
          }
        });
        notes = notes.slice(0, notes.length - 4);
      }

      let headline = '';
      if (this.formData?.controls?.headline?.value) {
        let items = [...this.formData?.controls?.headline?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          if (this.languages.some((lang: any) => lang.isoCode === el.language)) {
            headline += `${el.language}=${el.value}::: `;
          }
        });
        headline = headline.slice(0, headline.length - 4);
      }

      let longDescription = '';
      if (this.formData?.controls?.description?.value) {
        let items = [...this.formData?.controls?.description?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          if (this.languages.some((lang: any) => lang.isoCode === el.language)) {
            longDescription += `${el.language}=${el.value}::: `;
          }
        });
        longDescription = longDescription.slice(0, longDescription.length - 4);
      }

      let facebookDesc = '';
      if (this.formData?.controls?.facebookDesc?.value) {
        let items = [...this.formData?.controls?.facebookDesc?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          if (this.languages.some((lang: any) => lang.isoCode === el.language)) {
            facebookDesc += `${el.language}=${el.value}::: `;
          }
        });
        facebookDesc = facebookDesc.slice(0, facebookDesc.length - 4);
      }

      let twitterDesc = '';
      if (this.formData?.controls?.twitterDesc?.value) {
        let items = [...this.formData?.controls?.twitterDesc?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          if (this.languages.some((lang: any) => lang.isoCode === el.language)) {
            twitterDesc += `${el.language}=${el.value}::: `;
          }
        });
        twitterDesc = twitterDesc.slice(0, twitterDesc.length - 4);
      }

      let instagramDesc = '';
      if (this.formData?.controls?.instagramDesc?.value) {
        let items = [...this.formData?.controls?.instagramDesc?.value];
        items = items?.sort((a: any, b: any) => (a.language > b.language ? 1 : b.language > a.language ? -1 : 0));
        items?.forEach((el: any) => {
          if (this.languages.some((lang: any) => lang.isoCode === el.language)) {
            instagramDesc += `${el.language}=${el.value}::: `;
          }
        });
        instagramDesc = instagramDesc.slice(0, instagramDesc.length - 4);
      }

      return {
        notes,
        headline,
        longDescription,
        twitterDesc,
        instagramDesc,
        facebookDesc,
      };
    },
    campaignChannelsUpdated(value: any): void {
      this.campaignChannelValues = value;
      this.channelsTouched = true;
      this.setChangesMade();
    },
  },
});
