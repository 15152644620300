
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import FileUploader from '../../molecules/File-Uploader/File-Uploader.vue';
import Notification from '../../molecules/Notification/Notification.vue';
import RecommendedImages from '../../molecules/Recommended-Images/Recommended-Images.vue';
import Jimp from 'jimp';
import { mapActions, mapGetters } from 'vuex';
import Divider from '../../atoms/Divider/Divider.vue';

export default defineComponent({
  emits: {
    fileChange: (file: Blob[]) => file,
    masterFileChange: ({ file, type }: { file: Blob; type: string }) => ({
      file,
      type,
    }),
    singlePrimaryFileChange: (file: Blob) => file,
    singleSecondaryFileChange: (file: Blob) => file,
    missingRequirements: () => true,
    deletedImage: (id: string) => id,
  },

  components: {
    'arc-notification': Notification,
    'arc-file-uploader': FileUploader,
    'arc-recommended-images': RecommendedImages,
    'arc-divider': Divider,
  },

  data(): {
    selectedImageSize: {
      height: number;
      width: number;
    };
    overlayActive: any;
  } {
    return {
      selectedImageSize: {
        height: 0,
        width: 0,
      },
      overlayActive: [],
    };
  },

  props: {
    id: {
      type: String,
      default: 'upload',
    },
    uploadPercentage: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    multiple: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    imageSizeRequirements: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    disablePercentage: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    status: {},
    images: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
    requiredImageSizes: {
      type: Array,
    },
    attemptedSubmit: {
      type: Boolean,
      default: false,
    },
    marketingCampaignsTooltip: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    attemptedSubmit: {
      handler() {
        const GI = this.generatedImages.filter(
          (i: any) => i.url === 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/Photo-required.png',
        );
        if (GI?.length > 0) {
          this.$emit('missingRequirements');
        }
      },
    },
    uploadedImages: {
      handler() {
        this.overlayActive = [];
        const ui: any = this.uploadedImages;
        ui.forEach(() => {
          this.overlayActive.push(false);
        });
      },
    },
  },

  computed: {
    ...mapGetters(['selectedImageHeight', 'selectedImageWidth', 'currentImageType']),
    previewContainerStyle(): string {
      let images: any = this.images;
      if (images && images.length <= 1) {
        return 'display: flex; justify-content: center;';
      }
      return '';
    },
    imageContainerStyle(): string {
      if (!this.imageSizeRequirements) {
        return 'cursor: default;';
      }
      return '';
    },
    uploadedImages(): any[] {
      let images: any[] = this.images as any[];
      return images?.filter((img: any) => (img?.generated === 0 || (img?.generated === undefined && img?.url !== '')) && img?.oversized !== 1);
    },
    generatedImages(): any[] {
      return this.addMissingRequirements();
    },
  },

  methods: {
    ...mapActions(['updateSelectedImageHeight', 'updateSelectedImageWidth', 'updateCurrentImageType']),
    deleteImage(image: any) {
      this.$emit('deletedImage', image);
    },
    generatedImageStyle(url: string): string {
      if (this.attemptedSubmit === true && url === 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/Photo-required.png') {
        return 'border: 4px solid red; border-radius: 10px';
      }
      return '';
    },
    generatedTextStyle(url: string): string {
      if (this.attemptedSubmit === true && url === 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/Photo-required.png') {
        return 'color: red;';
      }
      return '';
    },
    addMissingRequirements() {
      let images: any[] = this.images as any[];
      let imageSizeRequirements: any[] = this.requiredImageSizes as any[];
      let filteredImages: any[] = images?.filter((img: any) => img?.generated === 1);
      if (this.required && images?.length > 0) {
        imageSizeRequirements?.forEach((size: any) => {
          let requirementMet = false;
          filteredImages?.forEach((image: any) => {
            if (size?.height === image?.height && size?.width === image?.width) {
              requirementMet = true;
            }
          });
          if (!requirementMet) {
            filteredImages.push({
              height: size.height,
              width: size.width,
              url: 'https://data-engagementagents-dev.s3.ca-central-1.amazonaws.com/Photo-required.png',
              aspectRatio: this.getAspectRatio(size.width, size.height),
            });
          }
          requirementMet = false;
        });
      }
      return filteredImages;
    },

    getAspectRatio(a: number, b: number): string {
      let ratio = '';
      let firstStep: string;
      if (a > b) {
        firstStep = a / b + '';
        if (firstStep.includes('.')) {
          ratio = firstStep.slice(0, firstStep.indexOf('.') + 2) + ':' + b / b;
        } else {
          ratio = a / this.gcd(a, b) + ':' + b / this.gcd(a, b);
        }
      }
      if (a < b) {
        firstStep = b / a + '';
        if (firstStep.includes('.')) {
          ratio = a / a + ':' + firstStep.slice(0, firstStep.indexOf('.') + 2);
        } else {
          ratio = a / this.gcd(b, a) + ':' + b / this.gcd(b, a);
        }
      }
      return ratio;
    },

    gcd(a: number, b: number): number {
      return b === 0 ? a : this.gcd(b, a % b);
    },

    setImageSize(image: any): void {
      this.selectedImageSize = { height: image?.height, width: image?.width };

      this.updateSelectedImageHeight(this.selectedImageSize?.height);
      this.updateSelectedImageWidth(this.selectedImageSize?.width);
      if (image?.marketingCampaignImageTypeId) {
        this.updateCurrentImageType(image?.marketingCampaignImageTypeId === 1 || image?.marketingCampaignImageTypeId === 0 ? 'primary' : 'secondary');
      }

      let fileUploader =
        this.currentImageType === 'primary'
          ? document.getElementById('primary-image-SingleImageUploader')
          : document.getElementById('secondary-image-SingleImageUploader');

      if (fileUploader) {
        fileUploader.click();
      }

      return;
    },
    async changeImage(files: Blob[]): Promise<void> {
      const file = files[0];
      const fileReader: FileReader = new FileReader();

      fileReader.onloadend = async (event): Promise<any> => {
        const image: Jimp = await Jimp.read(event?.target?.result as string);

        const { height, width } = image.bitmap;

        if (height === this.selectedImageHeight && width === this.selectedImageWidth) {
          return this.$emit('fileChange', files);
          // return this.currentImageType === 'primary'
          //   ? this.$emit('singlePrimaryFileChange', file)
          //   : this.$emit('singleSecondaryFileChange', file);
        }

        this.$notify({
          title: 'INVALID SIZE',
          text: `The image must be the exact size of ${this.selectedImageWidth}w x ${this.selectedImageHeight}h`,
          type: 'error',
          duration: 7500,
        });
      };

      fileReader.readAsDataURL(file);
    },
  },
});
