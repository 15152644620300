
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    position: {
      type: String,
      default: 'above',
    },
    tooltipText: {
      type: String,
      required: true,
    },
    tooltipSecondaryText: {
      type: String,
    },
    tooltipStyles: {
      type: String,
      default: '',
    },
  },
});
