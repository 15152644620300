/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { TagState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { ITag } from '../../../../interfaces/src/v2/index';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';
import { AnyARecord } from 'dns';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const TagModule = {
  state(): TagState {
    return {
      tags: [],
      loadingTags: false,
    };
  },

  mutations: {
    clearTags(state: TagState): void {
      state.tags = [];
    },
    updateTags(state: TagState, payload: ITag[]): void {
      state.tags = payload;
    },
    updateLoadingTags(state: TagState, payload: boolean): void {
      state.loadingTags = payload;
    },
  },

  actions: {
    clearTags(context: ActionContext<TagState, State>): void {
      context.commit('clearTags');
    },

    async fetchTag(context: ActionContext<TagState, State>, payload: { tagId: string }): Promise<any> {
      const records: AxiosResponse<Response> = await new HttpService().getTagById(payload.tagId);

      const tag = records.data.data;

      return tag;
    },

    async fetchTags(context: ActionContext<TagState, State>): Promise<any> {
      context.commit('updateLoadingTags', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getTags(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey === 'id' ? 'tagId' : payload?.sortKey,
          payload?.sortDirection,
        );
        records.data.data.forEach((tag: ITag) => {
          tag.companyDesc = tag.company?.description;
          tag.brandDesc = tag.brand?.description;
        });

        context.commit('updateTags', records.data.data);
        context.commit('updateLoadingTags', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingTags', false);
      }
    },

    saveTag(context: ActionContext<TagState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveTag(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchTags');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    updateTag(context: ActionContext<TagState, State>, payload: { payload: any; tagId: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateTag(payload.payload, payload.tagId)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchTags');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    deleteTag(context: ActionContext<TagState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .deleteTag(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchTags');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },
  },

  getters: {
    tags(state: TagState): ITag[] {
      return state.tags;
    },
    loadingTags(state: TagState): boolean {
      return state.loadingTags;
    },
  },
};
