
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  props: {
    route: {
      type: String,
    },
    baseRoute: {
      type: String,
    },
    sidebarOpen: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    badgeCount: {
      type: String,
      required: false,
    },
  },

  computed: {
    ...mapGetters(['currentActiveRoute']),
    activeTab(): {
      'tab--active': boolean;
    } {
      return {
        'tab--active': this.baseRoute ? this.currentActiveRoute.startsWith(this.baseRoute) : this.currentActiveRoute.startsWith(this.route),
      };
    },
    activeIcon(): {
      'tab__icon--active': boolean;
    } {
      return {
        'tab__icon--active': this.baseRoute ? this.currentActiveRoute.startsWith(this.baseRoute) : this.currentActiveRoute.startsWith(this.route),
      };
    },
    activeText(): {
      'tab__text--active': boolean;
    } {
      return {
        'tab__text--active': this.baseRoute ? this.currentActiveRoute.startsWith(this.baseRoute) : this.currentActiveRoute.startsWith(this.route),
      };
    },
  },

  methods: {
    clicked(): void {
      if (this.route) {
        this.$router.push(this.route);
      } else {
        this.$emit('tabClicked');
      }
    },
  },
});
